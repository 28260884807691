import { find } from 'lodash';

export const MESSAGE_DATA_TYPES = {
  PERMISSION_REQUESTED: 'pushNotificationPermissionRequest',
  PERMISSION_ACCEPTED: 'pushNotificationPermissionAccepted',
  PERMISSION_DENIED: 'pushNotificationPermissionDenied',
};

export function userHasPushSubscriptions(user) {
  const userHasSubscriptions =
    !!find(user?.pushSubscriptions, { deviceType: 'ios' }) ||
    !!find(user?.pushSubscriptions, { deviceType: 'android' });
  return userHasSubscriptions;
}
