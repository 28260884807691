import React from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button, LoadingSpinner } from '@producepay/pp-ui';
import { ArrowLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { useResponsiveLayout } from 'helpers/dom';

import SupplyBreakdownSection from './SupplyBreakdownSection';
import PerformanceSection from './PerformanceSection';
import SummarySection from './SummarySection';
import InfoSection from './InfoSection';
import SiteVisitSection from './SiteVisitSection';

import { MOBILE_NAVBAR_HEIGHT } from 'styles/constants';

function Divider() {
  return <div className="my-2 border-b-2" />;
}

function ProfileModalView({
  isMarketRequestLoading,
  load,
  onClose,
  onCloseRoute,
  isSupply,
  onSubmitMarketRequestInterest,
}) {
  const { t } = useTranslation(['profileModal']);
  const { isMobile } = useResponsiveLayout();

  const modalClassName = 'lg:max-w-full md:max-w-full max-w-full sm:h-full h-full pt-20 bg-black px-4 md:px-8';
  const ctaAction = () => onSubmitMarketRequestInterest();

  const companyProfile = load?.companyProfile;
  const companyInfoVisible = companyProfile?.description || companyProfile?.certificates;
  const companySellerScore = companyProfile?.sellerScore;
  const companySiteInfoVisible = companyProfile?.lastSiteVisitDate || companyProfile?.siteVisitPhotoUrls?.length > 0;

  const DesktopDisplay = () => {
    return (
      <div className="lg:w-4/5 m-auto" style={{ maxWidth: '1200px' }}>
        <div className="grid grid-cols-2">
          <Link className="flex items-center" to={onCloseRoute}>
            <ArrowLeft />
            <span className="ml-2 text-2xl font-semibold">{isSupply ? t('returnVolume') : t('returnDemand')}</span>
          </Link>
          <div className="text-right">
            <Button
              className="font-medium"
              borderRadius=""
              label={isSupply ? t('chatSeller') : t('chatBuyer')}
              onClick={ctaAction}
              data-testid="btn-profile-cta"
              color="primary"
            />
          </div>
        </div>
        <SupplyBreakdownSection load={load} />
        {companyProfile && (
          <>
            <div className="flex justify-between">
              <div className="flex-grow">
                <SummarySection load={load} />
                {companyInfoVisible && <InfoSection companyProfile={companyProfile} />}
              </div>
              {companySellerScore && (
                <div className="ml-10">
                  <PerformanceSection companySellerScore={companySellerScore} />
                </div>
              )}
            </div>
            {companySiteInfoVisible && <SiteVisitSection companyProfile={companyProfile} />}
          </>
        )}
      </div>
    );
  };

  const MobileDisplay = () => {
    return (
      <div>
        <Link className="flex items-center py-4" to={onCloseRoute}>
          <ArrowLeft />
          <span className="ml-2 text-xl font-bold">{isSupply ? t('returnVolume') : t('returnDemand')}</span>
        </Link>
        <Divider />
        <SupplyBreakdownSection load={load} isMobile />
        {companyProfile && (
          <>
            <Divider />
            <SummarySection load={load} isMobile />
            {companySellerScore && (
              <>
                <Divider />
                <div className="py-4">
                  <PerformanceSection companySellerScore={companySellerScore} isMobile />
                </div>
              </>
            )}
            {(companyInfoVisible || companySiteInfoVisible) && (
              <>
                <Divider />
                {companyInfoVisible && <InfoSection companyProfile={companyProfile} isMobile />}
                {companySiteInfoVisible && <SiteVisitSection companyProfile={companyProfile} isMobile />}
              </>
            )}
          </>
        )}
        <div style={{ bottom: MOBILE_NAVBAR_HEIGHT }} className="text-center border-t p-4 bg-white">
          <Button
            className="font-medium"
            borderRadius=""
            label={isSupply ? t('chatSeller') : t('chatBuyer')}
            onClick={ctaAction}
            data-testid="btn-profile-cta"
            color="primary"
          />
        </div>
      </div>
    );
  };

  return (
    <Modal className={modalClassName} onClose={onClose} style={{ maxHeight: '100%' }}>
      {isMarketRequestLoading ? <LoadingSpinner /> : isMobile ? <MobileDisplay /> : <DesktopDisplay />}
    </Modal>
  );
}

export default ProfileModalView;
