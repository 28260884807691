import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import qs from 'qs';

import routes from '../../routes';
import { SHIPPING_POINT_SLUG } from '../../helpers/reportTypes';
import { useAuth } from '../../contexts/auth';

import BuildingPriceReportView from './view';

const USER_INTERVAL_FETCH_MS = 2000;
const USER_CREATION_TIMEOUT_MS = 15000;

function BuildingPriceReport() {
  const { unauthenticatedUser, user, register, refetchCurrentUser } = useAuth();
  const unauthenticatedEmail = unauthenticatedUser?.email;

  useEffect(() => {
    const interval = setInterval(() => refetchCurrentUser(), USER_INTERVAL_FETCH_MS);

    return () => clearInterval(interval);
  }, [refetchCurrentUser]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (unauthenticatedEmail && register) {
        register({ email: unauthenticatedEmail, ref: 'fb-ad-frontend-fallback' });
      }
    }, USER_CREATION_TIMEOUT_MS);

    return () => clearTimeout(timeout);
  }, [register, unauthenticatedEmail]);

  if (user) {
    const pathname = routes.marketInsightsPriceReport(SHIPPING_POINT_SLUG);
    const queryParmas = qs.stringify({ from_building_price_report: 't' });

    return <Redirect to={{ pathname, search: queryParmas }} />;
  }

  return <BuildingPriceReportView email={unauthenticatedEmail} />;
}

export default BuildingPriceReport;
