import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { EMAIL_AUTH_TOKEN_KEY } from '../../../contexts/auth';
import { ApolloClient, gql, InMemoryCache } from '@apollo/client';
import { useEffect, useState } from 'react';
import { trim } from 'lodash';

const APOLLO_CACHE = new InMemoryCache();
const CURRENT_USER = gql`
  query {
    currentUser {
      id
    }
  }
`;

const useEmailAuthToken = () => {
  const { search } = useLocation();
  const emailToken = qs.parse(search, { ignoreQueryPrefix: true })?.emailToken;
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    const token = emailToken || window?.localStorage?.getItem(EMAIL_AUTH_TOKEN_KEY);
    if (!token) {
      setIsDone(true);
      return;
    }
    const client = new ApolloClient({
      cache: APOLLO_CACHE,
      uri: trim(process.env.REACT_APP_DATA_PLATFORM_URL),
      headers: { authorization: `Bearer ${token}` },
    });

    client
      .query({ query: CURRENT_USER, fetchPolicy: 'no-cache' })
      .then(({ data }) => {
        const authSuccess = data?.currentUser?.id;
        if (authSuccess) {
          window?.localStorage?.setItem(EMAIL_AUTH_TOKEN_KEY, token);
        } else {
          window?.localStorage?.removeItem(EMAIL_AUTH_TOKEN_KEY);
        }
      })
      .catch(() => {
        window?.localStorage?.removeItem(EMAIL_AUTH_TOKEN_KEY);
      })
      .finally(() => setIsDone(true));
  }, [emailToken]);

  return { isDone };
};

export default useEmailAuthToken;
