import React from 'react';

const SvgPerson = ({ size = 24, color = 'currentColor', ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 12 12" fill="none" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 6a3 3 0 100-6 3 3 0 100 6zm0 1.5c-2.002 0-6 1.005-6 3V12h12v-1.5c0-1.995-3.998-3-6-3z"
      fill={color}
      fillOpacity={0.7}
    />
  </svg>
);

export default SvgPerson;
