import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import _ from 'lodash';

import { Formik, Form } from 'formik';
import { COUNTRY_ITEMS } from './helpers';

import FormikCheckbox from '../../../../components/formik/FormikCheckbox';
import FormikGeneralErrors from '../../../../components/formik/FormikGeneralErrors';
import FormikSelect from '../../../../components/formik/FormikSelect';
import FormikTextField from '../../../../components/formik/FormikTextField';
import FormikSubmit from '../../../../components/formik/FormikSubmit';
import OnboardingTitle from './OnboardingTitle';

function Step1({ title, onboardingInformation, onSubmit }) {
  const { t } = useTranslation(['onboarding', 'common', 'formErrors']);

  const followupQuestionMap = {
    grower: [
      { name: 'interestedInCashFlow', label: t('interestedInCashFlow') },
      { name: 'interestedInLowerInputs', label: t('interestedInLowerInputs') },
      { name: 'interestedInConnectingWithCustomers', label: t('interestedInConnectingWithCustomers') },
    ],
    distributor: [
      { name: 'interestedInConnectingWithGrowers', label: t('interestedInConnectingWithGrowers') },
      { name: 'interestedInBusinessIntelligence', label: t('interestedInBusinessIntelligence') },
    ],
    grower_distributor: [
      { name: 'interestedInCashFlow', label: t('interestedInCashFlow') },
      { name: 'interestedInLowerInputs', label: t('interestedInLowerInputs') },
      { name: 'interestedInConnectingWithCustomers', label: t('interestedInConnectingWithCustomers') },
      { name: 'interestedInBusinessIntelligence', label: t('interestedInBusinessIntelligence') },
    ],
  };

  const companyTypeItems = [
    { label: t('growerType'), value: 'grower' },
    { label: t('growerDistributorType'), value: 'grower_distributor' },
    { label: t('distributorType'), value: 'distributor' },
    { label: t('retailerType'), value: 'retailer' },
    { label: t('logisticsType'), value: 'logistics' },
    { label: t('otherCompanyType'), value: 'other' },
  ];

  return (
    <Formik
      initialValues={{
        fullName: onboardingInformation?.fullName || '',
        country: onboardingInformation?.country || '',
        companyName: onboardingInformation?.companyName || '',
        companyType: onboardingInformation?.companyType || '',
        interestedInCashFlow: onboardingInformation?.interestedInCashFlow || false,
        interestedInLowerInputs: onboardingInformation?.interestedInLowerInputs || false,
        interestedInConnectingWithCustomers: onboardingInformation?.interestedInConnectingWithCustomers || false,
        interestedInConnectingWithGrowers: onboardingInformation?.interestedInConnectingWithGrowers || false,
        interestedInBusinessIntelligence: onboardingInformation?.interestedInBusinessIntelligence || false,
        otherType: onboardingInformation?.otherType || '',
      }}
      validationSchema={yup.object().shape({
        fullName: yup.string().required('is required'),
        country: yup.string().required('is required'),
        companyName: yup.string().required('is required'),
        companyType: yup.string().required('is required'),
        interestedInCashFlow: yup.bool(),
        interestedInLowerInputs: yup.bool(),
        interestedInConnectingWithCustomers: yup.bool(),
        interestedInConnectingWithGrowers: yup.bool(),
        interestedInBusinessIntelligence: yup.bool(),
        otherType: yup.string().when('companyType', {
          is: (v) => v === 'other',
          then: yup.string().required('This field is required'),
          otherwise: yup.string(),
        }),
      })}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        const followupAttributes = followupQuestionMap[values.companyType]
          ? _.map(followupQuestionMap[values.companyType], 'name')
          : ['otherType'];

        onSubmit(_.pick(values, ['fullName', 'country', 'companyName', 'companyType', ...followupAttributes])).catch(
          () => {
            setSubmitting(false);
            setStatus({ errors: [t('formErrors:generalNetworkError')] });
          },
        );
      }}
    >
      {({ values }) => {
        const companyType = values?.companyType;

        return (
          <Form>
            <OnboardingTitle title={title || t('title')} />

            <FormikGeneralErrors />

            <FormikTextField className="mb-4" name="fullName" />

            <FormikSelect
              className="mb-4"
              selectClassName="rounded"
              name="country"
              items={COUNTRY_ITEMS}
              placeholder={t('common:selectOption')}
            />

            <FormikTextField className="mb-4" name="companyName" />

            <FormikSelect
              className="mb-4"
              selectClassName="rounded"
              name="companyType"
              items={companyTypeItems}
              placeholder={t('common:selectOption')}
            />

            {followupQuestionMap[companyType] ? (
              <div className="my-4">
                <div className="mb-4 text-sm text-black font-bold">{t('interestedIn')}:</div>

                {followupQuestionMap[companyType].map(({ label, name }, idx) => (
                  <FormikCheckbox key={name} className={idx !== 0 && 'mt-4'} name={name} label={label} />
                ))}
              </div>
            ) : companyType === 'other' ? (
              <FormikTextField
                className="mb-4"
                name="otherType"
                label={t('pleaseSpecify')}
                shouldPrependErrors={false}
              />
            ) : null}

            <div className="pt-4 flex justify-center">
              <FormikSubmit className="w-40" label={t('common:next')} />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default React.memo(Step1);
