const HASS_AVOCADO = '63';
const GREEN_SKIN_AVOCADO = '171';
const AVOCADO = '133';
const AVOCADO_SIZES = [
  '24s',
  '26s',
  '28s',
  '30s',
  '32s',
  '36s',
  '40s',
  '44s',
  '48s',
  '50s',
  '54s',
  '56s',
  '60s',
  '70s',
  '84s',
];

const SEEDLESS_TYPE_LIMES = '189';
const LIMES = '1202';
const SWEET_LIMES = '863';
const LIME_SIZES = ['110s', '130s', '135s', '150s', '160s', '175s', '180s', '200s', '205s', '230s', '235s', '250s'];

const BROCCOLI = '497';
const RABE_BROCCOLI = '730';
const CROWN_CUT_BROCCOLI = '1712';
const BROCOLLI_SIZES = ['10s', '12s', '14s', '16s', '18s', '20s', '24s'];

const BLUEBERRIES = '35';
const ROMA_PLUM_TOMATOES = '126';
const STRAWBERRIES = '157';
const BLACKBERRIES = '228';
const BEETS = '1269';
const RASPBERRIES = '591';
const RED_RASPBERRIES = '1701';
const TSHIRT_SIZES = ['S', 'M', 'L', 'XL', 'XXL'];

export const getAvailableSizes = (cviId) => {
  switch (cviId) {
    case AVOCADO:
    case HASS_AVOCADO:
    case GREEN_SKIN_AVOCADO:
      return AVOCADO_SIZES;
    case BROCCOLI:
    case RABE_BROCCOLI:
    case CROWN_CUT_BROCCOLI:
      return BROCOLLI_SIZES;
    case SEEDLESS_TYPE_LIMES:
    case LIMES:
    case SWEET_LIMES:
      return LIME_SIZES;
    case BLUEBERRIES:
    case ROMA_PLUM_TOMATOES:
    case STRAWBERRIES:
    case BEETS:
    case BLACKBERRIES:
    case RASPBERRIES:
    case RED_RASPBERRIES:
      return TSHIRT_SIZES;
    default:
      return null;
  }
};
