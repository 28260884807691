import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { Modal, Button } from '@producepay/pp-ui';
import PhotoCard from './PhotoCard';

import { useMutation, useQuery, gql } from '@apollo/client';
import { directUploadUrl } from 'helpers/urls';

import ProgressBar from '../ProgressBar';
import { UPLOAD_IMAGES_STEP, TOTAL_STEPS } from '../helpers';
import { MARKET_REQUEST_LOADS_FRAGMENT } from 'graphql/fragments/market-requests';
import { DirectUpload } from '@rails/activestorage';

import './upload-images-step.css';

const debug = require('debug')('pp:ui');

const FETCH_MARKET_REQUEST_LOAD = gql`
  ${MARKET_REQUEST_LOADS_FRAGMENT}
  query FetchMarketRequestLoad($id: String!) {
    marketRequestLoad(id: $id) {
      ...marketRequestLoadsFragment
    }
  }
`;

const ADD_IMAGE_TO_LOAD = gql`
  mutation AddImageToLoad($id: String!, $signedId: String!) {
    addImageToLoad(id: $id, signedId: $signedId) {
      id
    }
  }
`;

const DELETE_IMAGE_FROM_LOAD = gql`
  mutation deleteImageFromLoad($id: String!) {
    deleteImageFromLoad(id: $id) {
      id
    }
  }
`;

const MAX_IMAGES = 4;

function UploadImagesStep({ onSubmit, onPrev, isMobile, modalProps, placeholderLoadId }) {
  const { t } = useTranslation('marketplaceListSupplyPhotos');
  const progressPercent = (UPLOAD_IMAGES_STEP / TOTAL_STEPS) * 100;
  const uploadUrl = directUploadUrl();

  const [addImage] = useMutation(ADD_IMAGE_TO_LOAD);
  const [deleteImage] = useMutation(DELETE_IMAGE_FROM_LOAD);
  const [loading, setLoading] = useState(false);

  const { data, refetch } = useQuery(FETCH_MARKET_REQUEST_LOAD, {
    variables: { id: `${placeholderLoadId}` },
  });

  const images = data?.marketRequestLoad?.imageUrls || [];
  const availableImageSpace = MAX_IMAGES - images.length;

  const imagesArray = [...images].concat(Array(availableImageSpace).fill(null));

  const uploadFile = async (selectedFile) => {
    try {
      setLoading(true);
      const upload = new DirectUpload(selectedFile, uploadUrl);

      upload.create(async (error, blob) => {
        if (error) {
          debug(error);
        } else {
          const { signed_id: signedId } = blob;
          await addImage({ variables: { id: `${placeholderLoadId}`, signedId } });
          refetch();
        }
        setLoading(false);
      });
    } catch (err) {
      // setServerError({ message: err.message });
    }
  };

  const handleAttachment = async (e, image) => {
    const { files } = e.target;
    const selectedFile = files && files[0];
    if (selectedFile) {
      if (image) {
        await deleteFile(`${image.id}`);
      }
      await uploadFile(selectedFile);
    }
  };

  const deleteFile = async (imageId) => {
    try {
      setLoading(true);
      await deleteImage({ variables: { id: `${imageId}` } });
      refetch();
    } catch (err) {
      debug(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      className="mt-20"
      title={t('marketplaceListSupplyPhotos:title')}
      headerContent={t('subtitle')}
      footerContent={
        <>
          <ProgressBar progressPercent={progressPercent} />
          <div className="flex w-full p-4 justify-between border-t">
            <Button
              label={t('common:previous')}
              variant="outlined"
              onClick={onPrev}
              className={cx('mr-2', { 'w-full': isMobile })}
              data-testid="btn-prev"
            />
            <Button
              label={t('common:continue')}
              type="submit"
              onClick={onSubmit}
              className={isMobile ? 'w-full' : ''}
              data-testid="btn-submit"
            />
          </div>
        </>
      }
      footerClassNames={{ container: '' }}
      {...modalProps}
      onClose={null}
    >
      <div className="flex flex-row flex-wrap justify-evenly" style={{ minWidth: '338px' }}>
        {imagesArray.map((image, index) => (
          <PhotoCard
            key={image?.id || `index-${index}`}
            image={image}
            loading={loading}
            handleAttachment={handleAttachment}
            deleteFile={deleteFile}
          />
        ))}
      </div>
    </Modal>
  );
}

export default UploadImagesStep;
