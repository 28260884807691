import { oneOf, string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import InChatNotification from '../InChatNotification';
import { PO_ACCEPTED, PO_UPDATED, PO_CREATED } from '../constants.js';
import { signOrder } from 'routes';

const PurchaseOrderNotification = ({ type, supplierCompanyName, orderId }) => {
  const { t } = useTranslation('conversations');

  switch (type) {
    case PO_ACCEPTED:
      return (
        <InChatNotification
          link={{
            message: t('inChatNotifications.reviewPurchaseOrder'),
            to: signOrder(orderId),
          }}
          title={t('inChatNotifications.hasCompletedThePO', { companyName: supplierCompanyName })}
        />
      );
    case PO_UPDATED:
      return (
        <InChatNotification
          link={{
            message: t('inChatNotifications.pleaseReviewChanges'),
            to: signOrder(orderId),
          }}
          title={t('inChatNotifications.hasEditedSignedPO', { companyName: supplierCompanyName })}
        />
      );
    case PO_CREATED:
      return (
        <InChatNotification
          link={{
            message: t('inChatNotifications.reviewPurchaseOrder'),
            to: signOrder(orderId),
          }}
          title={t('inChatNotifications.hasPOReadyForReview', {
            companyName: supplierCompanyName,
          })}
        />
      );
    default:
      return null;
  }
};

PurchaseOrderNotification.propTypes = {
  type: oneOf(['PO_ACCEPTED', 'PO_UPDATED', 'PO_CREATED']),
  supplierCompanyName: string.isRequired,
};

export default PurchaseOrderNotification;
