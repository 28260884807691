import { gql } from '@apollo/client';

import { USER_FRAGMENT } from '../../contexts/auth';

export const FIND_USER = gql`
  query FindUser($email: String!) {
    user(email: $email) {
      id
    }
  }
`;

export const FIND_OR_CREATE_USER = gql`
  ${USER_FRAGMENT}
  mutation FindOrCreateUser($email: String!) {
    registerUser(email: $email) {
      ...currentUserFragment
    }
  }
`;
