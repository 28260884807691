import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import dateFnsFormat from 'date-fns/format';
import { es, enUS } from 'date-fns/locale';
import Cookies from 'js-cookie';

import en from './en.json';
import esTranslations from './es.json';
import { COOKIE_DOMAIN } from '../contexts/auth';

export const SUPPORTED_LOCALES = { es, enUS };
export const LOCALE_KEY = 'i18nextLng';
const INSIGHTS_USER_LOCALE = 'INSIGHTS_USER_LOCALE';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources: { en, es: esTranslations },
    fallbackLng: 'en',
    interpolation: {
      format(value, format, lng) {
        if (value instanceof Date || new Date(value).getTime() > 0) {
          return dateFnsFormat(value, format, { locale: SUPPORTED_LOCALES[lng.substring(0, 2)] });
        }
        return value;
      },
    },
  });

i18n.on('languageChanged', (lng) => {
  window?.localStorage?.setItem(LOCALE_KEY, lng);
  Cookies.set(INSIGHTS_USER_LOCALE, lng, { domain: COOKIE_DOMAIN });
});

export default i18n;
