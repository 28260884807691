import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import { map, orderBy, debounce } from 'lodash';

import { Autocomplete } from '@producepay/pp-ui';

export const COMMODITY_VARIETY_SEARCH_QUERY = gql`
  query CommodityVarietySearch($searchTerm: String, $limit: Int) {
    commodityVarietyInfos(searchTerm: $searchTerm, limit: $limit, dmrOnly: true) {
      id
      name
      commodityName
      varietyName
    }
  }
`;

function CommodityVarietySearch({ searchTerm, onSelected, otherOption, onSearchChange, ...rest }) {
  const [called, setCalled] = useState(false);
  const [items, setItems] = useState([]);

  const [searchCommodityVarieties, { data, loading }] = useLazyQuery(COMMODITY_VARIETY_SEARCH_QUERY, {
    onCompleted: () => {
      setCalled(true);
    },
  });
  const { t } = useTranslation('marketplaceListSupplyDetails');

  useEffect(() => {
    const cvInfoItems = orderBy(
      map(data?.commodityVarietyInfos, (cvInfo) => ({
        label: cvInfo.name,
        value: cvInfo.id,
      })),
      'label',
    );

    if (!loading && called) {
      const newItems = cvInfoItems;

      if (otherOption && searchTerm?.length > 0) {
        newItems.push(otherOption);
      }
      setItems(newItems);
    }
  }, [otherOption, searchTerm, loading, called, data]);

  const handleItemSelected = (item) => {
    const cvis = data?.commodityVarietyInfos || [];
    const found = cvis.find((cvi) => cvi.id === item.value);

    onSelected(found || item);
  };

  const debouncedSearch = useCallback(
    debounce(
      (currentSearchTerm) => searchCommodityVarieties({ variables: { searchTerm: currentSearchTerm, limit: 10 } }),
      500,
    ),
    [searchCommodityVarieties],
  );

  return (
    <Autocomplete
      searchTerm={searchTerm}
      onSearchChange={(value) => {
        setCalled(false);
        onSearchChange(value);
        debouncedSearch(value);
      }}
      items={items || []}
      isLoading={loading}
      onItemSelected={handleItemSelected}
      filterFunc={() => true}
      placeholder={t('searchPlaceholder')}
      {...rest}
    />
  );
}

CommodityVarietySearch.propTypes = {
  onSelected: PropTypes.func,
  onSearchChange: PropTypes.func,
  searchTerm: PropTypes.string,
  otherOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

CommodityVarietySearch.defaultProps = {
  onSelected: () => {},
  onSearchChange: () => {},
  searchTerm: '',
  otherOption: null,
};

export default React.memo(CommodityVarietySearch);
