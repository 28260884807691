import React from 'react';

import { useResponsiveLayout } from '../../../helpers/dom';
import useScrollLock from '../../../hooks/useScrollLock';

import {
  EMAIL_STEP,
  PROFILE_INFO_STEP,
  COMPANY_INFO_STEP,
  COMMODITY_INFO_STEP,
  CREATE_REQUESTS_STEP,
  LOGISTIC_STEP,
  UPLOAD_IMAGES_STEP,
  SUPPLY_LISTING_SUBMITTED_STEP,
} from './helpers';
import { KEYS, trackEvent } from 'helpers/tracking';

import EmailStep from './EmailStep';
import ProfileInfoStep from './ProfileInfoStep';
import CompanyInfoStep from './CompanyInfoStep';
import CommodityInfoStep from './CommodityInfoStep';
import CreateRequestsStep from './CreateRequestsStep';
import LogisticStep from './LogisticStep';
import SupplyListingSubmittedStep from './SupplyListingSubmittedStep';
import UploadImagesStep from './UploadImagesStep';

function SupplyForm({
  initialStep,
  step,
  setCurrentStep,
  user,
  onSubmitEmail,
  load,
  placeholderLoadId,
  setLoad,
  sizes,
  setSizes,
  onClose,
  wasUserInitiallyKnown,
  onSubmitOnboardingInfo,
  onSubmitCompanyInfo,
  onSubmitSupplyListing,
  onSubmitRequests,
  onSubmitLogistic,
  ...rest
}) {
  useScrollLock();

  switch (step) {
    case EMAIL_STEP:
      return <EmailStep user={user} onSubmit={onSubmitEmail} {...rest} />;
    case PROFILE_INFO_STEP:
      return (
        <ProfileInfoStep
          onboardingInformation={user?.onboardingInformation}
          onSubmit={onSubmitOnboardingInfo}
          onPrev={() => setCurrentStep(EMAIL_STEP)}
          {...rest}
        />
      );
    case COMPANY_INFO_STEP:
      return (
        <CompanyInfoStep
          onboardingInformation={user?.onboardingInformation}
          onSubmit={onSubmitCompanyInfo}
          onPrev={() => setCurrentStep(PROFILE_INFO_STEP)}
          {...rest}
        />
      );
    case COMMODITY_INFO_STEP:
      return (
        <CommodityInfoStep
          onboardingInformation={user?.onboardingInformation}
          onSubmit={onSubmitSupplyListing}
          load={load}
          setLoad={setLoad}
          sizes={sizes}
          setSizes={setSizes}
          onPrev={() => setCurrentStep(COMPANY_INFO_STEP)}
          initialStep={initialStep}
          {...rest}
        />
      );
    case CREATE_REQUESTS_STEP:
      return (
        <CreateRequestsStep
          onSubmit={onSubmitRequests}
          load={load}
          sizes={sizes}
          setLoad={setLoad}
          setSizes={setSizes}
          onPrev={() => setCurrentStep(COMMODITY_INFO_STEP)}
          {...rest}
        />
      );
    case UPLOAD_IMAGES_STEP:
      return (
        <UploadImagesStep
          onSubmit={() => setCurrentStep(LOGISTIC_STEP)}
          load={load}
          placeholderLoadId={placeholderLoadId}
          onPrev={() => setCurrentStep(CREATE_REQUESTS_STEP)}
          {...rest}
        />
      );
    case LOGISTIC_STEP:
      return (
        <LogisticStep
          onSubmit={onSubmitLogistic}
          load={load}
          setLoad={setLoad}
          onPrev={() => setCurrentStep(CREATE_REQUESTS_STEP)}
          {...rest}
        />
      );
    case SUPPLY_LISTING_SUBMITTED_STEP:
      return (
        <SupplyListingSubmittedStep
          onPrev={() => {
            trackEvent(KEYS.POST_MORE_SUPPLY);
            setCurrentStep(COMMODITY_INFO_STEP);
          }}
          setLoad={setLoad}
          setSizes={setSizes}
          initialStep={initialStep}
          {...rest}
        />
      );
    default:
      return null;
  }
}

function SupplyListingsModalView({
  user,
  onClose,
  onSubmitEmail,
  initialStep,
  step,
  load,
  placeholderLoadId,
  sizes,
  setLoad,
  setSizes,
  setCurrentStep,
  onSubmitOnboardingInfo,
  onSubmitCompanyInfo,
  onSubmitSupplyListing,
  wasUserInitiallyKnown,
  onSubmitRequests,
  onSubmitLogistic,
}) {
  const { isMobile } = useResponsiveLayout();

  const modalProps = {
    onClose,
    style: { minHeight: isMobile ? '110vh' : '560px' },
    footerClassNames: { container: 'sticky z-20 bg-white bottom-0 w-full' },
  };

  const commonProps = {
    isMobile,
    modalProps,
  };

  return (
    <SupplyForm
      step={step}
      initialStep={initialStep}
      setCurrentStep={setCurrentStep}
      user={user}
      setSizes={setSizes}
      setLoad={setLoad}
      load={load}
      placeholderLoadId={placeholderLoadId}
      sizes={sizes}
      onSubmitEmail={onSubmitEmail}
      onSubmitOnboardingInfo={onSubmitOnboardingInfo}
      onSubmitCompanyInfo={onSubmitCompanyInfo}
      onClose={onClose}
      wasUserInitiallyKnown={wasUserInitiallyKnown}
      onSubmitSupplyListing={onSubmitSupplyListing}
      onSubmitRequests={onSubmitRequests}
      onSubmitLogistic={onSubmitLogistic}
      {...commonProps}
    />
  );
}

export default SupplyListingsModalView;
