import React from 'react';
import cx from 'classnames';
import { ErrorMessage, Field, Formik, Form } from 'formik';
import * as yup from 'yup';
import { useTranslation, Trans } from 'react-i18next';

import { Button, Checkbox, Modal } from '@producepay/pp-ui';

import FormikTextField from 'components/formik/FormikTextField';
import FormikSelect from 'components/formik/FormikSelect';
import FormikGeneralErrors from 'components/formik/FormikGeneralErrors';

import ProgressBar from '../ProgressBar';
import { COMPANY_INFO_STEP, TOTAL_STEPS } from '../helpers';

function CompanyInfoStep({ onboardingInformation, onSubmit, onPrev, modalProps, isMobile }) {
  const { t } = useTranslation('marketplaceListSupply');

  const PROFILE_INFO_VALIDATION_SCHEMA = yup.object().shape({
    companyType: yup.string().required('is required'),
    numBoxesSoldPerWeek: yup.number().required(t('numBoxesInvalid')).typeError(t('numBoxesInvalid')),
    agreeToSupplyTerms: yup.bool().oneOf([true], t('termsCheckRequired')),
    agreeToLegalPermission: yup.bool().oneOf([true], t('legalPermissionCheckRequired')),
  });

  const companyTypeItems = [
    { label: t('growerType'), value: 'grower' },
    { label: t('growerDistributorType'), value: 'grower_distributor' },
    { label: t('distributorType'), value: 'distributor' },
    { label: t('otherCompanyType'), value: 'other_type' },
    // Passing other instead of other_type as the value causes the default to always be other
  ];

  const totalExportedItems = [
    { label: t('lessThan1M'), value: 'Less than $1M' },
    { label: t('1Mto3M'), value: '$1M to $3M' },
    { label: t('3Mto10M'), value: '$3M to $10M' },
    { label: t('10MPlus'), value: '$10M+' },
  ];

  const yearsExportedItems = [
    { label: t('never'), value: 'Never' },
    { label: t('lessThan2Years'), value: '<2 years' },
    { label: t('2To5Years'), value: '2-5 years' },
    { label: t('5PlusYears'), value: '5+ years' },
  ];

  const progressPercent = (COMPANY_INFO_STEP / TOTAL_STEPS) * 100;

  const handleCheckboxClick = (e, field, form) => {
    e.preventDefault();

    const { name } = field;
    const { setFieldTouched, setFieldValue } = form;

    setFieldTouched(name);
    setFieldValue(name, !field.value);
  };

  return (
    <Formik
      initialValues={{
        companyType: onboardingInformation?.companyType,
        totalExportedToUs: onboardingInformation?.totalExportedToUs,
        yearsExportedToUs: onboardingInformation?.yearsExportedToUs,
        numBoxesSoldPerWeek: onboardingInformation?.numBoxesSoldPerWeek,
        agreeToSupplyTerms: onboardingInformation?.agreeToSupplyTerms || false,
        agreeToLegalPermission: false,
      }}
      validationSchema={PROFILE_INFO_VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <Modal
          className="mt-20"
          title={t('title')}
          headerContent={t('subtitle')}
          footerContent={
            <>
              <ProgressBar progressPercent={progressPercent} />
              <div className="flex w-full p-4 justify-between border-t">
                <Button
                  label={t('common:previous')}
                  variant="outlined"
                  onClick={onPrev}
                  className={cx('mr-2', { 'w-full': isMobile })}
                  data-testid="btn-prev"
                />
                <Button
                  label={t('common:continue')}
                  type="submit"
                  onClick={() => handleSubmit(values)}
                  disabled={isSubmitting}
                  className={isMobile ? 'w-full' : ''}
                  data-testid="btn-submit"
                />
              </div>
            </>
          }
          {...modalProps}
        >
          <FormikGeneralErrors />

          <Form>
            <div className="py-2">
              <FormikSelect
                name="companyType"
                label={t('companyTypeLabel')}
                items={companyTypeItems}
                data-testid="input-country"
              />
            </div>
            <div className="py-2">
              <FormikSelect
                name="totalExportedToUs"
                label={t('totalExportedLabel')}
                items={totalExportedItems}
                data-testid="input-total-exported"
              />
            </div>
            <div className="py-2">
              <FormikSelect
                name="yearsExportedToUs"
                label={t('yearsExportedLabel')}
                items={yearsExportedItems}
                data-testid="input-years-exported"
              />
            </div>
            <div className="py-2">
              <FormikTextField
                name="numBoxesSoldPerWeek"
                type="number"
                label={t('boxesPerWeekLabel')}
                placeholder="10"
                data-testid="input-market-request-boxes-per-week"
                shouldPrependErrors={false}
              />
            </div>
            <div className="py-2">
              <Field name="agreeToSupplyTerms">
                {({ field, form }) => {
                  return (
                    <div className="flex items-center">
                      <Checkbox
                        {...field}
                        value="agreeToSupplyTerms"
                        checked={field.value || false}
                        onClick={(e) => handleCheckboxClick(e, field, form)}
                      />
                      <div className="ml-4 text-xs font-bold">
                        <Trans i18nKey="marketplaceListSupply:termsCheckbox">
                          I agree to the
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={process.env.REACT_APP_TERMS_AND_CONDITION_URL}
                            className="text-blue-medium"
                          >
                            Terms and Conditions
                          </a>
                          for posting my supply and trading as part of the ProducePay Marketplace.
                        </Trans>
                        <ErrorMessage name="agreeToSupplyTerms" component="div" className="pt-1 text-red-600" />
                      </div>
                    </div>
                  );
                }}
              </Field>
            </div>
            <div className="py-2">
              <Field name="agreeToLegalPermission">
                {({ field, form }) => {
                  return (
                    <div className="flex items-center">
                      <Checkbox
                        {...field}
                        value="agreeToLegalPermission"
                        checked={field.value || false}
                        onClick={(e) => handleCheckboxClick(e, field, form)}
                      />
                      <div className="ml-4 text-xs font-bold">
                        {t('marketplaceListSupply:legalPermissionCheckbox')}
                        <ErrorMessage name="agreeToLegalPermission" component="div" className="pt-1 text-red-600" />
                      </div>
                    </div>
                  );
                }}
              </Field>
            </div>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

export default CompanyInfoStep;
