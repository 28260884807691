import React from 'react';
import _ from 'lodash';
import cx from 'classnames';

export default ({ className, errors }) => {
  return errors.length > 0 ? (
    <div className={cx('mb-4', className)}>
      {_.map(errors, (message) => (
        <div key={message} className="mb-2 text-sm text-red-600 font-medium">
          {message}
        </div>
      ))}
    </div>
  ) : null;
};
