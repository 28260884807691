import React, { useCallback, useState } from 'react';

import { useResponsiveLayout } from 'helpers/dom';
import GeneralLayout from 'components/layouts/GeneralLayout';
import ChatBox from './ChatBox';

const Conversations = () => {
  const { isLowerRes } = useResponsiveLayout();
  const [displayFooter, setDisplayFooter] = useState(true);

  const setDisplay = useCallback(
    (isDisplayed) => {
      if (isLowerRes) setDisplayFooter(isDisplayed);
    },
    [isLowerRes],
  );

  return (
    <GeneralLayout isFooterSticky={false} isFooterDisplayed={displayFooter}>
      <ChatBox setDisplayFooter={setDisplay} isFooterDisplayed={displayFooter} />
    </GeneralLayout>
  );
};

export default Conversations;
