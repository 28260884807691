import { gql } from '@apollo/client';

export const SKU_SEARCH_QUERY = gql`
  query SearchSkus($search: String!) {
    commodityVarieties(search: $search) {
      commodity {
        name
        id
      }
      variety {
        name
        id
      }
    }
  }
`;

export const GET_SKU_COMPONENT_QUERY = gql`
  query FetchSkuComponents($commodityId: String!, $varietyId: String, $packageId: String, $sizeId: String) {
    skuComponents(commodityId: $commodityId, varietyId: $varietyId, packageId: $packageId, sizeId: $sizeId) {
      skuVarieties {
        id
        name
      }
      skuPackages {
        id
        name
      }
      skuSizes {
        id
        name
      }
      skuGrades {
        id
        name
      }
    }
  }
`;

export const SKU_SEARCH_BY_COMPONENT_IDS_QUERY = gql`
  query SearchSkuByComponents(
    $commodityId: String!
    $varietyId: String
    $packageId: String
    $sizeId: String
    $gradeId: String
    $isOrganic: Boolean
  ) {
    skuByComponentIds(
      commodityId: $commodityId
      varietyId: $varietyId
      packageId: $packageId
      sizeId: $sizeId
      gradeId: $gradeId
      isOrganic: $isOrganic
    ) {
      id
    }
  }
`;

export const SKU_FIND_BY_COMPONENTS_QUERY = gql`
  query SkuFindByComponents(
    $commodity: String!
    $variety: String!
    $package: String!
    $size: String!
    $grade: String!
    $isOrganic: Boolean!
  ) {
    skuFindByComponents(
      commodity: $commodity
      variety: $variety
      package: $package
      size: $size
      grade: $grade
      isOrganic: $isOrganic
    ) {
      id
      commodity {
        id
        name
      }
      variety {
        id
        name
      }
      package {
        id
        name
      }
      size {
        id
        name
      }
      grade {
        id
        name
      }
      isOrganic
    }
  }
`;
