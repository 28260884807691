import { useState, useEffect, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

import useStorage from './useStorage';

function useCachedGraphqlQuery(query, dataSelector, dependenciesObj = {}, queryOptions = {}, options = {}) {
  const { storageKeyAddition = '', expectedNumberOfRecords = 0 } = options;

  const [data, setData] = useStorage(
    `cached-query.${dataSelector}.${storageKeyAddition}.data`,
    [],
    window.localStorage,
  );

  const [cachedDependencies, setCachedDependencies] = useStorage(
    `cached-query.${dataSelector}.${storageKeyAddition}.dependencies`,
    null,
    window.localStorage,
  );

  const numRecords = _.size(data);
  const isRecordCountMismatch = expectedNumberOfRecords !== 0 && expectedNumberOfRecords !== numRecords;

  const areDependenciesEqual = _.isEqual(dependenciesObj, cachedDependencies);
  const dependenciesString = JSON.stringify(dependenciesObj);

  const needsDataFetch = isRecordCountMismatch || !areDependenciesEqual;

  const [isLoading, setIsLoading] = useState(needsDataFetch);
  const { refetch } = useQuery(query, { ...queryOptions, skip: true });

  const fetchData = useCallback(() => {
    refetch().then((res) => {
      const queryData = _.get(res, `data.${dataSelector}`);

      if (queryData) {
        setIsLoading(false);
        setData(queryData);
      }
    });
  }, [refetch, dataSelector, setIsLoading, setData]);

  useEffect(() => {
    if (needsDataFetch) {
      setIsLoading(true);
      fetchData();

      setCachedDependencies(JSON.parse(dependenciesString));
    }
  }, [needsDataFetch, setIsLoading, fetchData, setCachedDependencies, dependenciesString]);

  return { data, isLoading };
}

export default useCachedGraphqlQuery;
