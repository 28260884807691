import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useAuth } from 'contexts/auth';
import { CREATE_STREAM_TOKEN_AND_CHANNEL } from 'routes/partials/DistributionTermsModal/helpers';
import { useToast } from '@producepay/pp-ui';
import { KEYS, trackEvent } from 'helpers/tracking';
import { useTranslation } from 'react-i18next';
import routes from 'routes';

const UNAUTHENTICATED_MARKET_REQUEST = 'marketRequest';

export default function useStreamChatRedirect(marketRequestId, requestType, hasBeenClicked) {
  const location = useLocation();
  const history = useHistory();
  const { addToastToQueue } = useToast();
  const { refetchCurrentUser, user } = useAuth();
  const { t } = useTranslation(['marketplace']);

  const [createStreamTokenAndChannel] = useMutation(CREATE_STREAM_TOKEN_AND_CHANNEL, {
    onCompleted: () => {
      trackEvent(KEYS.STREAM_CHAT_INITIATED, {
        'chat-type': requestType,
      });
      refetchCurrentUser();
      history.push(routes.conversations());
    },
  });

  useEffect(() => {
    function doMutation(accessToken, marketRequestId, requestType) {
      createStreamTokenAndChannel({
        variables: {
          accessToken,
          marketRequestId,
          requestType,
        },
      });
    }

    let unauthenticatedMarketRequest = window?.localStorage?.getItem(UNAUTHENTICATED_MARKET_REQUEST);

    // FUNNEL TRACKING > CHAT_WITH_OUR_TEAM > STREAM_CHAT_INITIATED || CHAT_REQUEST_REDIRECTED_TO_LOGIN
    if (hasBeenClicked) {
      let trackedEvent = KEYS.CHAT_WITH_OUR_TEAM;
      if (requestType === 'demand') {
        trackedEvent = marketRequestId ? KEYS.CLICKED_CHAT_DEMAND_LISTING : KEYS.CLICKED_SUBMIT_BID_CHAT;
      }

      // ADDED TRACKED EVENT W/ METADATA
      trackEvent(trackedEvent, {
        'chat-type': requestType,
        'market-request': marketRequestId,
      });

      if (user) {
        // path > user taken to chat
        doMutation(user.accessToken, marketRequestId, requestType);
      } else {
        // path > user redirected to the login page
        trackEvent(KEYS.CHAT_REQUEST_REDIRECTED_TO_LOGIN);

        unauthenticatedMarketRequest = { requestType, unauthenticatedMarketRequestId: marketRequestId };
        window?.localStorage?.setItem(UNAUTHENTICATED_MARKET_REQUEST, JSON.stringify(unauthenticatedMarketRequest));
        history.push({
          pathname: routes.authSignIn(),
          state: { from: location },
          search: '?is_redirected=chat_login',
        });
        addToastToQueue({
          type: 'info',
          header: t('unableToChat'),
          body: t('pleaseLoginOrSignUp'),
        });
      }
    } else if (unauthenticatedMarketRequest !== null && user) {
      // path > user taken to chat
      const { unauthenticatedMarketRequestId, requestType } = JSON.parse(unauthenticatedMarketRequest);
      doMutation(user.accessToken, unauthenticatedMarketRequestId, requestType);
      window?.localStorage?.removeItem(UNAUTHENTICATED_MARKET_REQUEST);
    }
  }, [
    createStreamTokenAndChannel,
    location,
    history,
    user,
    addToastToQueue,
    t,
    requestType,
    hasBeenClicked,
    marketRequestId,
  ]);
}
