import { useEffect } from 'react';

const getDeviceType = () => {
  const ua = navigator.userAgent;

  let deviceType;
  if (/android/i.test(ua)) {
    deviceType = 'android';
  } else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
    deviceType = 'ios';
  }

  return deviceType;
};

export default function useReactNativeListener(callbackFunc, isDisabled = false) {
  useEffect(() => {
    if (!isDisabled) {
      // Android devices need to use document.addEventListener, while iOS devices
      // need to use window.addEventListener

      const deviceType = getDeviceType();

      if (deviceType === 'android') {
        document.addEventListener('message', callbackFunc);
      } else if (deviceType === 'ios') {
        window.addEventListener('message', callbackFunc);
      }
      return () => {
        if (deviceType === 'android') {
          document.removeEventListener('message', callbackFunc);
        } else if (deviceType === 'ios') {
          window.removeEventListener('message', callbackFunc);
        }
      };
    }
  }, [callbackFunc, isDisabled]);
}
