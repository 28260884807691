import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import routes from '../../../../routes';
import { formatPrice } from '../../../../helpers/format';
import { OrderStatus } from '../../../fulfillment/hooks/useMyOrders';

import { Button } from '@producepay/pp-ui';
import { useResponsiveLayout } from 'helpers/dom';
import { ChevronRight, Check } from 'react-feather';
import { CSSTransition } from 'react-transition-group';

import CreateOrderButton from '../CreateOrderButton';

const OrdersPopOutView = ({ showPopout, setShowPopout, orders, channelId, loadId, shouldShowCreateOrdersButton }) => {
  const { t } = useTranslation(['conversations', 'common']);

  const { isMobile } = useResponsiveLayout();

  const openOrders = orders?.filter((order) => order.orderStatus !== OrderStatus.accepted);
  const closedOrders = orders?.filter((order) => order.orderStatus === OrderStatus.accepted);

  const popoutClass = isMobile ? 'participants overflow-scroll w-11/12' : 'participants overflow-scroll w-1/3';

  const statusTranslationMap = {
    CREATED: 'orderCreated',
    REVIEW: 'needsReview',
    BOOKED: 'booked',
    DISPUTED: 'disputed',
    ACCEPTED: 'accepted',
  };

  const OrderCard = ({ order = {} }) => {
    let orderRoute = '';

    if (order.awaitingActions?.includes('AWAITING_ACCEPTANCE')) {
      orderRoute = routes.fulfillmentAcceptShipment(order.id);
    } else if (order?.awaitingActions.includes('AWAITING_SIGNATURE')) {
      orderRoute = routes.signOrder(order.id);
    } else {
      orderRoute = routes.viewOrder(order.id);
    }

    const inventorySum = order?.inventoryItems
      ?.map((item) => item?.pricePerUnit * item?.quantity)
      ?.reduce((prev, curr) => prev + curr, 0);

    const orderStatus = order?.awaitingActions?.length > 0 ? 'REVIEW' : order?.orderStatus;

    return (
      <div className="flex flex-col border border-gray-100 p-4 mb-4">
        <div className="flex justify-between">
          <span>
            <span className="font-semibold mr-3 uppercase">{t('poNumber')}:</span>
            {order?.referenceNumber}
          </span>
          <span
            className={cx('flex px-2 py-1 rounded-sm', {
              'bg-blueberry': orderStatus === OrderStatus.created,
              'bg-orange-medium': orderStatus === OrderStatus.booked,
              'bg-yellow': orderStatus === OrderStatus.disputed || orderStatus === 'REVIEW',
              'bg-primary-light': orderStatus === 'ACCEPTED',
            })}
          >
            {orderStatus === 'ACCEPTED' && <Check color="#086E4F" className="mr-2.5" />}
            <span
              className={cx('text-sm font-semibold', {
                'text-white': order?.orderStatus === OrderStatus.created,
                'text-primary': order?.orderStatus !== OrderStatus.created,
              })}
            >
              {t(statusTranslationMap[orderStatus])}
            </span>
          </span>
        </div>
        <div className="flex justify-between">
          <span>
            <span className="font-semibold mr-3 uppercase">{t('qtySold')}:</span>
            {order?.inventoryItems?.length}
          </span>
        </div>
        <div className="flex justify-between mt-3">
          <span>
            <span className="font-semibold mr-3 uppercase">{t('shipDate')}:</span>
            {order?.shipDate}
          </span>
        </div>
        <div className="flex justify-between items-end mt-3">
          <span>
            <span className="font-semibold mr-3 uppercase">{t('total')}:</span>
            {inventorySum ? formatPrice(inventorySum) : t('tbdConsignment')}
          </span>
          <Link to={orderRoute}>
            <Button onClick={() => {}} className="uppercase" label={t('goToOrder')} color="primary" variant="text" />
          </Link>
        </div>
      </div>
    );
  };

  return (
    <>
      <CSSTransition in={showPopout} timeout={750} classNames="participants-background">
        <div className="participants-background" onClick={() => setShowPopout(false)} />
      </CSSTransition>
      <CSSTransition in={showPopout} timeout={750} classNames="participants">
        <div className={popoutClass}>
          <div className="flex flex-col m-4 h-full">
            <div className="flex flex-row">
              <div className="flex border-b-2 py-4 w-full">
                <h4 className="text-lg font-bold">{t('orders')}</h4>
                <ChevronRight className="ml-auto cursor-pointer" onClick={() => setShowPopout(false)} />
              </div>
            </div>
            <div className="flex flex-row py-2 text-sm text-gray-500 mt-8">{t('openOrders')}</div>
            {openOrders?.length > 0 ? (
              openOrders.map((order) => {
                return <OrderCard order={order} key={order?.id} />;
              })
            ) : (
              <span className="font-semibold">{t('none')}</span>
            )}
            <div className="flex flex-row py-2 text-sm text-gray-500 mt-8">{t('closedOrders')}</div>
            {closedOrders?.length > 0 ? (
              closedOrders.map((order) => {
                return <OrderCard order={order} key={order?.id} />;
              })
            ) : (
              <span className="font-semibold">{t('none')}</span>
            )}
            {shouldShowCreateOrdersButton && (
              <div className="flex flex-col justify-end w-max mb-4 mt-4" style={{ flexGrow: 2 }}>
                <Link to={routes.createOrderWithMarketplaceLoad(loadId, channelId)}>
                  <CreateOrderButton onClick={() => {}} label={t('createOrder')} />
                </Link>
              </div>
            )}
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default OrdersPopOutView;
