import React from 'react';
import cx from 'classnames';

import { Link } from 'react-router-dom';
import routes from '../../../routes';

import logo from '../../../assets/images/pp-logo.svg';

function PPInsights({ className }) {
  return (
    <Link className={cx(className, 'flex flex-col')} to={routes.home()}>
      <img src={logo} alt="ProducePay" />

      <div className="text-xs font-bold self-end">Insights</div>
    </Link>
  );
}

export default React.memo(PPInsights);
