import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter, useParams } from 'react-router-dom';

import { useAuth } from '../../../../contexts/auth';
import { trackEvent } from '../../../../helpers/tracking';

import SetPasswordView from './view';

function SetPassword(props) {
  const { mode, onPasswordSet, shouldResendDmr } = props;

  const { resetPasswordRequest, createPasswordRequest } = useAuth();
  const { token } = useParams();

  const { t } = useTranslation(['authSetPassword', 'common']);

  const createPassword = (...args) => {
    return createPasswordRequest(...args).then((res) => {
      trackEvent('PASSWORD_SET');

      onPasswordSet(res?.data?.userCreatePassword?.user || {});

      return res;
    });
  };

  return (
    <SetPasswordView
      header={mode === 'create' ? t('choosePassword') : t('resetPassword')}
      resetPasswordRequest={mode === 'create' ? createPassword : resetPasswordRequest}
      token={token}
      shouldResendDmr={shouldResendDmr}
    />
  );
}

SetPassword.propTypes = {
  mode: PropTypes.oneOf(['create', 'reset']).isRequired,
  onPasswordSet: PropTypes.func,
};

SetPassword.defaultProps = {
  onPasswordSet: () => {},
};

export default React.memo(withRouter(SetPassword));
