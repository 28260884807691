import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Field, Formik } from 'formik';
import { Button, Modal, LoadingSpinner } from '@producepay/pp-ui';
import * as yup from 'yup';
import lockImage from 'assets/images/lock.svg';

export default function DistributionTermsModal({ handleModalClose, agreeToDistributionTerms, onSubmit }) {
  const { t } = useTranslation('distributionTermModal');

  const VALIDATION_SCHEMA = yup.object().shape({
    agreeToDistributionTerms: yup.bool().oneOf([true], t('termsCheckRequired')),
    agreeToLegalPermission: yup.bool().oneOf([true], t('marketplaceListSupply:legalPermissionCheckRequired')),
  });

  if (agreeToDistributionTerms) {
    return (
      <div className="p-4 flex justify-center items-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Formik
      initialValues={{
        agreeToDistributionTerms,
        agreeToLegalPermission: false,
      }}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Modal
          className="mt-20 h-fit"
          title={t('termsAndCondition')}
          onClose={handleModalClose}
          footerClassNames={{ container: 'sticky z-20 bg-white bottom-0 w-full' }}
          footerContent={
            <div className="flex w-full justify-center">
              <Button
                label={t('chatSeller')}
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
                className="w-full sm:w-2/6 m-2"
                data-testid="btn-submit"
              />
            </div>
          }
        >
          <div className="flex justify-center flex-col">
            <img className="px-5 sm:px-48 py-5" src={lockImage} alt="locked" />
            <div className="text-xl sm:text-xl text-center pb-5">{t('chatMessage')}</div>
            <div className="flex mx-6 mt-2">
              <div>
                <Field className="mt-1" name="agreeToDistributionTerms" type="checkbox" id="agreeCheckBox" />
              </div>
              <span className="ml-3 leading-5">
                <label className="mr-1" htmlFor="agreeCheckBox">
                  {t('termsLabel1')}
                </label>
                <span className="text-primary underline">
                  <a href={process.env.REACT_APP_TERMS_AND_CONDITION_URL} target="_blank" rel="noopener noreferrer">
                    {t('termsAndCondition')}
                  </a>
                </span>
                <label className="ml-1" htmlFor="agreeCheckBox">
                  {t('termsLabel2')}
                </label>
              </span>
            </div>
            <ErrorMessage name="agreeToDistributionTerms" component="div" className="pt-1 text-red-600 ml-12" />
            <div className="flex mx-6 mt-2">
              <div>
                <Field className="mt-1" name="agreeToLegalPermission" type="checkbox" id="agreeToLegalPermission" />
              </div>
              <label className="ml-3 leading-5" htmlFor="agreeToLegalPermission">
                {t('marketplaceListSupply:legalPermissionCheckbox')}
              </label>
            </div>
            <ErrorMessage name="agreeToLegalPermission" component="div" className="pt-1 text-red-600 ml-12" />
          </div>
        </Modal>
      )}
    </Formik>
  );
}
