import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from 'contexts/auth';
import { useMutation } from '@apollo/client';
import { CREATE_STREAM_TOKEN_AND_CHANNEL } from './helpers';
import { UPDATE_ONBOARDING_INFORMATION } from 'graphql/queries/update-onboarding-information';
import { KEYS, trackEvent } from 'helpers/tracking';
import routes from 'routes';
import { useToast } from '@producepay/pp-ui';
import DistributionTermModalView from './view';

export const MARKET_REQUEST_STORE_KEY = 'marketRequestDistributionTerms';

export default function DistributionTermsModal() {
  const history = useHistory();
  const { refetchCurrentUser, user } = useAuth();
  const { accessToken } = user || {};
  const location = useLocation();
  const { t } = useTranslation(['distributionTermModal', 'common', 'marketplace']);
  const { addToastToQueue } = useToast();
  const { load, closeRoute = routes.marketplaceSupply() } = location.state || {};
  const marketRequest = load?.marketRequests?.[0];
  const handleModalClose = useCallback(() => history.push(closeRoute), [history, closeRoute]);
  const agreeToDistributionTerms = user?.onboardingInformation?.agreeToDistributionTerms || false;

  const showErrorToast = () => {
    addToastToQueue({
      type: 'error',
      header: t('common:error'),
      body: t('generalError'),
    });
  };

  const [createStreamTokenAndChannel, { error }] = useMutation(CREATE_STREAM_TOKEN_AND_CHANNEL, {
    onCompleted: () => {
      trackEvent(KEYS.STREAM_CHAT_INITIATED, {
        'chat-type': marketRequest?.requestType,
      });
      history.push(routes.conversations());
    },
    onError: () => {
      showErrorToast();
    },
  });

  const [updateOnboardingInfo] = useMutation(UPDATE_ONBOARDING_INFORMATION, {
    onCompleted: () => {
      // If we succesfully, updated the agreeToDistributionTerms we should now
      // allow the user to create the chat channel.
      trackEvent(KEYS.AGREED_TO_DISTRIBUTION_TERMS);
      refetchCurrentUser();
    },
    onError: () => {
      showErrorToast();
    },
  });

  const onSubmit = async (values) => {
    updateOnboardingInfo({
      variables: {
        accessToken: user.accessToken,
        onboardingInformation: {
          agreeToDistributionTerms: values.agreeToDistributionTerms,
        },
      },
    });
  };

  useEffect(() => {
    if (accessToken && agreeToDistributionTerms && !error) {
      createStreamTokenAndChannel({
        variables: {
          accessToken,
          marketRequestId: `${marketRequest?.id}`,
          requestType: marketRequest?.requestType,
        },
      });
    }
  }, [agreeToDistributionTerms, error, accessToken, marketRequest, createStreamTokenAndChannel]);

  // Error:  Someone goes to this route directly
  if (!load || !marketRequest) {
    showErrorToast();
    handleModalClose();
    return null;
  }

  if (!user) {
    // User needs to login first
    trackEvent(KEYS.CHAT_REQUEST_REDIRECTED_TO_LOGIN);

    // The user wants to chat but is not yet logged in.  We save the load info into local storage.
    // This is used up by useStreamChatRedirect after the user has logged in.
    const unauthenticatedMarketRequest = {
      requestType: marketRequest?.requestType,
      unauthenticatedMarketRequestId: marketRequest?.id,
    };
    window.localStorage.setItem(MARKET_REQUEST_STORE_KEY, JSON.stringify(unauthenticatedMarketRequest));

    history.push({
      pathname: routes.authSignIn(),
      state: { from: location },
      search: '?is_redirected=chat_login',
    });

    addToastToQueue({
      type: 'info',
      header: t('marketplace:unableToChat'),
      body: t('marketplace:pleaseLoginOrSignUp'),
    });
  }

  return (
    <DistributionTermModalView
      handleModalClose={handleModalClose}
      agreeToDistributionTerms={agreeToDistributionTerms}
      onSubmit={onSubmit}
    />
  );
}
