import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@producepay/pp-ui';

import greenCheckmark from '../../../../assets/images/checkmark-green-bg.svg';

export default ({ onClose }) => {
  const { t } = useTranslation('marketInsights');

  return (
    <div className="w-full flex flex-col items-center p-4 lg:p-8">
      <div className="w-70">
        <img src={greenCheckmark} alt="Checkmark" />
      </div>
      <div className="mt-6">
        <h3 className="text-xl md:text-2xl font-bold">{t('authModal.profileCompleteHeader')}</h3>
      </div>
      <div className="mt-4 text-center">{t('authModal.profileCompleteDescription')}</div>
      <div className="my-8 w-40">
        <Button onClick={onClose} label={t('common:close')} className="w-full" />
      </div>
    </div>
  );
};
