import _, { compact } from 'lodash';
import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';

import { getPercentage } from './math';

const FORMAT_PROPS = { style: 'currency', currency: 'USD' };

export function formatPrice(value, trunc = true, roundToDollar = false) {
  if (value === null || value === undefined) return null;

  if (trunc) {
    const num = Math.floor(value);
    if (num >= 1e9) {
      return `${new Intl.NumberFormat('en-US', FORMAT_PROPS).format(num / 1e9)}B`;
    }
    if (num >= 1e6) {
      return `${new Intl.NumberFormat('en-US', FORMAT_PROPS).format(num / 1e6)}M`;
    }
    if (num >= 1e3) {
      const val = new Intl.NumberFormat('en-US', { ...FORMAT_PROPS, maximumSignificantDigits: 3 }).format(num / 1000);
      return `${val}K`;
    }
  }

  if (roundToDollar) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(value);
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(value);
}

export function formatLargeLoads(value) {
  const num = Math.floor(value);
  if (num >= 1e9) {
    return `${new Intl.NumberFormat('en-US').format(num / 1e9)}B`;
  }
  if (num >= 1e6) {
    return `${new Intl.NumberFormat('en-US').format(num / 1e6)}M`;
  }
  if (num >= 1e3) {
    return `${new Intl.NumberFormat('en-US').format(num / 1000)}K`;
  }

  return formatLoads(value);
}

export function formatLoads(value) {
  return new Intl.NumberFormat('en-US').format(value);
}

export function formatLargeNumber(value) {
  const strippedValue = String(value).replace(/,/, '');
  if (value === null || value === undefined || strippedValue === '') return null;
  const formattedLargeNumber = new Intl.NumberFormat('en-US').format(strippedValue);
  return formattedLargeNumber === 'NaN' ? null : formattedLargeNumber;
}

export function capitalizeEachWord(string) {
  return _.startCase(_.toLower(string));
}

export function getRoundedPercentage(newVal, oldVal, precision = 0) {
  const percentage = getPercentage(newVal, oldVal);

  return percentage !== null ? _.round(percentage, precision) : '--';
}

export function formatDateRange(startDate, endDate, formatStr = 'MM/dd/yyyy') {
  return isSameDay(startDate, endDate)
    ? format(startDate, formatStr)
    : `${format(startDate, formatStr)} - ${format(endDate, formatStr)}`;
}

export function parseDateRange(formattedDateRangeStr) {
  const parsedDateRange = _.split(formattedDateRangeStr, ' - ');
  return [new Date(parsedDateRange[0]), new Date(parsedDateRange[1])];
}

// item should be an object returned by shippingPointPriceReports
export function generateFullSkuName(item) {
  return _.join(_.compact([item.commodityUsdaName, item.skuName]), ' • ');
}

export function formatCommodityVarietyName(commodityName, varietyName, separator = ', ') {
  return _.compact([commodityName, varietyName]).join(separator);
}

export function formatEsNameAndId(name, id, token = '<<>>') {
  return _.compact([name, id]).join(token);
}

export function extractEsNameAndId(nameAndId, token = '<<>>') {
  return _.split(nameAndId, token);
}

export function formatPriceSimple(price) {
  return _.isNumber(price) ? `$${new Intl.NumberFormat().format(price)}` : '--';
}

export function formatPercentageSimple(percentage, precision = 1) {
  return _.isNumber(percentage) ? `${_.round(percentage, precision).toFixed(1)}%` : '--';
}

export function formatYearString(date) {
  return format(new Date(date), 'yyyy');
}

export function generateCompactList(inputs, separator) {
  return compact([...inputs]).join(separator);
}

export function getCommodityName(marketRequest) {
  const commodityName = marketRequest?.commodityVarietyInfo?.name || marketRequest?.customCommodityVarietyName;

  return capitalizeEachWord(commodityName);
}

export function generatePriceString(load, isSupply) {
  const { marketRequests = [] } = load;
  const totalMarketRequests = marketRequests.length;

  if (totalMarketRequests === 0) {
    return null;
  }

  if (totalMarketRequests === 1 || !isSupply) {
    return formatPrice(marketRequests[0]?.price);
  }

  const prices = _.map(marketRequests, (mr) => mr.price).sort((a, b) => a - b);
  const lowestPrice = prices[0];
  const highestPrice = prices[prices.length - 1];

  return `${formatPrice(lowestPrice)} - ${formatPrice(highestPrice)}`;
}

export function generateQuantityString(load, isSupply) {
  const { marketRequests = [] } = load;
  const totalMarketRequests = marketRequests.length;

  if (totalMarketRequests === 0) {
    return null;
  }

  if (totalMarketRequests === 1 || !isSupply) {
    return formatLargeNumber(marketRequests[0]?.quantity);
  }

  const totalQuantity = _.reduce(marketRequests, (sum, mr) => sum + parseInt(mr.quantity, 10), 0);
  return formatLargeNumber(totalQuantity);
}

export function generateSizeString(load, isSupply) {
  const { marketRequests = [] } = load;
  const totalMarketRequests = marketRequests.length;

  if (totalMarketRequests === 0) {
    return null;
  }

  if (totalMarketRequests === 1 || !isSupply) {
    return generateCompactList([marketRequests[0]?.size], ', ');
  }

  let sizes = [];
  sizes = _.map(marketRequests, (mr) => mr.size).sort((a, b) => {
    const aValue = a.replace(/\D+/g, '');
    const bValue = b.replace(/\D+/g, '');
    return aValue - bValue;
  });
  return generateCompactList([...sizes], ', ');
}

export function generateGradeString(marketRequest, isSupply) {
  if (isSupply) {
    const { marketRequests = [] } = marketRequest;
    const totalMarketRequests = marketRequests.length;

    if (totalMarketRequests === 0) {
      return null;
    }

    const grades = [];

    if (totalMarketRequests === 1) {
      grades.push(marketRequests[0]?.grade);
      return generateCompactList([...grades], ', ');
    }

    _.forEach(marketRequests, (mr) => {
      if (!grades.includes(mr.grade)) {
        grades.push(mr.grade);
      }
    });

    grades.sort((a, b) => a - b);
    return generateCompactList([...grades], ', ');
  }

  return generateCompactList([marketRequest?.grade], ', ');
}

export function generateBreakdown(marketRequest) {
  const quantityString = marketRequest?.quantity ? `${formatLargeNumber(marketRequest.quantity)}` : null;
  const packagingString = marketRequest?.packaging ? `${marketRequest?.packaging}` : null;

  const quantityPackagingString = packagingString ? `${quantityString} ${packagingString}` : `${quantityString}`;
  const gradeString = marketRequest?.grade ? `Grade ${marketRequest?.grade}` : null;
  return generateCompactList([marketRequest?.size, quantityPackagingString, gradeString], ' • ');
}

export function generateBulletedString(items) {
  return _.join(_.compact(items), ' • ');
}
