import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { capitalizeEachWord, generatePriceString, generateQuantityString, getCommodityName } from 'helpers/format';
import { getUTCDate } from 'helpers/dates';
import { imageFromLoad } from 'helpers/common';

import {
  generateBreakoutRowString,
  generateTotalQuantityString,
} from 'routes/Marketplace/partials/MarketRequestCard/helpers';

function SupplyBreakdownSection({ load, isMobile }) {
  const { t } = useTranslation('marketplaceMarketRequest');

  const isSupply = load?.requestType === 'supply';

  const {
    createdAt = null,
    grownLocation = null,
    imageUrls = [],
    marketRequests = [],
    packaging = null,
    shippingPoint = null,
  } = load;
  const firstMarketRequest = marketRequests[0];
  const imageUrl = imageFromLoad(load);
  const images = imageUrls || [];
  const commodityName = getCommodityName(load);
  const priceString = generatePriceString(load, isSupply);
  const quantityString = generateQuantityString(load, isSupply);
  const totalQuantityString = generateTotalQuantityString(quantityString, marketRequests, t);
  const createdAtUTC = getUTCDate(createdAt);
  const archiveDateUTC = getUTCDate(firstMarketRequest?.archiveDate);

  const [mainImage, setMainImage] = useState(images?.[0]?.original || imageUrl);

  const DesktopDisplay = () => {
    return (
      <div className="flex my-6">
        <div className="flex flex-col">
          <div className="p-2" style={{ width: '288px', height: '288px' }}>
            <img
              className="w-full h-full rounded border border-primary object-cover"
              src={mainImage}
              alt={commodityName}
            />
          </div>
          <div className="flex px-1" style={{ width: '288px' }}>
            {images.map((image, index) => {
              return (
                <div key={image.id} className="w-1/4 px-1" style={{ height: '64px' }}>
                  <img
                    src={image.small}
                    alt={`${commodityName} ${index}`}
                    className={cx('w-full h-full rounded object-cover cursor-pointer', {
                      'opacity-50': image.original !== mainImage,
                      'border-primary': image.original === mainImage,
                      border: image.original === mainImage,
                    })}
                    style={{ boxShadow: image.original === mainImage && '0px 0px 4px 0px rgba(8, 110, 79, 0.5)' }}
                    onClick={() => setMainImage(image.original)}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col w-full pt-6 pl-10">
          <div className="flex justify-between">
            <div className="flex flex-col">
              <span className="font-semibold text-2xl mb-3">{commodityName}</span>
              {packaging && <span className="font-semibold text-lg mb-3">{capitalizeEachWord(packaging)}</span>}
            </div>
            <div className="flex flex-col items-end text-right">
              <span className="font-semibold text-2xl mb-1">{priceString}</span>
              {totalQuantityString && <span className="font-semibold">{totalQuantityString}</span>}
            </div>
          </div>
          <span className="text-sm font-semibold mb-3">{t('supplyBreakout')}:</span>
          <div className="flex flex-col sm:flex-row justify-between">
            <div className="flex flex-col">
              <div>
                {marketRequests?.length > 0 &&
                  marketRequests.map((marketRequest) => {
                    return (
                      <div key={marketRequest.id} className="mb-3">
                        {generateBreakoutRowString(marketRequest, t)}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="flex flex-col items-end text-right ml-4">
              <div className="mb-3">
                <span>{t('availableFrom')}: </span>
                <span className="font-semibold">
                  {t('rangeAvailable', { startDate: createdAtUTC, endDate: archiveDateUTC })}
                </span>
              </div>
              {shippingPoint && (
                <div className="mb-3">
                  <span>{isSupply ? t('shippingLocation') : t('deliveryLocation')}: </span>
                  <span className=" font-semibold">{shippingPoint}</span>
                </div>
              )}
              {grownLocation && (
                <div>
                  <span>{t('grownIn')}: </span>
                  <span className="font-semibold">{grownLocation}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const MobileDisplay = () => {
    return (
      <div className="flex flex-col py-4">
        <div className={cx('flex w-full', { 'mb-5': images.length > 0 })}>
          {images.map((image, index) => {
            return (
              <div className="pr-2" key={image.id}>
                <img
                  src={image.small}
                  alt={`${commodityName} ${index}`}
                  className="rounded border border-primary"
                  style={{ width: '60px', height: '60px' }}
                />
              </div>
            );
          })}
        </div>
        <span className="font-semibold text-2xl mb-2">{commodityName}</span>
        {packaging && <span className="font-semibold mb-5">{capitalizeEachWord(packaging)}</span>}
        <div className="bg-gray-light p-2 mb-5">
          <span className="font-semibold">{t('supplyBreakout')}:</span>
          {marketRequests?.length > 0 &&
            marketRequests.map((marketRequest) => {
              return (
                <div key={marketRequest.id} className="mt-2">
                  {generateBreakoutRowString(marketRequest, t)}
                </div>
              );
            })}
        </div>
        <div className="mb-1 text-sm">
          <span>{t('availableFrom')}: </span>
          <span className="font-semibold">
            {t('rangeAvailable', { startDate: createdAtUTC, endDate: archiveDateUTC })}
          </span>
        </div>
        {shippingPoint && (
          <div className="mb-1 text-sm">
            <span>{isSupply ? t('shippingLocation') : t('deliveryLocation')}: </span>
            <span className=" font-semibold">{shippingPoint}</span>
          </div>
        )}
        {grownLocation && (
          <div className="mb-5 text-sm">
            <span>{t('grownIn')}: </span>
            <span className="font-semibold">{grownLocation}</span>
          </div>
        )}
        <span className="font-semibold text-xl mb-1">{priceString}</span>
        {totalQuantityString && <span className="font-semibold">{totalQuantityString}</span>}
      </div>
    );
  };

  return isMobile ? <MobileDisplay /> : <DesktopDisplay />;
}

SupplyBreakdownSection.propTypes = {
  load: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
};

SupplyBreakdownSection.defaultProps = {
  isMobile: false,
};

export default SupplyBreakdownSection;
