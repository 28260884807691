import { useAuth } from '../../../contexts/auth';
import routes from '../../../routes';

function Profile() {
  const { logout } = useAuth();

  logout();

  window.location.replace(routes.marketplaceProfile());

  return null;
}

export default Profile;
