import React from 'react';
import _ from 'lodash';

import { useFormikContext } from 'formik';

function FormikGeneralErrors({ className }) {
  const { status } = useFormikContext();

  return (
    <div className={className}>
      {!_.isEmpty(status?.errors) &&
        status.errors.map((error) => (
          <div key={error} className="mb-2 text-sm text-red-600 text-center">
            {error}
          </div>
        ))}
    </div>
  );
}

export default React.memo(FormikGeneralErrors);
