import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getStepInfoFromOnboardingInformation } from './helpers';
import useHideIntercom from '../../../../hooks/useHideIntercom';
import { trackEvent, KEYS } from '../../../../helpers/tracking';

import Step1 from './Step1';
import FollowupQuestion from './FollowupQuestion';
import PhoneNotificationsQuestion from './PhoneNotificationsQuestion';

function OnboardingView({
  title,
  onboardingInformation,
  updateOnboardingInformation,
  triggerPhoneNumberVerification,
  confirmPhoneNumber,
  onOnboardingCompleted,
}) {
  const { t } = useTranslation(['onboarding', 'common']);
  useHideIntercom(true);

  const [step, setStep] = useState(1);
  const [stepInfo, setStepInfo] = useState(getStepInfoFromOnboardingInformation(onboardingInformation, t));

  const { totalSteps, stepsArray } = stepInfo;
  const isLastStep = step === totalSteps;

  const questionDefinition = stepsArray[step - 2] || {};

  const incrementStep = () => {
    if (isLastStep) {
      trackEvent(KEYS.FULL_ONBOARDING_COMPLETED);
      onOnboardingCompleted();
    } else {
      setStep(step + 1);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div className="mb-4 text-xs text-gray-4 font-bold uppercase">
        {step} {t('common:of')} {totalSteps}
      </div>

      <div className="w-full">
        {step === 1 ? (
          <Step1
            title={title}
            onboardingInformation={onboardingInformation}
            onSubmit={(values) => {
              return updateOnboardingInformation(values).then(() => {
                trackEvent(KEYS.ONBOARDING_COMPLETED);

                setStepInfo(getStepInfoFromOnboardingInformation({ ...onboardingInformation, ...values }, t));
                incrementStep();
              });
            }}
          />
        ) : isLastStep ? (
          <PhoneNotificationsQuestion
            onboardingInformation={onboardingInformation}
            triggerPhoneNumberVerification={triggerPhoneNumberVerification}
            onConfirm={(values) => {
              return confirmPhoneNumber(values).then(incrementStep);
            }}
            previousQuestion={() => setStep(step - 1)}
            skipQuestion={incrementStep}
          />
        ) : (
          <FollowupQuestion
            title={title}
            key={questionDefinition.value}
            questionDefinition={questionDefinition}
            onboardingInformation={onboardingInformation}
            onSubmit={(values) => {
              return updateOnboardingInformation(values).then(incrementStep);
            }}
            previousQuestion={() => setStep(step - 1)}
            skipQuestion={incrementStep}
          />
        )}
      </div>
    </div>
  );
}

export default OnboardingView;
