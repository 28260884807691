import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useFormikContext } from 'formik';

import FormikTextField from 'components/formik/FormikTextField';
import trashCan from 'assets/images/trash-can.svg';
import copyFile from 'assets/images/copy-file.svg';

function RequestCard({ onDelete, onDuplicate, commodityName, request, index, disableDelete }) {
  const requestTitle = `${commodityName} ${request.size}`;
  const { t } = useTranslation(['marketplaceListSupplyDetails', 'common']);
  const { touched, errors } = useFormikContext();

  return (
    <div className="bg-white border-2 p-4 my-4">
      <div className="text-bold text-lg pb-4 px-2">{requestTitle}</div>
      <div className="flex flex-row justify-between pb-4">
        <div className="px-2">
          <FormikTextField
            name={`marketRequests.${index}.price`}
            type="number"
            label={t('common:price')}
            placeholder="$0.00"
            error={
              touched.marketRequests?.[index]?.price
                ? t([
                    `formErrors:${errors.marketRequests?.[index]?.price}`,
                    errors.marketRequests?.[index]?.price || '',
                  ])
                : null
            }
            onWheel={(e) => e.target.blur()}
          />
        </div>
        <div className="px-2">
          <FormikTextField
            name={`marketRequests.${index}.quantity`}
            type="number"
            label={t('marketplaceListSupplyDetails:caseCount')}
            placeholder="0"
            error={
              touched.marketRequests?.[index]?.quantity
                ? t([
                    `formErrors:${errors.marketRequests?.[index]?.quantity}`,
                    errors.marketRequests?.[index]?.quantity || '',
                  ])
                : null
            }
            onWheel={(e) => e.target.blur()}
          />
        </div>
        <div className="px-2">
          <FormikTextField
            name={`marketRequests.${index}.grade`}
            label={t('common:grade')}
            placeholder="1"
            onWheel={(e) => e.target.blur()}
          />
        </div>
      </div>

      <div className="flex flex-row justify-start">
        <div className="flex w-1/2 px-2">
          <div>
            <div className="form-label text-xs font-bold tracking-wide text-grey-darker">
              <label>{t('marketplaceListSupplyDetails:organic')}</label>
            </div>
            <div className="mt-2">
              <div>
                <Field
                  type="radio"
                  name={`marketRequests.${index}.isOrganic`}
                  value="true"
                  id={`marketRequests.${index}.organic`}
                />
                <label htmlFor={`marketRequests.${index}.organic`} className="ml-2 pt-2 text-sm">
                  {t('marketplaceListSupplyDetails:organic')}
                </label>
              </div>
              <div>
                <Field
                  type="radio"
                  name={`marketRequests.${index}.isOrganic`}
                  value="false"
                  id={`marketRequests.${index}.conventional`}
                />
                <label htmlFor={`marketRequests.${index}.conventional`} className="ml-2 pt-2 text-sm">
                  {t('marketplaceListSupplyDetails:conventional')}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="flex w-1/2 px-2">
          <div>
            <div className="form-label text-xs font-bold tracking-wide text-grey-darker">
              <label>{t('marketplaceListSupplyDetails:label')}</label>
            </div>
            <div className="mt-2">
              <div>
                <Field
                  type="radio"
                  name={`marketRequests.${index}.hasGrowerLabel`}
                  value="true"
                  id={`marketRequests.${index}.growerLabel`}
                />
                <label htmlFor={`marketRequests.${index}.growerLabel`} className="ml-2 pt-2 text-sm">
                  {t('marketplaceListSupplyDetails:growerLabel')}
                </label>
              </div>
              <div>
                <Field
                  type="radio"
                  name={`marketRequests.${index}.hasGrowerLabel`}
                  value="false"
                  id={`marketRequests.${index}.generic`}
                />
                <label htmlFor={`marketRequests.${index}.generic`} className="ml-2 pt-2 text-sm">
                  {t('marketplaceListSupplyDetails:generic')}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2 flex flex-row-reverse">
        <span>
          <button type="button" className="mr-2" onClick={onDuplicate}>
            <img src={copyFile} alt="copy" style={{ height: '20px' }} />
          </button>
          <button type="button" onClick={onDelete} disabled={disableDelete} className="disabled:opacity-50">
            <img src={trashCan} alt="trash can" style={{ height: '20px' }} />
          </button>
        </span>
      </div>
    </div>
  );
}

export default RequestCard;
