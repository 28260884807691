import startOfYear from 'date-fns/startOfYear';
import endOfYear from 'date-fns/endOfYear';
import subYears from 'date-fns/subYears';

import { DEFAULT_PRESETS } from '../../components/molecules/DateRangePicker';

export const FILTER_CONTEXT_ACTION_TYPES = {
  COMMODITIES: 'COMMODITIES_AND_VARIETIES',
  SIZE: 'SIZE',
  PACKAGING: 'PACKAGING',
  GRADE: 'GRADE',
  LABEL: 'LABEL',
  DATE_RANGE: 'DATE_RANGE',
  CUSTOMER: 'CUSTOMER',
  SALES_PERSON: 'SALES_PERSON',
  COUNTRY_OF_ORIGIN: 'COUNTRY_OF_ORIGIN',
  RESET: 'RESET',
  USDA_COMMODITY_PACKAGE: 'USDA_COMMODITY_PACKAGE',
  GROWER: 'GROWER',
};

export const DATE_RANGE_PRESET_OPTIONS = [
  ...DEFAULT_PRESETS,
  {
    label: 'Year To Date',
    key: 'year_to_date',
    start: startOfYear(new Date()),
    end: new Date(),
  },
  {
    label: 'Previous Year',
    key: 'previous_year',
    start: startOfYear(subYears(new Date(), 1)),
    end: endOfYear(subYears(new Date(), 1)),
  },
  {
    label: 'Last 3 Years',
    key: 'last_3_years',
    start: subYears(new Date(), 3),
    end: new Date(),
  },
  {
    label: 'Last 5 Years',
    key: 'last_5_years',
    start: subYears(new Date(), 5),
    end: new Date(),
  },
];
