import React from 'react';

const ConversationsIcons = (props) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M12.75 0.75C10.8788 0.747302 9.04097 1.24541 7.42723 2.19264C5.81349 3.13987 4.48264 4.5017 3.5728 6.13681C2.66295 7.77192 2.20726 9.62073 2.25302 11.4914C2.29877 13.362 2.8443 15.1863 3.833 16.775L0.75 23.25L7.224 20.167C8.6047 21.0254 10.1659 21.5515 11.7846 21.7038C13.4032 21.8561 15.0351 21.6304 16.5517 21.0446C18.0683 20.4587 19.4281 19.5287 20.524 18.3278C21.62 17.1268 22.422 15.6878 22.8671 14.1241C23.3121 12.5604 23.3879 10.9147 23.0886 9.31668C22.7892 7.71867 22.1229 6.212 21.142 4.91539C20.1612 3.61877 18.8926 2.56764 17.4363 1.84481C15.9801 1.12198 14.3758 0.747201 12.75 0.75V0.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ConversationsIcons;
