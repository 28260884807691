import React, { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import qs from 'qs';
import { useHistory, withRouter } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import { identifyUser, KEYS, trackEvent } from '../../helpers/tracking';
import { useFilterState } from '../FilterState';
import routes from '../../routes';
import useEmailAuthToken from '../../routes/fulfillment/hooks/useEmailAuthToken';
import { useRedirectUriRef } from '../../hooks/useRedirectUriRef';

const AUTH_TOKEN_KEY = 'auth-token';
const EMAIL_AUTH_TOKEN_KEY = 'email-auth-token';
// declare here so we can import TRUE_USER_TOKEN_KEY in helper files without
// triggering a reference error USER_FRAGMENT was not initialized
const TRUE_USER_TOKEN_KEY = 'auth-true-user-token';
const UNAUTHENTICATED_USER_TOKEN_KEY = 'userToken';
export const UNAUTHENTICATED_USER_EMAIL_KEY = 'userEmail';
export const INSIGHTS_SESSION_TOKEN = 'INSIGHTS_SESSION_TOKEN';
export const INSIGHTS_EMAIL_ADDRESS = 'INSIGHTS_EMAIL_ADDRESS';
export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;

export const NOTIFICATIONS_FRAGMENT = `
  fragment userNotifications on NotificationPreference {
    id
    dmrUnsubscribedAt
    salesReportUnsubscribedAt
  }
`;

export const USER_FRAGMENT = `
  ${NOTIFICATIONS_FRAGMENT}

  fragment currentUserFragment on User {
    id
    accountServiceCompanyId
    createdAt
    email
    accessToken
    isInsightsProUser
    infoExperimentType
    infoExperimentCompletedAt
    locale
    isAdmin
    isVerified
    isOnboarded
    marketRequestFormCompleted
    maxBluebookScore
    streamToken
    notificationPreference {
      ...userNotifications
    }
    onboardingInformation {
      fullName
      country
      companyName
      companyType
      interestedInCashFlow
      interestedInLowerInputs
      interestedInConnectingWithCustomers
      interestedInConnectingWithGrowers
      interestedInBusinessIntelligence
      otherType
      otherInventorySoftware
      yearsExportedToUs
      totalExportedToUs
      openToLatinAmericanGrowers
      inventorySoftware
      growerCommodities
      phoneNumber
      bidCreationCompletedAt
      bluebookCompanyId
      supplyListingsApplicationStatus
      numBoxesSoldPerWeek
      agreeToSupplyTerms
      agreeToDistributionTerms
    }
    company {
      tosAcceptedAt
    }
  }
`;

const CURRENT_USER = gql`
  ${USER_FRAGMENT}
  query getCurrentUser {
    currentUser {
      ...currentUserFragment
    }
  }
`;

const REGISTER = gql`
  ${USER_FRAGMENT}
  mutation Register($email: String!, $commodityVarietyInfoPreferenceIds: [Int!], $locale: String, $ref: String) {
    registerNewUser(
      email: $email
      commodityVarietyInfoPreferenceIds: $commodityVarietyInfoPreferenceIds
      locale: $locale
      ref: $ref
    ) {
      ...currentUserFragment
    }
  }
`;

const LOGIN = gql`
  ${USER_FRAGMENT}
  mutation signInUser($email: String!, $password: String!) {
    signInUser(email: $email, password: $password) {
      token
      user {
        ...currentUserFragment
      }
    }
  }
`;

const RESET_PASSWORD = gql`
  ${USER_FRAGMENT}
  mutation UserResetPassword($password: String!, $token: String!) {
    userResetPassword(password: $password, token: $token) {
      user {
        ...currentUserFragment
      }
      token
    }
  }
`;

const TOGGLE_UNSUBSCRIBED_AT = gql`
  ${NOTIFICATIONS_FRAGMENT}

  mutation ToggleNotificationPreferences {
    toggleNotificationPreferences(preferences: { dmrUnsubscribedAt: true }) {
      ...userNotifications
    }
  }
`;

const CREATE_PASSWORD = gql`
  ${USER_FRAGMENT}
  mutation UserCreatePassword($password: String!, $token: String!, $shouldResendDmr: Boolean) {
    userCreatePassword(password: $password, token: $token, shouldResendDmr: $shouldResendDmr) {
      user {
        ...currentUserFragment
      }
      token
    }
  }
`;

const storeUnauthenticatedUser = ({ token, email }) => {
  if (token) window?.localStorage?.setItem(UNAUTHENTICATED_USER_TOKEN_KEY, token);
  if (email) window?.localStorage?.setItem(UNAUTHENTICATED_USER_EMAIL_KEY, email);
};
const getStoredUnauthenticatedUser = (token, email) => {
  const newToken = token || window?.localStorage?.getItem(UNAUTHENTICATED_USER_TOKEN_KEY);
  const newEmail = email || window?.localStorage?.getItem(UNAUTHENTICATED_USER_EMAIL_KEY);
  const newPayload = { token: newToken, email: newEmail };

  storeUnauthenticatedUser(newPayload);
  return newPayload;
};

const AuthContext = React.createContext();

function AuthProvider(props) {
  const { resetFilters } = useFilterState();
  const history = useHistory();
  const { i18n } = useTranslation();

  const {
    location: { search },
    children,
  } = props;
  const { token, email, verification_token: verificationToken } = qs.parse(search, { ignoreQueryPrefix: true });

  const [unauthenticatedUser, setUnauthenticatedUser] = useState(getStoredUnauthenticatedUser(token, email));
  const [authToken, setAuthToken] = useState(window?.localStorage?.getItem(AUTH_TOKEN_KEY));
  const [currentUser, setCurrentUser] = useState(null);
  const emailToken = window?.localStorage?.getItem(EMAIL_AUTH_TOKEN_KEY);
  const { isDone: emailAuthIsDone } = useEmailAuthToken();
  const { current: redirectUri } = useRedirectUriRef();

  const [loginRequest, { client: loginClient }] = useMutation(LOGIN, {
    onCompleted: (data) => {
      const jwtToken = _.get(data, 'signInUser.token');
      const user = _.get(data, 'signInUser.user');
      onAuthUser(user, jwtToken);
    },
  });
  const [registerRequest] = useMutation(REGISTER, {
    onCompleted: (data) => {
      const user = data?.registerNewUser;
      if (user) onAuthUser(user);
    },
  });
  const [resetPasswordRequest] = useMutation(RESET_PASSWORD, {
    onCompleted: (data) => {
      const jwtToken = _.get(data, 'userResetPassword.token');
      const user = _.get(data, 'userResetPassword.user');

      onAuthUser(user, jwtToken);

      if (redirectUri) {
        window.location.replace(redirectUri);
      } else {
        history.replace(routes.home());
      }
    },
  });
  const [createPasswordRequest] = useMutation(CREATE_PASSWORD, {
    onCompleted: (data) => {
      const jwtToken = _.get(data, 'userCreatePassword.token');
      const user = _.get(data, 'userCreatePassword.user');

      onAuthUser(user, jwtToken);
    },
  });

  const [toggleSubscription] = useMutation(TOGGLE_UNSUBSCRIBED_AT);

  const onAuthUser = useCallback(
    (user, jwtToken) => {
      const unauthenticatedUserPayload = { token: user.accessToken, email: user.email };
      setUnauthenticatedUser(unauthenticatedUserPayload);
      storeUnauthenticatedUser(unauthenticatedUserPayload);

      setCurrentUser(user);

      Cookies.set(INSIGHTS_EMAIL_ADDRESS, user.email, { domain: COOKIE_DOMAIN });
      if (authToken) {
        Cookies.set(INSIGHTS_SESSION_TOKEN, authToken, { domain: COOKIE_DOMAIN });
      }
      if (jwtToken) {
        setAuthToken(jwtToken);
        window?.localStorage?.setItem(AUTH_TOKEN_KEY, jwtToken);
        Cookies.set(INSIGHTS_SESSION_TOKEN, jwtToken, { domain: COOKIE_DOMAIN });
      }

      const email = user?.email;

      if (email) {
        window.heap?.identify(email);
        window.heap?.addUserProperties({ email });
      }
    },
    [setUnauthenticatedUser, setAuthToken, authToken],
  );

  // We only want to prevent loading the app if we're waiting on the current user query to complete
  const doesNotHaveStoredUserInfo = !authToken;
  const [currentUserIsUpToDate, setCurrentUserIsUpToDate] = useState(doesNotHaveStoredUserInfo);

  const { data: currentUserData, refetch: refetchCurrentUser, client } = useQuery(CURRENT_USER, {
    skip: !authToken,
    onCompleted: () => {
      setCurrentUserIsUpToDate(true);
    },
  });

  const serverCurrentUser = currentUserData?.currentUser;

  useEffect(() => {
    if (!_.isEmpty(serverCurrentUser)) onAuthUser(serverCurrentUser);
  }, [serverCurrentUser, onAuthUser]);

  const userIsInsightsPro = currentUser?.isInsightsProUser;
  const hasPassword = currentUser?.isVerified;
  const locale = currentUser?.locale || '';

  useEffect(() => {
    const authorizedTraits = userIsInsightsPro
      ? { isInsightsProUser: true, companyType: 'shipper' }
      : { isInsightsProUser: false };

    identifyUser(unauthenticatedUser, authorizedTraits);
  }, [unauthenticatedUser, userIsInsightsPro]);

  useEffect(() => {
    if (authToken) {
      trackEvent(KEYS.USER_LOGIN);
    }
  }, [authToken]);

  function register(variables) {
    return registerRequest({ variables });
  }

  const login = useCallback(
    (variables, redirectTo) => {
      return loginRequest({ variables }).then(({ data: { signInUser } }) => {
        if (redirectUri) {
          window.location.replace(redirectUri);
        } else if (redirectTo) {
          history.replace(redirectTo);
        }

        return signInUser?.user || {};
      });
    },
    [redirectUri, history, loginRequest],
  );

  function toggleUnsubscribedAt() {
    toggleSubscription();
  }

  function logout(routeToSignIn = false) {
    setAuthToken(null);
    clearStorage();
    resetFilters();
    setCurrentUser(null);

    if (client) {
      client.resetStore();
    } else if (loginClient) {
      loginClient.resetStore();
    }

    if (routeToSignIn) {
      history.replace(routes.authSignIn());
    }
  }

  function getUserLanguage() {
    switch (locale.substring(0, 2)) {
      case 'en':
        return 'english';
      case 'es':
        return 'spanish';
      default:
        return 'english';
    }
  }

  useEffect(() => {
    if (i18n.language !== locale) i18n.changeLanguage(locale);
  }, [i18n, locale]);

  const language = getUserLanguage();

  // If we need to load the user, make sure we do that before rendering the rest of the app
  if (!currentUserIsUpToDate) return null;
  // If the emailToken authentication is not done, also return out
  if (!emailAuthIsDone) return null;

  return (
    <AuthContext.Provider
      value={{
        unauthenticatedUser,
        user: currentUser,
        refetchCurrentUser,
        isFulfillmentUser: false,
        register,
        login,
        logout,
        resetPasswordRequest,
        createPasswordRequest,
        toggleUnsubscribedAt,
        isAuthenticated: !!emailToken || !!authToken,
        language,
        onAuthUser,
        hasPassword,
        verificationToken,
        setUnauthenticatedUser,
        redirectUri,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  return useContext(AuthContext);
}

const WrappedAuthProvider = withRouter(React.memo(AuthProvider));
const MockAuthProvider = ({ value, children }) => {
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function clearStorage() {
  window?.localStorage?.clear();
  window?.analytics?.reset();
  Cookies.remove(INSIGHTS_SESSION_TOKEN, { domain: COOKIE_DOMAIN });
  Cookies.remove(INSIGHTS_EMAIL_ADDRESS, { domain: COOKIE_DOMAIN });
}

export {
  WrappedAuthProvider as AuthProvider,
  MockAuthProvider,
  useAuth,
  AUTH_TOKEN_KEY,
  EMAIL_AUTH_TOKEN_KEY,
  TRUE_USER_TOKEN_KEY,
  CURRENT_USER,
};
