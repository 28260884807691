import qs from 'qs';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { trackPageView } from '../helpers/tracking';

/*
 * useHistoryListener will track every route change and page view, replacing the legacy `usePageTracking`.
 */
export default function useHistoryListener() {
  // only let the initial view tracker run one time
  const isExecutedRef = useRef(false);
  const history = useHistory();

  // the history listener doesn't track the initial view, here is the workaround
  const trackInitialView = () => {
    if (!isExecutedRef.current) {
      trackPageView(history?.location?.pathname ?? '');
      isExecutedRef.current = true;
    }
  };

  // once and done
  trackInitialView();

  // track all subsequent navigation through the application
  useEffect(() => {
    return history.listen((location) => {
      const queryObj = qs.parse(window?.location?.search ?? '', { ignoreQueryPrefix: true });
      trackPageView(location?.pathname ?? '', {
        ...queryObj,
      });
    });
  }, [history]);
}
