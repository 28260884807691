import React from 'react';

import { ToastProvider } from '@producepay/pp-ui';
import { AdProvider } from './ad';
import { AdminProvider } from './admin';
import { AuthProvider } from './auth';
import { FilterStateProvider } from './FilterState';
import { SearchStateProvider } from './SearchState';
import { DmrCommodityVarietyProvider } from './DmrCommodityVarieties';
import { PreferencesProvider } from './preferences';
import { NotificationRequestProvider } from './NotificationRequests';
import { ChatProvider } from './Chat';
import { FeatureFlagsProvider } from './FeatureFlags';

function AppProviders(props) {
  return (
    <FeatureFlagsProvider>
      <FilterStateProvider>
        <NotificationRequestProvider>
          <ToastProvider>
            <AuthProvider>
              <ChatProvider>
                <AdminProvider>
                  <DmrCommodityVarietyProvider>
                    <AdProvider>
                      <SearchStateProvider>
                        <PreferencesProvider>{props.children}</PreferencesProvider>
                      </SearchStateProvider>
                    </AdProvider>
                  </DmrCommodityVarietyProvider>
                </AdminProvider>
              </ChatProvider>
            </AuthProvider>
          </ToastProvider>
        </NotificationRequestProvider>
      </FilterStateProvider>
    </FeatureFlagsProvider>
  );
}

export default React.memo(AppProviders);
