import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import routes from '../../routes';
import { useAuth } from '../../contexts/auth';

import FocusedLayout from '../../components/layouts/FocusedLayout';
import Onboarding from '../partials/auth/Onboarding';

function OnboardingRoute() {
  const history = useHistory();
  const { state } = useLocation();
  const { isAuthenticated, verificationToken } = useAuth();

  return (
    <FocusedLayout>
      <Onboarding
        onOnboardingCompleted={() => {
          const nextRoute = isAuthenticated
            ? state?.from || routes.home()
            : verificationToken
            ? routes.authSetPassword(verificationToken)
            : routes.authVerify();

          history.push(nextRoute);
        }}
      />
    </FocusedLayout>
  );
}

export default React.memo(OnboardingRoute);
