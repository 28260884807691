import { gql } from '@apollo/client';

import { USER_FRAGMENT } from '../../contexts/auth';

// Use current user fragment so it updates the information stored for currentUser without us needing
// to do anything
export const UPDATE_ONBOARDING_INFORMATION = gql`
  ${USER_FRAGMENT}
  mutation UpdateOnboardingInformation($accessToken: String!, $onboardingInformation: OnboardingInformationInput!) {
    updateOnboardingInformation(accessToken: $accessToken, onboardingInformation: $onboardingInformation) {
      ...currentUserFragment
    }
  }
`;
