import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Autocomplete from 'react-google-autocomplete';

import { Field, useFormikContext } from 'formik';
import { FormLabel } from '@producepay/pp-ui';

function FormikLocationSearch(props) {
  const {
    name,
    placeholder,
    onChange,
    label,
    hasLabel,
    helperText,
    shouldPrependErrors,
    labelClassName,
    fieldClassName,
    error,
  } = props;
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const { t } = useTranslation(['formFields', 'formErrors']);

  return (
    <Field name={name} onChange={onChange}>
      {({ form: { touched, errors } }) => {
        const resolvedLabel = label || t([`formFields:${name}`, _.startCase(name)]);
        const defaultError = touched[name] ? t([`formErrors:${errors[name]}`, errors[name] || '']) : null;

        return (
          <FormLabel
            className={labelClassName}
            label={hasLabel ? resolvedLabel : null}
            helperText={helperText}
            shouldPrependErrors={shouldPrependErrors}
            error={error || defaultError}
          >
            <Autocomplete
              className={fieldClassName}
              placeholder={placeholder}
              apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
              onPlaceSelected={(place) => {
                setFieldTouched(name);
                setFieldValue(name, place.formatted_address);
              }}
            />
          </FormLabel>
        );
      }}
    </Field>
  );
}

FormikLocationSearch.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  hasLabel: PropTypes.bool,
  helperText: PropTypes.string,
  shouldPrependErrors: PropTypes.bool,
  fieldClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  error: PropTypes.string,
};

FormikLocationSearch.defaultProps = {
  hasLabel: true,
};

export default React.memo(FormikLocationSearch);
