import React, { useState, useEffect, useContext, useRef } from 'react';
import { StreamChat } from 'stream-chat';
import { useAuth } from 'contexts/auth';

const debug = require('debug')('pp:chat');

const ChatContext = React.createContext();

const ChatProvider = ({ children }) => {
  const { user } = useAuth();
  const { email = '', accessToken = '', streamToken = '' } = user || {};
  const client = StreamChat.getInstance(process.env.REACT_APP_STREAM_ID);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const state = useRef();

  useEffect(() => {
    const disconnect = () => {
      if (state.current === 'connected') {
        setUnreadMessages(0);
        debug('disconnect');
        client.disconnectUser();
        state.current = 'disconnected';
      }
    };

    const initChat = async () => {
      if (streamToken) {
        debug('connecting', streamToken, email);
        const streamUser = await client.connectUser(
          {
            id: accessToken,
            name: email,
          },
          streamToken,
        );

        /* eslint-disable-next-line camelcase */
        setUnreadMessages(streamUser?.me?.total_unread_count);
      }
    };

    if (state.current !== 'connecting' || state.current !== 'connected') {
      state.current = 'connecting';
      initChat();
    }

    if (!streamToken) {
      disconnect();
    }

    return () => {
      disconnect();
    };
  }, [accessToken, email, streamToken, client]);

  client.on((event) => {
    if (event.total_unread_count !== undefined) {
      setUnreadMessages(event.total_unread_count);
    }

    if (event.type === 'connection.changed') {
      state.current = event.online ? 'connected' : 'disconnected';
      debug('state', state.current);
    }
  });

  return <ChatContext.Provider value={{ client, unreadMessages, state }}>{children}</ChatContext.Provider>;
};

const useChatState = () => {
  return useContext(ChatContext);
};

const MockChatContext = ({ children }) => {
  const client = StreamChat.getInstance(process.env.REACT_APP_STREAM_ID);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const state = useRef();
  return (
    <ChatContext.Provider value={{ client, unreadMessages, setUnreadMessages, state }}>{children}</ChatContext.Provider>
  );
};

export { ChatProvider, MockChatContext, useChatState };
