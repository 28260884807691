import React from 'react';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import routes from 'routes';
import { MOBILE_NAVBAR_HEIGHT } from 'styles/constants';

import { useResponsiveLayout } from 'helpers/dom';

import FinancingIcon from './images/financing.svg';
import HomeIcon from './images/home.svg';
import InsightsIcon from './images/insights.svg';
import TradingIcon from './images/trading.svg';

const navlinkCName =
  'flex pt-4 pb-3 border-transparent justify-center items-center font-semibold w-full px-2 opacity-50 text-xs';
const navLinkColumnCName = 'flex flex-col items-center';

function MobileBottomNavBar({ isFooterSticky, isFooterDisplayed }) {
  const { isLowerRes } = useResponsiveLayout();
  const { t } = useTranslation(['navBar', 'common']);

  const position = isFooterSticky ? 'sticky' : 'fixed';

  return (
    <>
      {isLowerRes && (
        <div
          data-testid="mobile-bottom-navbar-container"
          style={{ height: MOBILE_NAVBAR_HEIGHT, position, left: 0 }}
          className={cx('flex border-t p-4 bg-gray-100 sticky bottom-0 w-full z-30', {
            hidden: !isFooterDisplayed,
          })}
        >
          <a className={navlinkCName} activeStyle={{ opacity: 1 }} href={routes.marketplace()}>
            <div className={navLinkColumnCName}>
              <img className="max-w-xs" src={HomeIcon} alt={t('common:home')} />
              <span className="pt-1">{t('navBar:home')}</span>
            </div>
          </a>
          <a className={navlinkCName} activeStyle={{ opacity: 1 }} href={routes.financing()}>
            <div className={navLinkColumnCName}>
              <img className="max-w-xs" src={FinancingIcon} alt={t('common:home')} />
              <span className="pt-1">{t('navBar:financing')}</span>
            </div>
          </a>
          <a className={navlinkCName} activeStyle={{ opacity: 1 }} href={routes.trading()}>
            <div className={navLinkColumnCName}>
              <img className="max-w-xs" src={TradingIcon} alt={t('common:home')} />
              <span className="pt-1">{t('navBar:trading')}</span>
            </div>
          </a>
          <NavLink className={navlinkCName} activeStyle={{ opacity: 1 }} to={routes.marketInsights()}>
            <div className={navLinkColumnCName}>
              <img className="max-w-xs" src={InsightsIcon} alt={t('navBar:insights')} />
              <span className="pt-1">{t('navBar:insights')}</span>
            </div>
          </NavLink>
        </div>
      )}
    </>
  );
}

MobileBottomNavBar.propTypes = {
  isFooterSticky: PropTypes.bool,
  isFooterDisplayed: PropTypes.bool,
};

MobileBottomNavBar.defaultProps = {
  isFooterSticky: true,
  isFooterDisplayed: true,
};

export default React.memo(MobileBottomNavBar);
