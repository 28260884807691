import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import format from 'date-fns/format';
import subDays from 'date-fns/subDays';
import subYears from 'date-fns/subYears';
import _ from 'lodash';
import { useParams, useHistory } from 'react-router-dom';

import routes from '../../routes';
import {
  getTypeFromSlug,
  isAcceptableReportType,
  SHIPPING_POINT_SLUG,
  getLocationGroupNameFromType,
} from '../../helpers/reportTypes';
import useScrollReset from '../../hooks/useScrollReset';

import GeneralLayout from '../../components/layouts/GeneralLayout';
import { useDmrCommodityVarieties } from '../../contexts/DmrCommodityVarieties';

import CommodityVarietyShowView from './view';

const FETCH_SUMMARY_DATA = gql`
  query loadCommodityVarietyData(
    $reportType: PriceReportTypeEnum!
    $commodityUuids: [String!]
    $commodityVarietyInfoIds: [String!]!
    $tableFilters: PriceReportFilterInputs
    $tableGroupings: [String!]!
    $popularSkuFilter: PriceReportFilterInputs
  ) {
    commoditySummaries(reportType: $reportType, commodityVarietyInfoIds: $commodityVarietyInfoIds) {
      id
      previousDate
      pricingDayChange
      pricingWeekChange
      movementWeekChange
      movementYearChange
      hasGrowingRegions
      reportType
    }
    tablePricingData: groupedPriceReports(reportType: $reportType, filters: $tableFilters, groupings: $tableGroupings) {
      reportDate
      varietySkuName
      originLocationName
      destinationLocationName
      averagePrice
      highPrice
      lowPrice
      commodityId
    }
    commodityGrowingRegions(commodityUuids: $commodityUuids, inSeason: true) {
      growingRegionName
    }
    mostPopularSkus(reportType: $reportType, filters: $popularSkuFilter, showAllSkuNames: true) {
      varietySkuName
      commodityPackageId
      allVarietySkuNames
    }
  }
`;

const DATE_FORMAT_STR = 'yyyy/MM/dd';

function CommodityVarietyShow() {
  useScrollReset();

  const history = useHistory();
  const { commodityUuid, varietyUuid, commodityVarietyInfoId, reportType } = useParams();
  const { loading: dmrCvsLoading, itemFromUuids, itemFromId } = useDmrCommodityVarieties();

  const cvInfo = commodityVarietyInfoId
    ? itemFromId(commodityVarietyInfoId)
    : itemFromUuids(commodityUuid, varietyUuid);

  useEffect(() => {
    if (!isAcceptableReportType(reportType) && !dmrCvsLoading) {
      if (cvInfo?.id) {
        history.replace(routes.cvShow(cvInfo?.id, SHIPPING_POINT_SLUG));
      } else {
        history.replace(routes.home());
      }
    }
  }, [history, reportType, cvInfo, dmrCvsLoading]);

  const { loading: summaryLoading, error: summaryError, data: summaryData } = useQuery(FETCH_SUMMARY_DATA, {
    skip: _.isEmpty(cvInfo),
    variables: {
      reportType: getTypeFromSlug(reportType),
      commodityUuids: [cvInfo?.commodityUuid],
      varietyUuids: [cvInfo?.varietyUuid || '0'],
      commodityVarietyInfoIds: [_.toString(cvInfo.id)],
      tableFilters: {
        commodityVarietyInfoId: [_.toString(cvInfo.id)],
        startDate: format(subDays(new Date(), 8), DATE_FORMAT_STR),
        endDate: format(subDays(new Date(), 1), DATE_FORMAT_STR),
      },
      tableGroupings: ['reportDate', 'varietySkuName', 'commodityId', getLocationGroupNameFromType(reportType)],
      movementGroups: {
        commodityUsdaName: true,
        interval: 'week',
        yearOverYear: true,
      },
      popularSkuFilter: {
        commodityVarietyInfoId: [String(cvInfo?.id)],
        startDate: format(subYears(new Date(), 1), DATE_FORMAT_STR),
      },
    },
  });

  return (
    <GeneralLayout shouldShowAd isNavBarSticky={false}>
      <CommodityVarietyShowView
        reportType={reportType}
        summaryLoading={dmrCvsLoading || summaryLoading}
        summaryError={summaryError}
        summaryData={summaryData}
        cvInfo={cvInfo}
      />
    </GeneralLayout>
  );
}

export default React.memo(CommodityVarietyShow);
