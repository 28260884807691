import { gql } from '@apollo/client';

export const CREATE_STREAM_TOKEN_AND_CHANNEL = gql`
  mutation generateStreamTokenAndCreateChannel(
    $accessToken: String!
    $marketRequestId: String!
    $requestType: String!
  ) {
    generateStreamTokenAndCreateChannel(
      accessToken: $accessToken
      marketRequestId: $marketRequestId
      requestType: $requestType
    ) {
      streamToken
    }
  }
`;
