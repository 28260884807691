import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { gql, useQuery, useApolloClient } from '@apollo/client';
import routes from 'routes';

import ProfileModalView from './view';

import { MARKET_REQUEST_LOADS_FRAGMENT } from 'graphql/fragments/market-requests';

const FETCH_MARKET_REQUEST_LOAD = gql`
  ${MARKET_REQUEST_LOADS_FRAGMENT}
  query FetchMarketRequestLoad($id: String!) {
    marketRequestLoad(id: $id) {
      ...marketRequestLoadsFragment
    }
  }
`;

function ProfileModal({ onCloseRoute, isSupply }) {
  const history = useHistory();
  const location = useLocation();
  const { loadId } = useParams();
  const client = useApolloClient();

  const load = client.readFragment({
    id: `MarketRequestLoad:${loadId}`,
    fragment: gql`
      ${MARKET_REQUEST_LOADS_FRAGMENT}
    `,
    fragmentName: isSupply ? 'marketRequestLoadsFragment' : 'marketRequestsWithImagesAndUserBidsFragment',
  });

  const { data, loading: isMarketRequestLoading } = useQuery(FETCH_MARKET_REQUEST_LOAD, {
    variables: { id: `${loadId}` },
    skip: load,
  });

  const chatWithSeller = () => {
    history.push(routes.distributionTerms(), { load: load || data?.marketRequestLoad, closeRoute: location });
  };

  return (
    <ProfileModalView
      isMarketRequestLoading={isMarketRequestLoading}
      load={load || data?.marketRequestLoad}
      onClose={() => history.push(onCloseRoute)}
      onCloseRoute={onCloseRoute}
      isSupply={isSupply}
      onSubmitMarketRequestInterest={chatWithSeller}
    />
  );
}

export default ProfileModal;
