import React from 'react';
import cx from 'classnames';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { Modal, Button } from '@producepay/pp-ui';

import FormikTextField from '../../../../components/formik/FormikTextField';
import FormikSelect from '../../../../components/formik/FormikSelect';
import FormikPhoneInput from '../../../../components/formik/FormikPhoneInput';
import FormikGeneralErrors from '../../../../components/formik/FormikGeneralErrors';

import ProgressBar from '../ProgressBar';

import { COUNTRY_AND_CODES_MAP } from '../../auth/Onboarding/helpers';
import { PROFILE_INFO_STEP, TOTAL_STEPS } from '../helpers';

const PROFILE_INFO_VALIDATION_SCHEMA = yup.object().shape({
  fullName: yup.string().required('is required'),
  companyName: yup.string().required('is required'),
  phoneNumber: yup.string().required('is required'),
  country: yup.string().required('is required'),
});

function ProfileInfoStep({ onboardingInformation, onSubmit, onPrev, modalProps, isMobile }) {
  const { t } = useTranslation('marketplaceListSupply');

  const shippingLocationItems = [
    {
      label: t('canada'),
      value: 'Canada',
    },
    {
      label: t('chile'),
      value: 'Chile',
    },
    {
      label: t('mexico'),
      value: 'Mexico',
    },
    {
      label: t('peru'),
      value: 'Peru',
    },
    {
      label: t('unitedStates'),
      value: 'United States',
    },
    {
      label: t('other'),
      value: 'Other',
    },
  ];

  const progressPercent = (PROFILE_INFO_STEP / TOTAL_STEPS) * 100;

  return (
    <Formik
      initialValues={{
        fullName: onboardingInformation?.fullName,
        companyName: onboardingInformation?.companyName,
        phoneNumber: onboardingInformation?.phoneNumber || '',
        country: onboardingInformation?.country,
      }}
      validationSchema={PROFILE_INFO_VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <Modal
          className="mt-20"
          title={t('title')}
          headerContent={t('subtitle')}
          footerContent={
            <>
              <ProgressBar progressPercent={progressPercent} />
              <div className="flex w-full p-4 justify-between border-t">
                <Button
                  label={t('common:previous')}
                  variant="outlined"
                  onClick={onPrev}
                  className={cx('mr-2', { 'w-full': isMobile })}
                  data-testid="btn-prev"
                />
                <Button
                  label={t('common:continue')}
                  type="submit"
                  onClick={() => handleSubmit(values)}
                  disabled={isSubmitting}
                  className={isMobile ? 'w-full' : ''}
                  data-testid="btn-submit"
                />
              </div>
            </>
          }
          {...modalProps}
        >
          <FormikGeneralErrors />

          <Form>
            <div className="py-2">
              <FormikTextField
                name="fullName"
                label={t('formFields:fullName')}
                placeholder="Johnny Appleseed"
                data-testid="input-fullname"
              />
            </div>
            <div className="py-2">
              <FormikTextField
                name="companyName"
                placeholder="Great Apple Co."
                label={t('formFields:companyName')}
                data-testid="input-company-name"
              />
            </div>
            <div className="py-2">
              <FormikPhoneInput
                name="phoneNumber"
                defaultCountry={COUNTRY_AND_CODES_MAP[onboardingInformation?.country] || 'US'}
                data-testid="input-phone-number"
              />
            </div>
            <div className="py-2">
              <FormikSelect
                name="country"
                label={t('shippingCountryLabel')}
                items={shippingLocationItems}
                data-testid="input-country"
              />
            </div>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

export default ProfileInfoStep;
