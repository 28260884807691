import React from 'react';

const UserIcon = (props) => (
  <svg viewBox="0 0 26 26" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 17C15.7614 17 18 14.6892 18 11.8387C18 8.98821 15.7614 6.67742 13 6.67742C10.2386 6.67742 8 8.98821 8 11.8387C8 14.6892 10.2386 17 13 17Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 23.1935C5.66667 21.129 8.2 17 13 17C17.8 17 20.3333 21.129 21 23.1935"
      stroke="currentColor"
      strokeWidth="2"
    />
    <circle cx="13" cy="13" r="12" stroke="currentColor" strokeWidth="1.5" />
  </svg>
);

export default UserIcon;
