import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useStreamChatRedirect from 'hooks/useStreamChatRedirect';
import routes from 'routes';
import { useAuth } from 'contexts/auth';
import { useResponsiveLayout } from 'helpers/dom';

import { Button } from '@producepay/pp-ui';

function CTAButton({ classes, label, isSupply, variant, marketRequestId }) {
  const history = useHistory();
  const { isMobileOrTablet } = useResponsiveLayout();
  const { user } = useAuth();
  const { t } = useTranslation(['marketplace']);
  const [hasBeenClicked, setHasBeenClicked] = useState(false);

  useStreamChatRedirect(marketRequestId, 'demand', hasBeenClicked);

  const rejected = user?.onboardingInformation?.supplyListingsApplicationStatus === 'rejected';
  if (rejected) return null;

  let ctaAction = () => setHasBeenClicked(true);

  if (isSupply) {
    ctaAction = () => history.push(routes.marketplaceListSupply());
  }

  return (
    <>
      {isMobileOrTablet ? (
        <div className="flex justify-center w-1/2 px-2">
          <Button
            className="w-full font-bold"
            label={isSupply ? t('marketplace:uploadListing') : t('marketplace:chatWithUs')}
            onClick={ctaAction}
            data-testid="btn-marketplace-cta"
            color="primary"
          />
        </div>
      ) : (
        <Button
          className={classes}
          label={label}
          color="primary"
          onClick={ctaAction}
          variant={variant}
          data-testid="btn-marketplace-cta"
          borderRadius=""
        />
      )}
    </>
  );
}

CTAButton.propTypes = {
  classes: PropTypes.string,
  label: PropTypes.string,
  isSupply: PropTypes.bool.isRequired,
  variant: PropTypes.string,
  marketRequestId: PropTypes.string,
};

CTAButton.defaultProps = {
  classes: '',
  label: '',
  variant: 'solid',
  marketRequestId: '',
};

export default CTAButton;
