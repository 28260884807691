import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import routes from '../../routes';
import { useAuth } from '../../contexts/auth';

function AuthModalLink({ authModalPage, ...rest }) {
  const { isAuthenticated, hasPassword } = useAuth();
  const defaultAuthModalPage = isAuthenticated
    ? routes.authModalOnboarding()
    : hasPassword
    ? routes.authModalSignIn()
    : routes.authModalRegister();

  return <Link to={(location) => `${location.pathname}/${authModalPage || defaultAuthModalPage}`} {...rest} />;
}

AuthModalLink.propTypes = {
  authModalPage: PropTypes.oneOf([
    routes.authModalSignIn(),
    routes.authModalRegister(),
    routes.authModalOnboarding(),
    routes.authModalVerify(),
    routes.authModalForgotPassword(),
    routes.authModalSetPassword(),
  ]),
};

export default React.memo(AuthModalLink);
