export const SHIPPING_POINT_SLUG = 'shipping-point';
export const TERMINAL_POINT_SLUG = 'terminal-point';

export const SLUG_TO_TYPE_MAP = {
  'shipping-point': 'ShippingPointPriceReport',
  'terminal-point': 'TerminalPointPriceReport',
};

export function getTypeFromSlug(slug) {
  return SLUG_TO_TYPE_MAP[slug] || 'ShippingPointPriceReport';
}

export function isAcceptableReportType(slug) {
  return !!SLUG_TO_TYPE_MAP[slug];
}

export function getLocationGroupNameFromType(reportType) {
  return reportType === SHIPPING_POINT_SLUG ? 'originLocationName' : 'destinationLocationName';
}
