const _ = require('lodash');

const UUID_REGEX = '[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}';

const authModalSignIn = () => 'auth/sign-in';
const authModalRegister = () => 'auth/register';
const authModalOnboarding = () => 'auth/onboarding';
const authModalVerify = () => 'auth/verify';
const authModalForgotPassword = () => 'auth/forgot-password';
const authModalSetPassword = (token = ':token') => `auth/${token}/set-password`;
const authModalDmrSent = () => 'auth/complete';

const showCustomer = (customerId = ':customerId') => `/customers/${customerId}`;
/*
  The regex here replaces parentheses to be URL encoded
  '(' becomes '%28' and ')' becomes '%29'
 */
const showCommodity = (commodityName = ':commodityName', varietyName = ':varietyName?') =>
  `/commodities/${_.compact([commodityName, varietyName]).join('/')}`.replace(/\(/g, '%28').replace(/\)/g, '%29');

const marketplaceBaseUrl = process.env.REACT_APP_MARKETPLACE_BASE_URL;

module.exports = {
  /* Routes for everyone */
  home: () => '/',
  commodityVarietyShow: (commodityId = `:commodityUuid(${UUID_REGEX})`, varietyId = `:varietyUuid(${UUID_REGEX})?`) =>
    `/commodities-varieties/${_.compact([commodityId, varietyId]).join('/')}`,
  cvShow: (cvInfoId = ':commodityVarietyInfoId', reportType = ':reportType') =>
    `/commodity-varieties/${cvInfoId}/${reportType}`,
  buildingPriceReport: () => '/building-price-report',
  marketplace: () => marketplaceBaseUrl,
  marketplaceCreateBid: () => marketplaceBaseUrl,
  marketplaceDemand: () => marketplaceBaseUrl,
  marketplaceInsightsPriceReport: (type) =>
    new URL(['insights', 'price-report', type].filter((path) => !!path).join('/'), marketplaceBaseUrl),
  marketplaceInsightsFreightReport: () => new URL(['insights', 'freight-report'].join('/'), marketplaceBaseUrl),
  marketplaceInsightsWatchlist: () => new URL(['insights', 'watchlist'].join('/'), marketplaceBaseUrl),
  marketplaceProfile: () => `${marketplaceBaseUrl}/profile`,
  marketplaceSupply: () => marketplaceBaseUrl,
  marketplaceListSupply: () => marketplaceBaseUrl,
  marketplaceLogin: () => `${marketplaceBaseUrl}/account/login`,
  marketplaceForgotPassword: () => `${marketplaceBaseUrl}/account/login#forgot-password`,
  marketplaceSignUp: () => `${marketplaceBaseUrl}/account/sign-up`,
  sellerDashboard: () => `${marketplaceBaseUrl}/dashboard`,
  sellerStorefront: () => `${marketplaceBaseUrl}/seller/my-storefront`,
  tierFAQ: () => marketplaceBaseUrl,
  tierBuyerFAQ: () => marketplaceBaseUrl,
  tierSellerFAQ: () => marketplaceBaseUrl,
  trading: () => `${marketplaceBaseUrl}/trading`,
  distributionTerms: () => marketplaceBaseUrl,
  /* Unauthenticated routes */
  authModal: () => 'auth',
  authModalSignIn,
  authSignIn: () => `/${authModalSignIn()}`,
  authModalRegister,
  authRegister: () => `/${authModalRegister()}`,
  authModalOnboarding,
  authModalVerify,
  authVerify: () => `/${authModalVerify()}`,
  authModalForgotPassword,
  authForgotPassword: () => `/${authModalForgotPassword()}`,
  authModalSetPassword,
  authSetPassword: (token = ':token') => `/${authModalSetPassword(token)}`,
  authResetPassword: (token = ':token') => `/auth/${token}/reset-password`,
  authModalDmrSent,
  conversations: () => `${marketplaceBaseUrl}/conversations`,
  /* Authenticated routes - user must be signed in */
  dashboard: () => '/dashboard',
  dashboardPerformance: () => '/dashboard/performance',
  dashboardCommodities: () => '/dashboard/commodities',
  dashboardCustomers: () => '/dashboard/customers',
  dashboardForecasting: () => '/dashboard/forecasting',
  dashboardSalesPeople: () => '/dashboard/sales-people',
  financing: () => `${marketplaceBaseUrl}/financing`,
  forecasting: () => '/forecasting',
  logout: () => '/logout',
  // User routes - user must be known (email addressed stored/passed through params to auth context)
  fulfillmentShipments: () => '/fulfillment/shipments',
  fulfillmentAcceptShipment: (slug = ':slug') => `/fulfillment/shipments/${slug}/accept`,
  fulfillmentAcceptedShipments: (slug = ':slug') => `/fulfillment/shipments/${slug}/accepted`,
  fulfillmentDisputedShipment: (slug = ':slug') => `https://producepay.typeform.com/to/z6e8pfNF#slug=${slug}`,
  onboarding: () => '/onboarding',
  profile: () => '/profile',
  company: () => '/company',
  createOrder: () => '/fulfillment/orders/create',
  createOrderWithMarketplaceLoad: (loadId = ':loadId', channelId = ':channelId') =>
    `/fulfillment/orders/create?loadId=${loadId}&channelId=${channelId}`,
  ordersList: () => '/fulfillment/orders/list',
  orders: () => '/fulfillment/orders',
  viewOrder: (orderId = ':orderId') => `/fulfillment/orders/${orderId}/view`,
  updateOrder: (orderId = ':orderId') => `/fulfillment/orders/${orderId}/update`,
  signOrder: (orderId = ':orderId') => `/fulfillment/orders/${orderId}/sign`,
  uploadDocuments: (orderId = ':orderId') => `/fulfillment/orders/${orderId}/uploadDocuments`,
  ordersBuying: () => '/fulfillment/orders/buying',
  ordersSelling: () => '/fulfillment/orders/selling',
  financeRequest: () => `/fulfillment/financingRequest/create`,
  skuMapping: () => '/sku-mapping',
  marketInsights: () => '/market-insights',
  marketInsightsWatchlist: () => '/market-insights/watchlist',
  marketInsightsWatchlistAddCommodities: () => '/market-insights/watchlist/add-commodities',
  marketInsightsPriceReport: (type = ':reportType') => `/market-insights/price-report/${type}`,
  marketInsightsFreightReport: () => '/market-insights/freight-report',
  customers: () => '/customers',
  showCustomer,
  showCustomerPerformance: (customerId) => `${showCustomer(customerId)}/performance`,
  showCustomerCommodities: (customerId) => `${showCustomer(customerId)}/commodities`,
  showCustomerSalesReport: (customerId) => `${showCustomer(customerId)}/sales-report`,
  commodities: () => '/commodities',
  showCommodity,
  showCommoditySales: (commodityName, varietyName) => `${showCommodity(commodityName, varietyName)}/performance`,
  showCommodityCustomers: (commodityName, varietyName) => `${showCommodity(commodityName, varietyName)}/customers`,
  showCommodityTransactions: (commodityName, varietyName) =>
    `${showCommodity(commodityName, varietyName)}/transactions`,
  marketplaceAddCommodities: () => marketplaceBaseUrl,
  // admin only routes
  impersonate: () => '/admin/impersonate',
};
