import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

import { Button } from '@producepay/pp-ui';
import logo from '../../../assets/images/logo-orange-only-square.svg';
import blackberryImg from './images/blackberry.svg';
import './notification-request.css';

function NotificationRequest(props) {
  const { t } = useTranslation(['pushNotifications']);

  const { isOpen, onDeny, onAccept, header, body, onExited } = props;

  return (
    <CSSTransition in={isOpen} timeout={200} appear classNames="notification-request" unmountOnExit onExited={onExited}>
      <div className="bg-white fixed bottom-0 left-0 w-full h-auto rounded-lg rounded-b-none py-6 px-6">
        <p className="text-lg font-bold text-center">{header}</p>
        <p className="mt-2 mb-4 text-center">{body}</p>
        <div className="text-xs">
          <div className="flex justify-between items-center p-3 rounded-t-lg" style={{ background: '#E9E9E9' }}>
            <div className="flex items-center">
              <img src={logo} className="w-4 h-4 bg-white rounded-sm" alt="produce-pay" />
              <span className="ml-2">PRODUCEPAY</span>
            </div>
            <div>{t('now')}</div>
          </div>
          <div className="flex justify-between items-center p-3 rounded-b-lg" style={{ background: '#F4F4F4' }}>
            <span>{t('sampleBody')}</span>
            <img src={blackberryImg} alt="blackberries" />
          </div>
        </div>
        <Button className="mt-6 mb-2 w-full" label="Yes, notify me" onClick={() => onAccept()} />
        <Button className="mt-2 w-full" label="Not now" variant="text" onClick={() => onDeny()} />
      </div>
    </CSSTransition>
  );
}

NotificationRequest.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDeny: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  onExited: PropTypes.func,
  header: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

NotificationRequest.defaultProps = {
  isOpen: false,
  onDeny: () => {},
  onAccept: () => {},
  onExited: () => {},
  header: '',
  body: '',
};

export default React.memo(NotificationRequest);
