import _ from 'lodash';

export function orderByDateStr(list, key, dir = 'desc') {
  if (_.isEmpty(list)) return [];

  const sortedList = [...list]; // need this or javascript will throw an error trying to sort list

  return sortedList.sort((a, b) => {
    const date1 = new Date(a[key]);
    const date2 = new Date(b[key]);

    return dir === 'desc' ? date2 - date1 : date1 - date2;
  });
}

export function takeNth(arr, nth) {
  if (!Array.isArray(arr)) return arr;

  return arr.filter((val, idx) => idx % nth === 0);
}

export function notEq(obj) {
  return _.negate(_.matches(obj));
}

export const isPresent = _.negate(_.isEmpty);

export function removeFalseyValuesFromObj(obj) {
  return _.pickBy(obj, _.identity);
}

export function toggleArrayElementBy(array, item, key) {
  if (_.find(array, { [key]: item[key] })) {
    return _.reject(array, { [key]: item[key] });
  }
  return [...array, item];
}

export function makeKeyedMapFromArray(array) {
  return _.zipObject(
    array,
    array.map(() => true),
  );
}
