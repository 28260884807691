import React from 'react';

import routes from '../../routes';
import { useAuth } from '../../contexts/auth';

function SignInRoute() {
  const { logout } = useAuth();

  logout();

  window.location.replace(routes.marketplaceLogin());

  return null;
}

export default React.memo(SignInRoute);
