import React from 'react';
import PropTypes from 'prop-types';

import AdDisplay from '../../../contexts/ad/AdDisplay';
import NavBar from '../../molecules/NavBar';
import MobileBottomNavBar from '../../molecules/MobileBottomNavBar';

function GeneralLayout({ children, shouldShowAd, isNavBarSticky, isFooterSticky, isFooterDisplayed }) {
  return (
    <>
      {shouldShowAd && <AdDisplay placement="top" />}

      <NavBar isSticky={isNavBarSticky} />

      <div>{children}</div>

      <MobileBottomNavBar isFooterSticky={isFooterSticky} isFooterDisplayed={isFooterDisplayed} />
    </>
  );
}

GeneralLayout.propTypes = {
  shouldShowAd: PropTypes.bool,
  isNavBarSticky: PropTypes.bool,
  isFooterSticky: PropTypes.bool,
  isFooterDisplayed: PropTypes.bool,
};

GeneralLayout.defaultProps = {
  shouldShowAd: false,
  isNavBarSticky: true,
  isFooterSticky: true,
  isFooterDisplayed: true,
};

export default React.memo(GeneralLayout);
