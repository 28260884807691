import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import cx from 'classnames';

import { Button, LoadingSpinner } from '@producepay/pp-ui';

import logo from '../../assets/images/pp-logo.svg';

const COMMON_CONTAINER_CLASSNAMES = 'w-11/12 md:w-3/4 my-0 mx-auto bg-white shadow';
const MAILTO_HREF =
  'mailto:trading@producepay.com?subject=Add%20to%20email%20contacts&body=Hello!%0A%0ASend%20this%20email%20to%20automatically%20add%20us%20to%20your%20contacts.%20Doing%20so%20will%20help%20ensure%20that%20you%20never%20miss%20a%20daily%20price%20report%20email.%20You%20do%20not%20need%20to%20make%20any%20changes%20to%20this%20email.%20%0A%0APlease%20let%20us%20know%20if%20you%20have%20any%20questions!%0A%0AThe%20ProducePay%20Team%0A%0A----%0A%0A%C2%A1Hola!%0A%0AEnvia%20este%20correo%20para%20que%20de%20manera%20autom%C3%A1tica%20nos%20encontremos%20en%20tus%20contactos.%20Al%20hacer%20esto%20nos%20aseguramos%20de%20que%20nunca%20te%20pierdas%20ning%C3%BAn%20correo%20con%20el%20reporte%20de%20precios.%20No%20necesitas%20hacer%20ning%C3%BAn%20cambio%20en%20este%20correo.%0A%C2%A1Si%20tienes%20dudas%2C%20por%20favor%20h%C3%A1znoslo%20saber!%0A%0AEl%20equipo%20de%20ProducePay';

function BuildingPriceReportView() {
  const { t } = useTranslation('buildingPriceReport');

  return (
    <div data-testid="building-price-report" className="w-full h-screen">
      <div className="w-full p-6">
        <img className="w-40 my-0 mx-auto" src={logo} alt="ProducePay" />
      </div>
      <div className={cx(COMMON_CONTAINER_CLASSNAMES, 'p-6 md:p-8 text-center')}>
        <div className="py-4 md:py-8">
          <LoadingSpinner />
        </div>
        <div className="text-lg md:text-2xl text-gray-900 font-bold">{t('loadingTitle')}</div>
        <div className="py-2 md:py-4 text-sm md:text-base">{t('loadingSubtitle')}</div>
      </div>
      <div
        className={cx(
          COMMON_CONTAINER_CLASSNAMES,
          'my-4 md:my-6 lg:my-8 px-6 md:px-8 py-4 md:py-6 flex flex-col lg:flex-row justify-between items-center text-center lg:text-left',
        )}
      >
        <div className="flex-grow-0 pr-0 md:pr-6 lg:pr-8">
          <div className="font-bold md:text-lg">{t('ctaTitle')}</div>
          <div className="mt-1 text-sm md:text-base leading-5">
            <Trans i18nKey="ctaSubtitle">
              Adding&nbsp;
              <a href={MAILTO_HREF} className="text-blue-500 font-bold">
                trading@producepay.com
              </a>
              &nbsp;to your email contacts will help ensure that your custom price report makes it to your inbox.
            </Trans>
          </div>
        </div>
        <div className="flex-grow w-full md:w-auto mt-4 lg:mt-0">
          <a href={MAILTO_HREF}>
            <Button className="w-full whitespace-no-wrap">{t('ctaAction')}</Button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default BuildingPriceReportView;
