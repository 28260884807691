import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import cx from 'classnames';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Card } from '@producepay/pp-ui';
import { MOBILE_NAVBAR_HEIGHT } from 'styles/constants';
import { trackEvent, KEYS } from 'helpers/tracking';
import { useResponsiveLayout } from 'helpers/dom';
import { isPresent } from 'helpers/lodash';
import { SHIPPING_POINT_SLUG, TERMINAL_POINT_SLUG } from 'helpers/reportTypes';
import { usePreviousSearches } from './helpers';
import { useAuth } from 'contexts/auth';
import { useDmrCommodityVarieties } from 'contexts/DmrCommodityVarieties';
import { useSearchState } from 'contexts/SearchState';
import useMenu from 'hooks/useMenu';
import { formatCommodityVarietyName } from 'helpers/format';
import routes from 'routes';

import SearchSection from './SearchSection';

const FS_MENU_WRAPPER_CNAME = 'fixed inset-0 bg-white z-30 w-full';
const FS_MENU_CNAME = 'h-full overflow-y-auto scrolling-touch';

const SEARCH_QUERY = gql`
  query AuthenticatedSearch($term: String!) {
    erpSearch(term: $term) {
      objectType
      identifier
      subIdentifier
      value
    }
  }
`;

const SearchResults = ({
  searchText,
  erpSearchResults,
  usdaSearchResults,
  handleRouteClick,
  previousSearches,
  loading,
}) => {
  const { t } = useTranslation(['navBar', 'common']);

  return (
    <Card className="h-full" data-testid="navbar-search-results">
      {isPresent(searchText.trim()) ? (
        isPresent(erpSearchResults) || isPresent(usdaSearchResults) ? (
          <>
            <SearchSection
              title="Customers"
              items={_.filter(erpSearchResults, { objectType: 'customer' })}
              onRouteClick={handleRouteClick}
            />

            <SearchSection
              title="Products"
              items={_.filter(erpSearchResults, { objectType: 'product' })}
              onRouteClick={handleRouteClick}
            />

            <SearchSection
              title={t('common:marketInsights')}
              items={usdaSearchResults}
              onRouteClick={handleRouteClick}
            />
          </>
        ) : !loading ? (
          <div className="p-4 text-center">No Results Found</div>
        ) : null
      ) : (
        <SearchSection
          title="Recent Searches"
          items={previousSearches}
          onRouteClick={handleRouteClick}
          shouldShowHelperText
        />
      )}
    </Card>
  );
};

function Search({ className, setShowSearch }) {
  const { isLowerRes } = useResponsiveLayout();
  const { user, isAuthenticated } = useAuth();
  const { searchUsdaCommodities, itemFromUuids } = useDmrCommodityVarieties();
  const { shouldDisplayMenu, setShouldDisplayMenu, menuWrapperRef } = useMenu();
  const { setSearchStateText } = useSearchState();
  const { pathname } = useLocation();
  const { t } = useTranslation(['navBar', 'marketplace']);

  const [previousSearches, addPreviousSearch] = usePreviousSearches(itemFromUuids);
  const [searchText, setSearchText] = useState('');
  const inMarketplacePage = pathname === routes.marketplaceDemand() || pathname === routes.marketplaceSupply();

  const { data, loading } = useQuery(SEARCH_QUERY, {
    variables: { term: searchText },
    skip: !isAuthenticated || !user?.isInsightsProUser || _.isEmpty(searchText) || inMarketplacePage,
  });
  const erpSearchResults = _.get(data, 'erpSearch');

  setSearchStateText(searchText);

  const usdaSearchResults = searchUsdaCommodities(searchText).map((opt) => ({
    objectType: 'insights',
    identifier: opt.id,
    subIdentifier: opt.hasShippingPointData === false ? TERMINAL_POINT_SLUG : SHIPPING_POINT_SLUG,
    value: formatCommodityVarietyName(opt.commodityName, opt.varietyName),
  }));
  const sortedUsdaSearchResults = _.sortBy(usdaSearchResults, 'value');

  const handleRouteClick = (item) => {
    trackEvent(KEYS.SEARCH_RESULT_CLICKED, item);
    addPreviousSearch(item);
    setShouldDisplayMenu(false);
    setSearchText('');
    if (setShowSearch) {
      setShowSearch(false);
    }
  };

  const hasSomethingToShow =
    isPresent(erpSearchResults) ||
    isPresent(previousSearches) ||
    (isPresent(sortedUsdaSearchResults) && searchText.length > 1);

  return (
    <div ref={menuWrapperRef} className={cx(className, 'relative')}>
      <input
        className="sm:text-sm lg:text-base w-full rounded-full px-4 py-2 outline-none h-full border lg:py-4 lg:px-0 lg:border-0 lg:rounded-none appearance-none"
        data-testid="navbar-search"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onFocus={() => {
          setShouldDisplayMenu(true);
          if (setShowSearch) {
            setShowSearch(true);
          }
        }}
        placeholder={inMarketplacePage ? t('marketplace:searchPlaceholder') : t('searchCommodities')}
      />

      {shouldDisplayMenu && hasSomethingToShow && !inMarketplacePage ? (
        isLowerRes ? (
          <div className="lg:hidden">
            <div
              className={FS_MENU_WRAPPER_CNAME}
              style={{ top: MOBILE_NAVBAR_HEIGHT, height: `calc(100% - ${MOBILE_NAVBAR_HEIGHT}px)` }}
            >
              <div className={FS_MENU_CNAME}>
                <SearchResults
                  searchText={searchText}
                  erpSearchResults={erpSearchResults}
                  usdaSearchResults={sortedUsdaSearchResults}
                  handleRouteClick={handleRouteClick}
                  previousSearches={previousSearches}
                  loading={loading}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="hidden lg:block">
            <div
              className="absolute w-full overflow-y-auto shadow"
              style={{ paddingTop: 1, maxHeight: 'calc(100vh - 64px)' }}
            >
              <SearchResults
                searchText={searchText}
                erpSearchResults={erpSearchResults}
                usdaSearchResults={sortedUsdaSearchResults}
                handleRouteClick={handleRouteClick}
                previousSearches={previousSearches}
                loading={loading}
              />
            </div>
          </div>
        )
      ) : null}
    </div>
  );
}

export default React.memo(Search);
