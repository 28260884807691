import { ORDERS_QUERY } from 'graphql/queries/myOrders';
import { useFulfillmentQuery } from '../../../apollo-client';
import { getCommoditiesName } from 'helpers/commodityReducedNames';

export const OrderStatus = {
  created: 'CREATED',
  booked: 'BOOKED',
  disputed: 'DISPUTED',
  accepted: 'ACCEPTED',
};

export const OrderAction = {
  AWAITING_SIGNATURE: 'awaiting_signature',
  AWAITING_ACCEPTANCE: 'awaiting_acceptance',
};

const useMyOrders = () => {
  const response = useFulfillmentQuery(ORDERS_QUERY, { fetchPolicy: 'cache-and-network' });

  if (!response.data) return response;

  const { getOrders } = response.data;
  const orders = getOrders.orders.map((order) => {
    const supplierNames = order.suppliers.map(({ name }) => name).join(' & ');
    const receiverNames = order.receivers.map(({ name }) => name).join(' & ');
    const commodityName = getCommoditiesName(order.inventoryItems);
    return { ...order, supplierNames, receiverNames, commodityName };
  });

  return { ...response, data: orders };
};

export default useMyOrders;
