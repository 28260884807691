import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Switch, Redirect, Route } from 'react-router-dom';
import routes from '../../routes';

import AuthModalRoute from '../partials/AuthModalRoute';

import MarketplaceSupply from './MarketplaceSupply';
import MarketplaceDemand from './MarketplaceDemand';
import MarketplaceNav from './partials/MarketplaceNav';
import TabBar from './partials/TabBar';

import AddCommoditiesModal from '../partials/AddCommoditiesModal';
import SupplyListingsModal from '../partials/SupplyListingsModal';
import ProfileModal from '../partials/ProfileModal';
import TierFAQModal from '../partials/TierFAQModal';
import DistributionTermsModal from '../partials/DistributionTermsModal';

export const TOGGLE_MY_WATCHLIST_KEY = 'watchlist';
export const TOGGLE_ALL_COMMODITIES_KEY = 'all-commodities';

function MarketplaceView({ toggleKey, setToggleKey, cviPreferenceMap, onAddCommodities, onRemoveCommodities }) {
  const { t } = useTranslation('marketplace');

  return (
    <>
      <div className="bg-gray-light min-h-screen">
        <div className="lg:w-4/5 m-auto" style={{ maxWidth: '1200px' }}>
          <TabBar />
          <MarketplaceNav t={t} toggleKey={toggleKey} setToggleKey={setToggleKey} />

          <Switch>
            <Route path={routes.marketplaceSupply()}>
              <MarketplaceSupply toggleKey={toggleKey} />
              <AuthModalRoute
                registerTitle={t('marketInsights:authModal.signUpToEdit')}
                signInTitle={t('marketInsights:authModal.logInToEdit')}
              />
              <Route path={routes.marketplaceCreateBid()}>
                <ProfileModal onCloseRoute={routes.marketplaceSupply()} isSupply />
              </Route>
            </Route>

            <Route path={routes.marketplaceDemand()}>
              <MarketplaceDemand toggleKey={toggleKey} />
              <AuthModalRoute
                registerTitle={t('marketInsights:authModal.signUpToEdit')}
                signInTitle={t('marketInsights:authModal.logInToEdit')}
              />
              <Route path={routes.marketplaceCreateBid()}>
                <ProfileModal onCloseRoute={routes.marketplaceDemand()} isSupply={false} />
              </Route>
            </Route>

            <Route exact path={routes.marketplaceListSupply()}>
              <MarketplaceSupply toggleKey={toggleKey} />
              <SupplyListingsModal onCloseRoute={routes.marketplaceSupply()} />
            </Route>

            <Route path={routes.marketplaceAddCommodities()}>
              <AddCommoditiesModal
                onCloseRoute={routes.marketplace()}
                subscribedCviIdsMap={cviPreferenceMap}
                onAddCommodities={onAddCommodities}
                onRemoveCommodities={onRemoveCommodities}
              />
            </Route>

            <Route path={routes.tierFAQ()}>
              <TierFAQModal />
            </Route>

            <Route path={routes.distributionTerms()}>
              <DistributionTermsModal />
            </Route>

            <Redirect to={routes.marketplaceSupply()} />
          </Switch>
        </div>
      </div>
    </>
  );
}

MarketplaceView.propTypes = {
  toggleKey: PropTypes.oneOf([TOGGLE_MY_WATCHLIST_KEY, TOGGLE_ALL_COMMODITIES_KEY]).isRequired,
  setToggleKey: PropTypes.func.isRequired,
  cviPreferenceMap: PropTypes.object,
  onAddCommodities: PropTypes.func.isRequired,
  onRemoveCommodities: PropTypes.func.isRequired,
};

MarketplaceView.defaultProps = {
  marketRequests: [],
  cviPreferenceMap: {},
  user: null,
};

export default MarketplaceView;
