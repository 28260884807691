import React from 'react';

const SvgProduct = ({ size = 24, color = 'currentColor', ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 16 14" fill="none" {...rest}>
    <path
      d="M2.465 2.483H.62A6.456 6.456 0 007.074 8.94v4.151c0 .254.208.462.461.462h.922c.254 0 .461-.208.461-.462v-4.15a6.456 6.456 0 00-6.453-6.458zM13.527.638a6.447 6.447 0 00-5.637 3.32 7.392 7.392 0 011.7 3.103A6.458 6.458 0 0015.37.638h-1.844z"
      fill={color}
    />
  </svg>
);

export default SvgProduct;
