import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { get } from 'lodash';
import { gql, useMutation } from '@apollo/client';

import { useAuth } from '../../../../contexts/auth';
import { CLAIM_ACCOUNT } from '../Register';
import routes from '../../../../routes';

import VerifyView from './view';

const VERIFY_USER = gql`
  mutation VerifyUser($token: String!) {
    verifyUser(token: $token) {
      inviteToken
      email
    }
  }
`;

function VerifyUser(props) {
  const history = useHistory();
  const { onVerified = (token) => history.push(routes.authSetPassword(token)) } = props;

  const { refetchCurrentUser, user, unauthenticatedUser } = useAuth();

  const email = unauthenticatedUser?.email;
  const [emailResent, setEmailResent] = useState(false);

  const [verifyUser] = useMutation(VERIFY_USER, {
    onCompleted: (data) => {
      const token = get(data, 'verifyUser.inviteToken');
      onVerified(token);
    },
  });

  const [claimAccount] = useMutation(CLAIM_ACCOUNT, {
    onCompleted: () => {
      setEmailResent(true);
    },
  });

  const onVerifyUserSubmit = useCallback(
    (values) => {
      return verifyUser({ variables: values });
    },
    [verifyUser],
  );
  const resendEmail = useCallback(() => {
    return claimAccount({ variables: { email } });
  }, [claimAccount, email]);

  useEffect(() => {
    const intervalId = setInterval(() => refetchCurrentUser(), 4000);
    return () => clearInterval(intervalId);
  }, [refetchCurrentUser]);

  if (user?.isVerified) return <Redirect to={routes.home()} />;

  return (
    <VerifyView verifyUser={onVerifyUserSubmit} email={email} resendEmail={resendEmail} emailResent={emailResent} />
  );
}

export default VerifyUser;
