import _ from 'lodash';
import genericIcon from 'assets/images/generic-icon.svg';

export const textSearchCompare = (search, text) => !search || text.toLowerCase().includes(search.toLowerCase());

export const textSearchCompareByWord = (search, text) =>
  !search || _.every(search.split(' '), (word) => text.toLowerCase().includes(word.toLowerCase()));

export const extractGraphqlErrorMessages = (error) => _.map(error?.graphQLErrors, 'message');

export const extractGraphqlErrorDetails = (error) => {
  const detailMap = {};

  _.forEach(error?.graphQLErrors, (errorObj) => {
    _.forEach(errorObj?.extensions, (errorMessageArray, errorKey) => {
      const camelErrorKey = _.camelCase(errorKey);

      if (!detailMap[camelErrorKey]) detailMap[camelErrorKey] = [];
      detailMap[camelErrorKey].push(...errorMessageArray);
    });
  });

  return _.mapValues(detailMap, (errorsArray) => errorsArray.join(', '));
};

export const parseJson = (data) => {
  let parsedData;
  try {
    parsedData = JSON.parse(data);
  } catch {
    parsedData = data;
  }

  return parsedData;
};

export const imageFromLoad = (load) => {
  const uploadedImages = load?.imageUrls;
  const lastUploadeImage = uploadedImages?.[uploadedImages.length - 1]?.small;
  return lastUploadeImage || load?.commodityVarietyInfo?.commodity?.imageUrls?.original || genericIcon;
};
