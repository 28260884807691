import { useEffect } from 'react';

export default function useHideIntercom(shouldHideIntercom) {
  useEffect(() => {
    let intervalId;

    if (shouldHideIntercom) {
      intervalId = setInterval(() => {
        if (window.Intercom) {
          window.Intercom('update', { hide_default_launcher: true });
          clearInterval(intervalId);
        }
      }, 500);

      return () => {
        clearInterval(intervalId);
        if (window.Intercom) window.Intercom('update', { hide_default_launcher: false });
      };
    }
    clearInterval(intervalId);
    if (window.Intercom) window.Intercom('update', { hide_default_launcher: false });
  }, [shouldHideIntercom]);
}
