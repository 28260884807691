import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { snakeCase } from 'lodash';

import genericIcon from 'assets/images/generic-icon.svg';

import { generateSizeString } from 'helpers/format';

function CommodityNameDisplay(props) {
  const { isSmall, isSupply, labelClassName, load } = props;

  const imgWidth = isSmall ? 'w-10' : 'w-16';

  const sizeString = generateSizeString(load, isSupply);

  return (
    <div className="flex items-center" style={props.style} data-testid={snakeCase(props.name)}>
      <img
        src={props.imageUrl || genericIcon}
        alt={props.name}
        className={cx({ 'hidden md:inline-block': isSmall }, { 'inline-block': !isSmall }, imgWidth, 'h-auto mr-4')}
      />

      <div
        className={cx(
          { 'text-sm md:text-base': isSmall },
          { 'flex flex-col': !!sizeString },
          'leading-none py-4',
          labelClassName,
        )}
      >
        <span className={cx({ 'font-bold': sizeString })}>{props.name}</span>
        {sizeString && <span className="mt-1 text-gray-600 text-sm">{sizeString}</span>}
      </div>
    </div>
  );
}

CommodityNameDisplay.propTypes = {
  isSmall: PropTypes.bool,
  isSupply: PropTypes.bool,
  labelClassName: PropTypes.string,
  load: PropTypes.object,
};

CommodityNameDisplay.defaultProps = {
  isSmall: false,
  isSupply: true,
  labelClassName: '',
  load: {},
};

export default React.memo(CommodityNameDisplay);
