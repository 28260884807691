import React from 'react';

import routes from '../../routes';
import { useAuth } from '../../contexts/auth';

function VerifyRoute() {
  const { logout } = useAuth();

  logout();

  window.location.replace(routes.marketplace());

  return null;
}

export default React.memo(VerifyRoute);
