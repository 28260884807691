import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAdmin } from '../../contexts/admin';
import routes from '../../routes';

function AdminRoute({ component: Component, render, children, ...rest }) {
  const { isAdmin } = useAdmin();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAdmin ? (
          render ? (
            render(props)
          ) : Component ? (
            <Component {...props} />
          ) : null
        ) : (
          <Redirect to={routes.marketInsights()} />
        )
      }
    />
  );
}

export default React.memo(AdminRoute);
