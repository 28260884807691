import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Calendar, MapPin, ShoppingCart } from 'react-feather';

import goldStar from 'assets/images/gold-star.svg';
import greenCheck from 'assets/images/green-check.svg';
import backgroundCheckShield from 'assets/images/background-check-shield.svg';

import { capitalizeEachWord, formatYearString } from 'helpers/format';
import { getTierRating, backgroundCheckComplete } from 'helpers/load';

function SummarySection({ load, isMobile }) {
  const { t } = useTranslation(['profileModal']);

  const companyProfile = load?.companyProfile || {};
  const { commoditiesSold = null, companyName = '', location = null, yearEstablished = null } = companyProfile;
  const tierRating = getTierRating(load);
  const isBackgroundCheckComplete = backgroundCheckComplete(tierRating);
  const isSupply = load?.requestType === 'supply' || true;

  return (
    <div className={cx('summary-container', { 'py-4': isMobile, 'py-8': !isMobile })}>
      <div className="border-b-1 mb-4">
        <span
          className={cx('border-b-4 border-green-dark font-semibold inline-block pb-2', {
            'text-xl': isMobile,
            'text-2xl': !isMobile,
          })}
        >
          {t('companyProfile')}
        </span>
      </div>
      <div className={cx('flex flex-wrap items-center', { 'text-xl': isMobile, 'text-2xl': !isMobile })}>
        <span
          className={cx('font-semibold', {
            'mr-2': isMobile,
            'mr-4': !isMobile,
          })}
        >
          {capitalizeEachWord(companyName)}
        </span>
        {isBackgroundCheckComplete && (
          <div className="flex">
            <img src={backgroundCheckShield} alt="blue shield" />
          </div>
        )}
        {tierRating === 'Gold Verified' ? (
          <div className="flex">
            <div
              className={cx('flex items-center text-gold-medium text-sm rounded bg-gold-light font-semibold ml-2', {
                'px-1 py-0': isMobile,
                'px-2 py-1': !isMobile,
              })}
            >
              <img src={goldStar} alt="gold star" style={isMobile ? { height: '12px' } : { height: '16px' }} />
              <span className="ml-2">
                {isSupply ? t('summary.goldVerifiedSeller') : t('summary.goldVerifiedBuyer')}
              </span>
            </div>
          </div>
        ) : tierRating === 'Verified' ? (
          <div className="flex">
            <div
              className={cx('flex items-center text-primary text-sm rounded bg-primary-light font-semibold ml-2', {
                'px-1 py-0': isMobile,
                'px-2 py-1': !isMobile,
              })}
            >
              <img src={greenCheck} alt="green check" style={isMobile ? { height: '10px' } : { height: '8px' }} />
              <span className="ml-2">{isSupply ? t('summary.verifiedSeller') : t('summary.verifiedBuyer')}</span>
            </div>
          </div>
        ) : null}
      </div>
      {location && (
        <div className={cx('flex items-center', { 'mt-2': isMobile, 'mt-4': !isMobile })}>
          <MapPin className="text-gray-medium mr-2" style={{ height: '18px' }} />
          <div>{location}</div>
        </div>
      )}
      {yearEstablished && (
        <div className={cx('flex items-center', { 'mt-2': isMobile, 'mt-4': !isMobile })}>
          <Calendar className="text-gray-medium mr-2" style={{ height: '17px' }} />
          <div>Since {formatYearString(yearEstablished)}</div>
        </div>
      )}
      {commoditiesSold && (
        <div className={cx('flex items-center', { 'mt-2': isMobile, 'mt-4': !isMobile })}>
          <ShoppingCart className="text-gray-medium mr-2" style={{ height: '16px' }} />
          <div>{commoditiesSold}</div>
        </div>
      )}
    </div>
  );
}

SummarySection.propTypes = {
  load: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
};

SummarySection.defaultProps = {
  isMobile: false,
};

export default SummarySection;
