export default {
  fontFamily: 'Inter',
  fontSize: 12,
  axis: {
    ticks: {
      text: {
        fontFamily: 'Inter',
        fontSize: 12,
      },
    },
  },
};
