import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Field } from 'formik';
import { FormLabel, PhoneInput } from '@producepay/pp-ui';

function FormikPhoneInput(props) {
  const { name, label, helperText, shouldPrependErrors, hasLabel, className, ...rest } = props;

  const { t } = useTranslation(['formFields', 'formErrors']);

  return (
    <Field name={name}>
      {({ field, form: { touched, errors } }) => {
        const phoneInputProps = {
          ...field,
          ...rest,
          onChange: (value) => field.onChange({ target: { name, value } }),
        };

        return label || hasLabel ? (
          <FormLabel
            className={className}
            label={label || t([`formFields:${name}`, _.startCase(name)])}
            helperText={helperText}
            shouldPrependErrors={shouldPrependErrors}
            error={touched[name] ? t([`formErrors:${errors[name]}`, errors[name] || '']) : null}
          >
            <PhoneInput {...phoneInputProps} />
          </FormLabel>
        ) : (
          <PhoneInput className={className} {...phoneInputProps} />
        );
      }}
    </Field>
  );
}

FormikPhoneInput.propTypes = {
  ...PhoneInput.propTypes,
  name: PropTypes.string.isRequired,
  hasLabel: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FormikPhoneInput.defaultProps = {
  hasLabel: true,
};

export default React.memo(FormikPhoneInput);
