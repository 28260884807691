import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { Formik, Form } from 'formik';
import routes from '../../../../routes';
import { useAuth } from '../../../../contexts/auth';
import { extractGraphqlErrorDetails } from '../../../../helpers/common';

import FormikTextField from '../../../../components/formik/FormikTextField';
import LanguagePicker from '../../../../components/molecules/LanguagePicker';
import FormikSubmit from '../../../../components/formik/FormikSubmit';
import TermsAndPrivacy from '../TermsAndPrivacy';

const CLAIM_ACCOUNT_SCHEMA = yup.object().shape({
  email: yup.string().email('is invalid').required('is required'),
});

export const HEADER_CLASS_NAMES = 'text-xl text-black text-center font-bold pb-4';

function RegisterView({ className, title, register, basePath }) {
  const { unauthenticatedUser, user } = useAuth();
  const accessToken = unauthenticatedUser?.token;
  const existingEmail = user?.email || unauthenticatedUser?.email;

  const { t } = useTranslation(['authRegister', 'common']);
  const [hasPasswordBeenSet, setHasPasswordBeenSet] = useState(false);

  return (
    <Formik
      initialValues={{ email: existingEmail || '' }}
      validationSchema={CLAIM_ACCOUNT_SCHEMA}
      onSubmit={(values, { setFieldError, setSubmitting }) => {
        register(values).catch((e) => {
          const details = extractGraphqlErrorDetails(e);
          setSubmitting(false);

          if (details.userPassword) {
            setHasPasswordBeenSet(true);
          } else {
            setFieldError('email', e.message);
          }
        });
      }}
    >
      {({ values }) => (
        <Form className={cx(className, 'flex flex-col items-center')} data-testid="auth-register">
          <h3 className="text-xl font-bold pb-2">{title || t('title')}</h3>

          <TermsAndPrivacy className="pb-4" />

          <div className="w-full mt-6">
            <LanguagePicker accessToken={accessToken} />
          </div>
          <div className="w-full mt-6 mb-10">
            <FormikTextField name="email" type="email" helperText={t('helperText')} />
          </div>

          {hasPasswordBeenSet ? (
            <div className="mb-4 text-red-600 font-medium text-sm">
              <Trans i18nKey="hasPasswordError" t={t}>
                There is already an account associated with this email.
                <Link
                  className="text-primary underline"
                  to={{
                    pathname: `${basePath}${routes.authSignIn()}`,
                    state: { email: values?.email },
                  }}
                >
                  Click here to log in.
                </Link>
                ,
              </Trans>
            </div>
          ) : null}

          <FormikSubmit className="w-40" label={t('common:next')} />

          <div className="my-8 w-full border-b" />

          <div className="text-sm">
            {t('haveAnAccount')}&nbsp;
            <Link to={`${basePath}${routes.authSignIn()}`} className="text-sm text-primary">
              {t('common:logInHere')}
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
}

RegisterView.propTypes = {
  register: PropTypes.func.isRequired,
  basePath: PropTypes.string,
};

RegisterView.defaultProps = {
  basePath: '',
};

export default RegisterView;
