import fetch from 'unfetch';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, useQuery, from, useMutation } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { trim } from 'lodash';
import { logError } from 'helpers/logger';
import { useToast } from '@producepay/pp-ui';
import Cookies from 'js-cookie';

import {
  AUTH_TOKEN_KEY,
  EMAIL_AUTH_TOKEN_KEY,
  TRUE_USER_TOKEN_KEY,
  INSIGHTS_EMAIL_ADDRESS,
  INSIGHTS_SESSION_TOKEN,
  COOKIE_DOMAIN,
  UNAUTHENTICATED_USER_EMAIL_KEY,
  clearStorage,
} from './contexts/auth';
import { LOCALE_KEY } from './i18n';
import blacklist from './json/errors-blacklist';
import routes from './routes';

export const FULFILLMENT_API_CLIENT_NAME = 'fulfillment-api';

const dataPlatformLink = new HttpLink({
  uri: trim(process.env.REACT_APP_DATA_PLATFORM_URL),
  fetch,
  headers: {},
});

const fulfillmentApiLink = new HttpLink({
  uri: trim(process.env.REACT_APP_FULFILLMENT_API_URL),
  fetch,
  headers: {},
});

const debug = require('debug')('pp:appolloError');

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  const pathsToSkipRedirection = ['/auth/verify'];
  const { operationName, variables = {} } = operation || {};

  if ('password' in variables) {
    delete variables.password;
  }

  const currentPath = window.location.pathname;
  const shouldPathBeRedirected = !pathsToSkipRedirection.includes(currentPath);

  if (graphQLErrors && graphQLErrors.some((err) => err.message === 'Unauthenticated') && shouldPathBeRedirected) {
    clearStorage();
    window?.location?.replace(routes.authSignIn());
  }

  const ERR_TEMPLATE = [`[Apollo Global Error] - *${operationName}*`];
  ERR_TEMPLATE.push(`variables: ${JSON.stringify(variables)}`);

  if (graphQLErrors) {
    graphQLErrors
      .filter((err) => !blacklist.includes(err.message))
      .forEach(({ message, locations, path }, idx) => {
        const error = [...ERR_TEMPLATE];
        error.push(`[${idx}]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`);
        debug('error', error.join(' - '));
        logError(error.join(' - '));
      });
  }
  if (networkError) {
    const error = [...ERR_TEMPLATE, `${networkError.statusCode}: ${networkError.bodyText}`, networkError];
    debug('[Network error]:', error.join(' - '));
    logError(`[Network error]: ${error.join(' - ')}`);
  }
});

const authLink = setContext((request, { headers }) => {
  const authToken = window.localStorage.getItem(AUTH_TOKEN_KEY);
  const emailAuthToken = window.localStorage.getItem(EMAIL_AUTH_TOKEN_KEY);
  const token = emailAuthToken || authToken;
  const locale = window.localStorage.getItem(LOCALE_KEY);
  const trueUserToken = window.localStorage.getItem(TRUE_USER_TOKEN_KEY);
  setCookies();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'Accept-Language': locale,
      ...(trueUserToken && { 'X-True-User-Auth': trueUserToken }),
    },
  };
});

export const useFulfillmentQuery = (query, options = {}) => {
  const { addToastToQueue } = useToast();
  return useQuery(query, {
    context: {
      clientName: FULFILLMENT_API_CLIENT_NAME,
      ...options.context,
    },
    onError: (error) => {
      addToastToQueue({ header: 'Error!', body: error?.message, type: 'error', actions: [{ label: 'Dismiss' }] });
    },
    ...options,
  });
};

export const useFulfillmentMutation = (mutation, options = {}) => {
  return useMutation(mutation, {
    context: {
      clientName: FULFILLMENT_API_CLIENT_NAME,
      ...options.context,
    },
    ...options,
  });
};

const httpLink = authLink.concat(
  ApolloLink.split(
    (operation) => operation.getContext().clientName === FULFILLMENT_API_CLIENT_NAME,
    fulfillmentApiLink,
    dataPlatformLink,
  ),
);

const setCookies = () => {
  const authToken = window?.localStorage?.getItem(AUTH_TOKEN_KEY);
  const email = window?.localStorage?.getItem(UNAUTHENTICATED_USER_EMAIL_KEY);

  if (!Cookies.get(INSIGHTS_SESSION_TOKEN) && !!authToken) {
    Cookies.set(INSIGHTS_SESSION_TOKEN, authToken, { domain: COOKIE_DOMAIN });
  }
  if (!Cookies.get(INSIGHTS_EMAIL_ADDRESS) && !!email) {
    Cookies.set(INSIGHTS_EMAIL_ADDRESS, email, { domain: COOKIE_DOMAIN });
  }
};

export default new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache(),
});
