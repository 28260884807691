import React from 'react';
import { get, map } from 'lodash';

import { Button, PageSpinner } from '@producepay/pp-ui';

const Impersonate = ({ impersonateUser, stopImpersonating, loading, data, currentUser, trueUser }) => {
  return (
    <div className="w-full min-h-screen bg-white text-center p-6">
      {loading ? (
        <PageSpinner />
      ) : (
        <div className="m-auto w-3/4 text-left">
          <table className="table-secondary table-alternating-rows table-p-sm w-full">
            <thead>
              <tr>
                <th>User</th>
                <th>Company</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {map(get(data, 'insightsProUsers'), (user) => (
                <tr key={user.email} data-testid="tr-impersonate">
                  <td>{user.email}</td>
                  <td>{get(user, 'company.name')}</td>
                  <td>
                    {user.email === currentUser.email ? (
                      trueUser && trueUser.email === user.email ? (
                        'True User'
                      ) : (
                        <Button variant="text" label="Stop Impersonating" onClick={stopImpersonating} />
                      )
                    ) : user.isVerified ? (
                      <Button label="Impersonate" onClick={() => impersonateUser(user.email)} />
                    ) : (
                      'User has not verified their account'
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Impersonate;
