import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import shortid from 'shortid';
import _ from 'lodash';

import { Field } from 'formik';
import { FormLabel, TextField } from '@producepay/pp-ui';

import './formik-textfield.css';

function FormikTextField(props) {
  const { name, onChange, label, hasLabel, helperText, shouldPrependErrors, className, error, ...rest } = props;

  const { current: fieldId } = useRef(`${name}-${shortid.generate()}`);

  const { t } = useTranslation(['formFields', 'formErrors']);

  return (
    <Field name={name} onChange={onChange}>
      {({ field, form: { touched, errors } }) => {
        const resolvedLabel = label || t([`formFields:${name}`, _.startCase(name)]);
        const defaultError = touched[name] ? t([`formErrors:${errors[name]}`, errors[name] || '']) : null;

        const combinedOnChange = (...args) => {
          field.onChange(...args);
          if (onChange) onChange(...args);
        };

        return (
          <FormLabel
            className={className}
            label={hasLabel ? resolvedLabel : null}
            helperText={helperText}
            shouldPrependErrors={shouldPrependErrors}
            error={error || defaultError}
            htmlFor={fieldId}
          >
            <TextField {...field} {...rest} id={fieldId} onChange={combinedOnChange} />
          </FormLabel>
        );
      }}
    </Field>
  );
}

FormikTextField.propTypes = {
  ...TextField.propTypes,
  name: PropTypes.string.isRequired,
  hasLabel: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FormikTextField.defaultProps = {
  hasLabel: true,
};

export default React.memo(FormikTextField);
