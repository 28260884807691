import React, { useCallback } from 'react';
import { Redirect, Route, Switch, NavLink, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Modal } from '@producepay/pp-ui';
import routes from 'routes';
import { useResponsiveLayout } from 'helpers/dom';

import BuyerTiers from './BuyerTiers';
import SellerTiers from './SellerTiers';

const navlinkCName = 'flex text-2xl border-b-4 pb-3 border-transparent text-gray-4 font-semibold w-auto';

function TierFAQModal() {
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation(['tierFAQ']);
  const { isMobileOrTablet } = useResponsiveLayout();

  const onCloseRoute = pathname.includes(routes.tierSellerFAQ())
    ? routes.marketplaceSupply()
    : routes.marketplaceDemand();

  const handleModalClose = useCallback(() => history.push(onCloseRoute), [history, onCloseRoute]);

  const height = isMobileOrTablet ? '100%' : '90%';

  return (
    <Modal
      style={{ height, maxHeight: height, paddingBottom: '0' }}
      onClose={handleModalClose}
      title={
        <div>
          <div className="text-primary-dark text-center px-36 pt-10">{t('title')}</div>
        </div>
      }
      headerContent={
        <div className="flex justify-center pt-10">
          <NavLink
            className={`mr-4 md:mr-8 ${navlinkCName}`}
            activeClassName="border-secondary"
            activeStyle={{ color: '#086E4F' }}
            to={routes.tierBuyerFAQ()}
          >
            <span>{t('buyerTier')}</span>
          </NavLink>
          <NavLink
            className={`${navlinkCName}`}
            activeClassName="border-secondary"
            activeStyle={{ color: '#086E4F' }}
            to={routes.tierSellerFAQ()}
          >
            <span>{t('sellerTier')}</span>
          </NavLink>
        </div>
      }
    >
      <Switch>
        <Route path={routes.tierBuyerFAQ()}>
          <BuyerTiers />
        </Route>

        <Route path={routes.tierSellerFAQ()}>
          <SellerTiers />
        </Route>

        <Redirect to={routes.tierBuyerFAQ()} />
      </Switch>
    </Modal>
  );
}

export default TierFAQModal;
