import React from 'react';

import { useAuth } from '../../contexts/auth';
import routes from '../../routes';

function RegisterRoute() {
  const { logout } = useAuth();

  logout();

  window.location.replace(routes.marketplaceSignUp());

  return null;
}

export default React.memo(RegisterRoute);
