import { logError } from 'helpers/logger';

export const platformUrl = () => {
  const urlStr = process.env.REACT_APP_DATA_PLATFORM_URL?.trim();
  if (urlStr) {
    return new URL(urlStr);
  }

  logError(`Environment variable: REACT_APP_DATA_PLATFORM_URL[${urlStr}] is invalid.`);
  return null;
};

export const directUploadUrl = () => {
  return [platformUrl()?.origin, 'direct_uploads'].join('/');
};
