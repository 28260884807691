import * as React from 'react';
import { useFeatureFlagsService } from '../../lib/feature-flags/useFeatureFlagsService';
import { withFeatureFlagsProvider } from '../../lib/feature-flags/withFeatureFlagsProvider';
import { useContext } from 'react';

const FeatureFlagsContext = React.createContext({
  initialized: false,
  identifyFeatureFlagUser: undefined,
});

const FeatureFlagsProviderComponent = ({ children }) => {
  const { initialized, identify } = useFeatureFlagsService();

  const identifyFeatureFlagUser = (user = { anonymous: true }) => {
    if (!initialized) {
      return Promise.reject(new Error('FeatureFlagService is not initialized'));
    }

    return identify(user);
  };

  return (
    <FeatureFlagsContext.Provider value={{ identifyFeatureFlagUser, initialized }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

const featureFlagsProvider = withFeatureFlagsProvider(process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_KEY);

export const FeatureFlagsProvider = featureFlagsProvider(React.memo(FeatureFlagsProviderComponent));

export const useFeatureFlagsContext = () => {
  const featureFlagsContext = useContext(FeatureFlagsContext);

  if (!featureFlagsContext) {
    throw new Error('FeatureFlagContext should only be used within FeatureFlagsProvider');
  }

  return featureFlagsContext;
};
