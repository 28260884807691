import React, { useState, useEffect, useContext, useCallback } from 'react';
import { trackEvent, KEYS } from '../../helpers/tracking';
import { debounce } from 'lodash';

const debug = require('debug')('pp:search-state');

const SearchStateContext = React.createContext();

function SearchStateProvider({ children }) {
  const [searchStateText, setSearchStateText] = useState('');

  const debouncedSetSearchText = useCallback(
    debounce((currentSearchText) => {
      if (currentSearchText !== '') {
        trackEvent(KEYS.NAV_SEARCH, { currentSearchText });
        debug(currentSearchText);
      }
    }, 500),
    [],
  );

  useEffect(() => {
    debouncedSetSearchText(searchStateText);
  }, [searchStateText, debouncedSetSearchText]);

  return (
    <SearchStateContext.Provider value={{ searchStateText, setSearchStateText }}>
      {children}
    </SearchStateContext.Provider>
  );
}

const useSearchState = () => {
  return useContext(SearchStateContext);
};

const MockSearchStateProvider = ({ children }) => {
  const [searchStateText, setSearchStateText] = useState('');
  return (
    <SearchStateContext.Provider value={{ searchStateText, setSearchStateText }}>
      {children}
    </SearchStateContext.Provider>
  );
};

export { SearchStateProvider, MockSearchStateProvider, useSearchState };
