import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { Modal, Button } from '@producepay/pp-ui';

import Check from 'react-feather/dist/icons/check';

import ProgressBar from '../ProgressBar';
import { COMMODITY_INFO_STEP, SUPPLY_LISTING_SUBMITTED_STEP, TOTAL_STEPS } from '../helpers';

function SupplyListingSubmittedStep({ onPrev, setLoad, setSizes, isMobile, modalProps, initialStep }) {
  const { t } = useTranslation('marketplaceListSupply');
  const { onClose } = modalProps;
  const progressPercent = (SUPPLY_LISTING_SUBMITTED_STEP / TOTAL_STEPS) * 100;
  const shouldShowNavigation = initialStep !== COMMODITY_INFO_STEP;

  const handlePrev = () => {
    setLoad({});
    setSizes([]);
    onPrev();
  };

  return (
    <Modal
      className="mt-20"
      title={t('supplyListingSubmittedTitle')}
      footerContent={
        <>
          {shouldShowNavigation && <ProgressBar progressPercent={progressPercent} />}
          <div className="flex w-full p-4 justify-between border-t">
            <Button
              label={t('createAnotherListing')}
              variant="outlined"
              onClick={handlePrev}
              className={cx('mr-2', { 'w-full': isMobile })}
              data-testid="btn-prev"
            />
            <Button
              label={t('goToMarketplace')}
              type="submit"
              onClick={onClose}
              className={isMobile ? 'w-full' : ''}
              data-testid="btn-submit"
            />
          </div>
        </>
      }
      footerClassNames={{ container: '' }}
      {...modalProps}
    >
      <div className="w-full flex flex-col items-center px-6">
        <div className="flex justify-center items-center my-10 w-40 h-40 rounded-full bg-primary p-8">
          <Check size={100} color="#FFFFFF" />
        </div>
        <div className="font-bold text-xl">{t('listingReceived')}</div>
        <div className="text-center mt-2 leading-6">{t('listingReceivedNotice')}</div>
      </div>
    </Modal>
  );
}

export default SupplyListingSubmittedStep;
