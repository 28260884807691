import React from 'react';
import { gql, useQuery } from '@apollo/client';

import { useAdmin } from '../../../contexts/admin';
import useScrollReset from '../../../hooks/useScrollReset';

import ProfileLayout from '../../../components/layouts/ProfileLayout';
import ImpersonateView from './view';

const FETCH_USERS = gql`
  query InsightsProUsers {
    insightsProUsers {
      email
      isVerified
      company {
        name
      }
    }
  }
`;

const Impersonate = () => {
  useScrollReset();

  const { impersonateUser, stopImpersonating, trueUser, currentUser } = useAdmin();
  const { data, loading } = useQuery(FETCH_USERS);

  return (
    <ProfileLayout>
      <ImpersonateView
        impersonateUser={impersonateUser}
        stopImpersonating={stopImpersonating}
        trueUser={trueUser}
        loading={loading}
        data={data}
        currentUser={currentUser}
      />
    </ProfileLayout>
  );
};

export default Impersonate;
