import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import * as yup from 'yup';
import _ from 'lodash';

import { Formik, Form } from 'formik';
import { Button } from '@producepay/pp-ui';
import { extractGraphqlErrorMessages } from '../../../../helpers/common';
import FormikGeneralErrors from '../../../../components/formik/FormikGeneralErrors';
import FormikTextField from '../../../../components/formik/FormikTextField';
import Checkmark from '../../../../components/icons/Checkmark';

const VERIFY_SCHEMA = yup.object().shape({
  token: yup.string().length(7, 'must be 7 digits').required('is required'),
});

function VerifyView({ verifyUser, email, resendEmail, emailResent }) {
  const { t } = useTranslation(['authVerify', 'common', 'formErrors']);

  return (
    <Formik
      initialValues={{ token: '' }}
      validationSchema={VERIFY_SCHEMA}
      onSubmit={(values, { setStatus }) => {
        verifyUser(values).catch((error) => {
          const graphqlErrors = extractGraphqlErrorMessages(error);
          const resolvedErrors = _.isEmpty(graphqlErrors) ? [t('formErrors:generalNetworkError')] : graphqlErrors;

          setStatus({ errors: resolvedErrors });
        });
      }}
    >
      {({ setFieldValue, status, setStatus }) => (
        <Form className="flex flex-col items-center">
          <h3 className="text-xl font-bold pb-4">{t('title')}</h3>

          <div className="text-sm text-center">
            <Trans i18nKey="description" t={t}>
              Enter the 7-digit verification code we sent to your email address or click on the link in the email to
              proceed. If you did not receive a verification email, please email
              <a href="mailto:trading@producepay.com" className="text-blue-600">
                trading@producepay.com.
              </a>
            </Trans>
          </div>

          <FormikTextField
            className={`w-full mt-6 ${!_.isEmpty(status?.errors) ? 'mb-6' : 'mb-2'}`}
            label={t('verificationCode')}
            name="token"
            type="token"
            onChange={(e) => {
              const value = _.get(e, 'target.value', '');
              setFieldValue('token', value.toUpperCase());
            }}
          />

          <FormikGeneralErrors className="mb-2" />

          <div className="mb-10">
            {emailResent ? (
              <div className="flex text-success">
                <Checkmark size={14} className="mr-1" />

                <span className="text-sm">{t('emailSent')}</span>
              </div>
            ) : email ? (
              <Button
                type="button"
                className="text-sm"
                label={t('resendEmail')}
                variant="text"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  resendEmail().catch(() => {
                    setStatus({ errors: [t('formErrors:generalNetworkError')] });
                  });
                }}
              />
            ) : null}
          </div>

          <Button type="submit" className="w-40">
            {t('common:next')}
          </Button>
        </Form>
      )}
    </Formik>
  );
}

export default VerifyView;
