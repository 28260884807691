import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import qs from 'qs';
import * as yup from 'yup';
import cx from 'classnames';

import _ from 'lodash';

import { Formik, Form } from 'formik';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@producepay/pp-ui';
import routes from '../../../../routes';
import { VALIDATE_PASSWORD } from 'helpers/validations';
import { extractGraphqlErrorMessages } from 'helpers/common';

import FormikTextField from 'components/formik/FormikTextField';
import GraphqlErrors from 'components/molecules/GraphqlErrors';

const SIGN_IN_SCHEMA = yup.object().shape({
  email: yup.string().email('is invalid').required('is required'),
  password: VALIDATE_PASSWORD,
});

const LoginMessage = ({ t, basePath, isRedirected }) => {
  const payload = {
    i18nKey: 'description',
    message: "If you receive the Daily Market Report, but haven't set a password yet, you will need to&nbsp;",
    link: 'sign up',
  };

  if (isRedirected === 'chat_login') {
    payload.i18nKey = 'loginDescription';
    payload.message = "In order to chat, log in below. If you don't have an account, sign up in seconds&nbsp";
    payload.link = 'here';
  }

  return (
    <Trans i18nKey={payload.i18nKey} t={t}>
      {payload.message}
      <Link className="text-primary" to={`${basePath}${routes.authRegister()}`}>
        {payload.link}
      </Link>
    </Trans>
  );
};

function SignInView({ className, title, login, basePath }) {
  const location = useLocation();
  const from = location?.state?.from;
  const defaultEmail = location?.state?.email;
  // eslint-disable-next-line camelcase
  const { update_preferences, is_redirected: isRedirected } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const { t } = useTranslation(['authSignIn', 'common']);

  // eslint-disable-next-line camelcase
  const isUpdatingPrefs = !!update_preferences;
  const resolvedTitle = title || (isUpdatingPrefs ? t('updatePreferencesTitle') : t('common:logIn'));

  return (
    <Formik
      initialValues={{ email: defaultEmail || '', password: '' }}
      validationSchema={SIGN_IN_SCHEMA}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        login(_.pick(values, ['email', 'password']), from).catch((e) => {
          setSubmitting(false);
          setStatus({ errors: extractGraphqlErrorMessages(e) });
        });
      }}
    >
      {({ isSubmitting, status, setStatus }) => {
        const handleTextFieldChange = () => {
          if (status?.errors) setStatus({});
        };

        return (
          <Form className={cx(className, 'flex flex-col items-center')}>
            <h3 className="text-xl font-bold pb-2">{resolvedTitle}</h3>

            {_.isEmpty(status?.errors) ? (
              <div className="mb-6 text-sm text-center leading-normal px-6">
                <LoginMessage t={t} basePath={basePath} isRedirected={isRedirected} />
              </div>
            ) : (
              <GraphqlErrors className="mt-2" errors={status?.errors} />
            )}

            <FormikTextField className="w-full mb-4" name="email" type="email" onChange={handleTextFieldChange} />
            <FormikTextField className="w-full mb-2" name="password" type="password" onChange={handleTextFieldChange} />

            <Link
              to={`${basePath}${routes.authForgotPassword()}${location.search}`}
              className="block self-end mb-6 text-sm text-primary"
            >
              {t('forgotLink')}
            </Link>

            <Button type="submit" className="w-40 mb-6" disabled={isSubmitting}>
              {t('common:logIn')}
            </Button>

            <div className="mb-4 w-full border-b" />

            <div className="text-sm">
              <Trans i18nKey="noAccount" t={t}>
                Don't have an account yet?&nbsp;
                <Link to={`${basePath}${routes.authRegister()}${location.search}`} className="text-sm text-primary">
                  Sign up for free
                </Link>
              </Trans>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

SignInView.propTypes = {
  login: PropTypes.func.isRequired,
  basePath: PropTypes.string,
};

SignInView.defaultProps = {
  basePath: '',
};

export default React.memo(SignInView);
