import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import * as yup from 'yup';
import { Link } from 'react-router-dom';

import { Formik, Form } from 'formik';
import { Button } from '@producepay/pp-ui';
import routes from '../../../../routes';
import FormikTextField from '../../../../components/formik/FormikTextField';
import CheckmarkCircleOutline from '../../../../components/icons/CheckmarkCircleOutline';
import GraphqlErrors from '../../../../components/molecules/GraphqlErrors';
import { extractGraphqlErrorMessages } from '../../../../helpers/common';

const FORGOT_PASSWORD_SCHEMA = yup.object().shape({
  email: yup.string().email('is invalid').required('is required'),
});

function ForgotPasswordView(props) {
  const { forgotPasswordRequest, basePath } = props;

  const { t } = useTranslation(['authForgotPassword', 'common']);

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={FORGOT_PASSWORD_SCHEMA}
      onSubmit={(values, { setStatus, setSubmitting }) => {
        setStatus(undefined);
        forgotPasswordRequest({ variables: { ...values } })
          .then(({ data: { userForgotPassword } }) => {
            const { user } = userForgotPassword;

            if (user && user.id) {
              setSubmitting(false);
              setStatus({ user });
            }
          })
          .catch((e) => {
            setSubmitting(false);
            setStatus({ errors: extractGraphqlErrorMessages(e) });
          });
      }}
      render={({ isSubmitting, status }) =>
        status?.user ? (
          <div className="flex flex-col items-center">
            <div className="flex mb-2">
              <CheckmarkCircleOutline className="text-primary mr-4" />

              <h3 className="text-xl font-bold pb-4">{t('emailSent')}</h3>
            </div>

            <div className="mb-4">
              <Trans i18nKey="successMessage" t={t} email={status.user.email}>
                An email has been sent to&nbsp;
                <span className="font-semibold">{{ email: status.user.email }}</span>
                &nbsp;with further instructions.
              </Trans>
            </div>

            <div className="pt-4">
              <Link to={`${basePath}${routes.authSignIn()}`}>
                <Button type="button" className="w-40">
                  {t('common:logInHere')}
                </Button>
              </Link>
            </div>
          </div>
        ) : (
          <>
            <h3 className="text-xl text-center font-bold pb-4">{t('title')}</h3>

            <Form>
              <GraphqlErrors errors={status && status.errors ? status.errors : []} />

              <FormikTextField className="mb-6" name="email" type="email" />

              <div className="flex flex-col items-center">
                <Button type="submit" className="w-40 mb-6" disabled={isSubmitting}>
                  {t('common:submit')}
                </Button>

                <div className="mb-4 w-full border-b" />

                <div className="text-sm">
                  {t('rememberPassword')}&nbsp;
                  <Link to={`${basePath}${routes.authSignIn()}`} className="text-sm text-primary">
                    {t('common:logInHere')}
                  </Link>
                </div>
              </div>
            </Form>
          </>
        )
      }
    />
  );
}

ForgotPasswordView.propTypes = {
  forgotPasswordRequest: PropTypes.func.isRequired,
  basePath: PropTypes.string,
};

ForgotPasswordView.defaultProps = {
  basePath: '',
};

export default ForgotPasswordView;
