import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { DateUtils } from 'react-day-picker';
import subDays from 'date-fns/subDays';
import dateFnsParse from 'date-fns/parse';
import isAfter from 'date-fns/isAfter';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import subWeeks from 'date-fns/subWeeks';
import subMonths from 'date-fns/subMonths';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';

import { Select, Button } from '@producepay/pp-ui';

import CustomInput from './CustomInput';

import 'react-day-picker/lib/style.css';
import './datepicker.css';

const LABEL_CNAME = 'text-sm pb-1';

const CUSTOM_SELECT_ITEM = { label: 'Custom Range', value: 'custom' };

const DATEPICKER_FORMAT = 'MM/dd/yyyy';

const START_END_DATE_ERROR = 'must be before End Date';

export const DEFAULT_PRESETS = [
  {
    label: 'Last 7 Days',
    key: 'last_7_days',
    start: subDays(new Date(), 7),
    end: new Date(),
  },
  {
    label: 'Previous Week',
    key: 'previous_week',
    start: startOfWeek(subWeeks(new Date(), 1)),
    end: endOfWeek(subWeeks(new Date(), 1)),
  },
  {
    label: 'Last 30 Days',
    key: 'last_30_days',
    start: subDays(new Date(), 30),
    end: new Date(),
  },
  {
    label: 'Previous Month',
    key: 'previous_month',
    start: startOfMonth(subMonths(new Date(), 1)),
    end: endOfMonth(subMonths(new Date(), 1)),
  },
  {
    label: 'Last 90 Days',
    key: 'last_90_days',
    start: subDays(new Date(), 90),
    end: new Date(),
  },
  {
    label: 'Last 13 Weeks',
    key: 'last_13_weeks',
    start: startOfWeek(subWeeks(new Date(), 13)),
    end: new Date(),
  },
  {
    label: 'Last 365 Days',
    key: 'last_365_days',
    start: subDays(new Date(), 365),
    end: new Date(),
  },
];

function parseInputDate(str, format) {
  const parsed = dateFnsParse(str, format, new Date());
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function DateRangePicker(props) {
  const { dateKey, startDate, endDate, onChange, items } = props;

  const startDateInputRef = useRef(null);
  const startDateInputInnerRef = useRef(null);
  const endDateInputInnerRef = useRef(null);

  const [validationErrors, setValidationErrors] = useState(
    _.pickBy({
      startDate: isAfter(endDate, startDate) ? null : START_END_DATE_ERROR,
    }),
  );

  const applyChanges = useCallback(
    (dateKey, startDate, endDate) => {
      if (isAfter(endDate, startDate)) {
        setValidationErrors({});
        onChange(dateKey, startDate, endDate);
      } else {
        setValidationErrors({ startDate: START_END_DATE_ERROR });
      }
    },
    [onChange],
  );

  const onDateChange = useCallback(
    (item) => {
      const newDateKey = item.value;

      if (newDateKey === 'custom') {
        applyChanges(newDateKey, startDate, endDate);
      } else {
        const newItem = _.find(items, { key: newDateKey });
        applyChanges(newDateKey, newItem.start, newItem.end);
      }
    },
    [applyChanges, items, startDate, endDate],
  );

  const onStartDateInputChange = useCallback(
    (e) => {
      if (startDateInputRef.current) {
        startDateInputRef.current.hideDayPicker();
        e.target.blur();

        if (endDateInputInnerRef.current) {
          // focus the end date input
          endDateInputInnerRef.current.focus();
        }
      }

      const parsedDate = parseInputDate(e.target.value, DATEPICKER_FORMAT);
      if (parsedDate) {
        applyChanges(dateKey, parsedDate, endDate);
      }
    },
    [dateKey, applyChanges, endDate],
  );

  const onEndDateInputChange = useCallback(
    (e) => {
      const parsedDate = parseInputDate(e.target.value, DATEPICKER_FORMAT);
      if (parsedDate) {
        applyChanges(dateKey, startDate, parsedDate);
      }
    },
    [dateKey, applyChanges, startDate],
  );

  const onApplyButtonClick = useCallback(() => {
    const parsedStartDate = parseInputDate(startDateInputInnerRef.current.value, DATEPICKER_FORMAT);
    const parsedEndDate = parseInputDate(endDateInputInnerRef.current.value, DATEPICKER_FORMAT);

    if (parsedStartDate && parsedEndDate) applyChanges(dateKey, parsedStartDate, parsedEndDate);
  }, [dateKey, applyChanges, startDateInputInnerRef, endDateInputInnerRef]);

  const commonDayPickerProps = {
    numberOfMonths: 1,
    showWeekNumbers: true,
    modifiers: {
      start: startDate,
      end: endDate,
    },
    selectedDays: [{ from: startDate, to: endDate }],
  };

  const itemsForSelect = _.concat(
    items.map((i) => ({ label: i.label, value: i.key })),
    CUSTOM_SELECT_ITEM,
  );

  return (
    <div data-testid="date-picker-wrapper">
      <Select
        items={itemsForSelect}
        selectedItem={_.find(itemsForSelect, { value: dateKey })}
        onChange={onDateChange}
        color="primary"
        rounded={false}
      />

      {dateKey === 'custom' ? (
        <div>
          <div className="my-4">
            <div className={LABEL_CNAME}>Start Date</div>
            <div className="w-full">
              <CustomInput
                ref={startDateInputRef}
                value={startDate}
                onChange={(date, modifiers) => {
                  if (modifiers.disabled) return;
                  applyChanges(dateKey, date, endDate);
                }}
                dayPickerProps={{
                  ...commonDayPickerProps,
                  disabledDays: { after: endDate },
                  month: startDate,
                }}
                placeholderDate={startDate}
                inputProps={{
                  ref: startDateInputInnerRef,
                  onKeyDown: (e) => {
                    if (e.key === 'Enter') {
                      onStartDateInputChange(e);
                    }
                  },
                  'data-testid': 'input-start-date',
                }}
              />
            </div>
          </div>

          <div className="my-4">
            <div className={LABEL_CNAME}>End Date</div>
            <div className="w-full">
              <CustomInput
                value={endDate}
                onChange={(date, modifiers) => {
                  if (modifiers.disabled) return;
                  applyChanges(dateKey, startDate, date);
                }}
                dayPickerProps={{
                  ...commonDayPickerProps,
                  disabledDays: { before: startDate, after: new Date() },
                  month: endDate,
                }}
                placeholderDate={endDate}
                inputProps={{
                  ref: endDateInputInnerRef,
                  onKeyDown: (e) => {
                    if (e.key === 'Enter') {
                      onEndDateInputChange(e);
                    }
                  },
                  'data-testid': 'input-end-date',
                }}
              />
            </div>
          </div>

          {validationErrors &&
            _.map(validationErrors, (message, label) => (
              <div key={label} className="mb-4 text-sm text-red-600">
                {_.startCase(label)} {message}
              </div>
            ))}

          <div>
            <Button className="w-full" onClick={onApplyButtonClick}>
              Apply
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
}

DateRangePicker.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      start: PropTypes.instanceOf(Date).isRequired,
      end: PropTypes.instanceOf(Date).isRequired,
    }),
  ).isRequired,
};

DateRangePicker.defaultProps = {};

export default React.memo(DateRangePicker);
