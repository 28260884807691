import _ from 'lodash';

export function getCommoditiesName(inventoryItems = []) {
  if (!inventoryItems.length) return '';

  if (inventoryItems.length === 1) {
    return inventoryItems[0]?.commodity.name;
  }

  const orderedCommodities = _.orderBy(inventoryItems, 'quantity', 'desc');
  const commodityNames = Object.keys(
    orderedCommodities.reduce((obj, { commodity }) => ({ ...obj, [commodity.name]: true }), {}),
  );

  return `${commodityNames[0]} +${commodityNames.length - 1}`;
}
