import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import routes from 'routes';

import { Button } from '@producepay/pp-ui';

const EmptyState = () => {
  const { t } = useTranslation('conversations');

  return (
    <div className="empty-state">
      <div className="center empty-state-container">
        <div className="empty-header">{t('noConversations')}</div>
        <div className="empty-desc center">{t('visitMarketplace')}</div>
        <div className="empty-button center">
          <NavLink exact to={routes.marketplace()}>
            <Button>{t('goToMarketplace')}</Button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default EmptyState;
