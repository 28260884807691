import React from 'react';

import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import routes from 'routes';

import { useResponsiveLayout } from 'helpers/dom';

import { usePreferences } from 'contexts/preferences';

import { FETCH_MARKET_REQUEST_LOADS } from 'graphql/queries/fetch-market-requests';

import { LoadingSpinner } from '@producepay/pp-ui';

const navlinkCName =
  'flex border-b-4 pt-4 pb-3 border-transparent text-gray-4 justify-center items-center text-center font-semibold w-auto ml-4 lg:ml-0 lg:text-xl';

function TabBar() {
  const { preferencesAreLoading } = usePreferences();
  const { isMobileOrTablet } = useResponsiveLayout();

  const { t } = useTranslation(['common', 'navBar']);

  const { data, loading } = useQuery(FETCH_MARKET_REQUEST_LOADS, {
    variables: {
      requestType: 'demand',
    },
    skip: preferencesAreLoading,
  });

  return (
    <div
      className={`flex border-b-1/2 border-primary-light border-opacity-25 lg:pt-8 ${isMobileOrTablet && 'bg-white'}`}
    >
      {loading ? (
        <div className="text-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <NavLink
            className={`mr-4 md:mr-8 ${navlinkCName}`}
            activeClassName="border-secondary"
            activeStyle={{ color: '#000000' }}
            to={routes.marketplaceSupply()}
          >
            <span>{t('common:availableVolume')}</span>
          </NavLink>
          {data?.marketRequestLoads.length > 0 && (
            <NavLink
              className={navlinkCName}
              activeClassName="border-secondary"
              activeStyle={{ color: '#000000' }}
              to={routes.marketplaceDemand()}
            >
              <span>{t('common:buyerDemand')}</span>
            </NavLink>
          )}
        </>
      )}
    </div>
  );
}

export default TabBar;
