import React, { useContext, useState, useCallback } from 'react';
import { createPortal } from 'react-dom';

import NotificationRequest from '../../components/elements/NotificationRequest';

const NotificationRequestContext = React.createContext();

function NotificationRequestContainer(props) {
  const { notificationRequest, setNotificationRequest } = props;
  const { header, body, onAccept, onDeny } = notificationRequest;

  return createPortal(
    <div
      className="overflow-hidden fixed bottom-0 left-0 w-full h-full z-50"
      style={{ backgroundColor: 'rgba(67, 72, 77, 0.5)' }}
    >
      <NotificationRequest
        header={header}
        body={body}
        onDeny={() => {
          onDeny();
          setNotificationRequest(null);
        }}
        onAccept={() => {
          onAccept();
        }}
        isOpen={notificationRequest}
      />
    </div>,
    document.body,
  );
}

function NotificationRequestProvider({ children }) {
  const [notificationRequest, setNotificationRequest] = useState(null);

  const triggerNotificationRequest = useCallback(
    ({ header, body, onAccept, onDeny, className = '' }) => {
      const newNotificationRequest = {
        header,
        body,
        onAccept,
        onDeny,
        className,
      };

      setNotificationRequest(newNotificationRequest);
    },
    [setNotificationRequest],
  );

  return (
    <NotificationRequestContext.Provider
      value={{
        triggerNotificationRequest,
        isModalOpen: !!notificationRequest,
        hideNotificationRequest: () => setNotificationRequest(null),
      }}
    >
      {children}

      {notificationRequest && (
        <NotificationRequestContainer
          notificationRequest={notificationRequest}
          setNotificationRequest={setNotificationRequest}
        />
      )}
    </NotificationRequestContext.Provider>
  );
}

function useNotificationRequest() {
  return useContext(NotificationRequestContext);
}

export { NotificationRequestProvider, useNotificationRequest };
