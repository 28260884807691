import React from 'react';

const SvgCheckmarkCircleOutline = ({ size = 24, color = 'currentColor', ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 183 183" {...rest}>
    <g fill="none" fillRule="evenodd">
      <path fill={color} d="M74.014 112.698L50.878 89.563 43 97.386l31.014 31.013 66.576-66.576L132.767 54z" />
      <circle stroke={color || 'currentColor'} strokeWidth={8} cx={91.5} cy={91.5} r={87.5} />
    </g>
  </svg>
);

export default SvgCheckmarkCircleOutline;
