import { useRef } from 'react';
import { useLocation } from 'react-router-dom';

const REDIRECT_URI_QUERY_PARAM_KEY = 'redirect_uri';

function buildValidProducePayAppDomainRegex() {
  const alternateToken = '|';
  const producePayApps = ['insights', 'marketplace'].join(alternateToken);
  const availableNonProdEnvironments = ['dev', 'qa', 'staging'].join(alternateToken);

  return `(http|https)\\:\\/\\/(local\\.){0,1}((${producePayApps})\\.)((${availableNonProdEnvironments})\\.){0,1}producepay\\.(io|com)`;
}

function isValidProducePayAppUri(uri) {
  const startOfTheLineToken = '^';
  const caseInsensitiveFlag = 'i';

  const producePayAppDomainRegex = buildValidProducePayAppDomainRegex();
  const uriStartsWithProducePayAppDomainRegex = new RegExp(
    `${startOfTheLineToken}${producePayAppDomainRegex}`,
    caseInsensitiveFlag,
  );

  return uriStartsWithProducePayAppDomainRegex.test(uri);
}

const useRedirectUriRef = () => {
  const { search } = useLocation();
  const redirectUriRef = useRef();

  const searchParams = new URLSearchParams(search);
  const redirectUri = searchParams.get(REDIRECT_URI_QUERY_PARAM_KEY);

  redirectUriRef.current = isValidProducePayAppUri(redirectUri) ? redirectUri : undefined;

  return redirectUriRef;
};

export { useRedirectUriRef, REDIRECT_URI_QUERY_PARAM_KEY };
