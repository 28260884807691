import React from 'react';
import _ from 'lodash';

import { useAd } from './index';
import { useAuth } from '../auth';

function AdDisplay({ placement, className }) {
  const { html, placement: adPlacement } = useAd();
  const { user } = useAuth();

  const adMatchesPlacement = adPlacement === placement;
  const isInsightsPro = _.get(user, 'isInsightsProUser');

  if (!adMatchesPlacement || !html || isInsightsPro) return null;

  return <div className={className} dangerouslySetInnerHTML={{ __html: html }} />;
}

export default AdDisplay;
