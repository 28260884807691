import React from 'react';

import { Link } from 'react-router-dom';
import { Button, PageSpinner } from '@producepay/pp-ui';
import routes from '../../routes';
import useNotificationListener from '../../hooks/useNotificationListener';
import useNotificationRequestListener from '../../hooks/useNotificationRequestListener';
import useHistoryListener from '../../hooks/useHistoryListener';
import useReferrer from '../../hooks/useReferrer';

import Routes from '../Routes';
import { useFeatureFlagsContext } from '../../contexts/FeatureFlags';
import { useAuth } from '../../contexts/auth';
import { logError } from '../../helpers/logger';

function RootView({ isErrored, clearError }) {
  useNotificationListener();
  useNotificationRequestListener();
  useHistoryListener();
  useReferrer();

  const { identifyFeatureFlagUser, initialized: featureFlagInitialized } = useFeatureFlagsContext();
  const { unauthenticatedUser, user } = useAuth();

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (featureFlagInitialized) {
      const featureFlagUserIdentificationPromise = user?.email
        ? identifyFeatureFlagUser?.(user)
        : identifyFeatureFlagUser?.();

      featureFlagUserIdentificationPromise
        .catch((error) => {
          logError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [unauthenticatedUser, user, identifyFeatureFlagUser, featureFlagInitialized]);

  if (loading) {
    return (
      <div className="w-full h-screen flex flex-col items-center justify-center">
        <PageSpinner />
      </div>
    );
  }

  return isErrored ? (
    <div className="w-full md:max-w-md mx-auto p-6 md:p-8">
      <h2 className="text-center text-3xl mb-6">Uh oh!</h2>

      <div className="leading-relaxed text-center">
        Looks like we've run into a problem! Our team has been alerted and we're working on getting a fix out. Thanks
        for your patience!
      </div>

      <div className="mt-6 flex justify-center">
        <Link to={routes.dashboard()}>
          <Button onClick={clearError}>Go to Home</Button>
        </Link>
      </div>
    </div>
  ) : (
    <Routes />
  );
}

export default React.memo(RootView);
