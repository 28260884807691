import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useStreamChatRedirect from 'hooks/useStreamChatRedirect';

import { Button } from '@producepay/pp-ui';

const headerCName = 'flex flex-row text-xl text-primary-dark font-semibold pb-4';
const descriptionCName = 'flex flex-row pb-10 leading-snug';

function SellerTiers() {
  const { t } = useTranslation(['tierFAQ']);

  const [hasBeenClicked, setHasBeenClicked] = useState(false);

  useStreamChatRedirect('', 'demand', hasBeenClicked);

  return (
    <div className="flex flex-col px-16 py-10">
      <div className={`${headerCName}`}>
        <span>{t('seller.verifiedTitle')}</span>
      </div>
      <div className={`${descriptionCName}`}>
        <span>{t('seller.verifiedDescription')}</span>
      </div>
      <div className={`${headerCName}`}>
        <span>{t('accessToFinancing')}</span>
      </div>
      <div className={`${descriptionCName}`}>
        <span>{t('seller.accessToFinancingDescription')}</span>
      </div>
      <div className={`${headerCName}`}>
        <span>{t('robustProfiles')}</span>
      </div>
      <div className={`${descriptionCName}`}>
        <span>{t('seller.robustProfilesDescription')}</span>
      </div>
      <div className={`${headerCName}`}>
        <span>{t('eliteGroup')}</span>
      </div>
      <div className={`${descriptionCName}`}>
        <span>{t('seller.eliteGroupDescription')}</span>
      </div>
      <div className="flex flex-row justify-center pt-4">
        <Button
          className="w-full"
          label={t('chatWithRepresentative')}
          onClick={() => setHasBeenClicked(true)}
          data-testid="btn-tier-faq-chat"
          color="primary"
          borderRadius=""
        />
      </div>
    </div>
  );
}

export default SellerTiers;
