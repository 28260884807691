import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import cx from 'classnames';

import { Button, Modal, TextField } from '@producepay/pp-ui';
import routes from '../../../routes';
import { useResponsiveLayout } from '../../../helpers/dom';
import { formatCommodityVarietyName } from '../../../helpers/format';
import { useDmrCommodityVarieties } from '../../../contexts/DmrCommodityVarieties';

import AddRemoveCommodityRow from './AddRemoveCommodityRow';
import SearchIcon from '../../../components/icons/Search';
import CancelCircleIcon from '../../../components/icons/CancelCircle';

const CONTENT_HEADER_CNAME = 'text-gray-4 text-sm pb-2 md:pb-4';

function AddCommoditiesModal(props) {
  const { subscribedCviIdsMap, onAddCommodities, onRemoveCommodities, onComplete, onCloseRoute } = props;

  const history = useHistory();
  const { t } = useTranslation(['marketInsightsAddCommodities', 'common']);
  const { isMobileOrTablet } = useResponsiveLayout();
  const { commodityVarietyInfos, topCommodityVarietyInfos, searchUsdaCommodities } = useDmrCommodityVarieties();
  const [hasAWatchlist] = useState(!_.isEmpty(subscribedCviIdsMap));

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleModalClose = useCallback(() => history.push(onCloseRoute), [history, onCloseRoute]);

  const [searchText, setSearchText] = useState('');
  const isSearching = _.size(searchText) > 1;

  const results = isSearching ? searchUsdaCommodities(searchText) : null;
  const hasResults = !_.isEmpty(results);

  const subscribedCommodityVarietyInfos = commodityVarietyInfos.filter((cvi) => subscribedCviIdsMap[cvi.id]);
  const hasSubscribedCommodityVarietyInfos = !_.isEmpty(subscribedCommodityVarietyInfos);

  const mostPopularAggregates = _.groupBy(_.sortBy(topCommodityVarietyInfos, 'commodityName'), 'commodityName');

  const mapCommodityVarietyInfosToRows = (filteredCvis) => {
    return _.sortBy(filteredCvis, ['commodityName', 'varietyName']).map((cvi, idx) => (
      <AddRemoveCommodityRow
        key={cvi.id}
        className={idx !== 0 && 'md:border-t'}
        name={formatCommodityVarietyName(cvi.commodityName, cvi.varietyName)}
        image={cvi.commodityImage}
        onAdd={() => onAddCommodities([cvi.id])}
        onRemove={() => onRemoveCommodities([cvi.id])}
        isSubscribed={!!subscribedCviIdsMap[cvi.id]}
      />
    ));
  };

  const height = isMobileOrTablet ? '100%' : '98%';

  return (
    <Modal
      style={{ height, maxHeight: height }}
      onClose={isMobileOrTablet ? null : handleModalClose}
      title={isMobileOrTablet ? t('addCommodities') : t('title')}
      headerContent={
        <div>
          <div className="text-sm pt-1 pb-4">{t('description')}</div>

          <div className="flex">
            <div className="w-full relative">
              <TextField
                className={cx('w-full', { 'pr-8': !!searchText })}
                name="search"
                value={searchText}
                data-testid="add-commodities-search-input"
                onChange={(e) => setSearchText(e.target.value)}
                placeholder={t('placeholder')}
                iconPrefix={<SearchIcon size={16} className={cx({ 'text-gray-4': !searchText })} />}
              />

              {!!searchText && (
                <div
                  className="absolute p-2 cursor-pointer"
                  style={{ right: 2, top: 4 }}
                  onClick={() => setSearchText('')}
                >
                  <CancelCircleIcon size={16} className="text-light-gray" />
                </div>
              )}
            </div>

            {isMobileOrTablet && (
              <Button
                className="pl-4 text-sm flex-shrink-0"
                variant="text"
                onClick={handleModalClose}
                label={t('common:cancel')}
              />
            )}
          </div>
        </div>
      }
      actions={[
        {
          className: 'w-full md:w-40',
          onClick: () => {
            if (onComplete) {
              setIsSubmitting(true);
              onComplete();
            } else {
              handleModalClose();
            }
          },
          label: t('common:done'),
          isLoading: isSubmitting,
          disabled: isSubmitting,
          'data-testid': 'add-commodities-submit',
        },
      ]}
    >
      {isSearching ? (
        hasResults ? (
          <>
            <div className={CONTENT_HEADER_CNAME}>{t('common:result', { count: _.size(results) })}</div>

            {mapCommodityVarietyInfosToRows(results)}
          </>
        ) : (
          <div className="pt-24 md:pt-32 flex justify-center">
            <div className="max-w-xs text-center text-gray-medium leading-tight">{t('emptyState')}</div>
          </div>
        )
      ) : hasAWatchlist && hasSubscribedCommodityVarietyInfos ? (
        <>
          <div className={CONTENT_HEADER_CNAME}>{t('yourWatchlist')}</div>

          {mapCommodityVarietyInfosToRows(subscribedCommodityVarietyInfos)}
        </>
      ) : (
        <>
          <div className={CONTENT_HEADER_CNAME}>{t('mostPopular')}</div>

          {_.toPairs(mostPopularAggregates).map((keyValue, idx) => {
            const [commodityName, cviListForPopularCommodity] = keyValue;

            const firstCvi = cviListForPopularCommodity[0];
            const isSubscribed = cviListForPopularCommodity.every((cvi) => !!subscribedCviIdsMap[cvi.id]);
            const cviIds = _.map(cviListForPopularCommodity, 'id');

            return (
              <AddRemoveCommodityRow
                key={commodityName}
                className={idx !== 0 && 'md:border-t'}
                name={
                  <>
                    <div>{commodityName}</div>
                    <div className="text-sm">({t('variety', { count: cviListForPopularCommodity.length })})</div>
                  </>
                }
                image={firstCvi.commodityImage}
                onAdd={() => onAddCommodities(cviIds)}
                onRemove={() => onRemoveCommodities(cviIds)}
                isSubscribed={isSubscribed}
              />
            );
          })}
        </>
      )}
    </Modal>
  );
}

AddCommoditiesModal.propTypes = {
  onCloseRoute: PropTypes.string,
};

AddCommoditiesModal.defaultProps = {
  onCloseRoute: routes.marketInsights(),
};

export default AddCommoditiesModal;
