import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';

import { Button } from '@producepay/pp-ui';

import routes from 'routes';

import { useAuth } from 'contexts/auth';

import { useResponsiveLayout } from 'helpers/dom';

import { MOBILE_NAVBAR_HEIGHT } from 'styles/constants';

import CTAButton from './CTAButton';

function CTA() {
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation('marketplace');

  const { unauthenticatedUser, user, isAuthenticated } = useAuth();
  const { isMobileOrTablet } = useResponsiveLayout();

  const rejected = user?.onboardingInformation?.supplyListingsApplicationStatus === 'rejected';
  if (rejected) return null;

  const isUnAuthenticatedUserKnown = unauthenticatedUser?.email && unauthenticatedUser?.token;
  const isUserUnknown = !isAuthenticated && !isUnAuthenticatedUserKnown;

  const isSupply = !_.includes(pathname, routes.marketplaceDemand());
  const ctaButtonLabel = isSupply ? 'uploadListing' : 'chatWithTeamToPost';

  const DesktopDisplay = () => (
    <>
      {!isUserUnknown && (
        <div className="flex">
          <Button
            className="mr-6"
            label={t('editCommodities')}
            color="primary"
            variant="outlined"
            borderRadius=""
            onClick={() => {
              history.push(routes.marketplaceAddCommodities());
            }}
            data-testid="btn-edit-commodities"
          />
        </div>
      )}
      <CTAButton label={t(ctaButtonLabel)} isSupply={isSupply} />
    </>
  );

  const MobileDisplay = () => (
    <div style={{ bottom: MOBILE_NAVBAR_HEIGHT }} className="flex justify-evenly border-t p-4 bg-white sticky">
      {!isUserUnknown && (
        <div className="flex justify-center w-1/2 px-2">
          <Button
            className="w-full font-bold"
            label={t('editCommodities')}
            color="primary"
            variant="outlined"
            onClick={() => {
              history.push(routes.marketplaceAddCommodities());
            }}
            data-testid="btn-edit-commodities"
          />
        </div>
      )}
      <CTAButton label="cta" isSupply={isSupply} />
    </div>
  );

  return <>{isMobileOrTablet ? <MobileDisplay /> : <DesktopDisplay />}</>;
}

export default CTA;
