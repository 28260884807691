import { useCallback } from 'react';
import _ from 'lodash';

import useStorage from '../../../hooks/useStorage';

export function usePreviousSearches(itemFromUuids) {
  const [previousSearches, setPreviousSearches] = useStorage('previous-searches', [], localStorage);

  const addSearchObj = useCallback(
    (searchObj) => {
      setPreviousSearches((oldPreviousSearches) => {
        const uniqSearches = _.uniqBy([searchObj, ...oldPreviousSearches], 'identifier');
        return uniqSearches.slice(0, 5);
      });
    },
    [setPreviousSearches],
  );

  // handle stored search results that uses old commodity variety show route with uuids
  const resolvedPreviousSearches = _.map(previousSearches, (search) => {
    if (search.objectType === 'insights' && typeof search.identifier === 'string' && search.identifier.includes('-')) {
      const cvPair = search.identifier.split('/');
      const cvItem = itemFromUuids(cvPair[0], cvPair[1]);
      return { ...search, identifier: cvItem?.id };
    }
    return search;
  });

  return [_.uniqBy(resolvedPreviousSearches, (search) => [search.objectType, search.identifier].join()), addSearchObj];
}
