import { gql } from '@apollo/client';
import { ORDER_FRAGMENT } from '../fragments/fulfillment/order';

export const ORDERS_QUERY = gql`
  ${ORDER_FRAGMENT}

  query Orders {
    getOrders(perPage: 100, orderBy: TRANSACTION_OCCURRED_AT) {
      orders {
        ...orderFragment
      }
    }
  }
`;
