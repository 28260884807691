import React from 'react';

import { Route, Redirect, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import routes from '../../routes';

function UnauthenticatedRoute({ component: Component, render, children, ...rest }) {
  const { isAuthenticated, user, redirectUri } = useAuth();
  const { state } = useLocation();

  let redirectTo = routes.home();
  if (state?.from) {
    redirectTo = state?.from;
  } else if (user?.isVerified && !user?.isOnboarded) {
    redirectTo = routes.onboarding();
  }

  if (isAuthenticated && redirectUri) {
    window.location.replace(redirectUri);
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Redirect to={redirectTo} />
        ) : render ? (
          render(props)
        ) : Component ? (
          <Component {...props} />
        ) : null
      }
    />
  );
}

export default React.memo(UnauthenticatedRoute);
