export const EMAIL_STEP = 1;
export const PROFILE_INFO_STEP = 2;
export const COMPANY_INFO_STEP = 3;
export const COMMODITY_INFO_STEP = 4;
export const CREATE_REQUESTS_STEP = 5;
export const LOGISTIC_STEP = 6;
export const UPLOAD_IMAGES_STEP = 7; // nice to have
export const VERIFICATION_STEP = 8; // nice to have
export const SUPPLY_LISTING_SUBMITTED_STEP = 9;
export const TOTAL_STEPS = 9;

export const getCurrentStep = (user) => {
  if (!user || !user?.email) return EMAIL_STEP;

  if (
    !user.onboardingInformation?.fullName ||
    !user.onboardingInformation?.companyName ||
    !user.onboardingInformation?.phoneNumber ||
    !user.onboardingInformation?.country
  ) {
    return PROFILE_INFO_STEP;
  }

  if (
    !user.onboardingInformation?.totalExportedToUs ||
    !user.onboardingInformation?.companyType ||
    !user.onboardingInformation?.agreeToSupplyTerms
  ) {
    return COMPANY_INFO_STEP;
  }

  return COMMODITY_INFO_STEP;
};
