import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

import { useAuth } from 'contexts/auth';
import { usePreferences } from 'contexts/preferences';

import { TOGGLE_MY_WATCHLIST_KEY, TOGGLE_ALL_COMMODITIES_KEY } from './partials/MarketplaceNav/helpers';

import GeneralLayout from 'components/layouts/GeneralLayout';

import MarketplaceView from './view';

const WATCHED_ALERTS = ['demand_alert_whatsapp', 'supply_alert_whatsapp'];

function Marketplace() {
  const { unauthenticatedUser, user, isAuthenticated, onAuthUser } = useAuth();
  const { cviPreferenceMap, addPreferences, removePreferences } = usePreferences();
  const { source } = qs.parse(useLocation().search, { ignoreQueryPrefix: true });

  const isUnAuthenticatedUserKnown = unauthenticatedUser?.email && unauthenticatedUser?.token;
  const isUserUnknown = !isAuthenticated && !isUnAuthenticatedUserKnown;

  useEffect(() => {
    onAuthUser(user, null);
  }, [onAuthUser, user]);

  // Only toggle on when the user comes from supply alert
  const initialToggleState = WATCHED_ALERTS.includes(source) ? TOGGLE_MY_WATCHLIST_KEY : TOGGLE_ALL_COMMODITIES_KEY;
  const [toggleKey, setToggleKey] = useState(initialToggleState);

  return (
    <GeneralLayout>
      <MarketplaceView
        isUserUnknown={isUserUnknown}
        toggleKey={toggleKey}
        setToggleKey={setToggleKey}
        cviPreferenceMap={cviPreferenceMap}
        onAddCommodities={(cviIds) => addPreferences(cviIds, unauthenticatedUser?.token || user?.token)}
        onRemoveCommodities={(cviIds) => removePreferences(cviIds, unauthenticatedUser?.token || user?.token)}
      />
    </GeneralLayout>
  );
}

export default Marketplace;
