export function getTierRating(load) {
  const marketRequests = load?.marketRequests || [];
  const firstMarketRequest = marketRequests[0];
  const tierRating = firstMarketRequest?.tierRating || null;

  return tierRating;
}

// Returns true if a background check would have been completed
// for a given tier.
export function backgroundCheckComplete(tierRating) {
  return ['background check complete', 'verified', 'gold verified'].includes(tierRating?.toLowerCase());
}
