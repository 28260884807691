import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@producepay/pp-ui';
import FormikSubmit from '../../../../components/formik/FormikSubmit';

function FollowupButtons(props) {
  const { previousQuestion, skipQuestion } = props;

  const { t } = useTranslation(['onboarding', 'common']);

  return (
    <>
      <div className="pt-4 flex justify-center">
        <Button type="button" className="w-40 mr-4" variant="outlined" onClick={previousQuestion}>
          {t('common:previous')}
        </Button>

        <FormikSubmit className="w-40" label={t('common:next')} />
      </div>

      {skipQuestion && (
        <div className="pt-6 flex justify-center">
          <Button type="button" className="w-40 text-sm" variant="text" onClick={skipQuestion}>
            {t('skipQuestion')}
          </Button>
        </div>
      )}
    </>
  );
}

export default FollowupButtons;
