import React, { useEffect, useMemo, useState } from 'react';
import { Chat, Channel, ChannelList, MessageInput, MessageList, Thread, Window } from 'stream-chat-react';
import { LoadingSpinner } from '@producepay/pp-ui';
import PropTypes from 'prop-types';
import CustomChannelHeader from './CustomChannelHeader';
import CustomMessage from './CustomMessage';
import CustomList from './CustomList';
import CustomListItem from './CustomListItem';
import EmptyState from './EmptyState';
import ParticipantPopOut from './ParticipantPopOut';
import OrdersPopOut from './OrdersPopOut';

import { useAuth } from 'contexts/auth';
import { useChatState } from 'contexts/Chat';

import 'stream-chat-react/dist/css/index.css';
import './Chatbox.css';

const checkForChannels = async (client, filters, sort) => {
  return client.queryChannels(filters, sort, {
    watch: true,
    state: true,
  });
};

const Chatbox = ({ setDisplayFooter, isFooterDisplayed }) => {
  const { user } = useAuth();
  const { streamToken, accessToken } = user || {};
  const { client, state } = useChatState();
  const [showParticipantPopout, setShowParticipantPopout] = useState(false);
  const [showOrdersPopout, setShowOrdersPopout] = useState(false);

  const messageActions = ['flag', 'pin', 'react', 'reply'];

  // Defaulting to true prevents the empty screen from flashing
  // while we're waiting for the client and user to load
  const [userHasChannels, setUserHasChannels] = useState(true);

  // We don't need to pass a specific channel to the Channel component,
  // ChannelList will figure out which channel to display based off the filters, which is
  // the channel with the most recent message that a user belongs to
  const filters = useMemo(() => ({ type: 'messaging', members: { $in: [accessToken] } }), [accessToken]);
  const sort = useMemo(() => ({ last_message_at: -1 }), []);
  const height = isFooterDisplayed ? 'calc(100% - 63px)' : '100%';

  useEffect(() => {
    if (client && state.current === 'connected') {
      checkForChannels(client, filters, sort).then((channels) => {
        setUserHasChannels(channels.length > 0);
      });
    }
  }, [client, filters, sort, state]);

  if (!client || !streamToken) {
    return <LoadingSpinner />;
  }

  if (client && streamToken && !userHasChannels) {
    return <EmptyState />;
  }

  return (
    <div className="chat-container" style={{ height }}>
      <Chat client={client}>
        <ChannelList
          filters={filters}
          sort={sort}
          Preview={CustomListItem}
          List={CustomList}
          EmptyStateIndicator={() => <EmptyState />}
        />
        <Channel Message={CustomMessage}>
          <Window>
            <ParticipantPopOut setShowPopout={setShowParticipantPopout} showPopout={showParticipantPopout} />
            <OrdersPopOut setShowPopout={setShowOrdersPopout} showPopout={showOrdersPopout} />
            <CustomChannelHeader
              setShowParticipantPopout={setShowParticipantPopout}
              setShowOrdersPopout={setShowOrdersPopout}
            />
            <MessageList messageActions={messageActions} />
            <MessageInput
              additionalTextareaProps={{
                onFocus: () => setDisplayFooter(false),
                onBlur: () =>
                  setTimeout(() => {
                    setDisplayFooter(true);
                  }, 100),
              }}
            />
          </Window>
          <Thread />
        </Channel>
      </Chat>
    </div>
  );
};

Chatbox.propTypes = {
  setDisplayFooter: PropTypes.func.isRequired,
};

export default React.memo(Chatbox);
