import React, { useRef } from 'react';

const CustomListItem = (props) => {
  const {
    active,
    Avatar,
    channel,
    displayImage,
    displayTitle,
    lastMessage,
    setActiveChannel,
    unread,
    watchers,
  } = props;

  const channelPreviewButton = useRef(null);

  const activeClass = active ? 'str-chat__channel-preview-messenger--active' : '';
  const unreadClass = unread && unread >= 1 ? 'str-chat__channel-preview-messenger--unread' : '';

  const avatarName = displayTitle || channel.state.messages[channel.state.messages.length - 1]?.user?.id;

  const onSelectChannel = () => {
    if (setActiveChannel) {
      setActiveChannel(channel, watchers);
    }
    if (channelPreviewButton?.current) {
      channelPreviewButton.current.blur();
    }
  };

  return (
    <button
      className={`str-chat__channel-preview-messenger ${unreadClass} ${activeClass}`}
      data-testid="channel-preview-button"
      onClick={onSelectChannel}
      ref={channelPreviewButton}
    >
      <div className="str-chat__channel-preview-messenger--left">
        <Avatar image={displayImage} name={avatarName} size={40} />
      </div>
      <div className="str-chat__channel-preview-messenger--right">
        <div className="str-chat__channel-preview-messenger--name">
          <span>{displayTitle}</span>
        </div>
        <div className="str-chat__channel-preview-messenger--last-message">{lastMessage.text}</div>
      </div>
      {unread > 0 && (
        <div
          className="text-white rounded-full bg-blue-600 text-xs flex items-center justify-center ml-auto"
          style={{ height: '1.1rem', width: '1rem' }}
        >
          {unread}
        </div>
      )}
    </button>
  );
};

export default CustomListItem;
