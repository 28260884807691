import { useEffect, useCallback } from 'react';
import { useToast } from '@producepay/pp-ui';
import differenceInDays from 'date-fns/differenceInDays';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { useNotificationRequest } from 'contexts/NotificationRequests';
import { KEYS, trackEvent } from 'helpers/tracking';
import { MESSAGE_DATA_TYPES, userHasPushSubscriptions } from 'helpers/pushNotifications';
import { logError } from 'helpers/logger';
import useStorage from './useStorage';
import useReactNativeListener from './useReactNativeListener';
import { useAuth } from 'contexts/auth';

const NOTIFICATION_REQUEST_TRIGGERED_AT_KEY = 'notificationRequestTriggeredAt';

const CURRENT_USER_WITH_SUBSCRIPTIONS = gql`
  query getCurrentUserWithSubscriptions {
    currentUser {
      accessToken
      userCommodityVarietyPreferences {
        id
      }
      pushSubscriptions {
        deviceType
        subscriptionInfo {
          expoPushToken
        }
      }
    }
  }
`;

export default function useNotificationRequestListener() {
  const { addToastToQueue } = useToast();
  const { triggerNotificationRequest, isModalOpen, hideNotificationRequest } = useNotificationRequest();
  const { user: authenticatedUser, isAuthenticated } = useAuth();
  const { t } = useTranslation(['pushNotifications']);

  const [notificationRequestTriggeredAt, setNotificationRequestTriggeredAt] = useStorage(
    NOTIFICATION_REQUEST_TRIGGERED_AT_KEY,
    null,
    window.localStorage,
  );

  const { data } = useQuery(CURRENT_USER_WITH_SUBSCRIPTIONS, { skip: !isAuthenticated });

  const onMessage = useCallback(
    (message) => {
      try {
        const data = typeof message.data === 'object' ? message.data : JSON.parse(message.data);
        if (data.type === MESSAGE_DATA_TYPES.PERMISSION_ACCEPTED) {
          addToastToQueue({
            type: 'success',
            header: t('acceptHeader'),
            body: t('acceptBody'),
          });
          trackEvent(KEYS.PUSH_NOTIFICATIONS_ENABLED);
          hideNotificationRequest();
        } else if (data.type === MESSAGE_DATA_TYPES.PERMISSION_DENIED) {
          addToastToQueue({
            type: 'alert',
            header: t('denyHeader'),
            body: t('denyNativeBody'),
          });
          trackEvent(KEYS.PUSH_NOTIFICATIONS_DENIED);
          hideNotificationRequest();
        }
      } catch (e) {
        logError(e);
      }
    },
    [addToastToQueue, hideNotificationRequest, t],
  );

  useReactNativeListener(onMessage, !isModalOpen);

  const user = data?.currentUser;

  useEffect(() => {
    if (!data || !authenticatedUser) {
      return;
    }
    const userHasSubscriptions = userHasPushSubscriptions(user);

    if (window.ReactNativeWebView && !userHasSubscriptions && isAuthenticated) {
      const acceptNotificationRequest = () => {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: MESSAGE_DATA_TYPES.PERMISSION_REQUESTED,
            accessToken: authenticatedUser.accessToken,
          }),
        );
      };

      const denyNotificationRequest = () => {
        trackEvent(KEYS.PUSH_NOTIFICATION_MODAL_DISMISSED);
      };

      const today = new Date();
      const daysBeforeExpiration = 14;
      const requestIsExpired =
        differenceInDays(today, Date.parse(notificationRequestTriggeredAt)) > daysBeforeExpiration;

      const shouldTriggerNotificationRequest = requestIsExpired && user?.userCommodityVarietyPreferences?.length > 0;

      if (!notificationRequestTriggeredAt || shouldTriggerNotificationRequest) {
        const tid = setTimeout(() => {
          triggerNotificationRequest({
            header: t('permissionHeader'),
            body: t('permissionBody'),
            onAccept: () => acceptNotificationRequest(),
            onDeny: () => denyNotificationRequest(),
          });
          setNotificationRequestTriggeredAt(new Date());
          trackEvent(KEYS.PUSH_NOTIFICATION_MODAL_DISPLAYED);
        }, 30000);
        return () => {
          clearTimeout(tid);
        };
      }
    }
  }, [
    authenticatedUser,
    isAuthenticated,
    addToastToQueue,
    notificationRequestTriggeredAt,
    setNotificationRequestTriggeredAt,
    triggerNotificationRequest,
    user,
    data,
    t,
  ]);
}
