import * as React from 'react';
import PropTypes from 'prop-types';

function SvgCheckmark({ color, size, ...rest }) {
  return (
    <svg width={size} height={size} viewBox="0 0 12 9" fill="none" {...rest}>
      <path
        d="M9.9 1L4.28 6.62 1.42 3.76a.724.724 0 00-1.02 0c-.28.28-.28.74 0 1.02l3.36 3.36a.726.726 0 001.04 0l6.12-6.12c.28-.28.28-.74 0-1.02S10.2.72 9.9 1z"
        fill={color}
      />
    </svg>
  );
}

SvgCheckmark.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SvgCheckmark.defaultProps = {
  color: 'currentColor',
  size: '24',
};

export default SvgCheckmark;
