import { get } from 'lodash';

import { AUTH_TOKEN_KEY, TRUE_USER_TOKEN_KEY } from '../contexts/auth';
import { getSource } from './sources';

const debug = require('debug')('pp:trackEvent');

const UUID_REGEX = /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/gi;

export const KEYS = {
  SUBMIT_VARIETY_COMMODITY_INFO: 'SUBMIT_VARIETY_COMMODITY_INFO',
  SUBMIT_MARKET_REQUESTS: 'SUBMIT_MARKET_REQUESTS',
  SUBMIT_LOGISTICS_STEP: 'SUBMIT_LOGISTICS_STEP',
  POST_MORE_SUPPLY: 'POST_MORE_SUPPLY',
  CLICKED_SUBMIT_BID_CHAT: 'CLICKED_SUBMIT_BID_CHAT',
  CLICKED_CHAT_DEMAND_LISTING: 'CLICKED_CHAT_DEMAND_LISTING',
  CHAT_WITH_OUR_TEAM: 'CHAT_WITH_OUR_TEAM',
  CHAT_REQUEST_REDIRECTED_TO_LOGIN: 'CHAT_REQUEST_REDIRECTED_TO_LOGIN',
  SEARCH_RESULT_CLICKED: 'SEARCH_RESULT_CLICKED',
  PHONE_CONFIRMATION_TRIGGERED: 'PHONE_CONFIRMATION_TRIGGERED',
  PHONE_NUMBER_CONFIRMED: 'PHONE_NUMBER_CONFIRMED',
  PRICING_GRAPH_SKU_CHANGE: 'PRICING_GRAPH_SKU_CHANGE',
  PRICING_GRAPH_SHIPPING_POINT_CHANGE: 'PRICING_GRAPH_SHIPPING_POINT_CHANGE',
  USER_LOGIN: 'USER_LOGIN',
  USER_REGISTRATION: 'USER_REGISTRATION',
  UNVERIFIED_USER_BLOCKED: 'UNVERIFIED_USER_BLOCKED',
  UNVERIFIED_USER_LOGIN: 'UNVERIFIED_USER_LOGIN',
  VERIFICATION_EMAIL_SENT: 'VERIFICATION_EMAIL_SENT',
  ONBOARDING_COMPLETED: 'ONBOARDING_COMPLETED',
  FULL_ONBOARDING_COMPLETED: 'FULL_ONBOARDING_COMPLETED',
  MARKET_INSIGHTS_ENTERED_EMAIL: 'MARKET_INSIGHTS_ENTERED_EMAIL',
  MARKET_INSIGHTS_CLICKED_CUSTOMIZE: 'MARKET_INSIGHTS_CLICKED_CUSTOMIZE',
  MARKET_INSIGHTS_ADDED_COMMODITIES: 'MARKET_INSIGHTS_ADDED_COMMODITIES',
  MARKET_INSIGHTS_REMOVED_COMMODITIES: 'MARKET_INSIGHTS_REMOVED_COMMODITIES',
  MARKET_INSIGHTS_PRICE_SUPPLY_SKU_SELECTED: 'MARKET_INSIGHTS_PRICE_SUPPLY_SKU_SELECTED',
  MARKET_INSIGHTS_MOVEMENT_TYPE_SELECTED: 'MARKET_INSIGHTS_MOVEMENT_TYPE_SELECTED',
  PRICE_SUPPLY_CLICKED_BLOCKING: 'PRICE_SUPPLY_CLICKED_BLOCKING',
  PRICE_SUPPLY_COMPLETED_ONBOARDING: 'PRICE_SUPPLY_COMPLETED_ONBOARDING',
  FROM_WEB_APP_CTA_TOP: 'FROM_WEB_APP_CTA_TOP',
  FROM_WEB_APP_CTA_BOTTOM: 'FROM_WEB_APP_CTA_BOTTOM',
  INSIGHTS_PRO_FILTER_CHANGED: 'INSIGHTS_PRO_FILTER_CHANGED',
  INSIGHTS_PRO_CHART_DOWNLOADED: 'INSIGHTS_PRO_CHART_DOWNLOADED',
  INSIGHTS_PRO_FOB_COMPARISON_SELECTED: 'INSIGHTS_PRO_FOB_COMPARISON_SELECTED',
  SESSION_STARTED: 'SESSION_STARTED',
  PUSH_NOTIFICATION_MODAL_DISPLAYED: 'PUSH_NOTIFICATION_MODAL_DISPLAYED',
  PUSH_NOTIFICATION_MODAL_DISMISSED: 'PUSH_NOTIFICATION_MODAL_DISMISSED',
  PUSH_NOTIFICATIONS_ENABLED: 'PUSH_NOTIFICATIONS_ENABLED',
  PUSH_NOTIFICATIONS_DENIED: 'PUSH_NOTIFICATIONS_DENIED',
  MARKET_REQUESTS_APP_SUPPLY_SHOWN: 'MARKET_REQUESTS_APP_SUPPLY_SHOWN',
  MARKET_REQUESTS_APP_DEMAND_SHOWN: 'MARKET_REQUESTS_APP_DEMAND_SHOWN',
  MARKET_REQUEST_INTERESTED_BUTTON_CLICK: 'MARKET_REQUEST_INTERESTED_BUTTON_CLICK',
  MARKET_REQUEST_BID_EMAIL_STEP_COMPLETE: 'MARKET_REQUEST_BID_EMAIL_STEP_COMPLETE',
  MARKET_REQUEST_BID_PROFILE_INFO_STEP_COMPLETE: 'MARKET_REQUEST_BID_PROFILE_INFO_STEP_COMPLETE',
  MARKET_REQUEST_BID_CREATED: 'MARKET_REQUEST_BID_CREATED',
  MARKET_REQUEST_GET_CONNECTED_CONFIRMED: 'MARKET_REQUEST_GET_CONNECTED_CONFIRMED',
  MARKET_REQUEST_VIEW_DETAILS_CLICKED: 'MARKET_REQUEST_VIEW_DETAILS_CLICKED',
  NAV_SEARCH: 'NAV_SEARCH',
  SUPPLY_LISTING_EMAIL_STEP_COMPLETE: 'SUPPLY_LISTING_EMAIL_STEP_COMPLETE',
  SUPPLY_LISTING_PROFILE_INFO_STEP_COMPLETE: 'SUPPLY_LISTING_PROFILE_INFO_STEP_COMPLETE',
  SUPPLY_LISTING_COMPANY_INFO_STEP_COMPLETE: 'SUPPLY_LISTING_COMPANY_INFO_STEP_COMPLETE',
  SUPPLY_LISTING_SUBMITTED_STEP_COMPLETE: 'SUPPLY_LISTING_SUBMITTED_STEP_COMPLETE',
  AGREED_TO_DISTRIBUTION_TERMS: 'AGREED_TO_DISTRIBUTION_TERMS',
  POST_SUPPLY_LISTING_BUTTON_CLICK: 'POST_SUPPLY_LISTING_BUTTON_CLICK',
  STREAM_CHAT_INITIATED: 'STREAM_CHAT_INITIATED',
  OPEN_RATINGS_FAQS: 'OPEN_RATINGS_FAQS',
};

function isCurrentlyImpersonating() {
  return (
    window.localStorage.getItem(TRUE_USER_TOKEN_KEY) &&
    window.localStorage.getItem(TRUE_USER_TOKEN_KEY) !== window.localStorage.getItem(AUTH_TOKEN_KEY)
  );
}

const isSegmentEnabled = () => {
  return (
    process.env.REACT_APP_SEGMENT_ENABLED === 'true' &&
    window.analytics &&
    !window.Cypress &&
    !isCurrentlyImpersonating()
  );
};

export const identifyUser = (params, authorizedTraits = {}) => {
  const token = get(params, 'token');
  const email = get(params, 'email');

  if (isSegmentEnabled()) window.analytics.identify(token, { email, ...authorizedTraits });
};

const defaultAttributes = () => {
  return {
    source: getSource(),
    'pp-referrer': window.sessionStorage.getItem('pp-referrer'),
  };
};

const removeUUIDandID = (url) => {
  return url.replace(UUID_REGEX, '#id').replace(/\d+/gi, '#id');
};

const extractUUIDandID = (url) => {
  const uuids = url.match(UUID_REGEX);
  const ids = url.replace(UUID_REGEX, '').match(/\d+/gi);
  return {
    uuids,
    ids,
  };
};

export const sanitizePage = (page) => {
  const url = removeUUIDandID(page);
  if (url.includes('forgot-password')) return '/auth/forgot-password';
  if (url.includes('reset-password')) return '/auth/reset-password';
  if (url.includes('set-password')) return '/auth/set-password';
  if (url.includes('sign-in')) return '/auth/sign-in';
  if (url.includes('register')) return '/auth/register';
  if (url.includes('onboarding')) return '/auth/onboarding';
  if (url.includes('verify')) return '/auth/verify';
  if (url.includes('conversations')) return '/conversations';
  if (url.includes('shipping-point')) return '/commodity-varieties/shipping-point';
  if (url.includes('terminal-point')) return '/commodity-varieties/terminal-point';
  return url;
};

export const trackEvent = (eventName, otherData = {}) => {
  if (isSegmentEnabled()) {
    const eventData = { ...defaultAttributes(), ...otherData };
    debug(eventName, eventData);
    window.analytics.track(eventName, eventData);
  }
};

export const trackPageView = (page, additionalData) => {
  if (isSegmentEnabled()) {
    const { uuids, ids } = extractUUIDandID(page);
    const sanitizedPage = sanitizePage(page);
    const pageData = {
      raw_url: window?.location?.href,
      ...(uuids && { uuids }),
      ...(ids && { ids }),
      ...defaultAttributes(),
      ...additionalData,
    };
    debug(sanitizedPage, pageData);
    window.analytics.page(null, sanitizedPage, pageData);
  }
};
