import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';

import { useAuth, USER_FRAGMENT } from '../../../../contexts/auth';
import { UPDATE_ONBOARDING_INFORMATION } from '../../../../graphql/queries/update-onboarding-information';

import OnboardingView from './view';

const TRIGGER_PHONE_NUMBER_VERIFICATION = gql`
  mutation TriggerPhoneNumberVerification($userId: Int!, $phoneNumber: String!) {
    triggerPhoneNumberVerification(userId: $userId, phoneNumber: $phoneNumber) {
      isSuccessful
    }
  }
`;

const CONFIRM_PHONE_NUMBER = gql`
  ${USER_FRAGMENT}
  mutation ConfirmPhoneNumber($userId: Int!, $phoneNumber: String!, $phoneConfirmationToken: String!) {
    confirmPhoneNumber(userId: $userId, phoneNumber: $phoneNumber, phoneConfirmationToken: $phoneConfirmationToken) {
      ...currentUserFragment
    }
  }
`;

function Onboarding(props) {
  const { title, onOnboardingCompleted } = props;

  const { user } = useAuth();
  const userId = user?.id;
  const accessToken = user?.accessToken;

  const [updateOnboardingInformation] = useMutation(UPDATE_ONBOARDING_INFORMATION);
  const [triggerPhoneNumberVerification] = useMutation(TRIGGER_PHONE_NUMBER_VERIFICATION);
  const [confirmPhoneNumber] = useMutation(CONFIRM_PHONE_NUMBER);

  const handleUpdateOnboardingInformation = useCallback(
    (onboardingInformation) => {
      return updateOnboardingInformation({ variables: { accessToken, onboardingInformation } });
    },
    [updateOnboardingInformation, accessToken],
  );

  const handleTriggerPhoneVerification = useCallback(
    (values) => {
      return triggerPhoneNumberVerification({ variables: { userId: Number(userId), ...values } });
    },
    [triggerPhoneNumberVerification, userId],
  );

  const handlePhoneConfirmation = useCallback(
    (values) => {
      return confirmPhoneNumber({ variables: { userId: Number(userId), ...values } });
    },
    [confirmPhoneNumber, userId],
  );

  return (
    <OnboardingView
      title={title}
      onboardingInformation={user?.onboardingInformation || {}}
      updateOnboardingInformation={handleUpdateOnboardingInformation}
      triggerPhoneNumberVerification={handleTriggerPhoneVerification}
      confirmPhoneNumber={handlePhoneConfirmation}
      onOnboardingCompleted={onOnboardingCompleted}
    />
  );
}

Onboarding.propTypes = {
  title: PropTypes.string,
  onOnboardingCompleted: PropTypes.func.isRequired,
};

export default Onboarding;
