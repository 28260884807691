import { useRef, useEffect, useState, useCallback } from 'react';

const getWidthFromElement = (el) => (el ? el.getBoundingClientRect().width : 0);

export function useWidth(defaultReference = null) {
  const ref = useRef(defaultReference);
  const [width, setWidth] = useState(getWidthFromElement(defaultReference));

  const setWidthFromRef = useCallback(() => {
    setWidth(getWidthFromElement(ref.current));
  }, [ref, setWidth]);

  useEffect(() => {
    setWidthFromRef();
    window.addEventListener('resize', setWidthFromRef);
    return () => window.removeEventListener('resize', setWidthFromRef);
  }, [setWidthFromRef]);

  return { ref, width };
}

export function useHeight() {
  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  const setHeightFromRef = useCallback(() => {
    const newHeight = ref.current ? ref.current.getBoundingClientRect().height : 0;
    setHeight(newHeight);
  }, [ref, setHeight]);

  useEffect(() => {
    setHeightFromRef();
    window.addEventListener('resize', setHeightFromRef);
    return () => window.removeEventListener('resize', setHeightFromRef);
  }, [setHeightFromRef]);

  return { ref, height, setHeightFromRef };
}

export function useMobileFullscreenOpen() {
  useEffect(() => {
    document.body.classList.add('modal-open'); // add this class to prevent 2 scrollbars in safari
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);
}

export const MOBILE_WIDTH = 640;
export const TABLET_WIDTH = 768;
export const SMALL_DESKTOP_WIDTH = 1024;

export function useResponsiveLayout() {
  const { width } = useWidth(document.body);

  return {
    width,
    isMobile: width > 0 && width < MOBILE_WIDTH,
    isTablet: width > MOBILE_WIDTH && width <= TABLET_WIDTH,
    isMobileOrTablet: width > 0 && width <= TABLET_WIDTH,
    isLowerRes: width > 0 && width < SMALL_DESKTOP_WIDTH,
  };
}
