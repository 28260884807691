import { useState, useCallback } from 'react';

export default function useStorage(key, initialValue, storage = window.sessionStorage) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = storage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value) => {
      setStoredValue((previousValue) => {
        const valueToStore = value instanceof Function ? value(previousValue) : value;

        try {
          storage.setItem(key, JSON.stringify(valueToStore));
        } catch (e) {}

        return valueToStore;
      });
    },
    [key, storage, setStoredValue],
  );

  return [storedValue, setValue];
}
