import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { useField, useFormikContext } from 'formik';
import { FormLabel, TextField } from '@producepay/pp-ui';

import FormikTextField from '../FormikTextField';
import CommodityVarietySearch from '../../molecules/CommodityVarietySearch';
import SKUCommoditySearch from '../../molecules/SKUCommoditySearch';

function FormikCommodityVarietySearch(props) {
  const {
    name,
    searchFieldName, // use this if we don't want the value typed into the text input to be saved as "name"
    otherFieldName,
    onChange,
    initialSearchTerm = '',
    label,
    hasLabel,
    helperText,
    shouldPrependErrors,
    className,
    otherOption,
    otherFieldClassName,
    otherFormLabel,
    dataSource,
    customHandleSelect,
    ...rest
  } = props;

  const { t } = useTranslation(['formFields', 'formErrors']);

  const resolvedFieldName = searchFieldName || name;
  const [field] = useField({ name: resolvedFieldName, onChange });
  const { setFieldValue, setFieldTouched, touched, errors, values } = useFormikContext();
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

  const resolvedLabel = label || t([`formFields:${name}`, _.startCase(name)]);

  const handleSelected = (selectedOption) => {
    if (customHandleSelect) {
      customHandleSelect(name, selectedOption);
    } else if (otherOption && selectedOption?.value === otherOption?.value) {
      setFieldValue(name, null);
      setFieldValue('isOtherSelected', true);
    } else {
      setFieldValue('isOtherSelected', false);
      setFieldValue(name, selectedOption);
    }
  };

  const searchProps = {
    searchTerm,
    onSearchChange: (value) => {
      setSearchTerm(value);
      if (resolvedFieldName !== name) {
        setFieldTouched(name);
      }
    },
    onSelected: handleSelected,
    otherOption,
  };

  return (
    <div>
      <FormLabel
        className={className}
        label={hasLabel ? resolvedLabel : null}
        helperText={helperText}
        shouldPrependErrors={shouldPrependErrors}
        error={touched[name] ? t([`formErrors:${errors[name]}`, errors[name] || '']) : null}
      >
        {dataSource === 'pn' ? (
          <CommodityVarietySearch className="relative z-60" {...searchProps} {...field} {...rest} />
        ) : (
          <SKUCommoditySearch className="relative z-60" {...searchProps} {...field} {...rest} />
        )}
      </FormLabel>
      {values.isOtherSelected && (
        <FormikTextField name={otherFieldName} label={otherFormLabel} className={cx('mt-2', otherFieldClassName)} />
      )}
    </div>
  );
}

FormikCommodityVarietySearch.propTypes = {
  ...TextField.propTypes,
  name: PropTypes.string.isRequired,
  hasLabel: PropTypes.bool,
  onChange: PropTypes.func,
  otherOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  otherFormLabel: PropTypes.string,
  otherFieldClassName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataSource: PropTypes.string,
  customHandleSelect: PropTypes.func,
};

FormikCommodityVarietySearch.defaultProps = {
  hasLabel: true,
  otherOption: null,
  otherFormLabel: null,
  otherFieldClassName: '',
  dataSource: 'pn',
  customHandleSelect: null,
};

export default React.memo(FormikCommodityVarietySearch);
