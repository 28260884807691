import React from 'react';
import cx from 'classnames';
import { Formik, Form, Field } from 'formik';

import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Modal, Button, RadioButton, FormLabel } from '@producepay/pp-ui';
import ProgressBar from '../ProgressBar';
import { LOGISTIC_STEP, TOTAL_STEPS } from '../helpers';

import FormikLocationSearch from 'components/formik/FormikLocationSearch';
import FormikDatePicker from 'components/formik/FormikDatePicker';

function LogisticStep({ onSubmit, onPrev, setLoad, load, modalProps, isMobile }) {
  const { t } = useTranslation('marketplaceListSupplyLogistic');
  const progressPercent = (LOGISTIC_STEP / TOTAL_STEPS) * 100;

  const validationSchema = yup.object().shape({
    grownLocation: yup.string().required('formErrors:is required'),
    shippingPoint: yup.string().required('formErrors:is required'),
    isAtShippingPoint: yup.string().required('formErrors:is required'),
    estimatedPackDate: yup.date().required('formErrors:is required'),
    arrivalDate: yup.date().when('isAtShippingPoint', {
      is: 'false',
      then: yup.date().required('formErrors:is required'),
      otherwise: yup.date(),
    }),
  });

  const preprocessSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const {
        grownLocation,
        shippingPoint,
        isAtShippingPoint: isAtShippingPointString,
        estimatedPackDate,
        arrivalDate,
      } = values;
      const { packaging, commodityVarietyInfo, customCommodityVarietyName } = load;
      const isAtShippingPoint = isAtShippingPointString === 'true';

      let variables = {
        requestType: 'supply',
        grownLocation,
        shippingPoint,
        isAtShippingPoint,
        packaging,
        estimatedPackDate,
      };

      if (!isAtShippingPoint) {
        variables = { ...variables, arrivalDate };
      }

      if (commodityVarietyInfo) {
        variables = { ...variables, commodityVarietyInfoId: commodityVarietyInfo.id };
      } else {
        variables = { ...variables, customCommodityVarietyName };
      }

      onSubmit(variables);
    } finally {
      setSubmitting(false);
    }
  };

  const handlePrev = (values) => {
    setLoad({ ...load, ...values });
    onPrev();
  };

  const initialValues = () => {
    return {
      grownLocation: load.grownLocation || '',
      shippingPoint: load.shippingPoint || '',
      isAtShippingPoint: load.isAtShippingPoint || 'false',
      estimatedPackDate: load.estimatedPackDate || '',
      arrivalDate: load.arrivalDate || '',
    };
  };

  return (
    <Formik initialValues={initialValues()} onSubmit={preprocessSubmit} validationSchema={validationSchema}>
      {({ values, handleSubmit, handleBlur, isSubmitting }) => (
        <Modal
          className="mt-20"
          title={t('title')}
          footerContent={
            <>
              <ProgressBar progressPercent={progressPercent} />
              <div className="flex w-full p-4 justify-between border-t">
                <Button
                  label={t('common:previous')}
                  variant="outlined"
                  onClick={() => handlePrev(values)}
                  className={cx('mr-2', { 'w-full': isMobile })}
                  data-testid="btn-prev"
                />
                <Button
                  label={t('common:continue')}
                  type="submit"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  className={isMobile ? 'w-full' : ''}
                  data-testid="btn-submit"
                />
              </div>
            </>
          }
          {...modalProps}
          onClose={null}
        >
          <Form>
            <div className="py-2">
              <FormikLocationSearch
                name="grownLocation"
                label={t('commodityGrown')}
                placeholder={t('search')}
                data-testid="input-load-grown-at"
                onWheel={(e) => e.target.blur()}
                fieldClassName="mt-2 p-2 textfield w-full border outline-none appearance-none text-base textfield-base rounded focus:border-blue-400"
              />
            </div>
            <div className="py-2">
              <FormikLocationSearch
                name="shippingPoint"
                label={t('shippingPoint')}
                placeholder={t('search')}
                data-testid="input-shipping-point"
                onWheel={(e) => e.target.blur()}
                fieldClassName="mt-2 p-2 textfield w-full border outline-none appearance-none text-base textfield-base rounded focus:border-blue-400"
              />
            </div>
            <div className="py-2">
              <FormLabel label={t('atShippingPoint')}>
                <div className="py-2 px-3">
                  <Field name="isAtShippingPoint">
                    {({ field }) => (
                      <>
                        <RadioButton
                          {...field}
                          id="true"
                          label={t('yes')}
                          className="my-1"
                          value="true"
                          onClick={() => {}}
                          checked={values.isAtShippingPoint === 'true'}
                        />
                        <RadioButton
                          {...field}
                          id="false"
                          label={t('no')}
                          className="my-1"
                          value="false"
                          onClick={() => {}}
                          checked={values.isAtShippingPoint === 'false'}
                        />
                      </>
                    )}
                  </Field>
                </div>
              </FormLabel>
            </div>
            <div className="py-2">
              <FormikDatePicker
                name="estimatedPackDate"
                handleBlur={handleBlur}
                label={values.isAtShippingPoint === 'true' ? t('whenProducePacked') : t('producePackedDate')}
                placeholder={t('selectDate')}
                onWheel={(e) => e.target.blur()}
              />
            </div>
            {values.isAtShippingPoint === 'false' && (
              <div className="py-2">
                <FormikDatePicker
                  name="arrivalDate"
                  handleBlur={handleBlur}
                  placeholder={t('selectDate')}
                  label={t('arrivalAtShippingPoint')}
                  onWheel={(e) => e.target.blur()}
                />
              </div>
            )}
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

export default LogisticStep;
