import React, { PureComponent } from 'react';
import ReactPixel from 'react-facebook-pixel';

import RootView from './view';

import { trackEvent, KEYS } from '../../helpers/tracking';

function getHeapScript(heapAnalyticsKey) {
  return `
    window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){try{heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}catch(e){window.Rollbar && window.Rollbar.error(errorOrMessage);}}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
    heap.load("${heapAnalyticsKey}");
  `;
}

function getGoogleAnalyticsScript(googleAnalyticsKey) {
  return `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', '${googleAnalyticsKey}');
  `;
}

class Root extends PureComponent {
  state = {
    isErrored: false,
  };

  static getDerivedStateFromError() {
    return { isErrored: true };
  }

  clearError = () => {
    this.setState({ isErrored: false });
  };

  componentDidMount() {
    ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID);
    trackEvent(KEYS.SESSION_STARTED);

    // set up heap analytics
    const heapAnalyticsKey = process.env.REACT_APP_HEAP_ANALYTICS_KEY;

    if (heapAnalyticsKey) {
      const heapScript = document.createElement('script');
      heapScript.innerHTML = getHeapScript(process.env.REACT_APP_HEAP_ANALYTICS_KEY);
      heapScript.id = 'heap-analytics';
      heapScript.async = true;

      document.body.appendChild(heapScript);
    }

    // set up google analytics
    const googleAnalyticsKey = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;

    if (googleAnalyticsKey) {
      const gaScript = document.createElement('script');
      gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsKey}`;
      gaScript.async = true;

      document.body.appendChild(gaScript);

      const gaScript2 = document.createElement('script');
      gaScript2.id = 'google-analytics';
      gaScript2.innerHTML = getGoogleAnalyticsScript(googleAnalyticsKey);

      document.body.appendChild(gaScript2);
    }
  }

  render() {
    return <RootView isErrored={this.state.isErrored} clearError={this.clearError} />;
  }
}

export default React.memo(Root);
