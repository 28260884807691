import * as React from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';

export const useFeatureFlagsService = () => {
  const ldClient = useLDClient();

  React.useEffect(() => {
    // Enable live feature flag updates
    ldClient?.setStreaming(true);
  }, [ldClient]);

  return {
    initialized: !!ldClient,
    identify: (user) => {
      let userIdentity = { anonymous: true };

      if (user?.email) {
        const { id, email } = user;

        userIdentity = {
          key: email,
          id,
          email,
        };
      }

      return ldClient?.identify(userIdentity) ?? Promise.resolve();
    },
  };
};
