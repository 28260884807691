import React from 'react';
import { useTranslation } from 'react-i18next';

import routes from '../../../../routes';

import { LINK_CNAME, ACTIVE_LINK_CNAME } from '../index';

function MarketplaceNavLink() {
  const { t } = useTranslation(['navBar', 'common']);

  return (
    <a href={routes.trading()} className={LINK_CNAME} activeClassName={ACTIVE_LINK_CNAME}>
      {t('navBar:trading')}
    </a>
  );
}

export default MarketplaceNavLink;
