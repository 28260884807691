import React from 'react';
import leaf from 'assets/images/leaf.svg';
import { useTranslation } from 'react-i18next';

function PerformanceSection({ isMobile, companySellerScore }) {
  const { t } = useTranslation(['profileModal']);

  return (
    <div className="flex flex-col p-4 md:p-8 border-2 border-opacity-50" style={{ minWidth: !isMobile && '433px' }}>
      <div className="flex justify-between">
        <div className="text-2xl font-semibold">
          <div className="flex flex-col">
            <span>{t('performance.trading')}</span>
            <span>{t('performance.performance')}</span>
          </div>
        </div>
        <div className="flex flex-col">
          <span className="text-2xl font-semibold flex">
            <img className="mr-2" src={leaf} alt="leaf" />
            {companySellerScore?.tradingPerformance?.toFixed(2)}
          </span>
          <span className="text-sm text-right">{t('performance.outOf')}</span>
        </div>
      </div>
      <div className="flex justify-between mt-8 mb-2">
        <span>{t('performance.casesToSpec')}</span>
        <span className="font-semibold">{companySellerScore?.casesToSpec?.toFixed(2)}%</span>
      </div>
      <div className="flex justify-between my-2">
        <span>{t('performance.onTimeDelivery')}</span>
        <span className="font-semibold">{companySellerScore?.percentOnTime?.toFixed(2)}%</span>
      </div>
      <div className="flex justify-between my-2">
        <span>{t('performance.numOfTrades')}</span>
        <span className="font-semibold">{companySellerScore?.totalEntries}</span>
      </div>
    </div>
  );
}

export default PerformanceSection;
