import React, { useContext, useState, useCallback } from 'react';
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import _ from 'lodash';

import { useAuth } from '../auth';
import { useDmrCommodityVarieties } from '../DmrCommodityVarieties';
import useStorage from 'hooks/useStorage';
import { trackEvent, KEYS } from 'helpers/tracking';

const PREFERENCES_FRAGMENT = `
  fragment preferencesFragment on UserCommodityVarietyPreference {
    commodityId
    varietyId
    commodityVarietyInfoId
  }
`;

const FETCH_PREFERENCES = gql`
  ${PREFERENCES_FRAGMENT}
  query FetchPreferences {
    userCommodityVarietyPreferences {
      ...preferencesFragment
    }
  }
`;

const ADD_PREFERENCE = gql`
  ${PREFERENCES_FRAGMENT}
  mutation AddPreferences($cviIds: [Int!]!) {
    createUserCommodityVarietyPreferences(commodityVarietyInfoIds: $cviIds) {
      ...preferencesFragment
    }
  }
`;

const REMOVE_PREFERENCE = gql`
  ${PREFERENCES_FRAGMENT}
  mutation DeletePreferences($cviIds: [Int!]!) {
    deleteUserCommodityVarietyPreferences(commodityVarietyInfoIds: $cviIds) {
      ...preferencesFragment
    }
  }
`;

const DAILY_REPORT_QUERY = gql`
  query DailyReportCommodities($reportType: PriceReportTypeEnum!, $reportDate: Date!) {
    dailyReportCommodities(reportType: $reportType, reportDate: $reportDate) {
      reportDate
      commodity {
        name
        imageUrls {
          original
          small
        }
      }
      varieties {
        varietyName
        varietySkuName
        commodityVarietyInfoId
        shippingPoints {
          cityName
          lowPrice
          averagePrice
          highPrice
          delta
        }
      }
      movementTruckLoads {
        thisWeeksReport
        lastWeeksReport
        twoWeeksReport
        percentChange
      }
    }
  }
`;

const PreferencesContext = React.createContext();

function PreferencesProvider({ children }) {
  const { isAuthenticated } = useAuth();
  const { loading: cvisAreLoading, commodityVarietyInfos } = useDmrCommodityVarieties();

  const [preferences, setPreferences] = useStorage('user-preferences', [], window.localStorage);

  const [
    getDailyReportData,
    {
      data: dailyReportData,
      loading: dailyReportLoading,
      networkStatus: dailyReportNetworkStatus,
      refetch: dailyReportRefetch,
    },
  ] = useLazyQuery(DAILY_REPORT_QUERY);

  // Use local state to track query loading to avoid race condition between setting prefs
  // and the loading state
  const [preferencesAreLoading, setPreferencesAreLoading] = useState(isAuthenticated);
  useQuery(FETCH_PREFERENCES, {
    skip: !isAuthenticated,
    onCompleted: (data) => {
      if (data?.userCommodityVarietyPreferences) {
        setPreferences(data?.userCommodityVarietyPreferences);
      }

      setPreferencesAreLoading(false);
    },
  });

  const [addPreferencesRequest] = useMutation(ADD_PREFERENCE, {
    onCompleted: (data) => {
      const newPrefs = data?.createUserCommodityVarietyPreferences;

      if (newPrefs) {
        setPreferences((prevPrefs) => _.uniqBy([...prevPrefs, ...newPrefs], 'commodityVarietyInfoId'));
        if (dailyReportRefetch) dailyReportRefetch();
      }
    },
  });
  const addPreferences = useCallback(
    (cviIds, accessToken = null) => {
      trackEvent(KEYS.MARKET_INSIGHTS_ADDED_COMMODITIES);
      const variables = { cviIds, ...(accessToken ? { accessToken } : {}) };
      return addPreferencesRequest({ variables });
    },
    [addPreferencesRequest],
  );

  const [removePreferencesRequest] = useMutation(REMOVE_PREFERENCE, {
    onCompleted: (data) => {
      if (data?.deleteUserCommodityVarietyPreferences) {
        setPreferences(data?.deleteUserCommodityVarietyPreferences);
        if (dailyReportRefetch) dailyReportRefetch();
      }
    },
  });
  const removePreferences = useCallback(
    (cviIds, accessToken = null) => {
      trackEvent(KEYS.MARKET_INSIGHTS_REMOVED_COMMODITIES);
      const variables = { cviIds, ...(accessToken ? { accessToken } : {}) };
      return removePreferencesRequest({ variables });
    },
    [removePreferencesRequest],
  );

  const cviPreferenceMap = _.keyBy(preferences, 'commodityVarietyInfoId');
  const cvisForUser = _.filter(commodityVarietyInfos, (cvi) => !!cviPreferenceMap[Number(cvi.id)]);

  return (
    <PreferencesContext.Provider
      value={{
        loading: cvisAreLoading || (preferencesAreLoading && _.isEmpty(preferences)),
        preferences,
        cvisForUser,
        cviPreferenceMap,
        addPreferences,
        removePreferences,
        getDailyReportData,
        dailyReportData,
        dailyReportLoading,
        dailyReportNetworkStatus,
      }}
    >
      {children}
    </PreferencesContext.Provider>
  );
}

function usePreferences() {
  return useContext(PreferencesContext);
}

export { PreferencesProvider, usePreferences };
