import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import cx from 'classnames';

import { Field } from 'formik';
import { FormLabel, Select } from '@producepay/pp-ui';

function FormikSelect(props) {
  const {
    name,
    className,
    selectClassName,
    items,
    hasLabel,
    label,
    helperText,
    shouldPrependErrors,
    customHandleSelect,
    ...rest
  } = props;

  const { t } = useTranslation(['formFields', 'formErrors']);

  return (
    <Field name={name}>
      {({ field, form: { touched, errors, setFieldValue, setFieldTouched } }) => {
        const commonSelectProps = {
          ...field,
          ...rest,
          selectedItem: _.find(items, { value: _.get(field, 'value') }) || null,
          onChange: (newItem) => {
            if (customHandleSelect) {
              customHandleSelect(name, newItem);
            } else {
              setFieldTouched(name);
              setFieldValue(name, newItem.value);
            }
          },
          items,
        };

        return label || hasLabel ? (
          <FormLabel
            className={className}
            label={label || t([`formFields:${name}`, _.startCase(name)])}
            helperText={helperText}
            shouldPrependErrors={shouldPrependErrors}
            error={touched[name] ? t([`formErrors:${errors[name]}`, errors[name] || '']) : null}
          >
            <Select className={selectClassName} {...commonSelectProps} />
          </FormLabel>
        ) : (
          <Select className={cx(className, selectClassName)} {...commonSelectProps} />
        );
      }}
    </Field>
  );
}

FormikSelect.propTypes = {
  ..._.without(Select.propTypes, ['selectedItem', 'onChange']),
  selectClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  hasLabel: PropTypes.bool,
  customHandleSelect: PropTypes.func,
};

FormikSelect.defaultProps = {
  hasLabel: true,
  customHandleSelect: null,
};

export default React.memo(FormikSelect);
