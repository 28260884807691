import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button } from '@producepay/pp-ui';

import AuthModalLink from '../../../partials/AuthModalLink';

import routes from '../../../../routes';

import { TOGGLE_ALL_COMMODITIES_KEY } from '../MarketplaceNav/helpers';

function MarketplaceEmptyState({ isUserUnknown, toggleKey, isSearching }) {
  const { t } = useTranslation('marketplace');
  const history = useHistory();

  const isEmptyBecauseOfSearch = isSearching && toggleKey === TOGGLE_ALL_COMMODITIES_KEY;

  return (
    <div data-testid="market-requests-empty-container" className="w-full text-center flex-col mt-12">
      <div className="font-bold text-lg text-black py-2">
        {isEmptyBecauseOfSearch
          ? t('emptyFromSearchingHeader')
          : isUserUnknown
          ? t('emptyStateUnknownHeader')
          : t('emptyStateNoMatchHeader')}
      </div>
      {!isEmptyBecauseOfSearch && (
        <>
          <div className="py-2 text-off-black" data-testid="marketplace-empty-search-container">
            {isUserUnknown ? t('emptyStateUnknownDescr') : t('emptyStateNoMatchDescr')}
          </div>
          <div className="w-full py-2">
            {isUserUnknown ? (
              <AuthModalLink>
                <Button label={t('common:signUp')} data-testid="btn-marketplace-sign-up" />
              </AuthModalLink>
            ) : (
              <Button
                label={t('editCommodities')}
                variant="outlined"
                onClick={() => {
                  history.push(routes.marketplaceAddCommodities());
                }}
                data-testid="btn-empty-edit-commodities"
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default MarketplaceEmptyState;
