import React, { useContext, useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { gql, useMutation } from '@apollo/client';
import routes from '../../routes';

import { useAuth, USER_FRAGMENT, TRUE_USER_TOKEN_KEY } from '../auth';
import { useFilterState } from '../FilterState';

const TRUE_USER_KEY = 'auth-true-user';

const IMPERSONATE_USER = gql`
  ${USER_FRAGMENT}
  mutation impersonateUser($email: String!) {
    impersonateUser(email: $email) {
      token
      trueUserToken
      user {
        ...currentUserFragment
      }
      trueUser {
        ...currentUserFragment
      }
    }
  }
`;

function getStoredTrueUser() {
  try {
    const userObj = JSON.parse(window?.localStorage?.getItem(TRUE_USER_KEY));
    return userObj;
  } catch (e) {
    return null;
  }
}

function setStoredTrueUser(trueUser, trueUsertoken) {
  window?.localStorage?.setItem(TRUE_USER_TOKEN_KEY, trueUsertoken);
  window?.localStorage?.setItem(TRUE_USER_KEY, JSON.stringify(trueUser));
}

const AdminContext = React.createContext();

function AdminProvider(props) {
  const { children } = props;
  const history = useHistory();
  const location = useLocation();
  const { onAuthUser, user } = useAuth();
  const { resetFilters } = useFilterState();
  const [trueUser, setTrueUser] = useState(getStoredTrueUser() || user);

  const [adminImpersonateUser] = useMutation(IMPERSONATE_USER, {
    onCompleted: (data) => {
      const jwtToken = _.get(data, 'impersonateUser.token');
      const user = _.get(data, 'impersonateUser.user');
      const trueUser = _.get(data, 'impersonateUser.trueUser');
      onAuthUser(user, jwtToken);

      setTrueUser(trueUser);
      setStoredTrueUser(trueUser, _.get(data, 'impersonateUser.trueUserToken'));
      resetFilters();
      history.push(routes.dashboard());
    },
  });

  const impersonateUser = useCallback(
    (email) => {
      adminImpersonateUser({ variables: { email } });
    },
    [adminImpersonateUser],
  );

  const stopImpersonating = useCallback(() => {
    onAuthUser(trueUser, window?.localStorage?.getItem(TRUE_USER_TOKEN_KEY));
    if (location.pathname !== routes.impersonate()) {
      window.location.reload();
    }
  }, [location.pathname, onAuthUser, trueUser]);

  return (
    <AdminContext.Provider
      value={{
        impersonateUser,
        stopImpersonating,
        trueUser,
        currentUser: user,
        isAdmin: (user && user.isAdmin) || (trueUser && trueUser.isAdmin),
      }}
    >
      {children}
    </AdminContext.Provider>
  );
}

function useAdmin() {
  return useContext(AdminContext);
}
const MockAdminProvider = ({ value, children }) => {
  return <AdminContext.Provider value={value}>{children}</AdminContext.Provider>;
};

export { AdminProvider, MockAdminProvider, useAdmin };
