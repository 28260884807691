import React from 'react';
import { Trans } from 'react-i18next';
import cx from 'classnames';

function TermsAndPrivacy({ className }) {
  return (
    <span className={cx(className, 'text-sm')}>
      <Trans i18nKey="common:termsAndPrivacy">
        By signing up, you agree to our
        <a
          href="https://producepay.com/terms-of-use/"
          className="text-primary"
          target="_blank"
          rel="noopener noreferrer"
        >
          terms of service
        </a>
        and
        <a
          href="https://producepay.com/privacy-policy/"
          className="text-primary"
          target="_blank"
          rel="noopener noreferrer"
        >
          privacy policy.
        </a>
      </Trans>
    </span>
  );
}

export default TermsAndPrivacy;
