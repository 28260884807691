import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFulfillmentQuery } from '../../../apollo-client';
import { map, orderBy, debounce } from 'lodash';

import { Autocomplete } from '@producepay/pp-ui';

import { SKU_SEARCH_QUERY } from 'graphql/queries/fulfillment/get-sku-component.js';

function SKUCommoditySearch({ searchTerm, onSelected, otherOption, onSearchChange, ...rest }) {
  const [cvLoaded, setCvLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [cvSearch, setCvSearch] = useState('');

  const { t } = useTranslation('poCreation');

  const { data, loading } = useFulfillmentQuery(SKU_SEARCH_QUERY, {
    variables: {
      search: cvSearch,
    },
    skip: !cvSearch,
    onCompleted: () => {
      setCvLoaded(true);
    },
  });

  useEffect(() => {
    const queriedItems = orderBy(
      map(data?.commodityVarieties, (item) => ({
        label: `${item.variety.name} ${item.commodity.name}`,
        value: item,
      })),
      'label',
    );

    if (!loading && cvLoaded) {
      const newItems = queriedItems;

      if (otherOption && searchTerm?.length > 0) {
        newItems.push(otherOption);
      }
      setItems(newItems);
    }
  }, [otherOption, searchTerm, loading, data, cvLoaded]);

  const handleItemSelected = (item) => {
    let selectedItem = item;
    const dataList = data?.commodityVarieties || [];

    const found = dataList.find((dataItem) => {
      const label = `${dataItem.variety.name} ${dataItem.commodity.name}`;
      return label === item.label;
    });

    if (found) {
      selectedItem = {
        label: `${found.variety.name} ${found.commodity.name}`,
        value: found,
      };
    }

    onSelected(selectedItem);
  };

  const debouncedSearch = useCallback(
    debounce((currentSearchTerm) => {
      setCvSearch(currentSearchTerm);
    }, 500),
    [setCvSearch],
  );

  return (
    <Autocomplete
      searchTerm={searchTerm}
      onSearchChange={(value) => {
        setCvLoaded(false);
        onSearchChange(value);
        debouncedSearch(value);
      }}
      items={items || []}
      isLoading={loading}
      onItemSelected={handleItemSelected}
      filterFunc={() => true}
      placeholder={t('poCreation:searchProduce')}
      {...rest}
    />
  );
}

SKUCommoditySearch.propTypes = {
  onSelected: PropTypes.func,
  onSearchChange: PropTypes.func,
  searchTerm: PropTypes.string,
  otherOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

SKUCommoditySearch.defaultProps = {
  onSelected: () => {},
  onSearchChange: () => {},
  searchTerm: '',
  otherOption: null,
};

export default React.memo(SKUCommoditySearch);
