import React from 'react';
import PropTypes from 'prop-types';

const SvgCalendarIcon = (props) => {
  const { color, size, ...otherProps } = props;

  return (
    <svg height={size} width={size} viewBox="0 0 100 100" {...otherProps}>
      <path
        style={{
          textIndent: 0,
          textTransform: 'none',
          blockProgression: 'tb',
        }}
        d="M30.031 5a2 2 0 00-2 2v12.25c-3.444.891-6 4.04-6 7.75 0 4.395 3.606 8 8 8 4.395 0 8-3.605 8-8 0-3.701-2.567-6.82-6-7.719V7a2 2 0 00-2-2zm40 0a2 2 0 00-2 2v12.25c-3.444.891-6 4.04-6 7.75 0 4.395 3.606 8 8 8 4.395 0 8-3.605 8-8 0-3.701-2.567-6.82-6-7.719V7a2 2 0 00-2-2zm-54 6C10.511 11 6 15.51 6 21.031V84.97C6 90.489 10.51 95 16.031 95H83.97C89.489 95 94 90.49 94 84.969V21.03C94 15.511 89.49 11 83.969 11H78c-1.057-.015-2.031.943-2.031 2s.974 2.015 2.031 2h5.969C87.339 15 90 17.66 90 21.031V39H10V21.031C10 17.661 12.66 15 16.031 15H22c1.057.015 2.031-.943 2.031-2s-.974-2.015-2.031-2h-5.969zM38 11a2 2 0 100 4h24a2 2 0 100-4H38zm-7.969 12c2.233 0 4 1.767 4 4s-1.767 4-4 4a3.97 3.97 0 01-4-4c0-2.233 1.767-4 4-4zm40 0c2.233 0 4 1.767 4 4s-1.767 4-4 4a3.97 3.97 0 01-4-4c0-2.233 1.767-4 4-4zM10 43h80v41.969C90 88.339 87.34 91 83.969 91H16.03C12.661 91 10 88.34 10 84.969V43z"
        overflow="visible"
        fill={color}
      />
    </svg>
  );
};

SvgCalendarIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SvgCalendarIcon.defaultProps = {
  color: 'currentColor',
  size: '24',
};

export default SvgCalendarIcon;
