import React, { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import _ from 'lodash';

import { useAuth } from '../auth';

const FETCH_AD = gql`
  query fetchAd {
    ad {
      html
      placementType
    }
  }
`;

const AdContext = React.createContext();

function AdProvider({ children }) {
  const { user } = useAuth();

  const { data } = useQuery(FETCH_AD, {
    skip: _.get(user, 'isInsightsProUser'),
  });

  return (
    <AdContext.Provider
      value={{
        html: _.get(data, 'ad.html'),
        placement: _.get(data, 'ad.placementType'),
      }}
    >
      {children}
    </AdContext.Provider>
  );
}

function useAd() {
  return useContext(AdContext);
}

export { AdProvider, useAd };
