import React from 'react';

import NavBar from '../../molecules/NavBar';
import MobileBottomNavBar from '../../molecules/MobileBottomNavBar';

function ProfileLayout({ children }) {
  return (
    <>
      <NavBar />

      <div>{children}</div>

      <MobileBottomNavBar />
    </>
  );
}

export default React.memo(ProfileLayout);
