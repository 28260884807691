import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { generateBulletedString } from 'helpers/format';

function DesktopCertificates(certificates) {
  return <span>{generateBulletedString(certificates)}</span>;
}

function MobileCertificates(certificates) {
  return (
    <div>
      {certificates.map((cert) => {
        return (
          <div key={cert} className="flex flex-row pt-1">
            {cert}
          </div>
        );
      })}
    </div>
  );
}

function InfoSection({ companyProfile, isMobile }) {
  const { t } = useTranslation(['profileModal']);

  const { description = null, certificates = null } = companyProfile;
  const splitCertificates = certificates ? certificates.split(',') : [];

  return (
    <div className={cx('profile-container', { 'border-t-1 border-b-1': !isMobile, 'border-0': isMobile })}>
      {description && (
        <>
          <div className={cx({ 'py-4': isMobile, 'py-8': !isMobile })}>
            <div className={cx('font-semibold text-sm', { 'pb-1': isMobile, 'pb-3': !isMobile })}>
              {t('info.sellerDescription')}
            </div>
            <p className="text-sm">{description}</p>
          </div>
        </>
      )}
      {description && certificates && <div className="border-b-1" />}
      {certificates && (
        <div className={cx({ 'py-4': isMobile, 'py-8': !isMobile })}>
          <div className={cx('font-semibold text-sm', { 'pb-1': isMobile, 'pb-3': !isMobile })}>
            {t('info.certificates')}
          </div>
          <div className="flex items-center">
            {isMobile ? MobileCertificates(splitCertificates) : DesktopCertificates(splitCertificates)}
          </div>
        </div>
      )}
    </div>
  );
}

InfoSection.propTypes = {
  companyProfile: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
};

InfoSection.defaultProps = {
  isMobile: false,
};

export default InfoSection;
