import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { useResponsiveLayout } from 'helpers/dom';

import { LoadingSpinner } from '@producepay/pp-ui';

import camera from 'assets/images/camera.svg';
import trashCan from 'assets/images/trash-can.svg';

function PhotoCard({ image, handleAttachment, deleteFile, loading }) {
  const { t } = useTranslation('marketplaceListSupplyPhotos');
  const { isMobile } = useResponsiveLayout();
  const hiddenFileInput = useRef(null);

  return (
    <div className="flex flex-col m-1 pb-10">
      <div
        role="button"
        className={cx(
          'relative flex justify-center items-center border border-gray-300 image-add rounded-lg w-40 h-40',
          {
            'border-dashed': !image,
            'w-36 h-36': isMobile,
          },
        )}
        onClick={() => (image ? null : hiddenFileInput.current.click())}
        tabIndex="0"
      >
        {loading && (
          <div className="absolute left-1/6 top-1/6">
            <LoadingSpinner />
          </div>
        )}
        <input
          ref={hiddenFileInput}
          className="hidden"
          onChange={(e) => {
            handleAttachment(e, image);
          }}
          type="file"
          accept="image/*"
        />
        {image ? (
          <div className="flex flex-row">
            <img
              src={image.original}
              alt="market request img"
              className={cx('rounded-lg max-h-40', { 'max-h-36:': isMobile })}
            />
          </div>
        ) : (
          <div className="flex flex-col">
            <div className="flex flex-row justify-center">
              <img className={cx('h-6', { 'h-5': isMobile })} src={camera} alt="camera" />
            </div>
            {!isMobile && (
              <div className="flex flex-row justify-center w-20 text-sm font-bold py-2">{t('addPhotos')}</div>
            )}
          </div>
        )}
      </div>
      {image && (
        <div className="flex flex-row mt-2 justify-end">
          <button type="button" onClick={() => deleteFile(image.id)} className="disabled:opacity-50">
            <img className="h-4" src={trashCan} alt="trash can" />
          </button>
        </div>
      )}
    </div>
  );
}

export default PhotoCard;
