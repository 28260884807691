import { useEffect } from 'react';

export default function useScrollLock(isEnabled = true) {
  useEffect(() => {
    if (!isEnabled) return;

    document.body.style.overflow = 'hidden';
    return function clearScrollLock() {
      document.body.style.overflow = '';
    };
  }, [isEnabled]);
}
