import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import cx from 'classnames';

import { Field } from 'formik';
import { Checkbox } from '@producepay/pp-ui';

function FormikCheckbox(props) {
  const { name, label, className, checkboxClassName, disabled, ...rest } = props;

  const { t } = useTranslation('formFields');

  return (
    <Field name={name}>
      {({ field, form: { setFieldValue, setFieldTouched } }) => {
        const handleClick = (e) => {
          e.stopPropagation();
          e.preventDefault();

          setFieldTouched(name);
          setFieldValue(name, !field.value);
        };

        return (
          <div
            className={cx(className, 'flex items-center', { 'cursor-pointer': !disabled })}
            onClick={!disabled ? handleClick : undefined}
          >
            {disabled ? (
              <input className="w-4 h-4" type="checkbox" checked={field.value} disabled />
            ) : (
              <Checkbox
                className={checkboxClassName}
                {...field}
                {...rest}
                value={name}
                checked={field.value}
                onClick={handleClick}
              />
            )}

            <div className={cx('ml-2 text-sm', { 'text-gray-medium': disabled })}>
              {label || t([`formFields:${name}`, _.startCase(name)])}
            </div>
          </div>
        );
      }}
    </Field>
  );
}

FormikCheckbox.propTypes = {
  ...Checkbox.propTypes,
  checkboxClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

FormikCheckbox.defaultProps = {
  disabled: false,
};

export default React.memo(FormikCheckbox);
