import { useEffect } from 'react';
import { useToast } from '@producepay/pp-ui';
import qs from 'qs';

export default function useNotificationListener() {
  const { addToastToQueue } = useToast();

  useEffect(() => {
    const searchObject = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    const { toastType, toastHeader, toastBody } = searchObject;

    if (toastHeader && toastBody) {
      addToastToQueue({
        type: toastType,
        header: toastHeader,
        body: toastBody,
      });
    }
  }, [addToastToQueue]);
}
