import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import cx from 'classnames';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { get } from 'lodash';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';
import { useChatState } from 'contexts/Chat';

import routes from '../../../routes';
import { SIDEBAR_WIDTH, MOBILE_NAVBAR_HEIGHT } from '../../../styles/constants';
import { useAuth } from '../../../contexts/auth';
import { useAdmin } from '../../../contexts/admin';
import useMenu from '../../../hooks/useMenu';

import { useResponsiveLayout } from '../../../helpers/dom';
import { parseISOWithFallback } from '../../../helpers/dates';

import UserIcon from '../../icons/User';
import ConversationsIcons from '../../icons/ConversationsIcons';
import Search from './Search';
import { Button, Card } from '@producepay/pp-ui';

import LogoOrange from './images/logo-orange-only.svg';
import ProfileIcon from './images/profile.svg';

import logo from '../../../assets/images/pp-only-logo-white-bg.svg';

import InsightsNavLink from './InsightsNavLink';
import MarketplaceNavLink from './MarketplaceNavLink';

export const LINK_CNAME = `min-w-max h-full text-sm text-parsnip-800 flex items-center
px-4 hover:bg-gray-200 font-semibold`;
export const SUBLINK_CNAME = 'block hover:bg-gray-200 py-2 px-4';
export const ACTIVE_LINK_CNAME = 'bg-gray-100';
const COMMON_HEADER_CNAME = 'sticky top-0 w-full z-100';
const CONVERSATION_OVERLAY_CNAME = `absolute right-0 mb-3 mr-3 bg-red-500
  flex h-4 min-h-[1.25rem] w-4 min-w-[1.25rem] items-center justify-center
  rounded-full text-center text-white text-xs`;
const MAX_UNREAD_COUNT_DISPLAY = 9;

const FETCH_LAST_UPDATED_AT = gql`
  query rawErpRecords {
    rawErpRecords(limit: 1) {
      createdAt
    }
  }
`;

function LastUpdatedAt({ lastUpdatedAt }) {
  return lastUpdatedAt ? (
    <div className="text-xs mt-1">{`Updated ${format(parseISOWithFallback(lastUpdatedAt), 'MM/dd/yyyy h:mma')}`}</div>
  ) : null;
}

const LowResolutionNavBar = ({ showSearch, navBarPosition, highlightSearch, setHighlightSearch }) => {
  const { unreadMessages } = useChatState();
  const { pathname } = useLocation();
  const { t } = useTranslation(['navBar', 'common']);

  if (pathname === routes.conversations()) return null;

  const shouldShowMissedMessageCount = unreadMessages !== 0;

  return (
    <>
      <div
        style={{ height: MOBILE_NAVBAR_HEIGHT, position: navBarPosition }}
        className={cx('text-center border-b p-4', COMMON_HEADER_CNAME, {
          'bg-white': highlightSearch,
          'bg-gray-100': !highlightSearch,
        })}
      >
        <div className="flex justify-between items-center">
          {!highlightSearch && (
            <div className="mr-4 md:mr-12">
              <Link to={routes.dashboard()}>
                <img className="w-8" src={LogoOrange} alt="ProducePay" />
              </Link>
            </div>
          )}

          {showSearch && (
            <>
              <div className="text-left flex-grow mr-4 md:mr-12">
                <Search className="w-full flex items-center" setShowSearch={setHighlightSearch} />
              </div>
              {highlightSearch && (
                <Button
                  className="self-stretch flex items-center"
                  label="Cancel"
                  variant="text"
                  onClick={() => setHighlightSearch(false)}
                />
              )}
            </>
          )}

          {!highlightSearch && (
            <>
              <a href={routes.conversations()} className={`relative ${LINK_CNAME}`} activeClassName={ACTIVE_LINK_CNAME}>
                {shouldShowMissedMessageCount && (
                  <div className={CONVERSATION_OVERLAY_CNAME}>
                    <span className="text-xs font-bold">
                      {unreadMessages > MAX_UNREAD_COUNT_DISPLAY ? `${MAX_UNREAD_COUNT_DISPLAY}+` : unreadMessages}
                    </span>
                  </div>
                )}
                <ConversationsIcons className="h-6 w-6" />
              </a>

              <Link to={routes.profile()}>
                <img src={ProfileIcon} alt={t('common:profile')} />
              </Link>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const ProfileNavLink = ({
  showProfileMenu,
  setShowProfileMenu,
  isInsightsProUser,
  isAdmin,
  trueUser,
  currentUser,
  stopImpersonating,
}) => {
  const { t } = useTranslation(['navBar', 'common']);
  return (
    <div className="relative">
      <div
        data-testid="profile-nav-link"
        className="h-full px-4 cursor-pointer flex items-center hover:bg-gray-200"
        onClick={() => setShowProfileMenu(!showProfileMenu)}
      >
        <UserIcon className="h-6 w-6 text-parsnip-800" />
      </div>

      {showProfileMenu && (
        <div
          className="absolute right-0"
          style={{ paddingTop: 1, minWidth: 200 }}
          onClick={() => setShowProfileMenu(false)}
        >
          <Card>
            <NavLink
              exact
              to={routes.profile()}
              className={`border-b ${SUBLINK_CNAME}`}
              activeClassName={ACTIVE_LINK_CNAME}
            >
              {t('common:settings')}
            </NavLink>

            <a href={routes.sellerStorefront()} className={`border-b ${SUBLINK_CNAME}`}>
              {t('common:sellerStorefront')}
            </a>

            {isInsightsProUser && (
              <>
                <NavLink
                  exact
                  to={routes.company()}
                  className={`border-b ${SUBLINK_CNAME}`}
                  activeClassName={ACTIVE_LINK_CNAME}
                >
                  {t('inviteUsers')}
                </NavLink>

                <NavLink
                  exact
                  to={routes.skuMapping()}
                  className={`border-b ${SUBLINK_CNAME}`}
                  activeClassName={ACTIVE_LINK_CNAME}
                >
                  {t('mapCommodities')}
                </NavLink>
              </>
            )}

            {isAdmin && (
              <>
                <NavLink exact className={`border-b ${SUBLINK_CNAME}`} to={routes.impersonate()}>
                  {t('impersonate')}
                </NavLink>
                {trueUser && currentUser.id !== trueUser.id ? (
                  <Button
                    variant="text"
                    label={t('stopImpersonating', { email: currentUser.email })}
                    className={`${SUBLINK_CNAME} font-bold`}
                    onClick={() => stopImpersonating()}
                  />
                ) : null}
              </>
            )}

            <Link to="/logout" data-testid="logout-button" className={SUBLINK_CNAME}>
              {t('common:logOut')}
            </Link>
          </Card>
        </div>
      )}
    </div>
  );
};

const AuthenticatedUserNavBar = ({
  showProfileMenu,
  setShowProfileMenu,
  user = {},
  isAdmin,
  trueUser,
  currentUser,
  stopImpersonating,
  showLinks,
}) => {
  const { unreadMessages } = useChatState();
  const { t } = useTranslation('common');

  const shouldShowMissedMessageCount = unreadMessages !== 0;

  return (
    <>
      {showLinks && (
        <>
          <a href={routes.marketplace()} className={LINK_CNAME} activeClassName={ACTIVE_LINK_CNAME}>
            {t('navBar:home')}
          </a>

          <a href={routes.financing()} className={LINK_CNAME} activeClassName={ACTIVE_LINK_CNAME}>
            {t('navBar:financing')}
          </a>

          <MarketplaceNavLink />

          <InsightsNavLink
            shouldDisplayProfileMenu={showProfileMenu}
            setShouldDisplayProfileMenu={setShowProfileMenu}
            user={user}
          />

          {process.env.REACT_APP_IS_ORDER_MANAGEMENT_ENABLED === 'true' && (
            <NavLink className={LINK_CNAME} exact to={routes.ordersList()}>
              {t('orders')}
            </NavLink>
          )}
        </>
      )}

      <a href={routes.conversations()} className={`relative ${LINK_CNAME}`} activeClassName={ACTIVE_LINK_CNAME}>
        {shouldShowMissedMessageCount && (
          <div className={CONVERSATION_OVERLAY_CNAME}>
            <span className="text-xs font-bold">
              {unreadMessages > MAX_UNREAD_COUNT_DISPLAY ? `${MAX_UNREAD_COUNT_DISPLAY}+` : unreadMessages}
            </span>
          </div>
        )}
        <ConversationsIcons className="h-6 w-6" />
      </a>

      <ProfileNavLink
        showProfileMenu={showProfileMenu}
        setShowProfileMenu={setShowProfileMenu}
        isInsightsUser={user?.isInsightsProUser}
        isAdmin={isAdmin}
        trueUser={trueUser}
        currentUser={currentUser}
        stopImpersonating={stopImpersonating}
      />
    </>
  );
};

function NavBar({ isSticky, showSearch, showLinks }) {
  const { user, isAuthenticated } = useAuth();
  const { trueUser, currentUser, isAdmin, stopImpersonating } = useAdmin();
  const { isLowerRes } = useResponsiveLayout();
  const { t } = useTranslation(['navBar', 'common']);

  const { shouldDisplayMenu: showProfileMenu, setShouldDisplayMenu: setShowProfileMenu } = useMenu();
  const [highlightSearch, setHighlightSearch] = useState(false);

  const { data } = useQuery(FETCH_LAST_UPDATED_AT, { skip: !isAuthenticated || !user?.isInsightsProUser });
  const lastUpdatedAt = get(data, 'rawErpRecords[0].createdAt');

  let navBarPosition = isSticky ? 'sticky' : 'relative';
  if (highlightSearch) navBarPosition = 'fixed';

  const showUnauthenticatedLinks = !isAuthenticated && showLinks;

  return (
    <>
      {isLowerRes ? (
        <LowResolutionNavBar
          showSearch={showSearch}
          navBarPosition={navBarPosition}
          highlightSearch={highlightSearch}
          setHighlightSearch={setHighlightSearch}
        />
      ) : (
        <div className={cx('flex h-16 border-b bg-white', COMMON_HEADER_CNAME)} style={{ position: navBarPosition }}>
          <div className="flex flex-shrink-0 items-center" style={{ width: SIDEBAR_WIDTH }}>
            <div className="flex flex-col ml-6">
              <div>
                <Link to={routes.home()}>
                  <img className="h-8 w-8 md:h-10 md:w-10" src={logo} alt="ProducePay" />
                </Link>
              </div>
              <LastUpdatedAt lastUpdatedAt={lastUpdatedAt} />
            </div>
          </div>

          {showSearch ? <Search className="h-full w-full" /> : <span className="h-full w-full" />}

          <div className="flex whitespace-no-wrap font-medium">
            {isAuthenticated && (
              <AuthenticatedUserNavBar
                showProfileMenu={showProfileMenu}
                setShowProfileMenu={setShowProfileMenu}
                user={user}
                isAdmin={isAdmin}
                trueUser={trueUser}
                currentUser={currentUser}
                stopImpersonating={stopImpersonating}
                showLinks={showLinks}
              />
            )}
            {showUnauthenticatedLinks && (
              <>
                <MarketplaceNavLink />
                <NavLink
                  exact
                  to={routes.home()}
                  className={LINK_CNAME}
                  activeClassName={ACTIVE_LINK_CNAME}
                  isActive={(match, location) => {
                    const { pathname } = location;
                    return pathname?.includes(routes.marketInsights());
                  }}
                >
                  {t('common:marketInsights')}
                </NavLink>
              </>
            )}

            {/* Show the login button if the user is logged out */}
            {!isAuthenticated && (
              <NavLink exact to={routes.authSignIn()} className={LINK_CNAME} activeClassName={ACTIVE_LINK_CNAME}>
                <Button className="min-w-max" variant="outlined">
                  {t('login')}
                </Button>
              </NavLink>
            )}
          </div>
        </div>
      )}
    </>
  );
}

NavBar.propTypes = {
  isSticky: PropTypes.bool,
  showSearch: PropTypes.bool,
  showLinks: PropTypes.bool,
};

NavBar.defaultProps = {
  isSticky: true,
  showSearch: true,
  showLinks: true,
};

export default React.memo(NavBar);
