import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { FieldArray, Form, Formik } from 'formik';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import * as yup from 'yup';
import { Modal, Button } from '@producepay/pp-ui';
import shortid from 'shortid';

import RequestCard from './RequestCard';
import ProgressBar from '../ProgressBar';
import { CREATE_REQUESTS_STEP, TOTAL_STEPS } from '../helpers';
import { getCommodityName } from 'helpers/format';

import './create-request-step.css';

function CreateRequestsStep({ onSubmit, onPrev, setLoad, setSizes, load, sizes = [], modalProps, isMobile }) {
  const { t } = useTranslation('marketplaceListSupplyDetails', 'marketplaceListSupply', 'formErrors', 'common');
  const progressPercent = (CREATE_REQUESTS_STEP / TOTAL_STEPS) * 100;
  const commodityName = getCommodityName(load);

  const initialValues = () => {
    // If we use the prev/continue button, requests may already have been created
    const marketRequests = load.marketRequests || [];

    // Verify that each size has at least one request already present
    // or create a new request
    sizes.forEach((size) => {
      const sizeFound = marketRequests.find((r) => r.size === size);
      if (!sizeFound) {
        marketRequests.push(createNewRequest(size));
      }
    });

    // Remove any request that is no longer part of the sizes
    const validRequests = marketRequests.filter((request) => sizes.includes(request.size));

    return { marketRequests: validRequests };
  };

  const createNewRequest = (size) => {
    return {
      id: `key-${size}-${shortid.generate()}`,
      isOrganic: 'true',
      hasGrowerLabel: 'true',
      size,
      quantity: null,
      price: null,
    };
  };

  const validationSchema = yup.object().shape({
    marketRequests: yup.array().of(
      yup.object().shape({
        quantity: yup
          .number()
          .integer()
          .typeError(t('formErrors:is required'))
          .required(t('formErrors:is required'))
          .min(1, t('marketplaceListSupply:quantityInvalid')),
        price: yup
          .number()
          .typeError(t('formErrors:is required'))
          .required(t('formErrors:is required'))
          .min(1, t('formErrors:is invalid'))
          .test('maxDigitsAfterDecimal', t('marketplaceListSupply:priceDecimalInvalid'), (number) =>
            /^\d+(\.\d{1,2})?$/.test(number),
          ),
      }),
    ),
  });

  const updateSizes = (values) => {
    const currentSizes = new Set();
    values.marketRequests?.forEach((r) => currentSizes.add(r.size));
    setSizes([...currentSizes]);
  };

  const preprocessSubmit = async (values) => {
    updateSizes(values);
    setLoad({ ...load, marketRequests: values.marketRequests });
    onSubmit();
  };

  const handlePrev = (values) => {
    setLoad({ ...load, marketRequests: values.marketRequests });
    updateSizes(values);
    onPrev();
  };

  return (
    <Formik initialValues={initialValues()} validationSchema={validationSchema} onSubmit={preprocessSubmit}>
      {({ handleSubmit, isSubmitting, values }) => (
        <Modal
          className="bg-gray-light mt-20"
          title={t('marketplaceListSupplyDetails:title')}
          footerContent={
            <>
              <ProgressBar progressPercent={progressPercent} />
              <div className="flex w-full p-4 justify-between border-t">
                <Button
                  label={t('common:previous')}
                  variant="outlined"
                  onClick={() => handlePrev(values)}
                  className={cx('mr-2', { 'w-full': isMobile })}
                  data-testid="btn-prev"
                />
                <Button
                  label={t('common:continue')}
                  type="submit"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  className={isMobile ? 'w-full' : ''}
                  data-testid="btn-submit"
                />
              </div>
            </>
          }
          {...modalProps}
          onClose={null}
        >
          <div>
            <Form>
              <FieldArray name="marketRequests">
                {(arrayHelpers) => (
                  <TransitionGroup className="requests-cards">
                    {values.marketRequests.map((request, index) => {
                      return (
                        <CSSTransition classNames="request-card" key={request.id} timeout={500}>
                          <RequestCard
                            commodityName={commodityName}
                            request={request}
                            index={index}
                            onDelete={() => arrayHelpers.remove(index)}
                            onDuplicate={() => arrayHelpers.insert(index + 1, createNewRequest(request.size))}
                            disableDelete={values.marketRequests.length <= 1}
                          />
                        </CSSTransition>
                      );
                    })}
                  </TransitionGroup>
                )}
              </FieldArray>
            </Form>
          </div>
        </Modal>
      )}
    </Formik>
  );
}

export default CreateRequestsStep;
