import { Link } from 'react-router-dom';
import React from 'react';
import { shape, string } from 'prop-types';

import page from 'assets/images/page.svg';

const InChatNotification = ({ link, title }) => {
  return (
    <div className="flex flex-col my-4 w-9/12 xl:w-1/2 border rounded-md p-2 xl:p-4">
      <div className="flex content-center">
        <img alt="page" className="mr-2" src={page} />
        <h3 className="text-sm xl:text-base font-semibold">{title}</h3>
      </div>
      {link && (
        <div className="mt-2 xl:mt-3">
          <Link
            className="text-sm xl:text-base font-semibold underline cursor-pointer"
            to={link.to}
            style={{ color: '#1B7DCE' }}
          >
            {link.message}
          </Link>
        </div>
      )}
    </div>
  );
};

InChatNotification.propTypes = {
  link: shape({
    message: string.isRequired,
    to: string.isRequired,
  }),
  title: string.isRequired,
};

export default InChatNotification;
