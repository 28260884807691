import { gql } from '@apollo/client';

import {
  MARKET_REQUEST_FRAGMENT,
  MARKET_REQUEST_WITH_USER_BIDS_FRAGMENT,
  MARKET_REQUEST_LOADS_FRAGMENT,
} from '../fragments/market-requests';
import { MARKET_REQUEST_BID_FRAGMENT } from '../fragments/market-request-bids';

export const FETCH_MARKET_REQUESTS_WITH_IMAGES = gql`
  ${MARKET_REQUEST_FRAGMENT}

  query FetchMarketRequests($limit: Int, $cvInfoIds: [String!], $requestType: String!) {
    marketRequests(limit: $limit, commodityVarietyInfoId: $cvInfoIds, requestType: $requestType) {
      ...marketRequestsWithImagesFragment
    }
  }
`;

export const FETCH_MARKET_REQUESTS_WITH_IMAGES_AND_BIDS = gql`
  ${MARKET_REQUEST_WITH_USER_BIDS_FRAGMENT}

  query FetchMarketRequests($limit: Int, $cvInfoIds: [String!], $requestType: String!) {
    marketRequests(limit: $limit, commodityVarietyInfoId: $cvInfoIds, requestType: $requestType) {
      ...marketRequestsWithImagesAndUserBidsFragment
    }
  }
`;

export const FETCH_MARKET_REQUEST_LOADS = gql`
  ${MARKET_REQUEST_LOADS_FRAGMENT}

  query FetchMarketRequestLoads($limit: Int, $cvInfoIds: [String!], $requestType: String!) {
    marketRequestLoads(limit: $limit, commodityVarietyInfoId: $cvInfoIds, requestType: $requestType) {
      ...marketRequestLoadsFragment
    }
  }
`;

export const FETCH_MARKET_REQUEST_LOAD = gql`
  ${MARKET_REQUEST_BID_FRAGMENT}
  query FetchMarketRequestLoad($id: String!) {
    marketRequestLoad(id: $id) {
      id
      accountServiceCompanyId
      createdAt
      updatedAt
      grownLocation
      shippingPoint
      packaging
      requestType
      isAtShippingPoint
      estimatedPackDate
      arrivalDate
      userId
      customCommodityVarietyName
      marketRequests {
        id
        userId
        commodityVarietyInfo {
          name
          commodityName
          varietyName
          commodity {
            imageUrls {
              small
              original
            }
          }
        }
        customCommodityVarietyName
        quantity
        grade
        size
        packaging
        terms
        price
        requestType
        approvedAt
        grownLocation
        shippingPoint
        createdAt
        archiveDate
        hasGrowerLabel
        isOrganic
        marketRequestBids {
          ...marketRequestBidFragment
        }
        certifications {
          title
        }
        tierRating
      }
      companyProfile {
        id
        companyName
        location
        yearEstablished
        commoditiesSold
        description
        certificates
        lastSiteVisitDate
        siteVisitPhotoUrls {
          id
          original
          small
        }
        sellerScore {
          sellerName
          tradingPerformance
          casesToSpec
          percentOnTime
          totalEntries
        }
      }
      commodityVarietyInfo {
        id
        name
        commodityName
        varietyName
        commodity {
          imageUrls {
            small
            original
          }
        }
      }
      imageUrls {
        id
        small
        original
      }
    }
  }
`;
