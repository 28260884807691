/* eslint-disable camelcase */
import React, { useRef } from 'react';
import {
  MessageText,
  MessageInput,
  useMessageContext,
  messageHasAttachments,
  messageHasReactions,
  MML,
  Modal,
  Attachment,
  Avatar,
  EditMessageForm,
  MessageDeleted,
  MessageOptions,
  MessageRepliesCountButton,
  MessageStatus,
  MessageTimestamp,
  ReactionSelector,
  ReactionsList,
} from 'stream-chat-react';
import PurchaseOrderNotification from './PurchaseOrderNotification';
import { PO_ACCEPTED, PO_UPDATED, PO_CREATED } from './constants';

const IN_CHAT_NOTIFICATION_TYPES = [PO_ACCEPTED, PO_UPDATED, PO_CREATED];

const CustomMessage = () => {
  const {
    additionalMessageInputProps,
    clearEditingState,
    editing,
    handleAction,
    handleOpenThread,
    handleRetry,
    isMyMessage,
    isReactionEnabled,
    message,
    onUserClick,
    onUserHover,
    reactionSelectorRef,
    showDetailedReactions,
    threadList,
  } = useMessageContext();

  const messageWrapperRef = useRef(null);

  const hasAttachment = messageHasAttachments(message);
  const hasReactions = messageHasReactions(message);

  const messageClasses = isMyMessage()
    ? 'str-chat__message str-chat__message--me str-chat__message-simple str-chat__message-simple--me'
    : 'str-chat__message str-chat__message-simple';

  if (message.customType === 'message.date') {
    return null;
  }

  if (message.deleted_at || message.type === 'deleted') {
    return <MessageDeleted message={message} />;
  }

  if (message?.message_custom_data?.type === 'intro') {
    // TODO: Update here with custom component for ratings / market request info
  }

  const customMessageType = message?.message_custom_data?.type;
  if (customMessageType && IN_CHAT_NOTIFICATION_TYPES.includes(customMessageType)) {
    const supplierCompanyName = message?.message_custom_data?.supplierCompanyName;
    const orderId = message?.message_custom_data?.orderId;
    if (orderId && supplierCompanyName) {
      return (
        <PurchaseOrderNotification
          orderId={orderId}
          supplierCompanyName={supplierCompanyName}
          type={customMessageType}
        />
      );
    }
  }

  return (
    <>
      {editing && (
        <Modal onClose={clearEditingState} open={editing}>
          <MessageInput
            clearEditingState={clearEditingState}
            Input={EditMessageForm}
            message={message}
            {...additionalMessageInputProps}
          />
        </Modal>
      )}
      <div
        className={`
            ${messageClasses}
            str-chat__message--${message.type}
            str-chat__message--${message.status}
            ${message.text ? 'str-chat__message--has-text' : 'has-no-text'}
            ${hasAttachment ? 'str-chat__message--has-attachment' : ''}
            ${hasReactions && isReactionEnabled ? 'str-chat__message--with-reactions' : ''}
            ${message.pinned ? 'pinned-message' : ''}
          `.trim()}
        key={message.id}
        ref={messageWrapperRef}
      >
        <MessageStatus />
        {message.user && (
          <Avatar
            image={message.user.image}
            name={message.user.name || message.user.id}
            onClick={onUserClick}
            onMouseOver={onUserHover}
            onFocus={onUserHover}
            user={message.user}
          />
        )}
        <div
          className="str-chat__message-inner"
          data-testid="message-inner"
          onClick={
            message.status === 'failed' && message.errorStatusCode !== 403 ? () => handleRetry(message) : undefined
          }
        >
          <>
            <MessageOptions messageWrapperRef={messageWrapperRef} />
            {hasReactions && !showDetailedReactions && isReactionEnabled && <ReactionsList reverse />}
            {showDetailedReactions && isReactionEnabled && <ReactionSelector ref={reactionSelectorRef} />}
          </>
          {message.attachments?.length && !message.quoted_message ? (
            <Attachment actionHandler={handleAction} attachments={message.attachments} />
          ) : null}
          <MessageText message={message} />
          {message.mml && (
            <MML actionHandler={handleAction} align={isMyMessage() ? 'right' : 'left'} source={message.mml} />
          )}
          {!threadList && !!message.reply_count && (
            <div className="str-chat__message-simple-reply-button">
              <MessageRepliesCountButton onClick={handleOpenThread} reply_count={message.reply_count} />
            </div>
          )}
          <div className="str-chat__message-data str-chat__message-simple-data">
            {!isMyMessage() && message.user ? (
              <span className="str-chat__message-simple-name">{message.user.name || message.user.id}</span>
            ) : null}
            <MessageTimestamp calendar customClass="str-chat__message-simple-timestamp" />
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomMessage;
