import React from 'react';

import { useAuth } from '../../contexts/auth';
import routes from '../../routes';

function SetPasswordRoute() {
  const { logout } = useAuth();

  logout();

  window.location.replace(routes.marketplace());

  return null;
}

export default React.memo(SetPasswordRoute);
