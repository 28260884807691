import React from 'react';
import { Button } from '@producepay/pp-ui';
import { PlusCircle } from 'react-feather';

const CreateOrderButton = ({ label, onClick, className }) => {
  return (
    <Button
      className={`font-medium mr-4 flex items-center ${className}`}
      borderRadius=""
      onClick={onClick}
      color="primary"
    >
      <PlusCircle className="mr-2" />
      <span className="font-medium">{label}</span>
    </Button>
  );
};

export default CreateOrderButton;
