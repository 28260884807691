import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Formik, Form } from 'formik';
import { Button, useToast } from '@producepay/pp-ui';

import { trackEvent, KEYS } from '../../../../helpers/tracking';
import { COUNTRY_AND_CODES_MAP } from './helpers';
import FormikGeneralErrors from '../../../../components/formik/FormikGeneralErrors';
import FormikTextField from '../../../../components/formik/FormikTextField';
import FormikPhoneInput from '../../../../components/formik/FormikPhoneInput';
import FollowupButtons from './FollowupButtons';
import OnboardingTitle from './OnboardingTitle';

function PhoneNotificationsQuestion({
  onboardingInformation,
  triggerPhoneNumberVerification,
  onConfirm,
  previousQuestion,
  skipQuestion,
}) {
  const { t } = useTranslation(['onboarding', 'common', 'formErrors']);
  const { addToastToQueue } = useToast();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [isPhoneInputStep, setIsPhoneInputStep] = useState(true);

  return isPhoneInputStep ? (
    <Formik
      initialValues={{ phoneNumber: onboardingInformation?.phoneNumber }}
      validationSchema={yup.object().shape({
        phoneNumber: yup.string().nullable().required('This field is required'),
      })}
      onSubmit={(values, { setSubmitting, setFieldError, setStatus }) => {
        triggerPhoneNumberVerification({ phoneNumber: values.phoneNumber })
          .then((res) => {
            setSubmitting(false);

            if (res?.data?.triggerPhoneNumberVerification?.isSuccessful) {
              trackEvent(KEYS.PHONE_CONFIRMATION_TRIGGERED, { phoneNumber: values.phoneNumber });
              setPhoneNumber(values.phoneNumber);
              setIsPhoneInputStep(false);
            } else {
              setFieldError('phoneNumber', t('smsVerificationError'));
            }
          })
          .catch(() => {
            setSubmitting(false);
            setStatus({ errors: [t('formErrors:generalNetworkError')] });
          });
      }}
    >
      {() => (
        <Form>
          <OnboardingTitle title={t('smsAlertsTitle')} />

          <FormikGeneralErrors />

          <FormikPhoneInput
            name="phoneNumber"
            helperText={t('smsAlertsHelperText')}
            defaultCountry={COUNTRY_AND_CODES_MAP[onboardingInformation?.country] || 'US'}
            shouldPrependErrors={false}
          />

          <FollowupButtons previousQuestion={previousQuestion} skipQuestion={skipQuestion} />
        </Form>
      )}
    </Formik>
  ) : (
    <Formik
      initialValues={{ phoneConfirmationToken: '' }}
      validationSchema={yup.object().shape({
        phoneConfirmationToken: yup.string().nullable().required('This field is required'),
      })}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        onConfirm({ phoneNumber, phoneConfirmationToken: values.phoneConfirmationToken })
          .then(() => {
            trackEvent(KEYS.PHONE_NUMBER_CONFIRMED, { phoneNumber });
          })
          .catch(() => {
            setSubmitting(false);
            setFieldError('phoneConfirmationToken', t('smsConfirmationError'));
          });
      }}
    >
      {() => (
        <Form>
          <OnboardingTitle title={t('smsVerificationTitle')} />

          <FormikTextField
            name="phoneConfirmationToken"
            helperText={t('smsConfirmationHelperText', { phoneNumber })}
            placeholder="123456"
            shouldPrependErrors={false}
          />

          <div className="text-center">
            <Button
              className="mt-6 mb-2 text-xs"
              type="button"
              onClick={() => {
                triggerPhoneNumberVerification({ phoneNumber }).then(() => {
                  addToastToQueue({
                    type: 'success',
                    header: t('smsResentHeader'),
                    body: t('smsResentConfirmation', { phoneNumber }),
                  });
                });
              }}
              variant="text"
            >
              {t('smsResend')}
            </Button>
          </div>

          <FollowupButtons previousQuestion={previousQuestion} skipQuestion={skipQuestion} />
        </Form>
      )}
    </Formik>
  );
}

export default React.memo(PhoneNotificationsQuestion);
