import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useChatContext } from 'stream-chat-react';
import qs from 'qs';

const CustomList = (props) => {
  const { children, loading } = props;
  const location = useLocation();
  const { openMobileNav } = useChatContext();
  const fromNav = useRef(qs.parse(location.search, { ignoreQueryPrefix: true }));

  useEffect(() => {
    if (!loading && fromNav?.current?.fromNav === 'true') {
      openMobileNav();
      fromNav.current = false;
    }
  });

  return (
    <div className="str-chat__channel-list-messenger">
      <div className="str-chat__channel-list-messenger__main">{children}</div>
    </div>
  );
};

export default CustomList;
