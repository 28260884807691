import { useAuth } from '../../contexts/auth';

function Logout() {
  const { logout } = useAuth();

  logout(true);

  return null;
}

export default Logout;
