import { MARKET_REQUEST_BID_FRAGMENT } from './market-request-bids';

export const MARKET_REQUEST_FRAGMENT = `
fragment marketRequestsWithImagesFragment on MarketRequest {
  id
  userId
  commodityVarietyInfo {
    name
    commodityName
    varietyName
    commodity{
      imageUrls {
        small
      }
    }
  }
  customCommodityVarietyName
  quantity
  grade
  terms
  price
  requestType
}
`;

export const MARKET_REQUEST_WITH_USER_BIDS_FRAGMENT = `
${MARKET_REQUEST_BID_FRAGMENT}
fragment marketRequestsWithImagesAndUserBidsFragment on MarketRequest {
  id
  userId
  commodityVarietyInfo {
    name
    commodityName
    varietyName
    commodity{
      imageUrls {
        small
        original
      }
    }
  }
  customCommodityVarietyName
  quantity
  grade
  size
  packaging
  terms
  price
  requestType
  approvedAt
  grownLocation
  shippingPoint
  createdAt
  archiveDate
  hasGrowerLabel
  isOrganic
  marketRequestBids {
    ...marketRequestBidFragment
  }
  certifications {
    title
  }
  tierRating
}
`;

export const MARKET_REQUEST_LOADS_FRAGMENT = `
${MARKET_REQUEST_WITH_USER_BIDS_FRAGMENT}
fragment marketRequestLoadsFragment on Load {
  id
  createdAt
  updatedAt
  grownLocation
  shippingPoint
  packaging
  requestType
  isAtShippingPoint
  estimatedPackDate
  arrivalDate
  userId
  marketRequests {
    ...marketRequestsWithImagesAndUserBidsFragment
  }
  imageUrls {
    id
    small
    original
  }
  commodityVarietyInfo {
    id
    name
    commodityName
    varietyName
    commodity{
      imageUrls {
        small
        original
      }
    }
  }
  customCommodityVarietyName
  companyProfile {
    id
    companyName
    location
    yearEstablished
    commoditiesSold
    description
    certificates
    lastSiteVisitDate
    siteVisitPhotoUrls {
      id
      original
      small
    }
    sellerScore {
      sellerName
      tradingPerformance
      casesToSpec
      percentOnTime
      totalEntries
    }
  }
}
`;
