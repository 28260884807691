import React, { useContext, useCallback } from 'react';
import _ from 'lodash';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import useCachedGraphqlQuery from '../../hooks/useCachedGraphqlQuery';
import { textSearchCompareByWord } from '../../helpers/common';

const EXPECTED_NUM_CVIS = 307;

const FETCH_DMR_CV_INFOS = gql`
  query FetchDmrCommodityVarietyInfos {
    commodityVarietyInfos(dmrOnly: true) {
      id
      name
      englishName
      commodityName
      varietyName
      isTopCommodity
      hasShippingPointData
      hasTerminalMarketData
      commodity {
        id
        uuid
        imageUrls {
          original
        }
      }
      variety {
        id
        uuid
      }
    }
  }
`;

const DmrCommodityVarietyContext = React.createContext();

function createUuidKey(commodityUuid, varietyUuid) {
  return _.compact([commodityUuid, varietyUuid]).join(':');
}

function DmrCommodityVarietyProvider(props) {
  const { i18n } = useTranslation();
  const { children } = props;

  const { data: commodityVarietyInfos, isLoading } = useCachedGraphqlQuery(
    FETCH_DMR_CV_INFOS,
    'commodityVarietyInfos',
    { language: i18n.language },
    {},
    { expectedNumberOfRecords: EXPECTED_NUM_CVIS },
  );

  const mappedCommodityVarietyInfos = _.map(commodityVarietyInfos, (cvInfo) => ({
    id: Number(cvInfo.id),
    name: cvInfo.name,
    englishName: cvInfo.englishName,
    commodityName: cvInfo.commodityName,
    varietyName: cvInfo.varietyName,
    isTopCommodity: cvInfo.isTopCommodity,
    hasShippingPointData: cvInfo.hasShippingPointData,
    hasTerminalMarketData: cvInfo.hasTerminalMarketData,
    commodityId: cvInfo.commodity.id,
    commodityUuid: cvInfo.commodity.uuid,
    commodityImage: cvInfo.commodity.imageUrls.original,
    varietyId: cvInfo.variety?.id,
    varietyUuid: cvInfo.variety?.uuid,
    uuidKey: createUuidKey(cvInfo.commodity.uuid, cvInfo.variety?.uuid),
  }));

  const itemFromUuids = useCallback(
    (commodityUuid, varietyUuid) =>
      _.find(mappedCommodityVarietyInfos, {
        uuidKey: createUuidKey(commodityUuid, varietyUuid),
      }) || {},
    [mappedCommodityVarietyInfos],
  );

  const nameFromUuids = useCallback(
    (commodityUuid, varietyUuid) => _.get(itemFromUuids(commodityUuid, varietyUuid), 'name'),
    [itemFromUuids],
  );

  const commodityNameFromUuid = useCallback(
    (commodityUuid) => {
      const cvInfo = _.find(mappedCommodityVarietyInfos, { commodityUuid });
      return _.get(cvInfo, 'commodityName', '');
    },
    [mappedCommodityVarietyInfos],
  );

  const searchUsdaCommodities = useCallback(
    (input) => {
      return mappedCommodityVarietyInfos.filter((opt) => {
        return textSearchCompareByWord(input, opt.englishName) || textSearchCompareByWord(input, opt.name);
      });
    },
    [mappedCommodityVarietyInfos],
  );

  const itemFromId = useCallback(
    (cvInfoId) =>
      _.find(mappedCommodityVarietyInfos, {
        id: Number(cvInfoId),
      }) || {},
    [mappedCommodityVarietyInfos],
  );

  const nameFromId = useCallback((cvInfoId) => _.get(itemFromId(cvInfoId), 'name'), [itemFromId]);

  const commodityNameFromCvInfoId = useCallback(
    (cvInfoId) => {
      return _.get(itemFromId(cvInfoId), 'commodityName', '');
    },
    [itemFromId],
  );

  return (
    <DmrCommodityVarietyContext.Provider
      value={{
        createUuidKey,
        loading: isLoading,
        commodityVarietyInfos: mappedCommodityVarietyInfos,
        topCommodityVarietyInfos: _.filter(mappedCommodityVarietyInfos, { isTopCommodity: true }),
        itemFromUuids, // TODO: Can get rid after we stop supporting cv uuid routes
        nameFromUuids, // TODO: Can get rid after we stop supporting cv uuid routes
        commodityNameFromUuid, // TODO: Can get rid after we stop supporting cv uuid routes
        searchUsdaCommodities,
        itemFromId,
        nameFromId,
        commodityNameFromCvInfoId,
      }}
    >
      {children}
    </DmrCommodityVarietyContext.Provider>
  );
}

function useDmrCommodityVarieties() {
  return useContext(DmrCommodityVarietyContext);
}

const MockDmrCommodityVarietyProvider = ({ value, children }) => {
  const context = value || { data: { commodityVarietyInfos: [] }, searchUsdaCommodities: () => [] };
  return <DmrCommodityVarietyContext.Provider value={context}>{children}</DmrCommodityVarietyContext.Provider>;
};

export { DmrCommodityVarietyProvider, MockDmrCommodityVarietyProvider, useDmrCommodityVarieties };
