import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Tooltip as TippyTooltip } from 'react-tippy';

import 'react-tippy/dist/tippy.css';
import './tooltip.css';

function Tooltip({ className, displayStyle, ...rest }) {
  return <TippyTooltip className={cx(className)} style={{ display: displayStyle }} arrow {...rest} />;
}

Tooltip.propTypes = {
  className: PropTypes.string,
  displayStyle: PropTypes.oneOf(['block', 'inline', 'inline-block', 'flex']),
};

Tooltip.defaultProps = {
  displayStyle: 'inline-block',
};

export default Tooltip;
