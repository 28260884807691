import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';

import { Link } from 'react-router-dom';
import routes from '../../../routes';

import Person from '../../icons/Person';
import Product from '../../icons/Product';

const ROUTE_FUNC_MAP = {
  customer: routes.showCustomer,
  product: routes.showCommodity,
  insights: routes.cvShow,
};
const ICON_COMPONENT_MAP = {
  customer: <Person style={{ marginRight: 10, marginLeft: 2 }} size={12} />,
  product: <Product color="#33CE9B" className="mr-2" size={16} />,
  insights: <Product color="#33CE9B" className="mr-2" size={16} />,
};
const HELPER_TEXT_MAP = {
  customer: 'Customer',
  product: 'Your product',
  insights: 'Market insights',
};

function SearchSection({ className, title, items, onRouteClick, shouldShowHelperText }) {
  if (_.isEmpty(items)) return null;

  return (
    <div className={cx(className, 'py-4')}>
      <div className="px-8 mb-2 text-base lg:text-lg font-semibold">{title}</div>

      {items.map((item) => {
        const routeFunc = ROUTE_FUNC_MAP[item.objectType];
        const iconComponent = ICON_COMPONENT_MAP[item.objectType];
        const helperText = HELPER_TEXT_MAP[item.objectType];

        return (
          <Link
            key={`${item.identifier} ${item.subIdentifier}`}
            className="py-1 px-8 flex items-center hover:bg-gray-200 text-lg lg:text-base"
            to={routeFunc ? routeFunc(item.identifier, item.subIdentifier ? item.subIdentifier : '') : null}
            onClick={() => onRouteClick(item)}
          >
            {iconComponent}

            {item.value}

            {shouldShowHelperText ? (
              <span className="text-gray-500 text-sm">
                &nbsp;
                {helperText}
              </span>
            ) : null}
          </Link>
        );
      })}
    </div>
  );
}

SearchSection.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      objectType: PropTypes.oneOf(['customer', 'product', 'insights']).isRequired,
      identifier: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  shouldShowHelperText: PropTypes.bool,
};

SearchSection.defaultProps = {
  shouldShowHelperText: false,
};

export default React.memo(SearchSection);
