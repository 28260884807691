import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export default function useScrollReset() {
  const params = useParams();
  const paramsKey = JSON.stringify(params || {});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [paramsKey]);
}
