import React, { useEffect, useRef } from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import { FormLabel } from '@producepay/pp-ui';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import shortid from 'shortid';

import { LOCALE_KEY } from 'i18n';

import 'react-datepicker/dist/react-datepicker.css';

registerLocale('es', es);

const DatePickerField = ({ className, name, label, handleBlur, placeholder, ...props }) => {
  const { values, setFieldValue, touched, errors } = useFormikContext();
  const { current: fieldId } = useRef(`${name}-${shortid.generate()}`);
  const locale = window.localStorage.getItem(LOCALE_KEY) === 'en-US' ? 'en' : 'es';
  const { t } = useTranslation(['formFields', 'formErrors']);

  useEffect(() => {
    setDefaultLocale(locale);
  }, [locale]);

  return (
    <FormLabel
      className={className}
      label={label}
      error={touched[name] ? t([`formErrors:${errors[name]}`, errors[name] || '']) : null}
      htmlFor={fieldId}
    >
      <DatePicker
        {...props}
        id={fieldId}
        selected={values[name]}
        className="mt-2 p-2 textfield w-full border outline-none appearance-none text-base textfield-base rounded focus:border-blue-400"
        name={name}
        placeholderText={placeholder}
        onBlur={handleBlur}
        popperClassName="z-30"
        onChange={(val) => {
          setFieldValue(name, val);
        }}
      />
    </FormLabel>
  );
};

export default DatePickerField;

DatePickerField.defaultProps = {
  className: 'w-full',
};
