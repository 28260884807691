import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import routes from '../../routes';
import { useAuth } from '../../contexts/auth';
import { SHIPPING_POINT_SLUG } from '../../helpers/reportTypes';

function PriceReports() {
  const { logout } = useAuth();

  logout();

  window.location.replace(routes.marketplaceInsightsPriceReport());

  return null;
}

function FreightReports() {
  const { logout } = useAuth();

  logout();

  window.location.replace(routes.marketplaceInsightsFreightReport());

  return null;
}

function MarketWatchlist() {
  const { logout } = useAuth();

  logout();

  window.location.replace(routes.marketplaceInsightsWatchlist());

  return null;
}

function MarketInsights() {
  const { isAuthenticated } = useAuth();

  return (
    <Switch>
      {isAuthenticated && <Route path={routes.marketInsightsPriceReport()} component={PriceReports} />}

      <Route path={routes.marketInsightsFreightReport()} component={FreightReports} />

      <Route path={routes.marketInsightsWatchlist()} component={MarketWatchlist} />

      <Redirect to={routes.marketInsightsPriceReport(SHIPPING_POINT_SLUG)} />
    </Switch>
  );
}

export default MarketInsights;
