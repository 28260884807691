import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { LOCALE_KEY } from 'i18n';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useAuth } from 'contexts/auth';

import 'swiper/swiper-bundle.min.css';
import 'styles/swiper.css';

// install Swiper modules
SwiperCore.use([Pagination]);

function SiteVisitSection({ companyProfile, isMobile }) {
  const { user } = useAuth();
  const { t } = useTranslation(['profileModal']);
  const { lastSiteVisitDate = null, siteVisitPhotoUrls = [] } = companyProfile;

  const locale = user ? user.locale : window.localStorage.getItem(LOCALE_KEY);
  const siteImages = siteVisitPhotoUrls || [];

  const PhotoGallery = () => {
    return isMobile ? (
      <div>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{
            dynamicBullets: true,
          }}
        >
          {siteImages.map((image, index) => {
            return (
              <SwiperSlide key={image.id} className="w-full" style={{ height: '280px' }}>
                <img
                  className="w-full h-full rounded border object-cover"
                  src={image.small}
                  alt={`site visit ${index}`}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    ) : (
      <div className="flex flex-wrap w-3/5">
        {siteImages.map((image, index) => {
          return (
            <div key={image.id} className="w-1/4 mr-3 mb-5" style={{ width: '144px', height: '120px' }}>
              <a target="_blank" rel="noopener noreferrer" href={image.original}>
                <img
                  className="w-full h-full rounded border object-cover"
                  src={image.original}
                  alt={`site visit ${index}`}
                />
              </a>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      className={cx('site-visit-container', {
        'py-8': !isMobile,
        'py-4': isMobile,
      })}
    >
      <div className={cx('font-semibold text-sm', { 'pb-1': isMobile, 'pb-3': !isMobile })}>
        {t('siteVisit.siteVisit')}
      </div>
      {lastSiteVisitDate && (
        <p className={cx({ 'pb-4': isMobile, 'pb-6': !isMobile })}>
          {`${t('siteVisit.conductedOn')} ${new Date(lastSiteVisitDate).toLocaleDateString(locale)}`}
        </p>
      )}
      {siteImages.length > 0 && <PhotoGallery />}
    </div>
  );
}

SiteVisitSection.propTypes = {
  companyProfile: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
};

SiteVisitSection.defaultProps = {
  isMobile: false,
};

export default SiteVisitSection;
