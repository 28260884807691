import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import { PageSpinner } from '@producepay/pp-ui';
import routes from '../routes';
import { SHIPPING_POINT_SLUG } from '../helpers/reportTypes';
import PrivateRoute from './partials/PrivateRoute';
import AdminRoute from './partials/AdminRoute';
import UnauthenticatedRoute from './partials/UnauthenticatedRoute';
import componentLoader from '../helpers/componentLoader';

import AuthSignIn from './auth/SignIn';
import AuthRegister from './auth/Register';
import SetPassword from './auth/SetPassword';
import ForgotPassword from './auth/ForgotPassword';
import AuthVerify from './auth/Verify';
import Logout from './auth/Logout';
import Onboarding from './users/Onboarding';
import Profile from './users/Profile';
import MarketInsights from './MarketInsights';
import BuildingPriceReport from './BuildingPriceReport';
import CommodityVarietyShow from './CommodityVarietyShow';
import Marketplace from './Marketplace';
import AdminImpersonate from './admin/Impersonate';
import Conversations from './Conversations';

const Shipments = lazy(() => import('./fulfillment/Shipments'));
const AcceptShipment = lazy(() => componentLoader(() => import('./fulfillment/AcceptShipment')));
const AcceptedShipments = lazy(() => componentLoader(() => import('./fulfillment/AcceptedShipments')));
const CreateOrder = lazy(() => componentLoader(() => import('./fulfillment/Orders/CreateOrder')));
const Orders = lazy(() => componentLoader(() => import('./fulfillment/Orders')));
const CreateOrderWithMarketplaceLoad = lazy(() => componentLoader(() => import('./fulfillment/Orders/CreateOrder')));
const UpdateOrder = lazy(() => componentLoader(() => import('./fulfillment/Orders/UpdateOrder')));
const SignOrder = lazy(() => componentLoader(() => import('./fulfillment/Orders/SignOrder')));
const UploadDocuments = lazy(() => componentLoader(() => import('./fulfillment/Orders/UploadDocuments')));
const ViewOrder = lazy(() => componentLoader(() => import('./fulfillment/Orders/ViewOrder')));
const OrdersList = lazy(() => componentLoader(() => import('./fulfillment/Orders/OrdersList')));
const FinanceRequest = lazy(() => componentLoader(() => import('./fulfillment/FinancingRequest')));

function Routes() {
  const { search } = useLocation();

  const redirectPathname = routes.marketInsights();

  return (
    <Suspense fallback={<PageSpinner />}>
      <Switch>
        {/* Routes for Everyone */}
        <Route path={routes.onboarding()} component={Onboarding} />
        <Route path={routes.marketplace()} component={Marketplace} />
        <Route path={routes.logout()} component={Logout} />

        <Route path={routes.authResetPassword()} render={(props) => <SetPassword mode="reset" {...props} />} />
        {/* Unauthenticated routes */}
        <UnauthenticatedRoute path={routes.authSignIn()} component={AuthSignIn} />
        <UnauthenticatedRoute path={routes.authRegister()} component={AuthRegister} />
        <UnauthenticatedRoute path={routes.authVerify()} component={AuthVerify} />
        <UnauthenticatedRoute path={routes.authForgotPassword()} component={ForgotPassword} />
        <UnauthenticatedRoute
          path={routes.authSetPassword()}
          render={(props) => <SetPassword mode="create" {...props} />}
        />

        {/* Authenticated Routes - Basic Users */}
        {/* Allow single commodity uuid or commodity + variety without optional params */}
        <PrivateRoute path={routes.commodityVarietyShow()} component={CommodityVarietyShow} />
        <PrivateRoute path={routes.cvShow()} component={CommodityVarietyShow} />
        {/* By passing undefined, it uses the default parameter value :commodityVarietyId
            This redirect is to make it so that anyone going to the original page gets
            redirected to the page with shipping point data */}
        <Redirect from={routes.cvShow(undefined, '')} to={routes.cvShow(undefined, SHIPPING_POINT_SLUG)} />
        <PrivateRoute path={routes.marketInsights()} component={MarketInsights} />
        <PrivateRoute
          path={routes.profile()}
          component={Profile}
          requiredAttr={(user) => user?.isInsightsProUser || user?.isOnboarded}
          authorizationRedirect={routes.onboarding()}
        />
        <PrivateRoute path={routes.conversations()} component={Conversations} />
        <PrivateRoute path={routes.buildingPriceReport()} component={BuildingPriceReport} />
        <PrivateRoute path={routes.fulfillmentAcceptShipment()} component={AcceptShipment} />
        <PrivateRoute path={routes.fulfillmentAcceptedShipments()} component={AcceptedShipments} />
        <PrivateRoute path={routes.fulfillmentShipments()} component={Shipments} />

        <PrivateRoute path={routes.viewOrder()} component={ViewOrder} />
        <PrivateRoute path={routes.createOrder()} component={CreateOrder} />
        <PrivateRoute path={routes.createOrderWithMarketplaceLoad()} component={CreateOrderWithMarketplaceLoad} />
        <PrivateRoute path={routes.updateOrder()} component={UpdateOrder} />
        <PrivateRoute path={routes.signOrder()} component={SignOrder} />
        <PrivateRoute path={routes.uploadDocuments()} component={UploadDocuments} />
        <PrivateRoute path={routes.financeRequest()} component={FinanceRequest} />
        <PrivateRoute path={routes.ordersList()} component={OrdersList} />
        <PrivateRoute path={routes.orders()} component={Orders} />

        {/* Authenticated Routes - Admin Users */}
        <AdminRoute exact path={routes.impersonate()} component={AdminImpersonate} />

        <Redirect to={{ pathname: redirectPathname, search }} />
      </Switch>
    </Suspense>
  );
}

export default React.memo(Routes);
