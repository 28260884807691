import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFormikContext } from 'formik';

import { Button } from '@producepay/pp-ui';

function FormikSubmit({ label, children, ...rest }) {
  const { isSubmitting } = useFormikContext();
  const { t } = useTranslation(['common']);

  return (
    <Button type="submit" isLoading={isSubmitting} disabled={isSubmitting} {...rest}>
      {label || children || t('submit')}
    </Button>
  );
}

export default React.memo(FormikSubmit);
