import { useRef, useEffect, useCallback, useState } from 'react';

export default function useMenu() {
  const [shouldDisplayMenu, setShouldDisplayMenu] = useState(false);

  const menuWrapperRef = useRef(null);
  const onWindowClick = useCallback(
    (e) => {
      const hasClickedWithinSearch = menuWrapperRef.current && menuWrapperRef.current.contains(e.target);
      if (!hasClickedWithinSearch) setShouldDisplayMenu(false);
      setShouldDisplayMenu(hasClickedWithinSearch);
    },
    [menuWrapperRef, setShouldDisplayMenu],
  );

  useEffect(() => {
    if (shouldDisplayMenu) {
      document.addEventListener('click', onWindowClick);
      return () => document.removeEventListener('click', onWindowClick);
    }
  }, [shouldDisplayMenu, onWindowClick]);

  return { shouldDisplayMenu, setShouldDisplayMenu, menuWrapperRef };
}
