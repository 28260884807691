import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useAuth } from '../../../../contexts/auth';

import SignInView from './view';

function SignIn(props) {
  const { onLogin, ...rest } = props;

  const { login } = useAuth();
  const loginRequest = useCallback(
    (...args) => {
      return login(...args).then((user) => {
        if (onLogin) onLogin(user);
      });
    },
    [login, onLogin],
  );

  return <SignInView login={loginRequest} {...rest} />;
}

SignIn.propTypes = {
  ...SignInView.propTypes,
  onLogin: PropTypes.func,
};

export default React.memo(SignIn);
