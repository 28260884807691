import React from 'react';
import { useChannelStateContext } from 'stream-chat-react';
import { useAuth } from 'contexts/auth';

import OrdersPopOutView from './view';

import { useQuery } from '@apollo/client';
import { useFulfillmentQuery } from '../../../../apollo-client';
import { ORDERS_BY_CHAT_ID } from '../../../../graphql/queries/fulfillment/get-orders';
import { FETCH_MARKET_REQUEST_LOAD } from '../../../../graphql/queries/fetch-market-requests';
import { FETCH_STREAM_CHANNEL } from '../../../../graphql/queries/fetch-stream-channel';

const ordersEnabled = process.env.REACT_APP_IS_ORDER_MANAGEMENT_ENABLED === 'true';

const OrdersPopOut = ({ showPopout, setShowPopout }) => {
  const { channel } = useChannelStateContext();
  const { user } = useAuth();

  const { data: streamChannelData } = useQuery(FETCH_STREAM_CHANNEL, {
    skip: !channel?.id,
    variables: {
      streamioId: channel?.id,
    },
  });

  const streamChannelId = streamChannelData?.streamChannel?.id;

  const { data: ordersData } = useFulfillmentQuery(ORDERS_BY_CHAT_ID, {
    skip: !streamChannelId || !ordersEnabled,
    variables: {
      chatId: streamChannelId,
    },
  });

  const orders = ordersData?.ordersByChatId;

  /* eslint-disable-next-line camelcase */
  const marketRequestId = channel?.data?.market_request?.id;

  const { data: marketRequestData } = useQuery(FETCH_MARKET_REQUEST_LOAD, {
    skip: !marketRequestId,
    variables: {
      id: `${marketRequestId}`,
    },
  });

  const loadId = marketRequestData?.marketRequest?.load?.id;

  const shouldShowCreateOrdersButton =
    process.env.REACT_APP_IS_CREATE_ORDER_BUTTON_ENABLED === 'true' &&
    marketRequestData?.marketRequest?.load?.id &&
    marketRequestData?.marketRequest?.load?.accountServiceCompanyId &&
    user?.accountServiceCompanyId;

  return (
    <OrdersPopOutView
      showPopout={showPopout}
      setShowPopout={setShowPopout}
      orders={orders}
      channelId={streamChannelId}
      loadId={loadId}
      shouldShowCreateOrdersButton={shouldShowCreateOrdersButton}
    />
  );
};

export default OrdersPopOut;
