import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { useAuth } from '../../contexts/auth';
import routes from '../../routes';
import { REDIRECT_URI_QUERY_PARAM_KEY } from '../../hooks/useRedirectUriRef';

function PrivateRoute({ component: Component, render, requiredAttr, authorizationRedirect, ...rest }) {
  const { user, isAuthenticated, verificationToken } = useAuth();
  const location = useLocation();

  const requiredAttrIsAuthorized =
    typeof requiredAttr === 'function' ? requiredAttr(user) : _.has(user, requiredAttr) && user[requiredAttr];

  const authRedirectPath = verificationToken ? routes.authSetPassword(verificationToken) : routes.authSignIn();

  const privateRouteSearchParams = new URLSearchParams(location.search);

  if (!isAuthenticated) {
    privateRouteSearchParams.set(REDIRECT_URI_QUERY_PARAM_KEY, window.location.href);
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          !requiredAttr || requiredAttrIsAuthorized ? (
            render ? (
              render(props)
            ) : Component ? (
              <Component {...props} />
            ) : null
          ) : (
            <Redirect
              to={{
                pathname: authorizationRedirect || routes.marketInsights(),
                search: location.search || null,
                state: { from: location },
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: authRedirectPath,
              search: privateRouteSearchParams.toString(),
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  render: PropTypes.func,
  requiredAttr: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  authorizationRedirect: PropTypes.string,
};

export default React.memo(PrivateRoute);
