import { withLDProvider } from 'launchdarkly-react-client-sdk';

export function withFeatureFlagsProvider(clientSideID) {
  return withLDProvider({
    clientSideID,
    deferInitialization: false,
    user: {
      anonymous: true,
    },
  });
}
