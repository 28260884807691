import React from 'react';
import { gql, useMutation } from '@apollo/client';

import ForgotPasswordView from './view';

const FORGOT_PASSWORD = gql`
  mutation UserForgotPassword($email: String!) {
    userForgotPassword(email: $email) {
      user {
        id
        email
      }
    }
  }
`;

export default (props) => {
  const [forgotPasswordRequest] = useMutation(FORGOT_PASSWORD);

  return <ForgotPasswordView {...props} forgotPasswordRequest={forgotPasswordRequest} />;
};
