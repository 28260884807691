import _ from 'lodash';

import { capitalizeEachWord, formatPrice, generateCompactList } from 'helpers/format';

export const generateDetailsString = (load, isSupply) => {
  const { marketRequests = [] } = load;
  const totalMarketRequests = marketRequests.length;

  if (totalMarketRequests === 0) {
    return null;
  }

  const certificationTitle = getCertificationTitle(marketRequests[0]);
  if (totalMarketRequests === 1 || !isSupply) {
    return generateCompactList([marketRequests[0]?.size, capitalizeEachWord(certificationTitle)], ', ');
  }

  let sizes = [];
  sizes = _.map(marketRequests, (mr) => mr.size).sort((a, b) => {
    const aValue = a.replace(/\D+/g, '');
    const bValue = b.replace(/\D+/g, '');
    return aValue - bValue;
  });
  return generateCompactList([...sizes, capitalizeEachWord(certificationTitle)], ', ');
};

export const getCertificationTitle = (marketRequest) => {
  return _.get(marketRequest, 'certifications[0].title', null);
};

export const generateBreakoutRowString = (marketRequest, t) => {
  const { grade, price, quantity, size, isOrganic, hasGrowerLabel } = marketRequest;
  const strings = [];

  if (size) strings.push(size);
  if (quantity) strings.push(`${quantity} ${t('cases')}`);
  if (grade) strings.push(`${t('grade')} ${grade}`);
  if (price) strings.push(`${formatPrice(price)}`);
  if (isOrganic) strings.push(t('organic'));
  if (hasGrowerLabel === false) strings.push(t('generic'));

  return strings.join(' • ');
};

export const generateTotalQuantityString = (quantityString, marketRequests, t) => {
  if (marketRequests === null || marketRequests.length === 0 || quantityString === null) return null;
  if (marketRequests.length > 1) return `${t('upTo')} ${quantityString} ${t('cases')}`;

  return `${quantityString} ${t('cases')}`;
};
