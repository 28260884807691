import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import routes from '../../../../routes';

import { LINK_CNAME, ACTIVE_LINK_CNAME } from '../index';

function InsightsNavLink() {
  const { t } = useTranslation(['navBar', 'common']);

  return (
    <NavLink to={routes.marketInsights()} className={LINK_CNAME} activeClassName={ACTIVE_LINK_CNAME}>
      {t('common:marketInsights')}
    </NavLink>
  );
}

export default InsightsNavLink;
