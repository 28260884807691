import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import _ from 'lodash';

import { Select } from '@producepay/pp-ui';

const LANGUAGE_OPTIONS = [
  { label: 'English', value: 'en-US' },
  { label: 'Español', value: 'es-MX' },
  { label: 'Español - Peru', value: 'es-PE' },
  { label: 'Español - Chile', value: 'es-CL' },
];

export const UPDATE_LOCALE = gql`
  mutation UpdateLocale($accessToken: String!, $locale: String!) {
    updateLocale(accessToken: $accessToken, locale: $locale) {
      id
      locale
    }
  }
`;

function LanguagePicker({ accessToken }) {
  const { i18n } = useTranslation();

  const [updateLocale] = useMutation(UPDATE_LOCALE);

  // If exact match does not exist, match only on the first 2 letters (unsupported es-*)
  const findLanguage = () => {
    let match = _.find(LANGUAGE_OPTIONS, (opt) => opt.value === i18n.language);
    if (!match) {
      match = _.find(LANGUAGE_OPTIONS, (opt) => opt.value.slice(0, 2) === i18n.language.slice(0, 2));
    }
    return match || LANGUAGE_OPTIONS[0];
  };

  return (
    <Select
      items={LANGUAGE_OPTIONS}
      selectedItem={findLanguage()}
      onChange={(item) => {
        i18n.changeLanguage(item.value);

        if (accessToken) {
          updateLocale({ variables: { accessToken, locale: item.value } });
        }
      }}
    />
  );
}

LanguagePicker.propTypes = {
  accessToken: PropTypes.string,
};

LanguagePicker.defaultProps = {
  accessToken: null,
};

export default React.memo(LanguagePicker);
