import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { useAuth, USER_FRAGMENT } from '../../../../contexts/auth';
import { extractGraphqlErrorMessages } from '../../../../helpers/common';
import routes from '../../../../routes';

import RegisterView from './view';
import { KEYS, trackEvent } from '../../../../helpers/tracking';

export const CLAIM_ACCOUNT = gql`
  ${USER_FRAGMENT}
  mutation ClaimAccount($email: String!, $locale: String) {
    claimAccount(email: $email, locale: $locale) {
      ...currentUserFragment
    }
  }
`;

function Register(props) {
  const history = useHistory();

  const { registrationFunction, onRegister = () => history.push(routes.onboarding()), ...rest } = props;

  const {
    i18n: { language },
  } = useTranslation();
  const { onAuthUser } = useAuth();

  const [claimAccount, { error }] = useMutation(CLAIM_ACCOUNT, {
    onCompleted: (data) => {
      if (data?.claimAccount) onAuthUser(data?.claimAccount);

      trackEvent(KEYS.VERIFICATION_EMAIL_SENT, {
        email: data.email,
      });
    },
  });

  const registerRequest = useCallback(
    (values) => {
      const registrationPromise = registrationFunction
        ? registrationFunction(values)
        : claimAccount({ variables: { ...values, locale: language } });

      return registrationPromise.then(() => onRegister());
    },
    [registrationFunction, claimAccount, language, onRegister],
  );

  return <RegisterView {...rest} register={registerRequest} errors={extractGraphqlErrorMessages(error)} />;
}

export default Register;
