import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import startOfWeek from 'date-fns/startOfWeek';
import startOfDay from 'date-fns/startOfDay';
import addDays from 'date-fns/addDays';
import parseISO from 'date-fns/parseISO';
import isEqual from 'date-fns/isEqual';

import _ from 'lodash';

const MONTHS = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

export function getUTCDate(dateString = Date.now()) {
  const date =
    dateString instanceof Date
      ? dateString
      : typeof dateString === 'number'
      ? new Date(dateString)
      : parseISOWithFallback(dateString);

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
}

export function gqlF(date) {
  return format(date, 'yyyy-MM-dd');
}

export function monthNumToName(monthNum) {
  return MONTHS[monthNum] || '';
}
export function monthNameToNum(name) {
  const month = MONTHS.indexOf(name);
  return month || 0;
}

export function isBetween(d, dStart, dEnd) {
  return isAfter(d, dStart) && isBefore(d, dEnd);
}

export function isOnOrAfter(d, dStart) {
  return !isBefore(d, dStart);
}

export function isOnOrBefore(d, dEnd) {
  return !isAfter(d, dEnd);
}

export function isBetweenInclusive(d, dStart, dEnd) {
  return isBetween(d, dStart, dEnd) || isEqual(d, dStart) || isEqual(d, dEnd);
}

// Convert a date string in the format YYYY-MM-DD 00:00:00 UTC to YYYY-MM-DD 07:00:00
export function utcDateStrToTimeZoneOffset(dateStr) {
  const timezoneOffset = new Date().getTimezoneOffset();
  const hoursOffset = _.padStart(Math.floor(timezoneOffset / 60), 2, '0');
  const minsOffset = _.padStart(timezoneOffset % 60, 2, '0');

  return dateStr
    .replace('00:00:00 UTC', `${hoursOffset}:${minsOffset}:00`)
    .replace('00:00:00Z', `${hoursOffset}:${minsOffset}:00`);
}

export function getWeeksBetween(startDate, endDate, useStartOfWeek = true) {
  const arr = [];
  let date = useStartOfWeek
    ? startOfWeek(new Date(startDate.valueOf()), { weekStartsOn: 1 })
    : new Date(startDate.valueOf());

  while (date.valueOf() <= endDate.valueOf()) {
    arr.push(date);
    date = startOfDay(addDays(date, 7));
  }

  return arr;
}

export function parseISOWithFallback(dateString) {
  if (!dateString) return new Date();

  let date = parseISO(dateString);

  if (isNaN(date)) {
    date = new Date(dateString);
  }

  return date;
}
