import { useEffect } from 'react';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

/**
 * Saves Produce Pay referrer (Example: sms or email)
 *  - Passed to the platform in the query parameters as "source"
 *  - Passed to every trackEvent
 *  - Kept in session storage until it is replaced or the session closes
 */
export default function useReferrer() {
  const { source } = qs.parse(useLocation().search, { ignoreQueryPrefix: true });

  useEffect(() => {
    if (source) {
      window.sessionStorage.setItem('pp-referrer', source);
    }
  }, [source]);
}
