import * as React from 'react';
import PropTypes from 'prop-types';

function SvgCancelCircle(props) {
  const { color, size, ...otherProps } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...otherProps}>
      <circle cx={8} cy={8} r={8} fill={color} />
      <path
        d="M11.5 5.205l-.705-.705L8 7.295 5.205 4.5l-.705.705L7.295 8 4.5 10.795l.705.705L8 8.705l2.795 2.795.705-.705L8.705 8 11.5 5.205z"
        fill="#fff"
      />
    </svg>
  );
}

SvgCancelCircle.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SvgCancelCircle.defaultProps = {
  color: 'currentColor',
  size: '24',
};

export default SvgCancelCircle;
