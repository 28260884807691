import React from 'react';
import cx from 'classnames';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { Button, FormLabel, Modal } from '@producepay/pp-ui';

import FormikTextField from 'components/formik/FormikTextField';
import FormikCommodityVarietySearch from 'components/formik/FormikCommodityVarietySearch';
import FormikToggle from 'components/formik/FormikToggle';
import FormikSelect from 'components/formik/FormikSelect';

import ProgressBar from '../ProgressBar';
import { COMMODITY_INFO_STEP, TOTAL_STEPS } from '../helpers';
import { getAvailableSizes } from './sizes';
import { getAvailablePackageTypes } from './packageTypes';

function CommodityInfoStep({ onSubmit, onPrev, setLoad, setSizes, load, sizes, modalProps, isMobile, initialStep }) {
  const { t } = useTranslation(['marketplaceListSupply']);
  const progressPercent = (COMMODITY_INFO_STEP / TOTAL_STEPS) * 100;
  const shouldShowNavigation = initialStep !== COMMODITY_INFO_STEP;

  const validationSchema = yup.object().shape(
    {
      commodityVarietyInfo: yup.object().when('isOtherSelected', {
        is: false,
        then: yup.object().required(t('formErrors:is required')).nullable().label('then'),
        otherwise: yup.object().nullable().notRequired().label('otherwize'),
      }),
      customCommodityVarietyName: yup.string().when('isOtherSelected', {
        is: true,
        then: yup.string().required(t('formErrors:is required')),
        otherwise: yup.string().notRequired(),
      }),
      packaging: yup.string().required(t('formErrors:is required')),
      // eslint-disable-next-line func-names
      size: yup.string().test('size', t('formErrors:is required'), function (value) {
        // eslint-disable-next-line react/no-this-in-sfc
        const { commodityVarietyInfo } = this.parent;
        const availableSizes = !!getAvailableSizes(commodityVarietyInfo?.id);
        return availableSizes || !!value;
      }),
      // eslint-disable-next-line func-names
      sizes: yup.string().test('sizes', t('formErrors:is required'), function (value) {
        // eslint-disable-next-line react/no-this-in-sfc
        const { commodityVarietyInfo } = this.parent;
        const availableSizes = !!getAvailableSizes(commodityVarietyInfo?.id);
        return !availableSizes || !!value;
      }),
    },
    [['commodityVarietyInfo', 'customCommodityVarietyName']],
  );

  const initialValues = () => {
    return {
      commodityVarietyInfo: load?.commodityVarietyInfo || null,
      customCommodityVarietyName: load?.customCommodityVarietyName || '',
      isOtherSelected: !!load?.customCommodityVarietyName,
      packaging: load?.packaging || '',
      sizes: load?.commodityVarietyInfo && sizes,
      size: sizes?.[0] || '',
    };
  };

  const preprocessSubmit = async (values) => {
    const { commodityVarietyInfo, customCommodityVarietyName, isOtherSelected, size, sizes, packaging } = values;

    if (isOtherSelected) {
      setLoad({ ...load, customCommodityVarietyName, packaging });
    } else {
      setLoad({ ...load, commodityVarietyInfo, packaging });
    }

    const availableSizes = getAvailableSizes(commodityVarietyInfo?.id);
    if (availableSizes) {
      // Sorts in order of the available sizes
      const validSizes = availableSizes.reduce((acc, size) => {
        if (sizes.includes(size)) {
          acc.push(size);
        }
        return acc;
      }, []);

      setSizes(validSizes);
    } else {
      setSizes([size]);
    }

    onSubmit(values);
  };

  return (
    <Formik initialValues={initialValues()} validationSchema={validationSchema} onSubmit={preprocessSubmit}>
      {({ values, errors, touched, handleSubmit, isSubmitting, setFieldTouched }) => {
        const { commodityVarietyInfo } = values;
        const availableSizes = getAvailableSizes(commodityVarietyInfo?.id);
        const availablePackageTypes = getAvailablePackageTypes(commodityVarietyInfo?.id);

        return (
          <Modal
            className="mt-20"
            title={t('createASupplyListing')}
            footerContent={
              <>
                {shouldShowNavigation && <ProgressBar progressPercent={progressPercent} />}
                <div className={`flex w-full p-4 ${shouldShowNavigation ? 'justify-between' : 'justify-end'} border-t`}>
                  {shouldShowNavigation && (
                    <Button
                      label={t('common:previous')}
                      variant="outlined"
                      onClick={onPrev}
                      className={cx('mr-2', { 'w-full': isMobile })}
                      data-testid="btn-prev"
                    />
                  )}
                  <Button
                    label={t('common:continue')}
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    className={isMobile ? 'w-full' : ''}
                    data-testid="btn-submit"
                  />
                </div>
              </>
            }
            {...modalProps}
          >
            <Form>
              <div className="py-2">
                <FormikCommodityVarietySearch
                  label={`${t('common:variety')} + ${t('common:commodity')}`}
                  name="commodityVarietyInfo"
                  searchFieldName="commodityVarietyInfo"
                  otherFieldName="customCommodityVarietyName"
                  initialSearchTerm={load?.commodityVarietyInfo?.name || (values.isOtherSelected ? t('other') : '')}
                  otherOption={{ label: t('other'), value: 'other' }}
                  otherFormLabel={t('specifyCommodity')}
                />
              </div>

              {!availablePackageTypes && (
                <div className="py-2">
                  <FormikTextField
                    name="packaging"
                    label={t('packagingTypeLabel')}
                    placeholder={t('cases')}
                    data-testid="input-market-request-packaging"
                    onWheel={(e) => e.target.blur()}
                  />
                </div>
              )}
              {availablePackageTypes && (
                <div className="py-2">
                  <FormikSelect
                    name="packaging"
                    placeholder={t('cases')}
                    label={t('packagingTypeLabel')}
                    onWheel={(e) => e.target.blur()}
                    items={availablePackageTypes.map((value) => ({ label: value, value }))}
                  />
                </div>
              )}

              {!availableSizes && (
                <div className="py-2">
                  <FormikTextField
                    name="size"
                    label={t('sizeLabel')}
                    placeholder={t('sizes')}
                    data-testid="input-market-request-size"
                    onWheel={(e) => e.target.blur()}
                  />
                </div>
              )}

              {availableSizes && (
                <FormLabel
                  className="my-3"
                  label={t('whichSize')}
                  error={touched.sizes && errors.sizes && t('sizeError')}
                  shouldPrependErrors={false}
                >
                  <div
                    role="group"
                    aria-labelledby="checkbox-group"
                    className="flex flex-wrap gap-5 py-2 mt-2"
                    onClick={() => setFieldTouched('sizes', true)}
                  >
                    {availableSizes.map((name) => (
                      <FormikToggle key={`size-${name}`} name="sizes" value={name} label={name} />
                    ))}
                  </div>
                </FormLabel>
              )}
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
}

export default CommodityInfoStep;
