import React, { useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery, gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import routes from 'routes';

import { useAuth } from 'contexts/auth';
import { KEYS, trackEvent } from 'helpers/tracking';
import { UPDATE_ONBOARDING_INFORMATION } from 'graphql/queries/update-onboarding-information';
import { FIND_USER, FIND_OR_CREATE_USER } from 'graphql/queries/find-or-create-user';
import { FETCH_MARKET_REQUEST_LOADS } from 'graphql/queries/fetch-market-requests';

import { getCurrentStep, CREATE_REQUESTS_STEP, UPLOAD_IMAGES_STEP, SUPPLY_LISTING_SUBMITTED_STEP } from './helpers';

import { useToast } from '@producepay/pp-ui';

import SupplyListingsModalView from './view';

const debug = require('debug')('pp:supply-listing-modal');

const CREATE_LOAD = gql`
  mutation createLoad(
    $accessToken: String!
    $commodityVarietyInfoId: String
    $customCommodityVarietyName: String
    $grownLocation: String
    $shippingPoint: String
    $packaging: String
    $requestType: String
    $isAtShippingPoint: Boolean
    $estimatedPackDate: Date
    $arrivalDate: Date
  ) {
    createLoad(
      grownLocation: $grownLocation
      shippingPoint: $shippingPoint
      packaging: $packaging
      requestType: $requestType
      isAtShippingPoint: $isAtShippingPoint
      estimatedPackDate: $estimatedPackDate
      arrivalDate: $arrivalDate
      accessToken: $accessToken
      commodityVarietyInfoId: $commodityVarietyInfoId
      customCommodityVarietyName: $customCommodityVarietyName
    ) {
      id
      commodityVarietyInfo {
        id
      }
      customCommodityVarietyName
      grownLocation
      shippingPoint
      packaging
      requestType
      isAtShippingPoint
      estimatedPackDate
      arrivalDate
      userId
    }
  }
`;

const UPDATE_LOAD = gql`
  mutation updateLoad(
    $id: String!
    $commodityVarietyInfoId: String
    $customCommodityVarietyName: String
    $grownLocation: String
    $shippingPoint: String
    $packaging: String
    $requestType: String
    $isAtShippingPoint: Boolean
    $estimatedPackDate: Date
    $arrivalDate: Date
  ) {
    updateLoad(
      id: $id
      grownLocation: $grownLocation
      shippingPoint: $shippingPoint
      packaging: $packaging
      requestType: $requestType
      isAtShippingPoint: $isAtShippingPoint
      estimatedPackDate: $estimatedPackDate
      arrivalDate: $arrivalDate
      commodityVarietyInfoId: $commodityVarietyInfoId
      customCommodityVarietyName: $customCommodityVarietyName
    ) {
      id
      commodityVarietyInfo {
        id
      }
      customCommodityVarietyName
      grownLocation
      shippingPoint
      packaging
      requestType
      isAtShippingPoint
      estimatedPackDate
      arrivalDate
      userId
    }
  }
`;

const CREATE_MARKET_REQUESTS = gql`
  mutation createMarketRequests($marketRequests: [MarketRequestInput!]!) {
    createMarketRequests(marketRequests: $marketRequests) {
      id
      packaging
      isOrganic
      hasGrowerLabel
      requestType
      customCommodityVarietyName
      commodityVarietyInfo {
        id
      }
      load {
        id
      }
    }
  }
`;

function SupplyListingsModal({ onCloseRoute }) {
  const history = useHistory();
  const { addToastToQueue } = useToast();
  const { t } = useTranslation(['marketplaceListSupply']);

  const { user, setUnauthenticatedUser } = useAuth();

  const [newSignedUpUser, setNewSignedUpUser] = useState(null);
  const [wasUserInitiallyKnown] = useState(!!user);

  const resolvedUser = user || newSignedUpUser;
  const [step, setCurrentStep] = useState(getCurrentStep(resolvedUser));

  const initialStep = useRef(step);

  const [inputEmail, setInputEmail] = useState(null);
  const [load, setLoad] = useState({});
  const [sizes, setSizes] = useState([]);
  const [placeholderLoadId, setPlaceholderLoadId] = useState(null);

  const [findUser] = useLazyQuery(FIND_USER, {
    onCompleted: (data) => {
      const { user = {} } = data;

      const redirectUser = () => {
        history.push(routes.authSignIn());
        addToastToQueue({
          type: 'info',
          header: t('userFound'),
          body: t('pleaseLogin'),
        });
      };
      return user?.id ? redirectUser() : findOrCreateUser({ variables: { email: inputEmail } });
    },
  });

  const [findOrCreateUser] = useMutation(FIND_OR_CREATE_USER, {
    onCompleted: (data) => {
      // Set the unauthenticated user in the context only when the modal closes
      // and not here, to prevent the market requests in marketplace from re-rendering while
      // the modal is open
      setNewSignedUpUser(data.registerUser);
      trackEvent(KEYS.SUPPLY_LISTING_EMAIL_STEP_COMPLETE);
      setCurrentStep(getCurrentStep(data.registerUser));
    },
  });

  const onSubmitEmail = useCallback(
    (values) => {
      const { email } = values;
      setInputEmail(email);
      findUser({ variables: { email } });
    },
    [findUser],
  );

  const [updateOnboardingInfo] = useMutation(UPDATE_ONBOARDING_INFORMATION, {
    onCompleted: (data) => {
      if (newSignedUpUser) {
        setNewSignedUpUser(data.updateOnboardingInformation);
      }

      trackEvent(KEYS.SUPPLY_LISTING_PROFILE_INFO_STEP_COMPLETE);
      setCurrentStep(getCurrentStep(data.updateOnboardingInformation));
    },
  });
  const onSubmitOnboardingInfo = useCallback(
    (values) => {
      const { fullName, companyName, phoneNumber, country } = values;

      updateOnboardingInfo({
        variables: {
          accessToken: resolvedUser.accessToken,
          onboardingInformation: {
            fullName,
            companyName,
            phoneNumber,
            country,
          },
        },
      });
    },
    [updateOnboardingInfo, resolvedUser],
  );

  const [updateCompanyInfo] = useMutation(UPDATE_ONBOARDING_INFORMATION, {
    onCompleted: (data) => {
      if (newSignedUpUser) {
        setNewSignedUpUser(data.updateOnboardingInformation);
      }

      trackEvent(KEYS.SUPPLY_LISTING_COMPANY_INFO_STEP_COMPLETE);
      setCurrentStep(getCurrentStep(data.updateOnboardingInformation));
    },
  });
  const onSubmitCompanyInfo = useCallback(
    (values) => {
      const { companyType, totalExportedToUs, yearsExportedToUs, numBoxesSoldPerWeek, agreeToSupplyTerms } = values;

      const userApplicationStatus = user?.onboardingInformation?.supplyListingsApplicationStatus;
      const supplyListingsApplicationStatus = userApplicationStatus || 'pending';

      updateCompanyInfo({
        variables: {
          accessToken: resolvedUser.accessToken,
          onboardingInformation: {
            companyType,
            totalExportedToUs,
            yearsExportedToUs,
            numBoxesSoldPerWeek,
            supplyListingsApplicationStatus,
            agreeToSupplyTerms,
          },
        },
      });
    },
    [updateCompanyInfo, resolvedUser, user],
  );

  const [updateLoad] = useMutation(UPDATE_LOAD, {
    onCompleted: (data) => {
      try {
        const { updateLoad } = data;
        const {
          commodityVarietyInfo,
          customCommodityVarietyName,
          grownLocation,
          id,
          packaging,
          requestType,
          shippingPoint,
        } = updateLoad;
        const { marketRequests } = load;
        const { accessToken } = resolvedUser;

        debug('load created successfully', data);

        const requests = marketRequests.map((request) => {
          const modified = {
            accessToken,
            loadId: id,
            price: request.price,
            size: request.size,
            quantity: `${request.quantity}`,
            grade: request.grade,
            grownLocation,
            isOrganic: request.isOrganic === 'true',
            hasGrowerLabel: request.hasGrowerLabel === 'true',
            packaging,
            requestType,
            shippingPoint,
          };

          if (commodityVarietyInfo) {
            modified.commodityVarietyInfoId = commodityVarietyInfo.id;
          } else {
            modified.customCommodityVarietyName = customCommodityVarietyName;
          }
          return modified;
        });

        onSubmitMarketRequests({ marketRequests: requests });
      } catch (e) {
        debug('error', e);
      } finally {
        setPlaceholderLoadId(null);
        setCurrentStep(SUPPLY_LISTING_SUBMITTED_STEP);
      }
    },
    onError: (data) => {
      debug('load update failed', data);
    },
  });

  const [createPlaceholderLoad] = useMutation(CREATE_LOAD);
  const [updateSimpleLoad] = useMutation(UPDATE_LOAD);

  const onSubmitLoad = useCallback(
    (values) => {
      trackEvent(KEYS.SUBMIT_LOGISTICS_STEP);
      const variables = { id: placeholderLoadId, ...values };
      updateLoad({ variables });
    },
    [updateLoad, placeholderLoadId],
  );

  const { refetch: refetchMarketRequests } = useQuery(FETCH_MARKET_REQUEST_LOADS, {
    variables: {
      requestType: 'supply',
    },
  });

  const [createMarketRequests] = useMutation(CREATE_MARKET_REQUESTS, {
    onCompleted: (data) => {
      debug('requests created successfully', data);
      refetchMarketRequests();
    },
    onError: (data) => {
      debug('requests creation failed', data);
    },
  });
  const onSubmitMarketRequests = useCallback(
    (values) => {
      const { marketRequests } = values;

      createMarketRequests({
        variables: {
          marketRequests,
        },
      });
    },
    [createMarketRequests],
  );

  const onSubmitSupplyListing = useCallback(
    (values) => {
      const { accessToken } = resolvedUser;
      const { commodityVarietyInfo, customCommodityVarietyName, packaging } = values;

      if (placeholderLoadId) {
        updateSimpleLoad({
          variables: {
            id: placeholderLoadId,
            commodityVarietyInfoId: commodityVarietyInfo?.id,
            customCommodityVarietyName,
            packaging,
          },
        });
      } else {
        createPlaceholderLoad({
          variables: {
            accessToken,
            commodityVarietyInfoId: commodityVarietyInfo?.id,
            customCommodityVarietyName,
            packaging,
          },
        }).then((loadData) => {
          setPlaceholderLoadId(loadData.data.createLoad.id);
        });
      }

      trackEvent(KEYS.SUBMIT_VARIETY_COMMODITY_INFO);
      setCurrentStep(CREATE_REQUESTS_STEP);
    },
    [createPlaceholderLoad, resolvedUser, placeholderLoadId, updateSimpleLoad],
  );

  const onSubmitRequests = useCallback(() => {
    trackEvent(KEYS.SUBMIT_MARKET_REQUESTS);
    return setCurrentStep(UPLOAD_IMAGES_STEP);
  }, []);

  return (
    <SupplyListingsModalView
      user={resolvedUser}
      initialStep={initialStep.current}
      step={step}
      wasUserInitiallyKnown={wasUserInitiallyKnown}
      setCurrentStep={setCurrentStep}
      load={load}
      placeholderLoadId={placeholderLoadId}
      setLoad={setLoad}
      sizes={sizes}
      setSizes={setSizes}
      onClose={() => {
        if (newSignedUpUser && !user) {
          setUnauthenticatedUser(newSignedUpUser);
        }
        history.push(onCloseRoute);
      }}
      onSubmitEmail={onSubmitEmail}
      onSubmitOnboardingInfo={onSubmitOnboardingInfo}
      onSubmitCompanyInfo={onSubmitCompanyInfo}
      onSubmitSupplyListing={onSubmitSupplyListing}
      onSubmitRequests={onSubmitRequests}
      onSubmitLogistic={onSubmitLoad}
    />
  );
}

export default SupplyListingsModal;
