export const INVENTORY_ITEM_FRAGMENT = `
  fragment inventoryItemFragment on InventoryItem {
    id
    skuId
    commodity {
      id
      name
    }
    variety {
      id
      name
    }
    package {
      id
      name
    }
    size {
      id
      name
    }
    grade {
      id
      name
    }
    isOrganic
    pricePerUnit
    hasGrowerLabel
    quantity
  }
`;

export const ASSET_COMPANY_FRAGMENT = `
  fragment assetCompanyFragment on AssetCompany {
    id
    companyId
    name
    role
    signature
  }
`;

export const ASSET_TRANSACTION_FRAGMENT = `
  fragment assetTransactionFragment on AssetTransaction {
    id
    note,
    transactionType
  }
`;

export const FINANCIAL_PRODUCT_FRAGMENT = `
  fragment financialProduct on FinancialProduct {
    balance
    productType
    terms
    financingRate
  }
`;

export const ORDER_FRAGMENT = `
  ${INVENTORY_ITEM_FRAGMENT}
  ${ASSET_COMPANY_FRAGMENT}

  fragment orderFragment on Order {
    id
    slug
    referenceNumber
    pickupNumber
    orderDestination
    shipDate
    deliveryDate
    orderStatus
    isEditable
    awaitingActions
    isFixedPrice
    receiverCommissionPercentage
    paymentTermDays
    suppliers {
      ...assetCompanyFragment
    }
    receivers {
      ...assetCompanyFragment
    }
    inventoryItems {
      ...inventoryItemFragment
    }
  }
`;
