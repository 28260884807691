import React from 'react';
import RelativePortal from 'react-relative-portal';
import PropTypes from 'prop-types';
import cx from 'classnames';
import dateFnsFormat from 'date-fns/format';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import { TextField } from '@producepay/pp-ui';
import CalendarIcon from '../../../icons/CalendarIcon';

const DATEPICKER_FORMAT = 'MM/dd/yyyy';

function formatInputDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}

function RelativePortalOverlay({ classNames, children, ...props }) {
  return (
    <RelativePortal component="div" left={0} top={0}>
      <div className={cx(classNames.overlayWrapper, 'InsightsDatePicker')} {...props}>
        <div className={classNames.overlay}>{children}</div>
      </div>
    </RelativePortal>
  );
}

const DateRangeCustomInput = React.forwardRef((props, ref) => {
  const { value, onChange, placeholderDate, inputProps, dayPickerProps } = props;

  const commonDayPickerInputProps = {
    format: DATEPICKER_FORMAT,
    formatDate: formatInputDate,
  };

  return (
    <DayPickerInput
      ref={ref}
      overlayComponent={RelativePortalOverlay}
      keepFocus={false}
      dayPickerProps={{
        ...dayPickerProps,
        onDayClick: onChange,
      }}
      placeholder={`${dateFnsFormat(placeholderDate, DATEPICKER_FORMAT)}`}
      component={TextField}
      value={value}
      inputProps={{
        ...inputProps,
        color: 'primary',
        icon: <CalendarIcon size={16} />,
        size: 'small',
        rounded: false,
      }}
      {...commonDayPickerInputProps}
    />
  );
});

DateRangeCustomInput.propTypes = {
  value: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
  dayPickerProps: PropTypes.object,
  inputProps: PropTypes.object,
  placeholderDate: PropTypes.instanceOf(Date),
};

DateRangeCustomInput.defaultProps = {
  dayPickerProps: {},
  inputProps: {},
  placeholderDate: new Date(),
};

export default React.memo(DateRangeCustomInput);
