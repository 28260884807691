import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { pick } from 'lodash';

import routes from 'routes';

import CTAButton from '../MarketplaceCTA/CTAButton';
import Tooltip from 'components/elements/Tooltip';

import { imageFromLoad } from 'helpers/common';

import goldStar from 'assets/images/gold-star.svg';
import greenCheck from 'assets/images/green-check.svg';
import leaf from 'assets/images/leaf.svg';
import backgroundCheckShield from 'assets/images/background-check-shield.svg';

import { capitalizeEachWord, generatePriceString, generateQuantityString, getCommodityName } from 'helpers/format';
import { getUTCDate } from 'helpers/dates';
import { useResponsiveLayout } from 'helpers/dom';
import { KEYS, trackEvent } from 'helpers/tracking';
import { getTierRating, backgroundCheckComplete } from 'helpers/load';
import { generateBreakoutRowString, generateDetailsString, generateTotalQuantityString } from './helpers';

const MAX_NUMBER_OF_BREAKOUT_ITEMS = 4;

function MarketRequestCard({ isSupply, load, source }) {
  const { isMobile, isLowerRes } = useResponsiveLayout();
  const { t } = useTranslation('marketplaceMarketRequest');
  const history = useHistory();

  const packaging = load?.packaging || null;
  const grownLocation = load?.grownLocation || null;
  const shippingPoint = load?.shippingPoint || null;
  const createdAtUTC = getUTCDate(load?.createdAt);
  const commodityName = getCommodityName(load);
  const priceString = generatePriceString(load, isSupply);
  const quantityString = generateQuantityString(load, isSupply);
  const detailsString = generateDetailsString(load, isSupply);
  const tierRating = getTierRating(load);
  const isBackgroundCheckComplete = backgroundCheckComplete(tierRating);

  const marketRequests = load?.marketRequests || [];
  const firstMarketRequest = marketRequests[0];
  const archiveDateUTC = getUTCDate(firstMarketRequest?.archiveDate);
  const totalQuantityString = generateTotalQuantityString(quantityString, marketRequests, t);

  const imageUrl = imageFromLoad(load);

  const tradingPerformance = load?.companyProfile?.sellerScore?.tradingPerformance;

  const marketRequestAnalyticsPayload = {
    ...pick(load, ['id', 'shippingPoint', 'requestType', 'packaging', 'grownLocation']),
    ...pick(firstMarketRequest, ['tierRating', 'terms', 'quantity', 'price', 'grade']),
    source,
  };

  const toolTipMessage = () => {
    const clickRoute = isSupply ? routes.tierSellerFAQ() : routes.tierBuyerFAQ();
    return (
      <>
        <div>
          <span className="text-white">{t('tierFaqTooltip')}</span>
        </div>
        <div
          className="pt-2"
          onClick={() => {
            trackEvent(KEYS.OPEN_RATINGS_FAQS);
            history.push(clickRoute);
          }}
        >
          <span className="text-white underline cursor-pointer">{t('tierFaqTooltipLink')}</span>
        </div>
      </>
    );
  };

  const backgroundCheckToolTipMessage = () => {
    return (
      <div>
        <span className="text-white">{t('backgroundCheckTooltip')}</span>
      </div>
    );
  };

  const DesktopDisplay = () => {
    return (
      <div
        className="flex bg-white rounded-lg mb-8 border-2 border-transparent hover:border-primary transition duration-500 transform hover:-translate-y-1"
        data-testid={`market-request-${load.id}`}
        style={{ height: '258px' }}
      >
        <div className="flex flex-col justify-center ml-3 mr-4 py-4 w-1/5">
          <img src={imageUrl} alt={commodityName} className="object-contain h-full rounded" />
        </div>
        <div className="flex flex-col justify-start m-6 w-4/5">
          <div className="flex flex-row justify-between mb-3">
            <span className="font-bold text-2xl">{commodityName}</span>
            <div className="flex">
              {isBackgroundCheckComplete && (
                <Tooltip html={backgroundCheckToolTipMessage()} position="top" interactive arrow={false}>
                  <img src={backgroundCheckShield} alt="blue shield" />
                </Tooltip>
              )}
              {tradingPerformance && (
                <div className="flex items-center px-3 py-1 text-primary-dark text-sm rounded bg-gray-lightest font-bold ml-2">
                  <img src={leaf} alt="leaf" style={{ height: '16px' }} />
                  <span className="text-lg ml-2 text-primary-dark">{tradingPerformance.toFixed(2)}</span>
                </div>
              )}
              <div className="ml-2">
                <Tooltip html={toolTipMessage()} position="top" interactive arrow={false}>
                  {tierRating === 'Gold Verified' ? (
                    <div className="flex">
                      <div className="flex items-center px-2 py-1 text-gold-medium text-sm rounded bg-gold-light font-bold">
                        <img src={goldStar} alt="gold star" style={{ height: '16px' }} />
                        <span className="ml-2 whitespace-nowrap">
                          {isSupply ? t('goldVerifiedSeller') : t('goldVerifiedBuyer')}
                        </span>
                      </div>
                    </div>
                  ) : tierRating === 'Verified' ? (
                    <div className="flex">
                      <div className="flex items-center px-2 py-1 text-primary text-sm rounded bg-primary-light font-bold">
                        <img src={greenCheck} alt="green check" style={{ height: '8px' }} />
                        <span className="ml-2 whitespace-nowrap">
                          {isSupply ? t('verifiedSeller') : t('verifiedBuyer')}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </Tooltip>
              </div>
              <div className="flex flex-col flex-grow">
                {!isSupply && (
                  <div className="flex flex-row justify-end ml-5">
                    <CTAButton
                      classes="hover:bg-primary hover:text-white"
                      isSupply={false}
                      label={t('marketplace:chatToBid')}
                      marketRequestId={firstMarketRequest?.id}
                      variant="outlined"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-row h-full">
            <div className="flex flex-col w-4/12">
              {packaging && <span className="mb-4 font-bold text-lg">{capitalizeEachWord(packaging)}</span>}
              <div className="text-sm mb-1">
                <span>{t('availableFrom')}: </span>
                <span>{t('rangeAvailable', { startDate: createdAtUTC, endDate: archiveDateUTC })}</span>
              </div>
              {shippingPoint && (
                <div className="text-sm mb-1">
                  <span>{isSupply ? t('shippingLocation') : t('deliveryLocation')}: </span>
                  <span>{shippingPoint}</span>
                </div>
              )}
              {grownLocation && (
                <div className="text-sm mb-1">
                  <span>{t('grownIn')}: </span>
                  <span>{grownLocation}</span>
                </div>
              )}
            </div>

            <div className="flex flex-col w-5/12 ml-4">
              <span className="mb-2 font-semibold text-base">{t('supplyBreakout')}</span>
              <div className="flex flex-col">
                {marketRequests?.length > 0 &&
                  marketRequests.map((marketRequest, index) => {
                    if (index < MAX_NUMBER_OF_BREAKOUT_ITEMS) {
                      return (
                        <span key={marketRequest.id} className="mb-1 text-sm">
                          {generateBreakoutRowString(marketRequest, t)}
                        </span>
                      );
                    }
                    return null;
                  })}
                {load?.marketRequests?.length > MAX_NUMBER_OF_BREAKOUT_ITEMS && (
                  <span className="text-sm">
                    + {load.marketRequests.length - MAX_NUMBER_OF_BREAKOUT_ITEMS} {t('moreSizes')}
                  </span>
                )}
              </div>
            </div>
            <div className="flex flex-col justify-end items-end w-3/12">
              <span className="font-bold text-2xl">{priceString}</span>
              {totalQuantityString && <span className="font-bold text-lg text-right ml-2">{totalQuantityString}</span>}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const MobileDisplay = () => {
    return (
      <div
        className="flex items-start bg-white rounded-lg mb-4 border-2 border-transparent hover:border-primary"
        style={{ height: '154px' }}
        data-testid={`market-request-${load.id}`}
      >
        <div className="flex flex-col p-2 w-1/4">
          <img
            src={imageUrl}
            style={{ width: '64px', height: '64px' }}
            alt={commodityName}
            className="object-cover h-full rounded"
          />
        </div>
        <div className="flex flex-col justify-around p-2 w-3/4 h-full text-sm">
          <div className="flex flex-row">
            {isBackgroundCheckComplete && <img src={backgroundCheckShield} alt="blue shield" />}
            {tierRating === 'Gold Verified' ? (
              <>
                <div className="flex items-center px-1 text-gold-medium text-sm rounded bg-gold-light font-bold ml-2">
                  <img src={goldStar} alt="gold star" style={{ height: '12px' }} />
                  <span className="ml-2">{isSupply ? t('goldSeller') : t('goldBuyer')}</span>
                </div>
                <span className="p-1 ml-2 rounded bg-orange-light font-bold">{t('recommended')}</span>
              </>
            ) : tierRating === 'Verified' ? (
              <div className="flex items-center px-1 text-primary text-sm rounded bg-primary-light font-bold ml-2">
                <img src={greenCheck} alt="green check" style={{ height: '10px' }} />
                <span className="ml-2">{isSupply ? t('verifiedSeller') : t('verifiedBuyer')}</span>
              </div>
            ) : null}
            {tradingPerformance && (
              <div className="flex items-center px-2 py-1 text-primary-dark text-sm rounded bg-gray-lightest font-bold ml-2">
                <img src={leaf} alt="leaf" style={{ height: '16px' }} />
                <span className="ml-2 text-primary-dark">{tradingPerformance.toFixed(2)}</span>
              </div>
            )}
          </div>
          <div className="flex flex-row">
            <span className="font-bold text-xl leading-none">{commodityName}</span>
          </div>
          <div className="flex flex-row">
            <div className="leading-none">
              <span className="font-bold">{priceString}</span>
              {totalQuantityString && <span className="ml-1">{totalQuantityString}</span>}
            </div>
          </div>
          <div className="flex flex-row">
            <span className="leading-none">{detailsString}</span>
          </div>
          <div className="flex flex-row">
            {archiveDateUTC && createdAtUTC && (
              <span>
                <span>{t('rangeAvailable', { startDate: createdAtUTC, endDate: archiveDateUTC })}</span>
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isMobile || isLowerRes ? (
        <a
          href={routes.marketplace()}
          onClick={() => trackEvent(KEYS.MARKET_REQUEST_VIEW_DETAILS_CLICKED, marketRequestAnalyticsPayload)}
        >
          <MobileDisplay />
        </a>
      ) : isSupply ? (
        <a
          href={routes.marketplaceCreateBid()}
          onClick={() => trackEvent(KEYS.MARKET_REQUEST_VIEW_DETAILS_CLICKED, marketRequestAnalyticsPayload)}
        >
          <DesktopDisplay />
        </a>
      ) : (
        <DesktopDisplay />
      )}
    </>
  );
}

MarketRequestCard.propTypes = {
  source: PropTypes.string,
};

MarketRequestCard.defaultProps = {
  source: 'marketplace-supply',
};

export default MarketRequestCard;
