// CVI ids
const HASS_AVOCADO = '63';
const GREEN_SKIN_AVOCADO = '171';
const AVOCADO = '133';
const AVOCADO_PACKAGE_TYPES = [
  '4 Kg Containers',
  '25 Lb Cartons',
  'Cartons',
  'Cartons 1 Layer',
  'Cartons 2 Layer',
  'Flats 1 Layer',
  'Flats 2 Layer',
];

const SEEDLESS_TYPE_LIMES = '189';
const LIMES = '1202';
const SWEET_LIMES = '863';
const LIME_PACKAGE_TYPES = [
  '10 Lb Cartons',
  '40 Lb Cartons',
  'Cartons 17 2 Lb Bags',
  'Cartons 18 2 Lb Bags',
  'Cartons 20 2 Lb Bags',
  'Flats 10 1 Lb Mesh Bags',
];

const BROCCOLI = '497';
const RABE_BROCCOLI = '730';
const CROWN_CUT_BROCCOLI = '1712';
const BROCCOLI_PACKAGE_TYPES = [
  '1 1/9 Bushel Cartons',
  '1 1/9 Bushel Cartons Bunched',
  '20 Lb Cartons',
  '20 Lb Cartons Bunched',
  'Cartons',
  'Cartons Bunched',
  'Crates',
  'Crates Bunched',
];

const BLUEBERRIES = '35';
const BLACKBERRIES = '228';
const RASPBERRIES = '591';
const RED_RASPBERRIES = '1701';
const BERRY_PACKAGE_TYPES = ['12 6 Oz Cups With Lids', '12 1 Pt Cups With Lids'];

const STRAWBERRIES = '157';
const STRAWBERRY_PACKAGE_TYPES = [
  '4 2 Lb Containers With Lids',
  '8 1 Lb Containers With Lids',
  '12 1 Pt Containers With Lids',
];

export const getAvailablePackageTypes = (cviId) => {
  switch (cviId) {
    case AVOCADO:
    case HASS_AVOCADO:
    case GREEN_SKIN_AVOCADO:
      return AVOCADO_PACKAGE_TYPES;
    case SEEDLESS_TYPE_LIMES:
    case LIMES:
    case SWEET_LIMES:
      return LIME_PACKAGE_TYPES;
    case BROCCOLI:
    case RABE_BROCCOLI:
    case CROWN_CUT_BROCCOLI:
      return BROCCOLI_PACKAGE_TYPES;
    case BLUEBERRIES:
    case BLACKBERRIES:
    case RASPBERRIES:
    case RED_RASPBERRIES:
      return BERRY_PACKAGE_TYPES;
    case STRAWBERRIES:
      return STRAWBERRY_PACKAGE_TYPES;
    default:
      return null;
  }
};
