import React from 'react';
import {
  useChannelStateContext,
  useChatContext,
  useTranslationContext,
  Avatar as DefaultAvatar,
} from 'stream-chat-react';
import { gql, useQuery } from '@apollo/client';
import { ArrowLeft, Users, FileText } from 'react-feather';
import { Button } from '@producepay/pp-ui';
import { createOrderWithMarketplaceLoad } from 'routes';
import { Link } from 'react-router-dom';
import { useAuth } from 'contexts/auth';
import { FETCH_STREAM_CHANNEL } from '../../../graphql/queries/fetch-stream-channel';

import { useTranslation } from 'react-i18next';
import { useResponsiveLayout } from '../../../helpers/dom';
import CreateOrderButton from './CreateOrderButton';

export const removeUnverifiedFromChannelName = (channelName) => {
  return channelName.replace(/ \(Unverified\)/g, '');
};

export const FETCH_MARKET_REQUEST_LOAD = gql`
  query($marketRequestId: Int!) {
    marketRequest(id: $marketRequestId) {
      load {
        accountServiceCompanyId
        id
      }
    }
  }
`;

const CustomChannelHeader = (props) => {
  const {
    Avatar = DefaultAvatar,
    image: propImage,
    live,
    title,
    setShowParticipantPopout,
    setShowOrdersPopout,
  } = props;
  const { isMobile } = useResponsiveLayout();

  const { user: currentUser } = useAuth();

  /* eslint-disable-next-line camelcase */
  const { channel, watcher_count: watcherCount } = useChannelStateContext();
  const { openMobileNav } = useChatContext();
  const { t: streamT } = useTranslationContext();

  const { id: channelId, image: channelImage, market_request: marketRequest, name, subtitle } = channel?.data || {};

  const { data } = useQuery(FETCH_MARKET_REQUEST_LOAD, {
    skip: !marketRequest,
    variables: {
      marketRequestId: marketRequest?.id,
    },
  });

  const { data: streamChannelData } = useQuery(FETCH_STREAM_CHANNEL, {
    skip: !channelId,
    variables: {
      streamioId: channelId,
    },
  });

  const streamChannelId = streamChannelData?.streamChannel?.id;

  const shouldShowCreateOrdersButton =
    process.env.REACT_APP_IS_CREATE_ORDER_BUTTON_ENABLED === 'true' &&
    data?.marketRequest?.load?.id &&
    data?.marketRequest?.load?.accountServiceCompanyId &&
    currentUser?.accountServiceCompanyId;

  const image = propImage || channelImage;
  const channelName = title || removeUnverifiedFromChannelName(name);

  const { t } = useTranslation('conversations');

  return (
    <div className="flex flex-col">
      <div className="str-chat__header-livestream flex justify-between">
        <div>
          {image && <Avatar image={image} shape="rounded" size={channel?.type === 'commerce' ? 60 : 40} />}
          <div className="str-chat__header-livestream-left text-center md:text-left flex items-end">
            <div className="str-chat__header-hamburger" onClick={openMobileNav}>
              <ArrowLeft className="stroke-current text-primary" />
            </div>
            <div>
              <p
                className="str-chat__header-livestream-left--title text-lg mb-2 cursor-pointer hover:underline"
                onClick={() => setShowParticipantPopout(true)}
              >
                {channelName}{' '}
                {live && <span className="str-chat__header-livestream-left--livelabel">{streamT('live')}</span>}
              </p>
              <p
                className="str-chat__header-livestream-left--members cursor-pointer hover:underline"
                onClick={() => setShowParticipantPopout(true)}
              >
                {`${watcherCount} ${t('membersOnline', { count: watcherCount })}`}
              </p>
            </div>
            {subtitle && <p className="str-chat__header-livestream-left--subtitle">{subtitle}</p>}
          </div>
        </div>
        {isMobile ? (
          <div className="str-chat__header-hamburger m-2 block" onClick={() => setShowParticipantPopout(true)}>
            <Users className="stroke-current text-primary" />
          </div>
        ) : (
          shouldShowCreateOrdersButton && (
            <div className="flex items-center">
              <Button
                className="font-medium mr-4 flex items-center"
                borderRadius=""
                onClick={() => setShowOrdersPopout(true)}
                data-testid="btn-profile-cta"
                color="default"
              >
                <FileText className="mr-2" />
                <span className="font-medium">{t('viewOrders')}</span>
              </Button>
              <Link to={createOrderWithMarketplaceLoad(data.marketRequest.load.id, streamChannelId)}>
                <CreateOrderButton onClick={() => {}} label={t('createOrder')} />
              </Link>
            </div>
          )
        )}
      </div>
      {isMobile && shouldShowCreateOrdersButton && (
        <div className="flex items-center bg-white justify-center p-4">
          <Button
            className="font-medium mr-4 flex items-center"
            borderRadius=""
            onClick={() => setShowOrdersPopout(true)}
            data-testid="btn-profile-cta"
            color="default"
          >
            <FileText className="mr-2" />
            <span className="font-medium">{t('viewOrders')}</span>
          </Button>
          <Link to={createOrderWithMarketplaceLoad(data.marketRequest.load.id, streamChannelId)}>
            <CreateOrderButton onClick={() => {}} label={t('createOrder')} />
          </Link>
        </div>
      )}
    </div>
  );
};

export default CustomChannelHeader;
