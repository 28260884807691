import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { map } from 'lodash';

import { useAuth } from 'contexts/auth';
import { usePreferences } from 'contexts/preferences';
import { useSearchState } from 'contexts/SearchState';

import { FETCH_MARKET_REQUEST_LOADS } from 'graphql/queries/fetch-market-requests';

import { TOGGLE_MY_WATCHLIST_KEY, TOGGLE_ALL_COMMODITIES_KEY } from '../partials/MarketplaceNav/helpers';

import MarketplaceDemandView from './view';

function MarketplaceDemand({ toggleKey }) {
  const { unauthenticatedUser, isAuthenticated } = useAuth();
  const { cvisForUser, loading: preferencesAreLoading } = usePreferences();

  const isUnAuthenticatedUserKnown = unauthenticatedUser?.email && unauthenticatedUser?.token;
  const isUserUnknown = !isAuthenticated && !isUnAuthenticatedUserKnown;

  const { searchStateText, setSearchStateText } = useSearchState();

  const { data, loading } = useQuery(FETCH_MARKET_REQUEST_LOADS, {
    variables: {
      requestType: 'demand',
      ...(toggleKey === TOGGLE_MY_WATCHLIST_KEY && { cvInfoIds: map(cvisForUser, (cvi) => String(cvi.id)) }),
    },
    skip: preferencesAreLoading,
  });

  return (
    <MarketplaceDemandView
      loading={loading}
      isUserUnknown={isUserUnknown}
      toggleKey={toggleKey}
      loads={data?.marketRequestLoads}
      searchText={searchStateText}
      setSearchText={setSearchStateText}
    />
  );
}

MarketplaceDemand.propTypes = {
  toggleKey: PropTypes.oneOf([TOGGLE_MY_WATCHLIST_KEY, TOGGLE_ALL_COMMODITIES_KEY]).isRequired,
};

export default MarketplaceDemand;
