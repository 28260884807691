import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button } from '@producepay/pp-ui';

import { useResponsiveLayout } from 'helpers/dom';

import CTA from '../MarketplaceCTA';

export const TOGGLE_MY_WATCHLIST_KEY = 'watchlist';
export const TOGGLE_ALL_COMMODITIES_KEY = 'all-commodities';

function MarketplaceNav({ toggleKey, setToggleKey }) {
  const { t } = useTranslation('marketplace');

  const { isMobileOrTablet } = useResponsiveLayout();

  const isOnAllCommodities = toggleKey === TOGGLE_ALL_COMMODITIES_KEY;
  const isOnWatchlist = toggleKey === TOGGLE_MY_WATCHLIST_KEY;

  const toggleWatchlist = (toggleKey) => {
    setToggleKey(toggleKey);
  };

  return (
    <div className="flex justify-between px-4 pt-4 lg:px-0 lg:pt-8">
      <div className="flex">
        <div className="mr-3">
          <Button
            className={cx({ 'font-bold': isOnAllCommodities })}
            color={isOnAllCommodities ? 'primary' : 'gray-300'}
            variant="outlined"
            onClick={() => {
              toggleWatchlist(TOGGLE_ALL_COMMODITIES_KEY);
            }}
            data-testid="btn-all"
            borderRadius="lg"
            style={{ backgroundColor: '#FFFFFF', padding: '12px 16px' }}
          >
            <div className="flex text-primary">{t('all')}</div>
          </Button>
        </div>
        <div className="mr-3">
          <Button
            className={cx({ 'font-bold': isOnWatchlist })}
            color={isOnWatchlist ? 'primary' : 'gray-300'}
            variant="outlined"
            onClick={() => {
              toggleWatchlist(TOGGLE_MY_WATCHLIST_KEY);
            }}
            data-testid="btn-watchlist"
            borderRadius="lg"
            style={{ backgroundColor: '#FFFFFF', padding: '12px 16px' }}
          >
            <div className="flex text-primary">{t('watchlist')}</div>
          </Button>
        </div>
      </div>
      {!isMobileOrTablet && (
        <div className="flex">
          <CTA />
        </div>
      )}
    </div>
  );
}

export default MarketplaceNav;
