import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { Button } from '@producepay/pp-ui';
import CommodityNameDisplay from '../CommodityNameDisplay';

// Translations are much bigger and need some hand holding
const SPANISH_BUTTON_WIDTH = 120;

function AddRemoveCommodityRow(props) {
  const { className, name, image, onAdd, onRemove, isSubscribed } = props;

  const { t, i18n } = useTranslation(['marketInsightsAddCommodities', 'marketInsights', 'common']);

  const buttonStyle = i18n.language?.includes('es') ? { minWidth: SPANISH_BUTTON_WIDTH } : {};

  return (
    <div
      className={cx('flex justify-between items-center py-2 cursor-pointer', className)}
      data-testid="add-remove-commodity-row"
      onClick={isSubscribed ? onRemove : onAdd}
    >
      <CommodityNameDisplay name={name} imageUrl={image} />

      {isSubscribed ? (
        <Button className="text-red-action ml-2" style={buttonStyle} variant="text" label={t('common:remove')} />
      ) : (
        <Button className="ml-2" style={buttonStyle} variant="outlined" label={t('common:add')} />
      )}
    </div>
  );
}

export default AddRemoveCommodityRow;
