import React from 'react';

function ProgressBar({ progressPercent }) {
  return (
    <div className="relative pt-1">
      <div className="overflow-hidden h-2 text-xs flex">
        <div
          style={{ width: `${progressPercent}%`, backgroundColor: '#33CE9B' }}
          className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
        />
      </div>
    </div>
  );
}

export default ProgressBar;
