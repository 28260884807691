import React from 'react';
import { useResponsiveLayout } from 'helpers/dom';
import { ChevronRight } from 'react-feather';
import { CSSTransition } from 'react-transition-group';
import goldStar from 'assets/images/gold-star.svg';
import greenCheck from 'assets/images/green-check.svg';
import { useTranslation } from 'react-i18next';
import { useChannelStateContext } from 'stream-chat-react';
import { camelCase } from 'lodash-es';

const ParticipantPopOut = (props) => {
  const { showPopout, setShowPopout } = props;
  const { isMobile } = useResponsiveLayout();

  const { channel } = useChannelStateContext();
  const { t } = useTranslation('conversations');

  const popoutClass = isMobile ? 'participants w-3/4' : 'participants w-1/3';

  function isProducePayMember(user) {
    return user?.email?.toLowerCase()?.endsWith('producepay.com');
  }

  // Returns data for member of the chat not 'Admins'
  function getMembers() {
    if (!channel?.state?.members) {
      return [];
    }

    const channelMembers = channel.state.members;
    return Object.entries(channelMembers)
      .map((entry) => entry[1].user)
      .filter((user) => !isProducePayMember(user));
  }

  // Returns chat participants that have the role admin or a producepay email
  function getAdmins() {
    if (!channel?.state?.members) {
      return [];
    }

    const channelMembers = channel.state.members;
    return Object.entries(channelMembers)
      .map((entry) => entry[1].user)
      .filter((user) => isProducePayMember(user));
  }

  function getMemberRatingForChannel(member) {
    if (isProducePayMember(member)) return null;

    /* eslint-disable-next-line camelcase */
    const marketRequest = channel?.data?.market_request;
    if (!marketRequest) return null;

    /* eslint-disable-next-line camelcase */
    const isRequestOwner = marketRequest?.user_access_token === member.id;
    let memberRating;
    let memberType;

    if (isRequestOwner) {
      memberRating = marketRequest.request_type === 'demand' ? member.buyer_rating : member.seller_rating;
      memberType = marketRequest.request_type === 'demand' ? 'Buyer' : 'Seller';
    } else {
      memberRating = marketRequest.request_type === 'demand' ? member.seller_rating : member.buyer_rating;
      memberType = marketRequest.request_type === 'demand' ? 'Seller' : 'Buyer';
    }

    // Override the rating if user is not registered
    if (memberRating?.toLowerCase().includes('register')) {
      memberRating = 'Unverified';
    }

    return `${memberRating} ${memberType}`;
  }

  // Renders components for Member section of popout
  function renderMembers(members) {
    const marketRequest = channel.data.market_request;

    return members?.map((member) => {
      const memberTier = getMemberRatingForChannel(member);
      const translationKey = camelCase(memberTier);

      return (
        <div key={member.id} className="flex flex-row mt-4">
          <div className="flex flex-col mt-1">
            <div className="flex flex-row">
              <span className="flex flex-row font-bold items-center">{member.name || member.email}</span>
              {channel.state.membership.user.id === member.id && <span className="font-bold ml-1">({t('you')})</span>}
            </div>
            {/* Render User Badge */}
            {marketRequest != null && memberTier.startsWith('Gold Verified') && (
              <div className="flex flex-row mt-2">
                <div className="flex flex-row items-center px-2 text-gold-medium text-sm rounded bg-gold-light font-bold">
                  <img src={goldStar} alt="gold star" style={{ height: '16px' }} />
                  <span className="ml-2">{t(translationKey)}</span>
                </div>
              </div>
            )}
            {marketRequest != null && memberTier.startsWith('Verified') && (
              <div className="flex flex-row mt-2">
                <div className="flex items-center px-2 text-primary text-sm rounded bg-primary-light font-bold">
                  <img src={greenCheck} alt="green check" style={{ height: '8px' }} />
                  <span className="ml-2">{t(translationKey)}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    });
  }

  return (
    <>
      <CSSTransition in={showPopout} timeout={750} classNames="participants-background">
        <div className="participants-background" onClick={() => setShowPopout(false)} />
      </CSSTransition>
      <CSSTransition in={showPopout} timeout={750} classNames="participants">
        <div className={popoutClass}>
          <div className="flex flex-col m-4">
            <div className="flex flex-row">
              <div className="flex border-b-2 py-4 w-full">
                <h4 className="text-lg font-bold">{t('chatMembers')}</h4>
                <ChevronRight className="ml-auto cursor-pointer" onClick={() => setShowPopout(false)} />
              </div>
            </div>
            <div className="flex flex-row py-2 text-gray-500 mt-8">{t('member')}</div>
            {renderMembers(getMembers())}
            <div className="flex flex-row">
              <h4 className="text-gray-500 mt-10">{t('administrator')}</h4>
            </div>
            {getAdmins().map((adminUser) => {
              return (
                <div key={adminUser.id} className="flex flex-row mt-6">
                  <p className="font-bold">{adminUser.name}</p>
                </div>
              );
            })}
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default ParticipantPopOut;
