import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import _ from 'lodash';

import { Formik, Form } from 'formik';
import FormikGeneralErrors from '../../../../components/formik/FormikGeneralErrors';
import FormikSelect from '../../../../components/formik/FormikSelect';
import FormikTextField from '../../../../components/formik/FormikTextField';
import FollowupButtons from './FollowupButtons';
import OnboardingTitle from './OnboardingTitle';

function FollowupQuestion({
  title,
  questionDefinition,
  onboardingInformation,
  onSubmit,
  previousQuestion,
  skipQuestion,
}) {
  const { label, value, type, options, helperText, isRequired, allowCustomAnswer } = questionDefinition;

  const { t } = useTranslation(['onboarding', 'common', 'formErrors']);

  const commonProps = {
    name: value,
    label,
    helperText,
    shouldPrependErrors: false,
  };

  return (
    <Formik
      initialValues={{
        [value]: _.get(onboardingInformation, value, '') || '',
        [allowCustomAnswer?.name || 'disregard']: _.get(onboardingInformation, allowCustomAnswer?.name, '') || '',
      }}
      validationSchema={yup.object().shape({
        [value]: yup.string().nullable().required('This field is required'),
        ...(allowCustomAnswer
          ? {
              [allowCustomAnswer.name]: yup.string().when(value, {
                is: (v) => v === allowCustomAnswer.selectedValue,
                then: yup.string().required('This field is required'),
                otherwise: yup.string(),
              }),
            }
          : {}),
      })}
      onSubmit={(values, { setStatus, setSubmitting }) => {
        const isCustomAnswer = allowCustomAnswer?.selectedValue === values[value];

        onSubmit({
          [value]: isCustomAnswer ? values[allowCustomAnswer.name] : values[value],
        }).catch(() => {
          setSubmitting(false);
          setStatus({ errors: [t('formErrors:generalNetworkError')] });
        });
      }}
    >
      {({ values }) => (
        <Form>
          <OnboardingTitle title={title || t('title')} />

          <FormikGeneralErrors />

          {type === 'textfield' ? (
            <FormikTextField {...commonProps} />
          ) : type === 'select' ? (
            <>
              <FormikSelect
                {...commonProps}
                selectClassName="rounded"
                items={options}
                placeholder={t('common:selectOption')}
              />
              {allowCustomAnswer && allowCustomAnswer.selectedValue === values[value] && (
                <FormikTextField name={allowCustomAnswer.name} className="my-4" label={t('inventorySoftwareOther')} />
              )}
            </>
          ) : null}

          <FollowupButtons previousQuestion={previousQuestion} skipQuestion={isRequired ? null : skipQuestion} />
        </Form>
      )}
    </Formik>
  );
}

export default React.memo(FollowupQuestion);
