import React from 'react';
import PropTypes from 'prop-types';

const Search = (props) => {
  const { color, size, ...otherProps } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...otherProps}>
      <path
        d="M18.797 16.9098L13.0641 11.1769C13.839 10.1075 14.3025 8.79731 14.3025 7.378C14.3025 7.30527 14.2925 7.23556 14.2811 7.16632C14.2927 7.09615 14.3027 7.02528 14.3027 6.95137C14.3027 3.37047 11.3893 0.457031 7.80839 0.457031C7.74475 0.457031 7.68343 0.467056 7.62212 0.475682C7.56034 0.467056 7.49949 0.457031 7.43585 0.457031C3.85472 0.457031 0.941273 3.37047 0.941273 6.95137C0.941273 7.02411 0.951065 7.09382 0.962721 7.16329C0.950832 7.23346 0.94104 7.3041 0.94104 7.378C0.94104 10.9589 3.85425 13.8726 7.43538 13.8726C7.49903 13.8726 7.56011 13.8626 7.62189 13.8537C7.6832 13.8626 7.74405 13.8726 7.80793 13.8726C9.02208 13.8726 10.1558 13.5313 11.1289 12.9487L16.9434 18.7632C17.1996 19.0194 17.5349 19.1472 17.8703 19.1472C18.2058 19.1472 18.5415 19.0192 18.7973 18.7632C19.309 18.2515 19.309 17.4218 18.797 16.9098ZM7.62189 11.2699C7.56011 11.261 7.49926 11.251 7.43538 11.251C5.2999 11.251 3.5626 9.51372 3.5626 7.37824C3.5626 7.3055 3.55281 7.23579 3.54116 7.16655C3.55304 7.09638 3.56284 7.02551 3.56284 6.95161C3.56284 4.81612 5.30036 3.07883 7.43585 3.07883C7.49926 3.07883 7.56034 3.06904 7.62212 3.06041C7.68343 3.06904 7.74451 3.07883 7.80839 3.07883C9.94388 3.07883 11.6812 4.81612 11.6812 6.95161C11.6812 7.02434 11.6912 7.09405 11.7026 7.16352C11.691 7.2337 11.6807 7.30433 11.6807 7.37824C11.6807 9.51372 9.94341 11.251 7.80793 11.251C7.74405 11.251 7.68297 11.261 7.62189 11.2699Z"
        fill={color}
      />
    </svg>
  );
};

Search.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Search.defaultProps = {
  color: 'currentColor',
  size: '24',
};

export default Search;
