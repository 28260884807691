import React, { useState } from 'react';

import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import { Modal } from '@producepay/pp-ui';
import { useAuth } from '../../contexts/auth';
import routes from '../../routes';
import SignIn from './auth/SignIn';
import Register from './auth/Register';
import Verify from './auth/Verify';
import Onboarding from './auth/Onboarding';
import ForgotPassword from './auth/ForgotPassword';
import SetPassword from './auth/SetPassword';
import Complete from './auth/Complete';

function AuthModalRoute(props) {
  const { registerTitle, signInTitle, onboardingTitle, onComplete, registrationFunction, ...rest } = props;

  const { user, isAuthenticated, hasPassword, verificationToken } = useAuth();
  const history = useHistory();
  const { search } = useLocation();

  const { t } = useTranslation('marketInsights');

  const { should_resend_dmr: shouldResendDmr } = qs.parse(search, { ignoreQueryPrefix: true });

  // Cache the value to first load
  const [isAuthenticatedAndOnboarded] = useState(isAuthenticated && user?.isOnboarded);
  if (isAuthenticatedAndOnboarded) return null;

  return (
    <Route
      path={`/:pathPrefix+/${routes.authModal()}`}
      render={(props) => {
        const originalPath = `/${props?.match?.params?.pathPrefix}`;
        const urlHash = props?.location?.hash || '';
        const getPathForRoute = (routeFunc) => `${originalPath}/${routeFunc()}`;

        const closeModal = () => history.push(originalPath);
        const authFlowComplete = (...args) => {
          if (onComplete) onComplete(...args);
          closeModal();
        };

        const redirectPath = isAuthenticated
          ? getPathForRoute(routes.authModalOnboarding)
          : hasPassword
          ? getPathForRoute(routes.authModalSignIn)
          : verificationToken
          ? getPathForRoute(routes.authModalOnboarding)
          : getPathForRoute(routes.authModalRegister);

        return (
          <Modal {...rest} onClose={closeModal} widthClassName="sm:max-w-lg" data-testid="auth-modal">
            <Switch>
              <Route
                path={getPathForRoute(routes.authModalSignIn)}
                render={() => (
                  <SignIn
                    title={signInTitle}
                    onLogin={(user) => {
                      if (user?.isOnboarded) {
                        authFlowComplete();
                      } else {
                        history.push(getPathForRoute(routes.authModalOnboarding));
                      }
                    }}
                    basePath={originalPath}
                  />
                )}
              />

              <Route
                path={getPathForRoute(routes.authModalRegister)}
                render={() => (
                  <Register
                    title={registerTitle}
                    registrationFunction={registrationFunction}
                    onRegister={(user) => {
                      const routeFuncToGoTo = user?.isOnboarded ? routes.authModalVerify : routes.authModalOnboarding;
                      history.push(getPathForRoute(routeFuncToGoTo));
                    }}
                    basePath={originalPath}
                  />
                )}
              />

              <Route
                path={getPathForRoute(routes.authModalOnboarding)}
                render={() => (
                  <Onboarding
                    title={shouldResendDmr ? t('authModal.setupAccountToReceiveFullDmr') : onboardingTitle}
                    onOnboardingCompleted={() => {
                      if (hasPassword) {
                        authFlowComplete();
                      } else if (verificationToken) {
                        history.push({
                          pathname: `${originalPath}/${routes.authModalSetPassword(verificationToken)}`,
                          search,
                        });
                      } else {
                        history.push(getPathForRoute(routes.authModalVerify));
                      }
                    }}
                  />
                )}
              />

              <Route
                path={getPathForRoute(routes.authModalVerify)}
                render={() => (
                  <Verify
                    onVerified={(token) => {
                      history.push(`${originalPath}/${routes.authModalSetPassword(token)}`);
                    }}
                  />
                )}
              />

              <Route
                path={getPathForRoute(routes.authModalSetPassword)}
                render={() => (
                  <SetPassword
                    mode="create"
                    onPasswordSet={() => {
                      if (shouldResendDmr) {
                        history.push(getPathForRoute(routes.authModalDmrSent));
                      } else {
                        authFlowComplete();
                      }
                    }}
                    shouldResendDmr={!!shouldResendDmr}
                  />
                )}
              />

              <Route
                path={getPathForRoute(routes.authModalForgotPassword)}
                render={() => <ForgotPassword basePath={originalPath} />}
              />

              <Route
                path={getPathForRoute(routes.authModalDmrSent)}
                render={() => <Complete onClose={() => authFlowComplete()} />}
              />

              <Redirect
                to={{
                  pathname: `${redirectPath}${urlHash}`,
                  search,
                }}
              />
            </Switch>
          </Modal>
        );
      }}
    />
  );
}

export default AuthModalRoute;
