import React from 'react';

import Div100vh from 'react-div-100vh';
import { Card } from '@producepay/pp-ui';
import { useAuth } from '../../../contexts/auth';
import { useResponsiveLayout } from '../../../helpers/dom';

import LanguagePicker from '../../molecules/LanguagePicker';

import PPInsights from './PPInsights';

const WrappedLanguagePicker = ({ accessToken }) => (
  <div className="mt-6 flex justify-center">
    <LanguagePicker accessToken={accessToken} />
  </div>
);

function FocusedLayout(props) {
  const { children, shouldShowLanguagePicker } = props;

  const { isMobile } = useResponsiveLayout();
  const { unauthenticatedUser } = useAuth();
  const accessToken = unauthenticatedUser?.token;

  return (
    <Div100vh className="flex flex-col items-center" style={{ height: 'auto', minHeight: '100rvh' }}>
      {!isMobile && <PPInsights className="py-12" />}

      <Card
        className={[
          'pt-5 md:pt-10 px-6 md:px-12 pb-6 md:pb-12',
          'w-full sm:max-w-lg mx-auto flex-1 sm:flex-initial',
          'flex flex-col items-center',
        ].join(' ')}
      >
        {isMobile && <PPInsights className="pb-6" />}

        <div className="w-full">{children}</div>

        {shouldShowLanguagePicker && isMobile && <WrappedLanguagePicker accessToken={accessToken} />}
      </Card>

      {shouldShowLanguagePicker && !isMobile && <WrappedLanguagePicker accessToken={accessToken} />}
    </Div100vh>
  );
}

export default React.memo(FocusedLayout);
