import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import cx from 'classnames';
import { Field } from 'formik';

import './formik-toggle.css';

function FormikToggle(props) {
  const { name, value, label, className } = props;
  const { t } = useTranslation('formFields');
  const id = `${name}[${value}]`;

  return (
    <span className={cx('flex items-center cursor-pointer toggle-container ', className)}>
      <Field className="toggle-checkbox opacity-0" name={name} value={value} type="checkbox" id={id} />
      <label
        htmlFor={id}
        className="toggle-label text-base text-primary-dark text-center h-full w-full pt-2 border border-primary-dark rounded"
      >
        {label || t([`formFields:${name}`, _.startCase(name)])}
      </label>
    </span>
  );
}

FormikToggle.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default React.memo(FormikToggle);
