import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import _ from 'lodash';

import { Formik, Form } from 'formik';
import { Button, FormLabel, TextField } from '@producepay/pp-ui';
import FormikGeneralErrors from '../../../../components/formik/FormikGeneralErrors';
import { extractGraphqlErrorMessages } from '../../../../helpers/common';

const RESET_PASSWORD_SCHEMA = yup.object().shape({
  password: yup.string().min(8, 'must be at least 8 characters').required('is required'),
});

export default ({ header, resetPasswordRequest, token, shouldResendDmr }) => {
  const [showPassword, setShowPassword] = useState(false);

  const { t } = useTranslation(['authSetPassword', 'common', 'formFields', 'formErrors']);

  return (
    <Formik
      initialValues={{ password: '' }}
      validationSchema={RESET_PASSWORD_SCHEMA}
      onSubmit={(values, { setStatus, setSubmitting }) => {
        setStatus(undefined);

        resetPasswordRequest({ variables: { ...values, token, shouldResendDmr } }).catch((e) => {
          const graphqlErrors = extractGraphqlErrorMessages(e);
          const resolvedErrors = _.isEmpty(graphqlErrors) ? [t('formErrors:generalNetworkError')] : graphqlErrors;

          setSubmitting(false);
          setStatus({ errors: resolvedErrors });
        });
      }}
    >
      {({ isSubmitting, values, errors, touched, setFieldValue }) => (
        <Form className="flex flex-col items-center">
          <h3 className="text-xl font-bold">{header}</h3>

          <div className="w-full mt-10 mb-8">
            <div className="flex">
              <FormLabel className="w-full" label={t('formFields:password')}>
                <TextField
                  className="rounded-r-none"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  value={values?.password}
                  onChange={(e) => setFieldValue('password', e.target.value)}
                />
              </FormLabel>

              <div
                className={[
                  'flex items-center justify-center p-4 cursor-pointer',
                  'self-end text-xs font-bold uppercase',
                  'rounded rounded-l-none border-t border-b border-r',
                ].join(' ')}
                style={{ height: 40, width: 80, background: '#F1F5F8', color: '#959DAC' }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? t('common:hide') : t('common:show')}
              </div>
            </div>

            {touched?.password && errors?.password ? (
              <div className="mt-1 text-red-700 text-xs">
                {t('formFields:password')} {t(`formErrors:${errors?.password}`)}
              </div>
            ) : (
              <div className="mt-1 text-xs text-gray-medium">
                {_.upperFirst(t('formErrors:must be at least 8 characters'))}
              </div>
            )}
          </div>

          <FormikGeneralErrors />

          <Button type="submit" className="w-40" disabled={isSubmitting}>
            {t('common:next')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
