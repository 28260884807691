import React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { Modal, Button } from '@producepay/pp-ui';

import FormikGeneralErrors from '../../../../components/formik/FormikGeneralErrors';
import FormikTextField from '../../../../components/formik/FormikTextField';

import ProgressBar from '../ProgressBar';
import { EMAIL_STEP, TOTAL_STEPS } from '../helpers';

const EMAIL_VALIDATION_SCHEMA = yup.object().shape({
  email: yup.string().email('is invalid').required('is required'),
});

function EmailStep({ user, onSubmit, modalProps, isMobile }) {
  const { t } = useTranslation('marketplaceListSupply');
  const progressPercent = (EMAIL_STEP / TOTAL_STEPS) * 100;

  return (
    <Formik initialValues={{ email: user?.email }} validationSchema={EMAIL_VALIDATION_SCHEMA} onSubmit={onSubmit}>
      {({ values, handleSubmit, isSubmitting }) => (
        <Modal
          className="mt-20"
          title={t('title')}
          headerContent={t('subtitle')}
          footerContent={
            <>
              <ProgressBar progressPercent={progressPercent} />
              <div className="flex w-full p-4 justify-end border-t">
                <Button
                  label={t('common:continue')}
                  type="submit"
                  onClick={() => handleSubmit(values)}
                  disabled={isSubmitting}
                  className={isMobile ? 'w-full' : ''}
                  data-testid="btn-submit"
                />
              </div>
            </>
          }
          {...modalProps}
        >
          <FormikGeneralErrors />

          <Form>
            <FormikTextField
              name="email"
              type="email"
              label={t('formFields:email')}
              placeholder="my@email.com"
              data-testid="input-email"
            />
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

export default EmailStep;
