import React, { useReducer, useEffect, useContext, useCallback } from 'react';
import _ from 'lodash';

import useStorage from '../../hooks/useStorage';
import { trackEvent, KEYS } from '../../helpers/tracking';

import { FILTER_CONTEXT_ACTION_TYPES, DATE_RANGE_PRESET_OPTIONS } from './helpers';
import { formatEsNameAndId } from '../../helpers/format';
import { parseISOWithFallback } from '../../helpers/dates';

const LAST_365_DAYS_OPTION = _.find(DATE_RANGE_PRESET_OPTIONS, { key: 'last_365_days' });

const graphqlFiltersReducer = (state, action) => {
  trackEvent(KEYS.INSIGHTS_PRO_FILTER_CHANGED);
  switch (action.type) {
    case FILTER_CONTEXT_ACTION_TYPES.COMMODITIES: {
      return {
        ...state,
        commodityVarietyName: action.selectedItems,
        commodityVarietyIdentifierPairs: _.map(action.selectedItems, (item) => item.cvPair),
      };
    }
    case FILTER_CONTEXT_ACTION_TYPES.SIZE:
      return { ...state, sizeName: action.selectedItems };
    case FILTER_CONTEXT_ACTION_TYPES.PACKAGING:
      return { ...state, packagingName: action.selectedItems };
    case FILTER_CONTEXT_ACTION_TYPES.GRADE:
      return { ...state, gradeName: action.selectedItems };
    case FILTER_CONTEXT_ACTION_TYPES.LABEL:
      return { ...state, labelName: action.selectedItems };
    case FILTER_CONTEXT_ACTION_TYPES.CUSTOMER:
      return { ...state, erpCustomerId: action.selectedItems };
    case FILTER_CONTEXT_ACTION_TYPES.GROWER:
      return { ...state, erpGrowerId: action.selectedItems };
    case FILTER_CONTEXT_ACTION_TYPES.SALES_PERSON:
      return { ...state, salespersonName: action.selectedItems };
    case FILTER_CONTEXT_ACTION_TYPES.COUNTRY_OF_ORIGIN:
      return { ...state, shippedFromCountry: action.selectedItems };
    case FILTER_CONTEXT_ACTION_TYPES.DATE_RANGE: {
      return { ...state, ..._.pick(action, ['dateKey', 'startDate', 'endDate']) };
    }
    case FILTER_CONTEXT_ACTION_TYPES.RESET: {
      return _.pick(state, ['dateKey', 'startDate', 'endDate']);
    }
    case FILTER_CONTEXT_ACTION_TYPES.USDA_COMMODITY_PACKAGE: {
      return { ...state, commodityPackage: action.commodityPackage };
    }
    default: {
      throw new Error();
    }
  }
};

const FilterStateContext = React.createContext();

function FilterStateProvider(props) {
  const { children } = props;

  const [sessionFilters, setSessionFilters] = useStorage('filters', {}, window.localStorage);
  const startingDateKey = sessionFilters.dateKey || 'last_365_days';

  let datePropOverrides = {};
  if (startingDateKey === 'custom') {
    datePropOverrides = {
      dateKey: 'custom',
      startDate: parseISOWithFallback(sessionFilters.startDate),
      endDate: parseISOWithFallback(sessionFilters.endDate),
    };
  } else {
    const presetOption = _.find(DATE_RANGE_PRESET_OPTIONS, { key: startingDateKey }) || LAST_365_DAYS_OPTION;
    datePropOverrides = { dateKey: startingDateKey, startDate: presetOption.start, endDate: presetOption.end };
  }

  const [state, dispatch] = useReducer(graphqlFiltersReducer, { ...sessionFilters, ...datePropOverrides });

  useEffect(() => {
    setSessionFilters(state);
  }, [state, setSessionFilters]);

  const handleDateRangeSelected = useCallback(
    (dateKey, from, to) => {
      dispatch({
        type: FILTER_CONTEXT_ACTION_TYPES.DATE_RANGE,
        dateKey,
        startDate: from,
        endDate: to,
      });
    },
    [dispatch],
  );

  const transFormFiltersToGqlVariables = useCallback(() => {
    const {
      dateKey,
      startDate,
      endDate,
      commodityPackage,
      commodityVarietyIdentifierPairs,
      erpCustomerId,
      erpGrowerId,
      ...otherFilters
    } = state;

    return {
      startDate,
      endDate,
      ..._.omitBy(
        _.mapValues(otherFilters, (items) => _.map(items, 'value')),
        (values) => values.length === 0,
      ),
      ...(!_.isEmpty(erpCustomerId)
        ? { erpCustomerNameAndId: _.map(erpCustomerId, (item) => formatEsNameAndId(item.label, item.value)) }
        : {}),
      ...(!_.isEmpty(erpGrowerId)
        ? { erpGrowerNameAndId: _.map(erpGrowerId, (item) => formatEsNameAndId(item.label, item.value)) }
        : {}),
    };
  }, [state]);

  return (
    <FilterStateContext.Provider
      value={{
        gqlFilterVariables: transFormFiltersToGqlVariables(), // elastic search soli_data _search queries
        filterValues: state,
        dispatch,
        handleDateRangeSelected,
        resetFilters: () => dispatch({ type: FILTER_CONTEXT_ACTION_TYPES.RESET }),
      }}
    >
      {children}
    </FilterStateContext.Provider>
  );
}

function useFilterState() {
  return useContext(FilterStateContext);
}

const WrappedFilterStateProvider = React.memo(FilterStateProvider);

export { WrappedFilterStateProvider as FilterStateProvider, useFilterState };
