import React from 'react';
import PropTypes from 'prop-types';
import { filter, map } from 'lodash';

import { LoadingSpinner } from '@producepay/pp-ui';

import { textSearchCompareByWord } from '../../../helpers/common';
import { useResponsiveLayout } from '../../../helpers/dom';

import MarketRequestCard from '../partials/MarketRequestCard';
import EmptyState from '../partials/EmptyState';
import CTA from '../partials/MarketplaceCTA';

function MarketplaceSupplyView({ isUserUnknown, toggleKey, loading, loads, searchText }) {
  const { isMobileOrTablet } = useResponsiveLayout();
  const filteredLoads = loading
    ? []
    : searchText === ''
    ? loads
    : filter(loads, (load) =>
        textSearchCompareByWord(searchText, load.commodityVarietyInfo?.name || load.customCommodityVarietyName),
      );

  const isSearching = searchText !== '' && filteredLoads.length === 0 && loads.length > 0;

  return (
    <>
      <div className="p-4 lg:px-0 lg:py-8 min-h-screen-wo-mobile-navbar">
        {loading ? (
          <div className="text-center">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            {map(filteredLoads, (load) => {
              return <MarketRequestCard key={load.id} isSupply load={load} source="marketplace-supply" />;
            })}
            {filteredLoads.length === 0 && (
              <EmptyState isUserUnknown={isUserUnknown} toggleKey={toggleKey} isSearching={isSearching} />
            )}
          </>
        )}
      </div>
      {isMobileOrTablet && <CTA isSupply />}
    </>
  );
}

MarketplaceSupplyView.propTypes = {
  isUserUnknown: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  loads: PropTypes.arrayOf(PropTypes.object),
  searchText: PropTypes.string.isRequired,
};

MarketplaceSupplyView.defaultProps = {
  loads: [],
};

export default MarketplaceSupplyView;
