import { gql } from '@apollo/client';

export const ORDERS_BY_CHAT_ID = gql`
  query getOrdersByChatId($chatId: String!) {
    ordersByChatId(chatId: $chatId) {
      id
      referenceNumber
      shipDate
      orderStatus
      inventoryItems {
        pricePerUnit
        quantity
      }
      awaitingActions
    }
  }
`;
